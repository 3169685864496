import React, { useEffect, useState } from 'react'
import { _l } from '../../hooks/utilities'
import moment from 'moment';
import { json } from 'react-router-dom';

const ProviderContractTable = ({
    data
}) => {
    const [contractData, setContractData] = useState({});
    const [spaceName, setSpaceName] = useState("");
    const [customFields, setCustomFields] = useState([]);
    useEffect(() => {
        if(data.documentData){
            setContractData(data.documentData);
            const spacesData = localStorage.getItem("spaces");
            if (spacesData) {
                var spacelist = JSON.parse(spacesData);
                const selectedSpace = spacelist.find((s) => s.project_id === data.documentData.project_id);
                if(selectedSpace){
                    setSpaceName(selectedSpace.office_title)
                }
            }
            if(data.documentData.custom_fields && data.documentData.custom_fields !== ""){
                // setCustomFields(JSON.parse(data.documentData.custom_fields))
                const parsedFields = JSON.parse(data.documentData.custom_fields);
                if (Array.isArray(parsedFields)) {
                    setCustomFields(parsedFields);
                }  else if (typeof parsedFields === 'object') {
                    setCustomFields([parsedFields]);
                }
            }
        }
    }, [data]);

    return (
        <div className="p-10">
            <div className="row">
                <div className="col-xl-12 ">
                    <div className='comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1'>
                        <table className='dataTable text-center tablewithborder'>
                            <thead className='comman-white-box-shadow'>
                                <tr>
                                    <th className="text-start" style={{ width: "20%" }} >{_l('l_field_name')}</th>
                                    <th className="text-start" style={{ width: "80%" }} >{_l('l_field_value')}</th>
                                </tr>
                            </thead>
                            <tbody style={{ verticalAlign: "middle" }}>

                                <tr>
                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_document_title')} </td>
                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                        <input 
                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                            value={contractData.document_name}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_space_link')} </td>
                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                        <input 
                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                            value={spaceName}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_provider_company_name')} </td>
                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                        <input 
                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                            value={contractData.provider_company_name}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_start_date')} </td>
                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                        <input 
                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                            value={contractData.start_date && moment(contractData.start_date).format("DD/MM/YY")}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_end_date')} </td>
                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                        <input 
                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                            value={contractData.end_date && moment(contractData.end_date).format("DD/MM/YY")}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_notice_date')} </td>
                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                        <input 
                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                            value={contractData.provider_notice_detail && contractData.provider_notice_detail.map(item => moment(item.notice_date).format("DD/MM/YY")).join(", ")}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_termination_conditions')} </td>
                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                        <input 
                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                            value={contractData.description}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        {customFields.length > 0 ?
                            <>
                                <div className="c-font f-20 fw-semibold mb-4">Custom fields</div>
                                <table className='dataTable text-center tablewithborder'>
                                    <thead className='comman-white-box-shadow'>
                                        <tr>
                                            <th className="text-start" style={{ width: "20%" }} >{_l('l_field_name')}</th>
                                            <th className="text-start" style={{ width: "80%" }} >{_l('l_field_value')}</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        {customFields.map((item) => {
                                            return (
                                                <tr>
                                                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{item.field_name} </td>
                                                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                                                        <input 
                                                            className={`form-control c-font f-13  color-white-80 text-truncate p-10 "bg-transparent`}
                                                            value={item.field_value}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProviderContractTable