import { EQUIPMENT_DETAIL_DATA, STAFF_DETAIL } from "../actions/action-type";
import {
  ADD_DOCUMENT_COMMENT,
  GET_COMMENTS,
  REMOVE_DOCUMENT_COMMENT,
  GET_SUBSCRIPTION_DETAILS,
  GET_ESTIMATE_DETAILS,
  GET_DOCUMENT_DETAILS,
  GET_INVOICE_DETAILS,
  GET_CREDIT_NOTE_DETAILS,
  GET_ONBOARD_DETAILS,
  GET_ONBOARD_PROVIDER_LIST,
  GET_ONBOARD_DOCUMENT_LIST,
  GET_DOCUMENT_TYPE,
  SET_PROVIDER_CONTRACT,
  SET_CONTRACT_DATA,
} from "../actions/documents-actions/documents-action-types";

const initialState = {
  estimate_comments: [{ id: "0", hash: "0", comments: [] }],
  subscription_comments: [{ id: "0", hash: "0", comments: [] }],
  invoice_comments: [{ id: "0", hash: "0", comments: [] }],
  credit_note_comments: [{ id: "0", hash: "0", comments: [] }],
  schedule_comments: [{ id: "0", hash: "0", comments: [] }],
  benchmark_comments: [{ id: "0", hash: "0", comments: [] }],
  proposal_comments: [{ id: "0", hash: "0", comments: [] }],
  task: [{ id: "0", hash: "0", comments: [] }],
  gmail_comments: [{ id: "0", hash: "0", comments: [] }],
  subscription_details: {},
  estimate_details: {},
  invoice_details: {},
  space_document_details:{},
  credit_note_details: {},
  provider_contract: {},
  all_contract_data: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  const full_name = localStorage.getItem("full_name");
  const profile_url = localStorage.getItem("contact_image");
  const staffid = localStorage.getItem("staff_id");
  const guestId = localStorage.getItem("guest_id");
  let index;

  switch (type) {
    case ADD_DOCUMENT_COMMENT:
      //Mandatory Values
      let { rel_id, rel_type, content, comment_id, data, coOrdinates } = payload;
      let existingComments = [];

      index = state[`${rel_type}_comments`].findIndex((x) => x.id == rel_id);
      if (index == -1) {
        index = 0;
        existingComments.push({
          id: rel_id,
          comments: [
            {
              content: content,
              full_name: full_name,
              addedfrom_details: { profile_url },
              is_allowed_to_edit: 1,
              guestId,
              staffid,
              position : coOrdinates
            },
          ],
        });
      } else {
        let position = state[`${rel_type}_comments`][index].comments.findIndex(
          (x) => x.id == payload.comment_id
        );
        let attachmentsPush = [];
        if (data.files_list && data.files_list.length) {
          data.files_list.forEach(file => {
            file['file_name'] = file.image_url

            attachmentsPush.push(file)
          });
        }
        //Add new comment
        existingComments = state[`${rel_type}_comments`];
        if (position == -1 && comment_id == 0) {

          existingComments[index].comments.push({
            content: content,
            full_name: full_name,
            addedfrom_details: { profile_url },
            is_allowed_to_edit: 1,
            guestId,
            staffid,
            id: data.comment_id,
            attachments: attachmentsPush,
            position : coOrdinates
          });
        }
        //Edit comment
        else {
          existingComments[index].comments[position].content = content;
          existingComments[index].comments[position].files_list = attachmentsPush
        }
      }

      return {
        ...state,
        [`${rel_type}_comments`]: existingComments
      };

    // case GET_MENTION_LIST:
    //   return {
    //     ...state,
    //     mentionList: payload.data,
    //   };

    case GET_COMMENTS:

      let comments = [] 
      if (payload.rel_type == "task" || payload.rel_type == "request-task") {
        comments = state[`${(payload.rel_type).replace("-" , "_")}`];
      } else {
        comments = state[`${payload.rel_type}_comments`] || [];
      }

      if(comments) {
        index = comments.findIndex((x) => x.id == payload.id);
        if (index !== -1) {
          comments.splice(index, 1);
        }
      }
      
      comments && comments.push({
        id: payload.id,
        comments: payload.comments,
      });
      return {
        ...state,
        [`${payload.rel_type}_comments`]: comments,
      };

    case REMOVE_DOCUMENT_COMMENT:
      let updatedCommetns = state[`${payload.rel_type}_comments`];
      index = state[`${payload.rel_type}_comments`].findIndex((x) => x.id == payload.rel_id);
      updatedCommetns[index].comments = state[`${payload.rel_type}_comments`][index].comments.filter(x => x.id != payload.comment_id)


      return { ...state, [`${payload.rel_type}_comments`]: updatedCommetns }

    case GET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscription_details: payload,
      };
    case GET_ESTIMATE_DETAILS:
      return {
        ...state,
        estimate_details: payload,
      };
      case GET_ESTIMATE_DETAILS:
      return {
        ...state,
        space_document_details: payload,
      };
    case GET_ONBOARD_DETAILS:
      return {
        ...state,
        getOnboardDocument: payload,
      };
    case GET_DOCUMENT_DETAILS:
      return {
        ...state,
        document_details: payload,
      };
    case GET_INVOICE_DETAILS:
      return {
        ...state,
        invoice_details: payload,
      };
    case GET_ONBOARD_DOCUMENT_LIST:
        return {
          ...state,
          onBoardDocumentList: payload,
        };
    case GET_ONBOARD_PROVIDER_LIST:
      return {
        ...state,
        onBoardProviderList: payload,
      };
    case GET_DOCUMENT_TYPE:
      return {
        ...state,
        ducumentType: payload,
      };
      
    case GET_CREDIT_NOTE_DETAILS:
      return {
        ...state,
        credit_note_details: {...payload.data, id: payload.id},
      };
    case STAFF_DETAIL:
      {
        return {
          ...state,
          staffData: payload,
        }
      }
    case EQUIPMENT_DETAIL_DATA:
      {
        return {
          ...state,
          equipmentData: payload,
        }
      }
    case SET_PROVIDER_CONTRACT:
      {
        return {
          ...state,
          provider_contract: payload,
        }
      }
      case SET_CONTRACT_DATA:
        {
          return {
            ...state,
            all_contract_data: payload,
          }
        }
    default:
      return state;
  }
}
