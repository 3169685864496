import React, { useRef, useEffect, useState } from "react";
import {
  _l,
  adjustDynamicInputHeight,
  getDatePickerLocale,
  getDatePickerTimeCaption,
  removeDuplicateObjects,
  selectedAssigneeForTaskAndRequest,
  showError,
  removeDuplicateImgs,
  TaskModalClose,
  getFileType,
} from "../../hooks/utilities";
import DatePicker from "react-datepicker";
import HeaderFilterDropdown from "../TaskModalComponents/HeaderFilterDropdown";
import TaskListStatusDropdown from "../Dropdowns/TaskListStatusDropdown";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Button, Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import CreateTaskViewDropdown from "../Dropdowns/CreateTaskViewDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaskCount,
  getTaskList,
  isBackDrop,
  projectTaskCounts,
  setCreateTaskModalIsopen,
  commanoffcanvasisOpen,
  getArLocalAnchors,
  isCreareTaskDefaultTopics,
  setEquipmentConsumbleCheckList,
  updateTaskList,
  setTaskAssigneeNew,
} from "../../actions/customer";
import CommentMinified from "../Comments/CommentMinified";
import moment from "moment";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../actions/messages";
import CustomerServices from "../../services/customer-services";
import SpaceListDropdown from "../Dropdowns/SpaceListDropdown";
import benchmarkServices from "../../services/benchmark-services";
import jquery from "jquery";
import { FloorPlanSummary } from "../SpaceOnBoarding/SpaceDetails/FloorPlanSummary";
import FollowersDropdown from "../Dropdowns/FollowersDropdown";
import CommentDropdown from "../Dropdowns/CommentDropdown";
import DeleteDropdown from "../Dropdowns/DeleteDropdown";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { getFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import BenchmarkServices from "../../services/benchmark-services";
import CreateChecklistTemplate from "../Modals/CreateChecklistTemplate";
import { CUSTOM_ASSIGNEE_CHANGE } from "../../constants/constants";
import customerServices from "../../services/customer-services";
import CheckListTemplates from "../Dropdowns/CheckListTemplates";
import AddAssigneeMainDropdownNew from "../TaskModalComponents/AddAssigneeMainDropdownNew";
import CommanOffCanvas from "./CommanOffCanvas";
import Lightbox from "yet-another-react-lightbox";
import UnControlMainFiltersDropDown from "../TaskModalComponents/LeadsMainFiltersDropDown";
import UnControlSubFiltersDropdown from "../TaskModalComponents/LeadsSubFiltersDropdown";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import BuildingsIcon from "../../assets/icons/BuildingsIcon";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import CaretLeftIcon from "../../assets/icons/CaretLeftIcon";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import ChatCircleIcons from "../../assets/icons/ChatCircleIcons";
import CheckIcon from "../../assets/icons/CheckIcon";
import CloudArrowUpIcon from "../../assets/icons/CloudArrowUpIcon";
import DotsNineIcon from "../../assets/icons/DotsNineIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
import FunnelIcon from "../../assets/icons/FunnelIcon";
import ListChecksIcon from "../../assets/icons/ListChecksIcon";
import MapPinIcon from "../../assets/icons/MapPinIcon";
import PaperclipIcon from "../../assets/icons/PaperclipIcon";
import PushPinFillIcon from "../../assets/icons/PushPinFillIcon";
import PushPinIcon from "../../assets/icons/PushPinIcon";
import SelectionPlusIcon from "../../assets/icons/SelectionPlusIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
import XIcon from "../../assets/icons/XIcon";
import XCircleIcon from "../../assets/icons/XCircleIcon";
import CubeIcon from "../../assets/icons/CubeIcon";
import WrenchIcon from "../../assets/icons/WrenchIcon";
let showComments = true;
const CreateTaskOffCanvasNew = ({
  taskTitle,
  setTaskTitle,
  setshowTaskList = () => {},
  pageName = "",
  isFromLiteVersion = false,
  handleClose = () => {},
  isRequest = false,
  from = "",
  is_task = false,
  is_fromCalendar = false,
  createtaskDate = new Date(),
  createTaskType = ""
}) => {
  const contact_id = localStorage.getItem("contact_id");
  const project_id = localStorage.getItem("selectedOffice");
  const contact_role = localStorage.getItem("contact_role");
  const userType = localStorage.getItem("user_type");
  const full_name = localStorage.getItem("full_name");
  const spaceState = useSelector((state) => state.customer.spaces);
  const {
    defaultTopics,
    userTeams,
    selectedProject,
    taskFilters,
    activeTaskStatusFilters,
    getSelectedFloorId,
    get_Ar_Local_Anchors,
    setPOILatLng,
    getActivePOIList,
    isBackdrop,
    createTaskModalIsopen,
    globalView,
    isCreatesTaskDefaultTopics,
    leftPanelTab,
    taskList
  } = useSelector((state) => state.customer);
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  const dispatch = useDispatch();
  const taskNameInputHeight = useRef();
  const taskCheckListInputHeight = useRef();
  const taskTitleInput = useRef();
  const [loginUserImage, setLoginUserImage] = useState(
    localStorage.getItem("contact_image")
  );
  const [inputActionIconShow, setinputActionIconShow] = useState(false);
  const [openSpaceListDropdown, setOpenSpaceListDropdown] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState({});
  const [calendarDateChanged, setcalendarDateChanged] = useState(false);
  const [calendarEndDateChanged, setCalendarEndDateChanged] = useState(false);
  const [startDate, setStartDate] = useState(
    is_fromCalendar ? createtaskDate : new Date()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [dropDownList, setdropDownList] = useState([]);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState([]);
  const [selectedAssignee] = useState([]);
  const [selectedAssigneeNew, setSelectedAssigneeNew] = useState([]);
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const [taskStatus, setTaskStatus] = useState(isRequest || contact_role == 3 ? 0 : 1);
  const [taskPriority, setTaskPriority] = useState(2);
  const [commentsList, setCommentsList] = useState([]);
  const [CommentFile, setCommentFile] = useState([]);
  const [taskPinned, setTaskPinned] = useState(false);
  const [loder, setLoder] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistFiles, setchecklistFiles] = useState([]);
  const [checklistMode, setChecklistMode] = useState("classic");
  const [selectedFloor, setSelectedFloor] = useState({ id: -1, title: "" });
  const [showFloorList, setShowFloorList] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [selectedTypeOfSpace, setSelectedTypeOfSpace] = useState({ id: -1 });
  const [openFollowersDropdown, setOpenFollowersDropdown] = useState(false);
  const [checklistItemAssigneeList, setChecklistItemAssigneeList] = useState(
    []
  );
  const [openCommentTab, setOpenCommentTab] = useState(false);
  const [commentCount, setCommentCount] = useState([]);
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [taskAssignee, setTaskAssignee] = useState([]);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [requestTitle, setRequestTitle] = useState("");
  const [showChecklistTemplateModal, setShowChecklistTemplateModal] =
    useState(false);
  const [defaultSubMenuOpen, setDefaultSubMenuOpen] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [x, setX] = useState("0");
  const [y, setY] = useState("0");
  const [z, setZ] = useState("0");
  const [pointId, setPointId] = useState("0");
  const [VisibleToCustomer, setVisibleToCustomer] = useState("0");
  const [VisibleToProvider, setVisibleToProvider] = useState("0");
  const [benchmarkCreate, setBenchmarkCreate] = useState("0");
  const [templateLoder, setTemplateLoder] = useState(false);
  const [openCheckListTemplate, setopenCheckListTemplate] = useState(false);
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };
  const [amount, setAmount] = useState("");
  const [showDefaultIcons, setShowDefaultIcons] = useState(["calendar"]);
  const [showCheckListArea, setShowCheckListArea] = useState(false);
  const [showCheckListIcons, setShowCheckListIcons] = useState(-1);
  const [taskAttachments, setTaskAttachments] = useState([]);
  const [allAttachmentsOfTask, setallAttachmentsOfTask] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [openpdfGallery, setOpenpdfGallery] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [previewImages, setpreviewImages] = useState([]);
  const [selectedTypeOfSpaceItem, setSelectedTypeOfSpaceItem] = useState();
  const [userSelectedStatus, setUserSelectedStatus] = useState(-1);
  const [lockTaskStatus, setLockTaskStatus] = useState(contact_role == 3);
  const [isBlankTitle, setIsBlankTitle] = useState(false);
  const [createTaskStep, setCreateTaskStep] = useState("first")
  const [previewSrc, setPreviewSrc] = useState([{src : '', type : ''}])
  const [euipmentModals, seteuipmentModals] = useState(-1)
  const [selectedSpaceProjectId, setSelectedSpaceProjectId] = useState([])
  const [createTaskTitle, setCreateTaskTitle] = useState("");
  let tempTopic = [];
  const handleSelectedSpace = (space) => {
    if (Object.keys(selectedSpace).length > 0) {
      if (space.project_id == selectedSpace.project_id) {
        localStorage.removeItem("selectedOffice");
        setSelectedSpace({});
      } else {
        setSelectedSpace(space);
        localStorage.setItem("selectedOffice", space.project_id);
      }
    } else {
      setSelectedSpace(space);
      localStorage.setItem("selectedOffice", space.project_id);
    }
  };
  const changeTaskStatus = (statusId) => {
    if (statusId != "task_convert_to_request") {
      setTaskStatus(statusId);
      setUserSelectedStatus(statusId);
    }
  };
  const handleAddEditComment = async (content, files, id) => {
    if (id > 0) {
      const commentIndex = commentsList.findIndex((w) => w.id == id);
      let tempComment = commentsList;
      tempComment[commentIndex].content = content;
      setCommentsList([...commentsList, tempComment]);
    } else {
      const staffid = localStorage.getItem("staff_id");
      const profile_url = localStorage.getItem("contact_image");
      let attachments = [];
      if (files && files.length <= 5) {
        let commentsfiles = [];
        Array.from(files).map((file) => {
          let tempImg = file;
          tempImg.task_key =
            "task_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss");
          commentsfiles.push(tempImg);
          attachments.push({
            file_url: URL.createObjectURL(file),
            file_name: file.name,
            task_key:
              "task_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss"),
          });
        });
        setCommentFile(CommentFile.concat(commentsfiles));
      } else {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_maximum_5_files_are_allowed_at_a_time")
          )
        );
      }
      setCommentsList(
        commentsList.concat([
          {
            id: commentsList.length + 1,
            content: content,
            contact_id: contact_id,
            staffid: staffid,
            full_name: full_name,
            profile_url: profile_url,
            attachments: attachments,
            date: moment().fromNow(),
            task_key:
              "task_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss"),
          },
        ])
      );
    }
  };
  const handleDeleteComment = (comment) => {
    setCommentsList(commentsList.filter((x) => x.content != comment.content));
  };
  const handleCreateTask = () => {
    if (formValidations()) {
      let title_name = createTaskTitle ? createTaskTitle : autoNameHandler();
      let sub_categories = [];
      let sub_category = selectedAssignee.filter(
        (s) =>
          s.main_key == "equipments" ||
          (s.main_key == "provider_task" &&
            userType == "contact" &&
            CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))
      );
      if (sub_category.length > 0) {
        sub_categories = sub_category.map((ss) => {
          return {
            main_key: ss.main_key,
            categories: ss.categories.map((sc) => {
              return {
                filter_key: sc.filter_key,
                categories: sc.sub_category.map((sc) => sc.id),
              };
            }),
          };
        });
      }
      setLoder(true);
      CustomerServices.createNewTask(
        selectedSpace.project_id
          ? selectedSpace.project_id
          : project_id
          ? project_id
          : 0,
        taskStatus == 0 ? 1 : 0,
        taskStatus,
        selectedAssignee.length ? selectedAssignee : [],
        "",
        title_name,
        VisibleToCustomer,
        VisibleToProvider,
        sub_categories,
        taskPriority,
        taskPinned,
        commentsList,
        checklistItems,
        CommentFile,
        checklistFiles,
        startDate,
        selectedAssigneeNew,
        amount == "" ? 0 : amount,
        benchmarkCreate,
        getSelectedFloorId,
        taskAttachments,
        0,
        createTaskType,
        endDate,
      ).then((res) => {
        if (res.status && (res.data.task_id || res.data.id)) {
          setOpenAssigneeDropdown(false);
          dispatch(commanoffcanvasisOpen(false))
          TaskModalClose();
          const taskId = res.data.id ? res.data.id : res.data.task_id;
          dispatch(isCreareTaskDefaultTopics(isCreatesTaskDefaultTopics, 'isFromCreateTask'));
          if (
            (isRequest || taskStatus == 0) &&
            from == "3dPlan" &&
            (x != 0 || y != 0 || z != 0)
          ) {
            customerServices
              .setArLocalAnchors(getSelectedFloorId, taskId, pointId, x, y, z)
              .then((res) => {
                if (res.status == 1) {
                  let localArray = get_Ar_Local_Anchors;
                  localArray.push({
                    checklist_id: 0,
                    checklist_title: null,
                    poi: pointId,
                    task_id: taskId,
                    title: requestTitle,
                    web_x: x,
                    web_y: y,
                    web_z: z,
                  });
                  dispatch(getArLocalAnchors(getSelectedFloorId))
                }
              });
          }
          dispatch(
            getTaskCount(
              "",
              "",
              selectedSpace.project_id
                ? selectedSpace.project_id
                : project_id
                ? project_id
                : 0,
              moment().format("YYYY-MM-DD")
            )
          );
          dispatch(
            projectTaskCounts(
              localStorage.getItem("selectedOffice"),
              taskFilters ? taskFilters : [],
              ""
            )
          );
          setLoder(false);
          dispatch(
            getTaskList(
              localStorage.getItem("client_id"),
              localStorage.getItem("contact_id"),
              project_id,
              is_fromCalendar
                ? ""
                : (pageName == "ChatAi" || globalView == "AichatView")
                ? new Date()
                : moment(localStorage.getItem("selectedDate")).format(
                    "YYYY-MM-DD"
                  ),
              false,
              activeTaskStatusFilters,
              taskFilters,
              "",
              0,
              (pageName == "ChatAi" || globalView == "AichatView") ? 0 : 1,
              is_fromCalendar
                ? moment(createtaskDate).subtract(4, "day")
                : (pageName == "ChatAi" || globalView == "AichatView")
                ? moment(new Date()).subtract(3, "days")
                : "",
              is_fromCalendar
                ? moment(createtaskDate).add(4, "day")
                : (pageName == "ChatAi" || globalView == "AichatView")
                ? moment(new Date()).add(3, "days")
                : "",
              getSelectedFloorId
            )
          );
          removeFieldData(res);
        } else {
          setLoder(false);
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      });
    }
  };
  const createNewTaskForSingleSpace = () => {
    if (formValidations()) {
      let sub_categories = [];
      let title_name = createTaskTitle ? createTaskTitle : autoNameHandler();
      let sub_category = selectedAssignee.filter(
        (s) =>
          s.main_key == "equipments" ||
          (s.main_key == "provider_task" &&
            userType == "contact" &&
            CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))
      );
      if (sub_category.length > 0) {
        sub_categories = sub_category.map((ss) => {
          return {
            main_key: ss.main_key,
            categories: ss.categories.map((sc) => {
              return {
                filter_key: sc.filter_key,
                categories: sc.sub_category.map((sc) => sc.id),
              };
            }),
          };
        });
      }
      setLoder(true);
      CustomerServices.createNewTaskForSingleSpaces(
        selectedSpaceProjectId,
        taskStatus == 0 ? 1 : 0,
        taskStatus,
        selectedAssignee.length ? selectedAssignee : [],
        "",
        title_name,
        VisibleToCustomer,
        VisibleToProvider,
        sub_categories,
        taskPriority,
        taskPinned,
        commentsList,
        checklistItems,
        CommentFile,
        checklistFiles,
        startDate,
        selectedAssigneeNew,
        amount == "" ? 0 : amount,
        benchmarkCreate,
        getSelectedFloorId,
        taskAttachments,
        0,
        createTaskType,
        endDate,
      ).then((res) => {
        if (res.status && (res.data.task_id || res.data.id)) {
          setOpenAssigneeDropdown(false);
          dispatch(showMessage("sucess", _l("l_success"), _l("l_task_created")));
          setLoder(false);
          handleCloseTask()
          setOpenAssigneeDropdown(false);
          dispatch(commanoffcanvasisOpen(false))
          TaskModalClose();
          const taskId = res.data.id ? res.data.id : res.data.task_id;
          dispatch(isCreareTaskDefaultTopics(isCreatesTaskDefaultTopics, 'isFromCreateTask'));
          dispatch(
            getTaskCount(
              "",
              "",
              selectedSpace.project_id
                ? selectedSpace.project_id
                : project_id
                ? project_id
                : 0,
              moment().format("YYYY-MM-DD")
            )
          );
          dispatch(
            projectTaskCounts(
              localStorage.getItem("selectedOffice"),
              taskFilters ? taskFilters : [],
              ""
            )
          );
          setLoder(false);
          customerServices.getTaskData(project_id,taskId, null ,0 ,"").then((res)=>{
            if (res && res.status) {
              let taskData = [{...res.data, is_allowed_to_delete : 1}]
              let tempTaskList = taskList.concat(taskData)
              dispatch(updateTaskList(tempTaskList))
            }
          })
          removeFieldData(res);
        } else {
          setLoder(false);
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      });
    }
  };
  const switchToClassicView = (e) => {
    setChecklistMode(
      e && e.target && e.target.value ? e.target.value : "classic"
    );
    setShowFloorList(false);
    setSelectedFloor({ ...selectedFloor, id: -1, title: "" });
  };
  const copyTemplateChecklist = async (template) => {
    try {
      const response = await benchmarkServices.getTemplateChecklistItems(
        selectedProject,
        0,
        template.id,
        1
      );
      if (response.status && response.data.length) {
        let templateItems = response.data.slice();
        let currentChecklistItems = checklistItems.slice();
        templateItems.forEach((x) => {
          if (
            !currentChecklistItems.find(
              (w) =>
                w.description &&
                w.description.trim().toLowerCase() ==
                  x.description.trim().toLowerCase() &&
                x.type_of_space_item_id == w.type_of_space_item_id
            )
          ) {
            currentChecklistItems.push({
              ...x,
              type_of_space_item_id: x.type_of_space_item_id,
              floor_id: x.floor_id,
              id: checklistItems.length + 1,
              finished: 0,
              status: 0,
            });
          }
        });
        currentChecklistItems = currentChecklistItems.filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                (obj.room_id == o.room_id &&
                  obj.type_of_space_item_id == o.type_of_space_item_id &&
                  obj.description == o.description) ||
                (obj.description == "" && o.description == "")
            )
        );
        currentChecklistItems.sort((a, b) => {
          if (a.description === "" && b.description !== "") {
            return 1;
          }
          if (a.description !== "" && b.description === "") {
            return -1;
          }
          return 0;
        });
        setChecklistItems(currentChecklistItems);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleAddChecklistItem = (e) => {
    displayChecklistArea();
    let checklistArr = e.target.value
      ? e.target.value.split("\n")
      : [`${taskCheckListInputHeight.current.value}`];
    if (e.type == "paste") {
      checklistArr = e.clipboardData.getData("Text").split("\n");
    }
    let newArr = checklistItems;
    for (let i = 0; i < checklistArr.length; i++) {
      if (checklistArr[i].trim().length) {
        if (selectedTypeOfSpace.id > 0) {
          if (selectedTypeOfSpaceItem) {
            newArr.push({
              description: checklistArr[i].trim(),
              finished: 0,
              floor_id: selectedFloor.id != -1 ? selectedFloor.id : 0,
              item_id: selectedTypeOfSpace.id,
              type_of_space_item_id: selectedTypeOfSpaceItem.id,
              id: checklistItems.length + 1,
              status: 0,
            });
          } else if (selectedTypeOfSpace.is_default > 0) {
            newArr.push({
              description: checklistArr[i].trim(),
              finished: 0,
              floor_id: selectedFloor.id != -1 ? selectedFloor.id : 0,
              item_id: selectedTypeOfSpace.id,
              type_of_space_item_id: 0,
              id: checklistItems.length + 1,
              status: 0,
            });
          }
        } else {
          newArr.push({
            description: checklistArr[i].trim(),
            finished: 0,
            floor_id: selectedFloor.id != -1 ? selectedFloor.id : 0,
            item_id: selectedTypeOfSpace.id != -1 ? selectedTypeOfSpace.id : 1,
            id: checklistItems.length + 1,
            status: 0,
          });
        }
      }
    }
    setChecklistItems(newArr);
    taskCheckListInputHeight.current.value = "";
    setTimeout(() => {
      /*Height reset after checklist added*/
      if (taskCheckListInputHeight.current) {
        taskCheckListInputHeight.current.style.height = "auto";
      }
    }, 100);
  };
  const getTypeOfSpaceCount = (id) => {
    let spaceItemsArray = [];
    floorDetails &&
      floorDetails.type_of_space.map((e) => {
        return (
          e.details &&
          e.details.length > 0 &&
          e.details.map((s) => {
            if (s.floor_id == id) {
              spaceItemsArray.push(s);
            }
          })
        );
      });
    return spaceItemsArray.length;
  };
  const getFloorwiseChecklistCount = (id) => {
    return checklistItems.filter((w) => w.floor_id == id).length;
  };
  const clearFloorFilter = () => {
    setSelectedFloor({ id: -1, title: "" });
    setShowFloorList(true);
    setSelectedTypeOfSpace({ id: -1, title: "" });
    setChecklistItems(checklistItems);
  };
  const applyFloorbaseFilter = (floor) => {
    setSelectedFloor(floor);
    setShowFloorList(false);
    setSelectedTypeOfSpace(
      floorDetails.type_of_space.find((w) => w.is_default == 1)
    );
  };
  const handleChecklistAssignee = (user, checklist_id) => {
    let assigneeId = 0;
    if (user) {
      user.profile_image_url = user.user_image || user.profile_image;
      assigneeId = user.assigneeid || user.id;
    }
    const index = checklistItems.findIndex((item) => item.id === checklist_id);
    let updatedChecklist = checklistItems[index];
    let updatedChecklistAssigneeIds = checklistItems[index];
    updatedChecklist.assigned_details = user;
    updatedChecklistAssigneeIds.assignee_id = user.id;
    if (user) {
      if (localStorage.getItem("user_type") == "contact") {
        updatedChecklist.assigned_details["id"] = user.id;
      } else {
        updatedChecklist.assigned_details["staffid"] = user.id;
      }
      updatedChecklist.assigned_details.full_name = user.name;
    }
    if (assigneeId) {
      updatedChecklist.assigned = 1;
    } else {
      updatedChecklist.assigned = 0;
    }
    setChecklistItems(
      Object.values({
        ...checklistItems,
        [index]: updatedChecklist,
        [index]: updatedChecklistAssigneeIds,
      })
    );
  };
  const handleTaskStatus = (
    checklistId,
    description,
    finished,
    checkUnCheck,
    index_id,
    priority,
    status
  ) => {
    const index = checklistItems.findIndex((item) => item.id == checklistId);
    let updatedChecklist = checklistItems[index];
    updatedChecklist.status = status;
    setChecklistItems(
      Object.values({ ...checklistItems, [index]: updatedChecklist })
    );
  };
  const handleEquipmentQuantity= (
    checklist,
    euipment
  ) => {
    const index = checklistItems.findIndex((item) => item.id == checklist.id);
    let updatedChecklist = checklistItems[index];
    let elementId = [];
    let ckecklist  = updatedChecklist.equipment_quantity ? updatedChecklist.equipment_quantity : []
    euipment.forEach((euiq) => {
      if (euiq.count > euiq.initialEuipment) {
        const sub_categories = euiq.sub_category.filter((sub) => sub.task_checklist_id == 0 && sub.is_euipment == 0);
        for (let index = 0; index < euiq.count - euiq.initialEuipment; index++) {
          elementId.push({equipment_status : 1, equipment_quantity_id : sub_categories[index].id})
          ckecklist.push({equipment_id : euiq.filter_key, equipment_quantity_id : sub_categories[index].id})
        }
      }else if (euiq.count < euiq.initialEuipment) {
          const sub_categories = euiq.sub_category.filter((sub) => sub.task_checklist_id != 0 && sub.is_euipment == 1);
          for (let index = 0; index < euiq.initialEuipment - euiq.count; index++) {
            elementId.push({equipment_status : 0, equipment_quantity_id : sub_categories[index].id})
            ckecklist = ckecklist.filter((checkID) => Number(checkID.equipment_quantity_id) != Number(sub_categories[index].id))
          }
        }
    })
    updatedChecklist.equipment_quantity = ckecklist.slice();
    setChecklistItems(
      Object.values({ ...checklistItems, [index]: updatedChecklist })
    );
    dispatch(setEquipmentConsumbleCheckList(elementId, 'isCreateTasks'));
  };
  const handleCheckUncheckedCheckListItem = (e, index, checklist) => {
    setChecklistItems(
      Object.values({
        ...checklistItems,
        [index]: {
          ...checklist,
          finished: e.target.checked,
        },
      })
    );
  };
  const itemPriority = (checklistId, isFinished, item, priority) => {
    const index = checklistItems.findIndex((item) => item.id == checklistId);
    let updatedChecklist = checklistItems[index];
    updatedChecklist.priority = priority;
    setChecklistItems(
      Object.values({ ...checklistItems, [index]: updatedChecklist })
    );
  };
  const handleDeleteChecklist = (description) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_you_want_to_delete_this_checklist"),
        _l("l_delete"),
        _l("l_cancel"),
        undefined,
        () => {
          removeChecklist(description);
        }
      )
    );
  };
  const removeChecklist = (description) => {
    setChecklistItems(
      checklistItems.filter((item) => item.description != description)
    );
    dispatch(toastCloseAction());
  };
  const setChecklistAssigneeList = async () => {
    let virtualSpaces = localStorage.getItem("selectedVirtualSpace");
    let teamIds = [];
    let taskMembers = [];
    taskAssignee &&
      taskAssignee.forEach((member) => {
        if (member.id > 0 && virtualSpaces) {
          teamIds.push(member.id);
        } else if (member.id < 0 && !virtualSpaces) {
          teamIds.push(Math.abs(member.id).toString());
        } else {
          taskMembers.push(member);
        }
      });
    if (teamIds.length) {
      try {
        if (userTeams) {
          userTeams.forEach((team) => {
            if (teamIds.includes(team.id) && team.manager_id) {
              taskMembers.push({
                id: team.manager_id,
                name: team.manager_name,
                profile_image: team.manager_profile_image,
              });
            }
            if (teamIds.includes(team.id) && team.team.length) {
              team.team.forEach((team_member) => {
                taskMembers.push({
                  id: virtualSpaces
                    ? `-${team_member.member_id}`
                    : team_member.member_id,
                  name: `${team_member.firstname} ${team_member.lastname}`,
                  profile_image: team_member.profile_image,
                });
              });
            }
          });
        }
      } catch (e) {}
    }
    setChecklistItemAssigneeList(removeDuplicateObjects(taskMembers));
  };
  const saveTemplate = async (title) => {
    setTemplateLoder(true);
    try {
      setShowChecklistTemplateModal(false);
      if (
        templatesList.find((x) => x.title && x.title.trim() == title.trim())
      ) {
        showError("l_template_already_exists");
        setTemplateLoder(false);
      } else if (title && title.trim().length) {
        let templateChecklistItems = checklistItems.slice();
        let formattedChecklistArray = [];
        if (templateChecklistItems && templateChecklistItems.length) {
          templateChecklistItems.forEach((checklist) => {
            if (checklist.description) {
              formattedChecklistArray.push({
                id: checklist.id || 0,
                floor_id: checklist.floor_id,
                checklist: [
                  {
                    name: checklist.description,
                    photo: 0,
                    selected: 1,
                    addedFromTemplate: false,
                    unique_id: checklist.id,
                    is_default_checklist: false,
                    tasks_per_month: 1,
                  },
                ],
                type_of_space_item_id: checklist.type_of_space_item_id,
              });
            }
          });
        }
        if (formattedChecklistArray.length) {
          const response = await BenchmarkServices.createChecklistTemplate(
            selectedProject,
            0,
            title.trim(),
            formattedChecklistArray,
            1
          );
          if (response.status && response.data.inserted_template_id) {
            setTemplatesList(
              templatesList.concat([
                { title: title.trim(), id: response.data.inserted_template_id },
              ])
            );
            setTemplateLoder(false);
            dispatch(
              showMessage("sucess", _l("l_success"), _l(response.message))
            );
          } else {
            showError("l_something_went_wrong");
            setTemplateLoder(false);
          }
        }
      } else {
        showError("l_please_enter_template_title");
        setTemplateLoder(false);
      }
    } catch (e) {
      setTemplateLoder(false);
    }
  };
  const formValidations = () => {
    if (selectedAssigneeNew.length == 0 && (isRequest || taskStatus == 0)) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_assignees"))
      );
      return false;
    } 
    if (checklistItems.length == 0) {
      if (taskCheckListInputHeight.current.value != "") {
        let newArr = checklistItems;
        newArr.push({
          description: taskCheckListInputHeight.current.value.trim(),
          finished: 0,
          status: 0,
        });
        setChecklistItems(newArr);
        taskCheckListInputHeight.current.value = "";
        return true;
      } else if (
        (isRequest || taskStatus == 0) &&
        taskCheckListInputHeight.current.value == "" &&
        checklistItems.every((a) => a && a.description.trim() == "")
      ) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_add_checklist_items"))
        );
        return false;
      }
    }
    if(userType == "contact" && [1, 2].includes(Number(contact_role)))
    {
      const providerAssignee = selectedAssigneeNew && selectedAssigneeNew.length ? selectedAssigneeNew.find((w) => w.main_key === "providers") : "";
      if(leftPanelTab == "my-space" && providerAssignee && !providerAssignee.categories.find((category) => category.filter_key === "myrai_support") && providerAssignee.categories.every((w) => !w.sub_category.find((z) => z.staffid)))
      {
        showError("l_please_select_operator_for_request");
        return false;
      }
    }
    return true;
  };
  const removeFieldData = (res) => {
    dispatch(toastCloseAction());
    setTaskTitle("");
    setRequestTitle("");
    setIsBlankTitle(false)
    setTaskPriority(2);
    setSelectedSpace({});
    setCommentsList([]);
    setTaskStatus(1);
    setSelectedAssigneeIds([]);
    setChecklistItems([]);
    setTaskPinned(false);
    setshowTaskList(true);
    setChecklistMode("classic");
    setSelectedFloor({ id: -1, title: "" });
    switchToClassicView();
    setSelectedFloor({ ...selectedFloor, id: -1, title: "" });
    handleClose(res.data.id ? res.data.id : res.data.task_id);
    dispatch(toastCloseAction());
    setShowFloorList(false);
    dispatch(isBackDrop(false));
    dispatch(setCreateTaskModalIsopen(false));
    setTaskAttachments([]);
    setallAttachmentsOfTask([]);
    setCommentFile([]);
    setchecklistFiles([]);
    setLoder(false);
    dispatch(
      showMessage(
        "sucess",
        _l("l_success"),
        _l(res.message) ? _l(res.message) : _l("l_task_created")
      )
    );
  };
  const handleCloseTask = () => {
    dispatch(toastCloseAction());
    setTaskTitle("");
    setRequestTitle("");
    setIsBlankTitle(false)
    setTaskPriority(2);
    setSelectedSpace({});
    setCommentsList([]);
    setSelectedAssigneeIds([]);
    setChecklistItems([]);
    setTaskPinned(false);
    setshowTaskList(true);
    setChecklistMode("classic");
    setSelectedFloor({ id: -1, title: "" });
    switchToClassicView();
    setSelectedFloor({ ...selectedFloor, id: -1, title: "" });
    dispatch(isBackDrop(false));
    setShowCheckListArea(false);
    dispatch(setCreateTaskModalIsopen(false));
    setTaskAssignee([]);
    setTaskAssigneePills([]);
    setTaskAttachments([]);
    setallAttachmentsOfTask([]);
    setCommentFile([]);
    setchecklistFiles([]);
    setShowDefaultIcons(["calendar"]);
    setSelectedAssigneeNew([]);
    handleClose();
    setLoder(false);
    setCreateTaskStep("first");
  };
  const handleToggleIcons = () => {
    if (inputActionIconShow == true) {
      let tempDefaultIcons = ["calendar", "assigneTo"];
      if (taskPinned) {
        tempDefaultIcons.push("pin");
      }
      if (taskAssignee.length > 0) {
        tempDefaultIcons.push("peopels");
      }
      if (taskAssigneePills.length > 0) {
        tempDefaultIcons.push("pills");
      }
      if (taskPriority == 3) {
        tempDefaultIcons.push("priority");
      }
      if (commentsList.length > 0) {
        tempDefaultIcons.push("comment");
      }
      setShowDefaultIcons(tempDefaultIcons);
      setinputActionIconShow(false);
    } else {
      let tempDefaultIcons = [
        "calendar",
        "assigneTo",
        "pin",
        "peopels",
        "pills",
        "priority",
        "cube",
        "map",
        "status",
        "comment",
        "checklistTemplate",
      ];
      setShowDefaultIcons(tempDefaultIcons);
      setinputActionIconShow(true);
    }
  };
  const displayChecklistArea = () => {
    setShowCheckListArea(true);
    setinputActionIconShow(false);
    dispatch(setCreateTaskModalIsopen(true));
  };
  const openDefaultAssigneeMenu = () => {
    let selectedTopic = localStorage.getItem("selectedTopic");
    selectedTopic =
      selectedTopic && selectedTopic.length ? JSON.parse(selectedTopic) : {};
    if (selectedTopic) {
      switch (selectedTopic.main_key) {
        case "providers":
          setDefaultSubMenuOpen([false, true, false, false, false, false]);
          break;
        default:
          break;
      }
    }
  };
  const typeOfSpaceItemToggle = (space) => {
    if (selectedTypeOfSpaceItem && space.id == selectedTypeOfSpaceItem.id) {
      setSelectedTypeOfSpaceItem();
    } else {
      setSelectedTypeOfSpaceItem(space);
    }
  };
  const isRestrictedToChangeTaskStatus = () => {
    let flag = false;
    switch (userType) {
      case "contact":
        if (Number(contact_role) === 3) {
          flag = true;
          setTaskStatus(0);
        } else if (taskAssigneePills.find((w) => w.main_key === "providers")) {
          flag = true;
          setTaskStatus(0);
        } else if (
          taskAssigneePills.filter((w) => w.main_key === "equipments")
            .length === 1
        ) {
          //If task is equipment task and equipment has provider assignee, consider it as a request other wise it will be created as task
          const equipmentDetails = taskAssigneePills.find(
            (w) => w.main_key === "equipments"
          );
          if (equipmentDetails) {
            let isEquipmentAssignedToProvider = false;
            if (defaultTopics && defaultTopics.length) {
              //Check in burger menu Equipments list to check for restriction
              defaultTopics.forEach((w) => {
                if (w.main_key === "equipments" && w.categories.length) {
                  w.categories.forEach((equipment) => {
                    if (equipment.sub_category.length) {
                      equipment.sub_category.forEach((equipmentItem) => {
                        if (
                          equipmentItem.id == equipmentDetails.id &&
                          equipment.provider_assignee > 0
                        ) {
                          isEquipmentAssignedToProvider = true;
                          setTaskStatus(0);
                        }
                      });
                    }
                  });
                }
              });
            }
            flag = isEquipmentAssignedToProvider;
          }
        } else if (
          defaultTopics &&
          defaultTopics.length &&
          taskAssignee.find((w) => w.main_key === "my_contact")
        ) {
          //If task is being assigned to the the role less then the current role, make it as task, else it will be request
          const contactAssigneeList = taskAssignee.filter(
            (w) => w.main_key === "my_contact"
          );
          contactAssigneeList.forEach((contactAssignee) => {
            defaultTopics.forEach((topic) => {
              if (topic.main_key === "my_contact" && topic.categories.length) {
                topic.categories.forEach((member) => {
                  if (member.filter_key == contactAssignee.id) {
                    if (
                      member.main_sub_key === "people" &&
                      member.contact_role < contact_role
                    ) {
                      flag = true;
                    }
                  }
                });
              }
            });
          });
          if (flag) {
            setTaskStatus(0);
          } else {
            setTaskStatus(1);
          }
        }
        break;
      case "operator":
        if (Number(contact_role) === 3) {
          flag = true;
          setTaskStatus(0);
        } else if (
          defaultTopics &&
          defaultTopics.length &&
          taskAssignee.find((w) => w.main_key === "my_operator")
        ) {
          //If task is being assigned to the the role less then the current role, make it as task, else it will be request
          const operatorAssignees = taskAssignee.filter(
            (w) => w.main_key === "my_operator"
          );
          operatorAssignees.forEach((operatorAssignee) => {
            defaultTopics.forEach((topic) => {
              if (topic.main_key === "my_operator" && topic.categories.length) {
                topic.categories.forEach((member) => {
                  if (member.filter_key == operatorAssignee.id) {
                    if (
                      member.main_sub_key === "people" &&
                      member.contact_role < contact_role
                    ) {
                      flag = true;
                    }
                  }
                });
              }
            });
          });
          if (flag) {
            setTaskStatus(0);
          } else {
            setTaskStatus(1);
          }
        }
        break;
      case "staff":
        if (
          defaultTopics &&
          defaultTopics.length &&
          taskAssigneePills.find((w) => w.main_key === "myr_support")
        ) {
          flag = true;
          setTaskStatus(0);
        }
        break;
      default:
        break;
    }
    return flag;
  }
  const hideBudgetField = () => {
    let flag = false;
    if(taskStatus > 0)
    {
      return true;
    }
    if(contact_role == 3 || !selectedAssigneeNew.filter((w) =>  ["providers", "myr_support"].includes(w.main_key)).length)
    {
      flag = true;
    }
    else if(selectedAssigneeNew.find((w) => w.main_key === "myr_support"))
    {
      selectedAssigneeNew.forEach((assignee) => {
        if(assignee.categories)
        {
          assignee.categories.forEach((category) => {
            if(category.main_sub_key !== "operator")
            {
              flag = true;
            }
          });
        }
      });
    }
    return flag;
  };
  const addAssigneeSpace = (projectId, tag = '') => {
    if (tag == "allSpacesAdd") {
      setSelectedSpaceProjectId(projectId);
    }else if (selectedSpaceProjectId.length == spaceState.length) {
      setSelectedSpaceProjectId([projectId]);
    }else{
      let findIdex = selectedSpaceProjectId.findIndex(id => id === projectId);
      if (findIdex === -1) {
        setSelectedSpaceProjectId(([projectId]).concat(selectedSpaceProjectId));
      }else{
        let tempArray = selectedSpaceProjectId.filter(id => id!== projectId);
        setSelectedSpaceProjectId(tempArray);
      }
    }
  }
  useEffect(() => {
    if (is_task && isFromLiteVersion && !taskTitle.length) {
      taskNameInputHeight.current.style.height = "auto";
    }
  }, [taskTitle]);
  useEffect(() => {
    if (taskAssignee) {
      setChecklistAssigneeList();
    }
  }, [JSON.stringify(taskAssignee), JSON.stringify(userTeams)]);
  useEffect(() => {
    dispatch(
      getFloorDetails(
        project_id ? project_id : selectedSpace ? selectedSpace.rel_id : null
      )
    );
  }, [project_id]);
  useEffect(() => {
    let tempArray = allAttachmentsOfTask.concat(CommentFile, checklistFiles);
    setallAttachmentsOfTask(removeDuplicateImgs(tempArray));
  }, [CommentFile, checklistFiles]);
  useEffect(() => {
    let previewImg = [];
    allAttachmentsOfTask.map((img) => {
      previewImg.push({
        image_url: URL.createObjectURL(img),
        dateadded: new Date(),
        file_name: URL.createObjectURL(img),
        id: Math.floor(Math.random() * 6) + 1,
      });
    });
    setpreviewImages(previewImg);
    let taskDetails = {
      name: requestTitle ? requestTitle : taskTitle,
      id: "CreateTask",
      priority: taskPriority,
      creator_profile_image: localStorage.getItem("contact_image"),
      creator_name: localStorage.getItem("full_name"),
      checklist_progress: 0,
      startdate: startDate,
      checklistItems: checklistItems,
      allAttachments: previewImg,
    };
    setTaskDetails(taskDetails);
  }, [
    checklistItems,
    allAttachmentsOfTask,
    taskPriority,
    requestTitle,
    checklistItems,
    taskTitle,
  ]);
  useEffect(() => {
    if (createTaskModalIsopen) {
      sessionStorage.setItem("isTaskCreationOngoing", 1);
      openDefaultAssigneeMenu();
    } else {
      sessionStorage.removeItem("isTaskCreationOngoing");
      dispatch(isBackDrop(false));
    }
  }, [createTaskModalIsopen]);
  useEffect(() => {
    if (setPOILatLng) {
      setX(setPOILatLng.x);
      setY(setPOILatLng.y);
      setZ(setPOILatLng.z);
    }
    if (getActivePOIList && getActivePOIList.length > 0) {
      let tempList = getActivePOIList.filter(
        (PO) => PO.web_x != 0 || PO.web_y != 0 || PO.web_z != 0
      );
      let temp_id = tempList.length > 0 ? tempList[0].id : 0;
      setPointId(temp_id);
    }
  }, [setPOILatLng, getActivePOIList]);
  useEffect(() => {
    if (selectedAssigneeNew) {
      selectedAssigneeForTaskAndRequest(
        selectedAssigneeNew,
        setTaskAssigneePills,
        setTaskAssignee,
        defaultTopics
      );
    }
  }, [JSON.stringify(selectedAssigneeNew)]);
  useEffect(() => {
    if (taskAssigneePills) {
      taskAssigneePills.forEach((dd) => {
        if (dd.main_key == "providers") {
          setVisibleToCustomer(1);
          setVisibleToProvider(1);
          setBenchmarkCreate(1);
          //Update status automatically to the request
          setTaskStatus(0);
        } else {
          if (userType == "operator") {
            setVisibleToProvider(1);
          } else {
            setVisibleToCustomer(1);
          }
        }
      });
    }
    if (taskAssignee) {
      taskAssignee.forEach((dd) => {
        if (dd.main_key == "providers") {
          setVisibleToCustomer(1);
          setVisibleToProvider(1);
          setBenchmarkCreate(1);
        } else {
          if (userType == "operator") {
            setVisibleToProvider(1);
          } else {
            setVisibleToCustomer(1);
          }
        }
      });
    }
    if(isRestrictedToChangeTaskStatus())
    {
        setLockTaskStatus(true);
    }
    else {
        setLockTaskStatus(false);
    }
  }, [JSON.stringify(taskAssigneePills), JSON.stringify(taskAssignee)]);
  useEffect(() => {
    const getChecklistTemplateList = async () => {
      try {
        const response = await BenchmarkServices.getChecklistTemplate(
          project_id,
          "",
          1
        );
        if (response.status) {
          setTemplatesList(response.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getChecklistTemplateList();
  }, [project_id]);
  useEffect(() => {
    if (isRequest) {
      setTaskStatus(0);
      displayChecklistArea();
    } else {
      setTaskStatus(contact_role == 3 ? 0 : 1);
    }
  }, [isRequest]);
  useEffect(() => {
    if (createTaskType == "vdi-task") {
      // Assign VDI custom tag
      setSelectedAssigneeNew([
        {
          main_key: "my_task",
          categories: [
            {
              filter_key: 1823,
              main_sub_key: "custom_tag",
              sub_category: []
            },
          ]
        }
      ])
      // Set defaul task name
      setCreateTaskTitle("Demande d’acces VDI");
      // Set defailt checklist
      setChecklistItems([{
        description: "Motif de la demande :",
        finished: 0,
        floor_id: 0,
        item_id: 1,
        id: 1,
        status: 0,
      }]);
      // Set default classic checklist view
      setTimeout(() => {
        switchToClassicView();  
        setShowCheckListArea(true);
        dispatch(setCreateTaskModalIsopen(true));
        dispatch(isBackDrop(true));
        setCreateTaskStep("second");
      }, 500);
      // Set start date changed
      setcalendarDateChanged(true);
    }
  }, []);
  useEffect(() => {
    if (openAssigneeDropdown) {
      setinputActionIconShow(false);
    } else {
      dispatch(isBackDrop(false));
    }
  }, [openAssigneeDropdown]);
  useEffect(() => {
    let completedChecklist = checklistItems.filter(
      (item) => item.finished == true
    );
    if (taskStatus > 0 && checklistItems.length) {
      if (
        checklistItems.length > 0 &&
        completedChecklist.length == 0 
      ) {
        setTaskStatus(userSelectedStatus > -1 ? userSelectedStatus : 1);
      } else if (
        checklistItems.length > 0 &&
        completedChecklist.length == checklistItems.length
      ) {
        setTaskStatus(userSelectedStatus > -1 ? userSelectedStatus : 5);
      } else if (
        checklistItems.length > 0 &&
        completedChecklist.length > 0
      ) {
        setTaskStatus(4);
      }
    }
  }, [checklistItems, checklistItems.length]);
  useEffect(() => {
  if (!isBlankTitle) {
    setRequestTitle(autoNameHandler());
  }
  }, [
    JSON.stringify(taskAssigneePills),
    JSON.stringify(taskAssignee),
    JSON.stringify(checklistItems),
  ]);
  const autoNameHandler = () => {
    if (!isBlankTitle) {
      let firstChecklist = checklistItems.find((w) => w && w.description.trim() != "");
      let categoryName = "";
      let autoTitle = "";
      if(taskAssigneePills && taskAssigneePills.length)
      {
        categoryName = taskAssigneePills[0].name;
      }
      else if(taskAssignee && taskAssignee.length)
      {
        categoryName = taskAssignee[0].name;
      }
      if(contact_role == 3 && ((taskAssigneePills && taskAssigneePills.length) || (taskAssignee && taskAssignee.length)))
      {
        autoTitle = `${_l("l_request")} - ${full_name} - `;
      }
      if(categoryName)
      {
        autoTitle = `${autoTitle} ${categoryName} :: `;
      }
      if(firstChecklist)
      {
        autoTitle = `${autoTitle} ${firstChecklist.description}`;
      }
      return autoTitle
    }
  }
  useEffect(() => {
    let selectedTopic = JSON.parse(localStorage.getItem("selectedTopic"));
    if (contact_role != 3 && selectedTopic && Object.keys(selectedTopic).length > 0) {
      if (taskFilters && taskFilters.length) {
        let removeFilters = taskFilters.filter(
          (w) =>
            w.main_key !== "my_task" ||
            (w.main_key === "my_task" &&
              !["created_by_me", "pinned", "overdue", "assigned_to_me"].includes(
                w.filter_key
              ))
        );
        if (removeFilters.length) {
          let tempData = removeFilters[0];
          const topicSelected = {
            filter_key : tempData.filter_key,
            image : tempData.image ? tempData.image : "",
            main_key : tempData.main_key,
            main_sub_key : tempData.main_sub_key,
            name : "",
            sub_category : tempData.sub_category ? tempData.sub_category : [],
          }
          handleRequestAssignee(topicSelected);
        }
      }
    }
  }, [JSON.stringify(localStorage.getItem("selectedTopic"))])
  const handleRequestAssignee = (assigneeDetail) => {
    let taskAssign =
      selectedAssigneeNew && selectedAssigneeNew.length
        ? selectedAssigneeNew.slice()
        : [];
    let Index = taskAssign.findIndex(
      (e) => e.main_key == assigneeDetail.main_key
    );
    if (Index > -1) {
      let IndexT = taskAssign[Index]["categories"].findIndex(
        (taskassign) => taskassign.filter_key == assigneeDetail.filter_key
      );
      if (IndexT > -1) {
        if (
          assigneeDetail.main_key == "equipments" ||
          (isRequest &&
            assigneeDetail.main_key == "providers" &&
            ((userType == "contact") ||
              userType == "staff") &&
            assigneeDetail.filter_key != "myrai_support")
        ) {
          let IndexU =
            taskAssign[Index]["categories"][IndexT].sub_category &&
            taskAssign[Index]["categories"][IndexT].sub_category.length > 0
              ? taskAssign[Index]["categories"][IndexT].sub_category.findIndex(
                  (taskassign) =>
                    (assigneeDetail.sub_category.length &&
                      assigneeDetail.main_key == "equipments" &&
                      taskassign == assigneeDetail.sub_category[0].id) ||
                    (taskassign &&
                      assigneeDetail.main_key != "equipments" &&
                      taskassign.id == assigneeDetail.sub_category[0].id)
                )
              : -1;
          if (IndexU > -1) {
            if (assigneeDetail.main_key == "equipments") {
              taskAssign[Index]["categories"][IndexT].sub_category = taskAssign[
                Index
              ]["categories"][IndexT].sub_category.filter(
                (taskassign) => taskassign != assigneeDetail.sub_category[0].id
              );
            } else {
              taskAssign[Index]["categories"][IndexT].sub_category = taskAssign[
                Index
              ]["categories"][IndexT].sub_category.filter(
                (taskassign) =>
                  taskassign &&
                  assigneeDetail.sub_category &&
                  assigneeDetail.sub_category.length &&
                  taskassign.id != assigneeDetail.sub_category[0].id
              );
            }
          } else {
            if (
              assigneeDetail.main_key == "equipments" &&
              assigneeDetail.sub_category.length > 0
            ) {
              taskAssign[Index]["categories"][IndexT].sub_category.push(
                assigneeDetail.sub_category[0].id
              );
            } else {
              if (taskAssign[Index]["categories"][IndexT].sub_category) {
                taskAssign[Index]["categories"][IndexT].sub_category.push(
                  assigneeDetail.sub_category[0]
                );
              } else {
                taskAssign[Index]["categories"][IndexT].sub_category = [
                  assigneeDetail.sub_category[0],
                ];
              }
            }
          }
        } else {
          taskAssign[Index]["categories"] = taskAssign[Index][
            "categories"
          ].filter(
            (taskassign) => taskassign.filter_key != assigneeDetail.filter_key
          );
        }
      } else {
        if ([ "providers", "myr_support"].includes(assigneeDetail.main_key)) {
          taskAssign[Index]["categories"] = [
            {
              filter_key: assigneeDetail.filter_key,
              main_sub_key: assigneeDetail.main_sub_key,
              sub_category: assigneeDetail.sub_category,
            },
          ];
        } else {
          taskAssign[Index]["categories"].push({
            filter_key: assigneeDetail.filter_key,
            main_sub_key: assigneeDetail.main_sub_key,
            sub_category:
              assigneeDetail.sub_category.length > 0
                ? assigneeDetail.sub_category.map((dd) => dd.id)
                : [],
          });
        }
      }
    } else {
      taskAssign.push({
        main_key: assigneeDetail.main_key,
        categories: [
          {
            filter_key: assigneeDetail.filter_key,
            main_sub_key: assigneeDetail.main_sub_key,
            sub_category:
              assigneeDetail.main_key == "providers"
                ? assigneeDetail.sub_category.length > 0
                  ? assigneeDetail.sub_category
                  : []
                : assigneeDetail.sub_category.length > 0
                ? assigneeDetail.sub_category.filter((sub, i) => i == 0).map((dd) => dd.id)
                : [],
          },
        ],
      });
    }
      tempTopic = taskAssign.slice();
      setSelectedAssigneeNew(taskAssign.slice());
  };
  useEffect(() => {
    if (leftPanelTab == "my-request") {
      setShowCheckListArea(true);
      dispatch(setCreateTaskModalIsopen(true));
      dispatch(isBackDrop(true));
      setCreateTaskStep("second");
    }else{
      setShowCheckListArea(false);
      dispatch(setCreateTaskModalIsopen(true));
      dispatch(isBackDrop(false));
      setCreateTaskStep("first");
    }
  }, [leftPanelTab])
  return (
    <>
      <div
        className={`daily-schedule-main w-900px my-0
          ${
            contact_role == 3 &&
            userType != "staff" &&
            (localStorage.getItem("selectedOffice") == 0 ||
              localStorage.getItem("selectedOffice") == undefined)
              ? contact_role == 3 ? '' : "d-none"
              : ""
          }`}
      > { ['pdf'].includes(getFileType(previewSrc && previewSrc[0].type)) ? 
            <></>
            :
            <Lightbox 
              open={openpdfGallery}
              close={() => setOpenpdfGallery(false)}
              slides={previewSrc}
            />
          }
        {showCheckListArea || createTaskModalIsopen ? (
          <a
            href="#/"
            className={`h50w50 liteversion-input-checlist-close_icon d-block ${
              from == "3dPlan" ? "space-request-close-btn" : ""
            }`}
            onClick={() => {
              handleCloseTask();
            }}
          >
            {from == "3dPlan" ? (
              <XIcon
                className={`btn-close btn-close-white HW30 stroke-width-04px`}
              />
            ) : (
              <XCircleIcon
                className={`stroke-width-04px HW50`}
              />
            )}
          </a>
        ) : (
          <></>
        )}
       {createTaskModalIsopen ? 
        <div
          className={`bg-base-header-color d-flex minh60vh  ${
            isFromLiteVersion
              ? "liteversion-input-checlist-abs-position pb-0"
              : "pb-0"
          } ${
            showCheckListArea || createTaskModalIsopen
              ? "liteversion-input-checlist-main pb-20px shadow-none"
              : ""
          }`}
        >
          {/* stepr count start  */}
          <div className="d-flex flex-column">
            <div className="d-flex flex-column justify-content-between position-relative flex-grow-1">
              <span className=" position-absolute h-100 start-50 bg-white-10-solid-imp" style={{width: "1px"}}></span>
              <div className="bg-base-header-color z-index-2">
                <div className="p-5px d-flex flex-column align-items-center bg-white-03 pe-20pximp ps-20pximp h64px lightthemebgblueimp">
                  <div className={`rounded-5 h32w32 bg-white-05 d-flex justify-content-center align-items-center light-theme-border lh-1 ${createTaskStep === "first" ? 'green-border-w-2' : ''}`}>1</div>
                  <div className="p-5px px-0 pt-0 c-font f-10 color-white-60">{_l("l_define")}</div>
                </div>
              </div>
              <div className="bg-base-header-color z-index-2">
                <div className="p-5px d-flex flex-column align-items-center pe-20pximp ps-20pximp">
                  <div className={`rounded-5 h32w32 bg-white-05 d-flex justify-content-center align-items-center light-theme-border lh-1 ${createTaskStep === "second" ? 'green-border-w-2' : ''}`}>2</div>
                  <div className="p-5px px-0 pt-0 c-font f-10 color-white-60">{_l("l_describe")}</div>
                </div>
              </div>
              <div className="bg-base-header-color z-index-2">
                <div className="p-5px d-flex flex-column align-items-center pe-20pximp ps-20pximp">
                  <div className={`rounded-5 h32w32 bg-white-05 d-flex justify-content-center align-items-center light-theme-border lh-1  ${createTaskStep === "third" ? 'green-border-w-2' : ''}`}>3</div>
                  <div className="p-5px px-0 pt-0 c-font f-10 color-white-60">{_l("l_submit")}</div>
                </div>
              </div>
            </div>
          </div>
          {/* stepr count end  */}
          <div className="d-flex flex-column flex-grow-1 p-10">
            <div className='align-items-center btnbluebg chat-comment-box comment-input-box d-flex light-theme-border lightthemebgtranspaent p-10 position-sticky start-0 top-0 z-2 mb-10 border rounded'>
              <textarea
                className="line-height-1-8 border-0 bg-transparent p-0 pe-2 ps-3"
                rows="1"
                ref={taskTitleInput}
                value={createTaskTitle}
                onInput={(e) => {
                  const val = e.target.value;
                  setCreateTaskTitle(val);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Prevents a new line from being added
                  }
                }}
                placeholder={`${_l("l_task_title")}`}
              />
            </div>
          <div
            className={
              isFromLiteVersion
                ? "position-sticky top-0 z-index-3 bg-base-header-color radius_3"
                : "position-sticky top-0 z-index-3 bg-base-header-color radius_3"
            }
          >
            <div
              className={`form-control border-0 mb-0 radius-bottom-0 p-15 z-index-12 bg-white-03  shadow-none h64px lighthtemebg ${
                isFromLiteVersion ? "position-relative" : ""
              }`}
            >
              {/* Create Task Request Button start  */}
              <div className="d-flex  justify-content-between align-items-center topBtncreate comman_action_icon">
                {leftPanelTab !== "my-space" && <div className={`task_assignee_main  me-10px h35px d-flex justify-content-center align-items-center bg-white-05 flex-grow-1 ${createTaskType == "vdi-task" ? "" : "w210px"} `} >
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex with_separator_10 with_minus ${
                        selectedSpaceProjectId && selectedSpaceProjectId.length >= 1
                          ? ""
                          : "d-none"
                      }`}
                    >
                      {selectedSpaceProjectId &&
                      selectedSpaceProjectId.length >= 1 ? (
                        spaceState.filter((item) => selectedSpaceProjectId.includes(item.project_id)).map((assignee, index) => {
                          if (index < 1)
                            return (
                              <div
                                className="badge rounded-pill text-nowrap list-toggle-view-none c-font f-12 d-flex align-items-center  max-width75"
                                key={index}
                              >
                                <span className={` p-0 text-truncate c-font f-10`}>
                                  {assignee.main_key == "my_task"
                                    ? "#"
                                    : ""}
                                  {_l(assignee.office_title)}
                                </span>
                              </div>
                            );
                        })
                      ) : (
                        <></>
                      )}
                      {selectedSpaceProjectId && selectedSpaceProjectId.length > 1 ? (
                        <a
                          href="#/"
                          className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px c-font f-12 d-flex align-items-center p-0 h25w25"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={handleTaskAssigneeData}
                        >
                          <div className="unread_count m-auto c-font f-12
                          ">
                            +{selectedSpaceProjectId.length - 1}
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                      {selectedSpaceProjectId && selectedSpaceProjectId.length ? (
                        <GeneralListing
                          handler={() => {}}
                          list={
                            spaceState &&
                            spaceState.length &&
                            spaceState.filter((item) => selectedSpaceProjectId.includes(item.project_id)).map((item, index) => {
                              return {
                                name: _l(item.office_title),
                                id: index,
                              };
                            })
                          }
                          isHash={true}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  <UncontrolledDropdown
                    className={`dropdown h35px`}
                    setActiveFromChild={true}
                    direction="end"
                  >
                    <DropdownToggle
                      className={`d-flex align-items-center h-100 justify-content-center w-100  on-hover-active-toggle-img text-on-hover-active-green h32wauto
                        `}
                      tag="a"
                      as="a"
                      href="#/"
                      type="button"
                      id="Loginuserdropdown"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <div
                        className={`light-theme-img d-flex align-items-center text-truncate`}
                      >
                        <MapPinIcon
                          className={`HW18`}
                        />
                        <span
                            className={`c-font ps-2 text-uppercase text-on-hover-active-green ${selectedSpaceProjectId && selectedSpaceProjectId.length > 0
                              ? "d-none"
                              : ""} `}
                          >
                            {_l("l_select")+ ' '+ _l("l_space")}
                          </span>
                      </div>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      <UnControlSubFiltersDropdown
                        data={spaceState}
                        filterName={"spaceListData"}
                        handleFilterLeads={addAssigneeSpace}
                        selectedleadfilers={selectedSpaceProjectId}
                      />
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>}
                <div className={`task_assignee_main  me-10px h35px d-flex justify-content-center align-items-center bg-white-05 flex-grow-1 ${leftPanelTab !== "my-space" && createTaskType != "vdi-task" ? 'w210px' : createTaskType == "vdi-task" ? "" : 'w-50'}`} >
                  <div
                    className={`align-items-center multi_inner_wrapper justify-content-end w-120 w-auto ${
                      taskAssigneePills.length > 0 || taskAssignee.length > 0
                        ? "d-flex"
                        : "d-none"
                    }`}
                  >
                  <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding me-10px">
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex with_separator_10 with_minus ${
                        taskAssigneePills && taskAssigneePills.length >= 1
                          ? ""
                          : "d-none"
                      }`}
                    >
                      {taskAssigneePills &&
                      taskAssigneePills.length >= 1 ? (
                        taskAssigneePills.map((assignee, index) => {
                          if (index < 1)
                            return (
                              <div
                                className="badge rounded-pill text-nowrap list-toggle-view-none c-font f-12 d-flex align-items-center  max-width75"
                                key={index}
                              >
                                <span className={` p-0 text-truncate c-font f-10`}>
                                  {assignee.main_key == "my_task"
                                    ? "#"
                                    : ""}
                                  {_l(assignee.name)}
                                </span>
                              </div>
                            );
                        })
                      ) : (
                        <></>
                      )}
                      {taskAssigneePills && taskAssigneePills.length > 1 ? (
                        <a
                          href="#/"
                          className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px c-font f-12 d-flex align-items-center p-0 h25w25"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={handleTaskAssigneeData}
                        >
                          <div className="unread_count m-auto c-font f-12
                          ">
                            +{taskAssigneePills.length - 1}
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                      {taskAssigneePills && taskAssigneePills.length ? (
                        <GeneralListing
                          handler={() => {}}
                          list={
                            taskAssigneePills &&
                            taskAssigneePills.length &&
                            taskAssigneePills.map((item, index) => {
                              return {
                                name: _l(item.name),
                                id: index,
                              };
                            })
                          }
                          isHash={true}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator2_10 with_minus ${
                        selectedAssigneeNew.length >= 1 ? "" : "d-none"
                      }`}
                     >
                      {selectedAssigneeNew.map((assignee, assigneeIndex) => {
                        const subCategories = assignee.categories
                          ? assignee.categories.reduce((acc, category) => {
                              if (category.sub_category && category.sub_category.length > 0) {
                                acc.push(...category.sub_category);
                              }
                              return acc;
                            }, [])
                          : [];
                        if (subCategories.length === 0) {
                          return null; 
                        }
                        const firstSubCategory = subCategories[0];
                        const remainingCount = subCategories.length - 1;
                        const generalListingData = subCategories.map((item) => ({
                          name: item.fullname || item.name,
                          image: item.profile_image_url || item.user_image || item.profile_image,
                          id: item.staffid || item.id,
                        }));
                        return (
                          <React.Fragment key={assignee.staffid || assignee.id || assigneeIndex}>
                            <a
                              href="#/"
                              className="comman-image-box multi-wrapper-border h25w25 rounded-circle one assignee-list-wrapper card-view-less-padding"
                              onClick={(e) => e.preventDefault()} 
                            >
                              <img
                                src={firstSubCategory.profile_image_url}
                                alt={firstSubCategory.fullname || "Assignee"}
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://myr.ai/orb/assets/images/default-image-placeholder.svg"; 
                                }}
                              />
                            </a>
                            {remainingCount > 0 && (
                              <a
                                href="#/"
                                className="comman-image-box h25w25 with_overlay d-flex rounded-circle dropdown-center"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={(e) => {
                                  e.preventDefault(); 
                                  handleTaskAssigneeData();
                                }}
                              >
                                <div className="unread_count m-auto c-font f-12">
                                  +{remainingCount}
                                </div>
                              </a>
                            )}

                            {/* --- Pass ALL subcategories to GeneralListing --- */}
                            {subCategories.length > 0 && (
                              <GeneralListing
                                handler={() => {}}
                                list={generalListingData}
                              />
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator2_10 with_minus ${
                        taskAssignee && taskAssignee.length >= 1
                          ? ""
                          : "d-none"
                      }`}
                    >
                      {taskAssignee && taskAssignee.length >= 1 ? (
                        taskAssignee.map((assignee, index) => {
                          if (index < 1)
                            return (
                              <a
                                href="#/"
                                data-bs-toggle="tooltip"
                                className="comman-image-box multi-wrapper-border h25w25 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                key={index}
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${
                                      assignee.user_image ||
                                      assignee.profile_image
                                    }')`,
                                  }}
                                ></div>
                              </a>
                            );
                        })
                      ) : (
                        <></>
                      )}
                      {taskAssignee && taskAssignee.length > 1 ? (
                        <a
                          href="#/"
                          className="comman-image-box h25w25 with_overlay d-flex rounded-circle dropdown-center"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={handleTaskAssigneeData}
                        >
                          <div className="unread_count m-auto c-font f-12">
                            +{taskAssignee.length - 1}
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                      {taskAssignee && taskAssignee.length ? (
                        <GeneralListing
                          handler={() => {}}
                          list={
                            taskAssignee &&
                            taskAssignee.length &&
                            taskAssignee.map((item, index) => {
                              return {
                                name: item.full_name || item.name,
                                image:
                                  item.user_image || item.profile_image,
                                id: index,
                              };
                            })
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                  <UncontrolledDropdown
                    className={`dropdown h35px`}
                    setActiveFromChild={true}
                    isOpen={openAssigneeDropdown}
                    direction="end"
                  >
                    <DropdownToggle
                      className={`d-flex align-items-center h-100 justify-content-center w-100 on-hover-active-toggle-img text-on-hover-active-green h32wauto ${
                        selectedAssigneeIds &&
                        selectedAssigneeIds.length > 0
                          ? "border-success"
                          : ""
                      }`}
                      id="AddAssigneeMainDropdownNew"
                      tag="a"
                      as="a"
                      onClick={() => {
                        setOpenAssigneeDropdown(!openAssigneeDropdown);
                      }}
                    >
                      <div
                        className={`light-theme-img d-flex align-items-center text-truncate`}
                        id={`AssigneeMainDropdownNew_undefined`}
                      >
                        <UserCircleGearIcon
                          className={`HW18 `}
                        />
                        <span
                            className={`c-font ps-2 text-uppercase text-on-hover-active-green ${
                              taskAssigneePills.length > 0 || taskAssignee.length > 0
                                ? "d-none"
                                : ""
                            } `}
                          >
                            {_l("l_assign_to")}
                            {/* Create a request/task FOR */}
                          </span>
                      </div>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                    {openAssigneeDropdown ? (
                      <AddAssigneeMainDropdownNew
                        openAssigneeDropdown={openAssigneeDropdown}
                        setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                        pageName={
                          pageName == "LiteVersion" ? "LiteVersion" : "task"
                        }
                        dropDownToggle={dropDownToggle}
                        isRequest={true}
                        selectedAssignee={selectedAssigneeNew}
                        setSelectedAssignee={setSelectedAssigneeNew}
                        saveBtnShow={true}
                        defaultSubMenuOpen={defaultSubMenuOpen}
                      />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>
                <div className={`custom-datepicker flex-grow-1 bg-white-05  withinputabsolute me-10px  on-hover-color-green on-hover-active-toggle-img h35px ${leftPanelTab !== "my-space" ? 'max-width-200px' : 'w-50'}`}>
                  <div
                    className={`align-items-center text-truncate d-flex h-100 w-100 justify-content-center light-theme-img position-relative bg-transparent  ${
                      calendarDateChanged || is_fromCalendar
                        ? "active"
                        : ""
                    }`}
                  >
                    <CalendarBlankIcon
                      className="HW18 m-0"
                    />
                    {calendarDateChanged || is_fromCalendar ?
                    <> 
                     <span className=" title-fonts">{`${moment(startDate).format(
                      "DD/MM/yyyy hh:mm a"
                    )}`}</span>
                    </>
                    :
                    <span className="ms-1">{`${_l(createTaskType == "vdi-task" ? "l_start_date" : "l_choose_when")}`}</span>
                    }
                    <DatePicker
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker"
                      timeCaption={getDatePickerTimeCaption()}
                      locale={getDatePickerLocale()}
                      className="close-toast"
                      onChange={(date) => {
                        setStartDate(date);
                        setcalendarDateChanged(true);
                      }}
                      showTimeSelect
                      selected={startDate}
                      selectsStart
                      dateFormat="dd/MM/yyyy hh:mm a"
                      calendarStartDay={1}
                    >
                      <div className="datepicker-label">
                        {_l("l_select_date_and_time")}
                      </div>
                    </DatePicker>
                  </div>
                </div>
                {/* {createTaskType == "vdi-task" ?
                <div className={`custom-datepicker flex-grow-1 bg-white-05  withinputabsolute me-10px  on-hover-color-green on-hover-active-toggle-img h35px ${leftPanelTab !== "my-space" ? 'max-width-200px' : 'w-50'}`}>
                  <div
                    className={`align-items-center text-truncate d-flex h-100 w-100 justify-content-center light-theme-img position-relative bg-transparent  ${
                      calendarEndDateChanged || is_fromCalendar
                        ? "active"
                        : ""
                    }`}
                  >
                    <CalendarBlankIcon className="HW18 m-0" />
                    {calendarEndDateChanged || is_fromCalendar ?
                    <span className=" title-fonts">{`${moment(endDate).format("DD/MM/yyyy hh:mm a")}`}</span>
                    : <span className="ms-1">{`${_l("l_end_date")}`}</span>
                    }
                    <DatePicker
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker"
                      timeCaption={getDatePickerTimeCaption()}
                      locale={getDatePickerLocale()}
                      className="close-toast"
                      onChange={(date) => {
                        setEndDate(date);
                        setCalendarEndDateChanged(true);
                      }}
                      showTimeSelect
                      selected={endDate}
                      selectsStart
                      dateFormat="dd/MM/yyyy hh:mm a"
                      calendarStartDay={1}
                    >
                      <div className="datepicker-label">
                        {_l("l_select_date_and_time")}
                      </div>
                    </DatePicker>
                  </div>
                </div>
                : <></>
                } */}
                <div className={`comman_action_icon me-10px`}>
                  <div className="white-border-box d-flex align-items-center h32w32 action_icon with_bg">
                    <div
                      className="priority-checkbox text_wrap position-relative"
                    >
                      <input
                        type="checkbox"
                        className="absolute-input"
                        id="priority"
                        checked={taskPriority == 3 ? true : false}
                        onChange={() => {
                          setTaskPriority(taskPriority == 3 ? 2 : 3);
                        }}
                      />
                      <div className="check_box">
                        <HighPriorityActiveIcon className="HW18 check_span_img active" />
                        <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                      </div>
                    </div>
                  </div>
                </div>
                <UncontrolledDropdown
                  setActiveFromChild={true}
                  direction="end"
                  className="w-200 dropdown comman_action_icon"
                >
                  <DropdownToggle className="border-0 p-0">
                    <a
                      href="#/"
                      className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="true"
                      onClick={() => {
                        setOpenSpaceListDropdown(true);
                      }}
                    >
                      <ListChecksIcon
                        className="HW18"
                      />
                    </a>
                  </DropdownToggle>
                  <DropdownItem toggle={false} className="p-0">
                  <CheckListTemplates
                    openCheckListTemplate={openCheckListTemplate}
                    setopenCheckListTemplate={setopenCheckListTemplate}
                    copyTemplateChecklist={copyTemplateChecklist}
                    templatesList={templatesList}
                    setShowChecklistTemplateModal={
                      setShowChecklistTemplateModal
                    }
                    cheklistLength={checklistItems.length}
                    setChecklistMode={setChecklistMode}
                    setShowFloorList={setShowFloorList}
                    switchToClassicView={switchToClassicView}
                    showFloorList={showFloorList}
                    checklistMode={checklistMode}
                    setSelectedFloor={setSelectedFloor}
                  />
                  </DropdownItem>
                </UncontrolledDropdown>
                {userType != "operator" &&
                    !hideBudgetField() ? (
                      <input
                        className="badge-white color-green c-font f-14 fw-semibold p-1 radius_3 text-center title-fonts border-0 border w-70px"
                        type="number"
                        placeholder={_l("l_budget")}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                        value={amount}
                      />
                    ) : (
                      <></>
                    )}
                <div className="d-flex align-items-center comman_action_icon d-none">
                    <div className="dropstart action_icon with_bg h32w32">
                      <a
                        className="line-height-0 light-theme-img d-flex w-100"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        onClick={() => {
                          handleToggleIcons();
                        }}
                      >
                        <DotsNineIcon
                          className="HW16"
                        />
                      </a>
                    </div>
                  </div>
              </div>
              {/* Create Task Request Button end  */}
              {/* create task title and icons start */}
              <div className="d-flex justify-content-between align-items-center h-100  d-none">
                <div className="d-flex align-items-center w100minus100per">
                  <div
                    className="task-creator-icon"
                    data-bs-toggle="tooltip"
                  >
                    <div className="h25w25 comman-round-box with-bg rounded-circle bg-white-05">
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                        style={{ backgroundImage: `url(${loginUserImage})` }}
                      ></div>
                    </div>
                  </div>
                  {isRequest || taskStatus == 0 ? (
                    <>
                      <div
                        className={`checklist-list flex-grow-1 line-height-1 ms-2`}
                      >
                        <textarea
                          className={
                            "w-100 bg-transparent border-0 checklist_textarea pt-0 ps-0 pe-0 line-height-1-8 max-h20vh border-bottom"
                          }
                          rows={1}
                          ref={taskNameInputHeight}
                          value={requestTitle}
                          placeholder={_l("l_task_name")}
                          onChange={(e) => {
                            setRequestTitle(e.target.value);
                            if (e.target.value.length == 0) {
                              setshowTaskList(true);
                            } else {
                              setshowTaskList(false);
                            }
                            if (e.target.value != "") {
                              setIsBlankTitle(true)
                            }
                          }}
                          onInput={(e) => {
                            adjustDynamicInputHeight(taskNameInputHeight, e);
                          }}
                          onClick={() => {
                            setShowCheckListArea(true);
                            dispatch(setCreateTaskModalIsopen(true));
                            setinputActionIconShow(false);
                          }}
                          onBlur={() => {
                            setinputActionIconShow(true);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={`checklist-list flex-grow-1 line-height-1`}>
                      <textarea
                        className={
                          "w-100 bg-transparent border-0 checklist_textarea pt-0 ps-0 pe-0 line-height-1-8 max-h20vh border-bottom"
                        }
                        rows={1}
                        ref={taskNameInputHeight}
                        placeholder={_l("l_task_name")}
                        onInput={(e) => {
                          adjustDynamicInputHeight(taskNameInputHeight, e);
                        }}
                        onClick={() => {
                          displayChecklistArea();
                        }}
                        onBlur={(e) => {
                          if (e.target.value != "") {
                            setIsBlankTitle(true)
                          }
                          setinputActionIconShow(true);
                          setTaskTitle(e.target.value);
                          setRequestTitle(e.target.value)
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex  align-items-center comman_action_icon ms-3">
                  <div className="d-flex  align-items-center">
                    {dropDownList.length > 0 ? (
                      <div className="align-item-center comman_action_icon d-flex dropdown me-10px">
                        <div className="badge rounded-pill text-nowrap list-toggle-view-none m-w100px text-truncate me-1">
                          <span className=" p-0 ">{dropDownList[0].name}</span>
                        </div>
                        {dropDownList.length > 1 ? (
                          <div
                            className={
                              "m-auto badge badge-white rounded-pill fw-light me-1 pill-task-active p-0 h20w20  d-flex"
                            }
                          >
                            <a
                              href="#/"
                              className={` m-auto`}
                              type="button"
                              id="HeaderFilterDropdown"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                            >
                              +{dropDownList.length - 1}
                            </a>
                            <HeaderFilterDropdown
                              list={dropDownList.map((data) => {
                                return {
                                  title: data.name,
                                  image: data.profile_image,
                                };
                              })}
                              pageName={"Liteversion"}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {showDefaultIcons.includes("status") ? (
                      <div className={`comman_action_icon me-10px `}>
                        <div
                          className="white-border-box color-white-60 action_icon with_bg h32w32  dropdown d-flex"
                          type="button"
                          id="TaskListStatusDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center m-auto">
                            <span
                              className={`c-dots c-10 ${
                                taskStatus == 0
                                  ? "bg-warning"
                                  : taskStatus == 5
                                  ? "done"
                                  : taskStatus == 1
                                  ? "in-progress-white"
                                  : taskStatus == 4
                                  ? "in-progress-blue"
                                  : "bg-warning"
                              } rounded-circle`}
                            ></span>
                            {!isRequest && !lockTaskStatus && (
                              <TaskListStatusDropdown
                                setTaskStatus={changeTaskStatus}
                                is_liteversion={true}
                                isRequest={isRequest}
                                istask={is_fromCalendar ? false : is_task}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {(project_id == 0 ||
                      project_id == null ||
                      project_id == undefined) &&
                    showDefaultIcons.includes("map") ? (
                      <div className="dropdown me-10px">
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32 justify-content-center align-items-center"
                          type="button"
                          id="SapceListMainDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="true"
                          onClick={() => {
                            setopenCheckListTemplate(true);
                          }}
                        >
                          <div
                            className={`on-hover-active-toggle-img text-on-hover-active-green d-flex w-100 h-100  ${
                              Object.keys(selectedSpace).length > 0 ||
                              project_id > 0
                                ? "active"
                                : ""
                            }`}
                          >
                            <MapPinIcon
                              className="HW18"
                            />
                          </div>
                        </a>
                        <SpaceListDropdown 
                          openSpaceListDropdown={openSpaceListDropdown}
                          SpaceList={spaceState}
                          setOpenSpaceListDropdown={setOpenSpaceListDropdown}
                          handler={handleSelectedSpace}
                          selectedSpace={selectedSpace}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {showDefaultIcons.includes("priority") ? (
                      <div className={`comman_action_icon  me-10px`}>
                        <div className="white-border-box d-flex align-items-center h32w32 action_icon with_bg">
                          <div
                            className="priority-checkbox text_wrap position-relative"
                          >
                            <input
                              type="checkbox"
                              className="absolute-input"
                              id="priority"
                              checked={taskPriority == 3 ? true : false}
                              onChange={() => {
                                setTaskPriority(taskPriority == 3 ? 2 : 3);
                              }}
                            />
                            <div className="check_box">
                              <HighPriorityActiveIcon className="HW18 check_span_img active" />
                              <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {showDefaultIcons.includes("pin") ? (
                      <div
                        className={`comman_action_icon me-10px`}
                      >
                        <a
                          href="#/"
                          className="action_icon with_bg h32w32  justify-content-center align-items-center"
                          onClick={() => {
                            setTaskPinned(!taskPinned);
                          }}
                        >
                          <div className="on-hover-active-toggle-img text-on-hover-active-green">
                            {taskPinned ? (
                              <PushPinFillIcon
                                className="stroke-transparent Push-Pin-fill HW18"
                              />
                            ) : (
                              <PushPinIcon
                                className="HW18"
                              />
                            )}
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {showDefaultIcons.includes("comment") ? (
                      <div
                        className={`dropend d-flex align-items-center me-10px`}
                      >
                        <UncontrolledDropdown className="w-100 d-flex">
                          <DropdownToggle
                            data-toggle="dropdown"
                            tag="div"
                            className="ps-0 d-flex"
                          >
                            <a
                              href="#/"
                              className="comman_action_icon"
                              type="button"
                              id="writeComMentDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-bs-auto-close="outside"
                            >
                              <div className="d-flex align-items-center text-on-hover-active-green on-hover-active-toggle-img">
                                <div
                                  className={`action_icon with_bg h32w32 position-relative ${
                                    commentsList.length > 0 ? "active" : ""
                                  }`}
                                >
                                  <ChatCircleIcons
                                    className="HW18"
                                  />
                                </div>
                                {commentsList.length > 0 ? (
                                  <span className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">
                                    {commentsList.length}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </a>
                          </DropdownToggle>
                          <DropdownItem toggle={false} className="p-0">
                            <CommentMinified
                              handleAddComment={handleAddEditComment}
                              id={""}
                              task={taskDetails}
                              handleImagePreview={""}
                              unContolList={true}
                              comments={commentsList}
                              deleteComment={handleDeleteComment}
                              is_liteversion={true}
                              showComments={showComments}
                            />
                          </DropdownItem>
                        </UncontrolledDropdown>
                      </div>
                    ) : (
                      <></>
                    )}
                    {showDefaultIcons.includes("checklistTemplate") ? (
                      <UncontrolledDropdown
                        setActiveFromChild={true}
                        direction="end"
                        className="w-200 dropdown comman_action_icon me-10px"
                      >
                        <DropdownToggle className="border-0 p-0">
                          <a
                            href="#/"
                            className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="true"
                            onClick={() => {
                              setOpenSpaceListDropdown(true);
                            }}
                          >
                            <ListChecksIcon
                              className="HW18 "
                            />
                          </a>
                        </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                        <CheckListTemplates
                          openCheckListTemplate={openCheckListTemplate}
                          setopenCheckListTemplate={setopenCheckListTemplate}
                          copyTemplateChecklist={copyTemplateChecklist}
                          templatesList={templatesList}
                          setShowChecklistTemplateModal={
                            setShowChecklistTemplateModal
                          }
                          cheklistLength={checklistItems.length}
                          setChecklistMode={setChecklistMode}
                          setShowFloorList={setShowFloorList}
                          switchToClassicView={switchToClassicView}
                          showFloorList={showFloorList}
                          checklistMode={checklistMode}
                          setSelectedFloor={setSelectedFloor}
                        />
                        </DropdownItem>
                      </UncontrolledDropdown>
                    ) : (
                      <></>
                    )}
                    <div
                      className={`align-items-center multi_inner_wrapper justify-content-end w-120 w-auto ${
                        taskAssigneePills.length > 0 || taskAssignee.length > 0
                          ? "d-flex"
                          : "d-none"
                      }`}
                    >
                      <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding me-10px">
                        <div
                          className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex with_separator_10 with_minus ${
                            taskAssigneePills && taskAssigneePills.length >= 1
                              ? ""
                              : "d-none"
                          }`}
                        >
                          {taskAssigneePills &&
                          taskAssigneePills.length >= 1 ? (
                            taskAssigneePills.map((assignee, index) => {
                              if (index < 1)
                                return (
                                  <div
                                    className="badge rounded-pill text-nowrap list-toggle-view-none c-font f-12 d-flex align-items-center h32wauto max-width75 ms-10px"
                                    key={index}
                                  >
                                    <span className={` p-0 text-truncate`}>
                                      {assignee.main_key == "my_task"
                                        ? "#"
                                        : ""}
                                      {_l(assignee.name)}
                                    </span>
                                  </div>
                                );
                            })
                          ) : (
                            <></>
                          )}
                          {taskAssigneePills && taskAssigneePills.length > 1 ? (
                            <a
                              href="#/"
                              className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px c-font f-12 d-flex align-items-center h32wauto"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={handleTaskAssigneeData}
                            >
                              <div className="unread_count m-auto c-font f-10">
                                +{taskAssigneePills.length - 1}
                              </div>
                            </a>
                          ) : (
                            <></>
                          )}
                          {taskAssigneePills && taskAssigneePills.length ? (
                            <GeneralListing
                              handler={() => {}}
                              list={
                                taskAssigneePills &&
                                taskAssigneePills.length &&
                                taskAssigneePills.map((item, index) => {
                                  return {
                                    name: _l(item.name),
                                    id: index,
                                  };
                                })
                              }
                              isHash={true}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator2_10 with_minus ${
                            taskAssignee && taskAssignee.length >= 1
                              ? ""
                              : "d-none"
                          }`}
                        >
                          {taskAssignee && taskAssignee.length >= 1 ? (
                            taskAssignee.map((assignee, index) => {
                              if (index < 1)
                                return (
                                  <a
                                    href="#/"
                                    data-bs-toggle="tooltip"
                                    className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                    key={index}
                                  >
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('${
                                          assignee.user_image ||
                                          assignee.profile_image
                                        }')`,
                                      }}
                                    ></div>
                                  </a>
                                );
                            })
                          ) : (
                            <></>
                          )}
                          {taskAssignee && taskAssignee.length > 1 ? (
                            <a
                              href="#/"
                              className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={handleTaskAssigneeData}
                            >
                              <div className="unread_count m-auto c-font f-12">
                                +{taskAssignee.length - 1}
                              </div>
                            </a>
                          ) : (
                            <></>
                          )}
                          {taskAssignee && taskAssignee.length ? (
                            <GeneralListing
                              handler={() => {}}
                              list={
                                taskAssignee &&
                                taskAssignee.length &&
                                taskAssignee.map((item, index) => {
                                  return {
                                    name: item.full_name || item.name,
                                    image:
                                      item.user_image || item.profile_image,
                                    id: index,
                                  };
                                })
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    {showDefaultIcons.includes("calendar") ? (
                      <div
                        className="comman_action_icon custom-datepicker withinputabsolute  me-10px w-330px"
                      >
                        <div
                          className={`action_icon with_bg h32w32  on-hover-active-toggle-img light-theme-img position-relative  ${
                            calendarDateChanged || is_fromCalendar
                              ? "active"
                              : ""
                          }`}
                        >
                          <CalendarBlankIcon
                            className="HW18"
                          />
                          <DatePicker
                            portalId="custom_datepicker"
                            calendarClassName="custom-datepicker"
                            timeCaption={getDatePickerTimeCaption()}
                            locale={getDatePickerLocale()}
                            className="close-toast"
                            onChange={(date) => {
                              setStartDate(date);
                              setcalendarDateChanged(true);
                            }}
                            showTimeSelect
                            selected={startDate}
                            selectsStart
                            dateFormat="dd/MM/yyyy hh:mm a"
                            calendarStartDay={1}
                          >
                            <div className="datepicker-label">
                              {_l("l_select_date_and_time")}
                            </div>
                          </DatePicker>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="d-flex align-items-center comman_action_icon">
                    <div className="dropstart me-10px action_icon with_bg h32w32">
                      <a
                        className="line-height-0 light-theme-img d-flex w-100"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        onClick={() => {
                          handleToggleIcons();
                        }}
                      >
                        <DotsNineIcon
                          className="HW16"
                        />
                      </a>
                      {/* <CreateTaskViewDropdown/> */}
                    </div>
                    {userType != "operator" &&
                    !hideBudgetField() ? (
                      <input
                        className="badge-white color-green c-font f-14 me-2 fw-semibold p-1 radius_3 text-center title-fonts border-0 border w-70px"
                        type="number"
                        placeholder={_l("l_budget")}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                        value={amount}
                      />
                    ) : (
                      <></>
                    )}
                    <div className={`${loder ? "for-disabled" : ""}`}>
                      <Button
                        className="h32wauto text-uppercase"
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          handleCreateTask();
                        }}
                      >
                        {loder ? _l("l_please_wait") : _l("l_create")}
                        {loder ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 `}
                            aria-hidden="true"
                          />
                        ) : (
                          <></>
                        )}
                      </Button>
                    </div>
                  </div>
                  <UncontrolledDropdown
                    className="dropend ms-10px"
                  >
                    <DropdownToggle
                      tag="a"
                      className="action_icon h32w32 with_bg on-hover-active-toggle-img p-0"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <FunnelIcon className="HW18" />
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                    <CreateTaskViewDropdown
                      checklistMode={checklistMode}
                      setChecklistMode={setChecklistMode}
                      setShowFloorList={setShowFloorList}
                      switchToClassicView={switchToClassicView}
                      setTaskPinned={setTaskPinned}
                      taskPinned={taskPinned}
                      selectedSpace={selectedSpace}
                      project_id={project_id}
                      setShowCheckListArea={setShowCheckListArea}
                    />
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>
              </div>
              {/* create task title and icons end */}
            </div>
            <div
              className={`${
                checklistItems.length > 0 && isFromLiteVersion
                  ? " border-0"
                  : ""
              }`}
            >
              {showFloorList ? (
                <React.Fragment></React.Fragment>
              ) : (
                <div
                  className={`checklist-inputbox ${
                    isFromLiteVersion ? "" : ""
                  }`}
                >
                  {isFromLiteVersion && checklistMode != "floor" ? (
                    <div className="comment-input-box d-flex ">
                      {
                        showCheckListArea || createTaskModalIsopen ? (
                          <>
                            <div
                              className={` ${
                                isFromLiteVersion ? "p-15 " : ""
                              } ${
                                checklistItems.length > 0 && isFromLiteVersion
                                  ? "pb-0"
                                  : "pt-0"
                              } w-100`}
                            >
                              <div className="form-control  d-flex align-items-center border-0 comman_action_icon pe-1 py-1 d-none">
                                <textarea
                                  className="line-height-1-8 max-h20vh border-0 bg-transparent p-0 pe-2"
                                  rows="1"
                                  ref={taskCheckListInputHeight}
                                  onPaste={handleAddChecklistItem}
                                  onKeyDown={(e) => {
                                    if (
                                      e.keyCode === 13 &&
                                      !e.ctrlKey &&
                                      !e.shiftKey
                                    ) {
                                      e.preventDefault();
                                      handleAddChecklistItem(e);
                                    }
                                  }}
                                  onInput={(e) => {
                                    adjustDynamicInputHeight(
                                      taskCheckListInputHeight,
                                      e
                                    );
                                  }}
                                  placeholder={`${_l(
                                    "l_create_checklist_item"
                                  )}`}
                                />
                                <UncontrolledDropdown
                                  setActiveFromChild={true}
                                  direction="end"
                                  className=""
                                >
                                  <DropdownToggle className="border-0  me-2 p-0 d-flex">
                                    <a
                                      href="#/"
                                      className="d-flex action_icon with_bg h32w32 d-none"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-bs-auto-close="true"
                                      onClick={() => {
                                        setOpenSpaceListDropdown(true);
                                      }}
                                    >
                                      <SelectionPlusIcon
                                        className="HW18"
                                      />
                                    </a>
                                  </DropdownToggle>
                                  <DropdownItem toggle={false} className="p-0">
                                  <CheckListTemplates
                                    openCheckListTemplate={
                                      openCheckListTemplate
                                    }
                                    setopenCheckListTemplate={
                                      setopenCheckListTemplate
                                    }
                                    copyTemplateChecklist={
                                      copyTemplateChecklist
                                    }
                                    templatesList={templatesList}
                                    setShowChecklistTemplateModal={
                                      setShowChecklistTemplateModal
                                    }
                                    cheklistLength={checklistItems.length}
                                    setChecklistMode={setChecklistMode}
                                    setShowFloorList={setShowFloorList}
                                    switchToClassicView={switchToClassicView}
                                    showFloorList={showFloorList}
                                    checklistMode={checklistMode}
                                    setSelectedFloor={setSelectedFloor}
                                  />
                                  </DropdownItem>
                                </UncontrolledDropdown>
                                <a
                                  href="#/"
                                  className={`action_icon with_bg h32w32`}
                                  onClick={handleAddChecklistItem}
                                >
                                  <ArrowRightIcon
                                    className="HW18"
                                  />
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      }
                    </div>
                  ) : (
                    <></>
                  )}
                  {isRequest || is_task ? (
                    <div className="comment-input-box">
                      {checklistMode != "floor" ? (
                        <>
                          <div className=" p-15 pt-0  w-100 pb-0">
                            <div className="form-control  d-flex align-items-center border-0 comman_action_icon pe-1 py-1 d-none">
                              <textarea
                                className="line-height-1-8 max-h20vh lightthemebgblue bg-transparent p-0 pe-2 border-0"
                                rows="1"
                                ref={taskCheckListInputHeight}
                                onInput={(e) => {
                                  adjustDynamicInputHeight(
                                    taskCheckListInputHeight,
                                    e
                                  );
                                }}
                                onPaste={handleAddChecklistItem}
                                onKeyDown={(e) => {
                                  if (
                                    e.keyCode === 13 &&
                                    !e.ctrlKey &&
                                    !e.shiftKey
                                  ) {
                                    e.preventDefault();
                                    handleAddChecklistItem(e);
                                  }
                                }}
                                placeholder={`${_l("l_create_checklist_item")}`}
                                onClick={() => setShowCheckListArea(true)}
                              />
                              <a
                                href="#/"
                                className={`action_icon with_bg h32w32`}
                                onClick={handleAddChecklistItem}
                              >
                                <ArrowRightIcon
                                  className="HW18"
                                />
                              </a>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {/* z-index-minus-1 */}
              {selectedFloor.id >= 0 ? (
                <React.Fragment>
                  <div
                    className={`d-flex justify-content-between border-bottom ps-2 p-2 bg-white-03-solid position-sticky top-0 z-index-2 d-none ${
                      isFromLiteVersion ? "mx-2 " : "w-100"
                    } `}
                  >
                    <div className="title-fonts fw-semibold">
                      {selectedFloor.title}
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="with_separator_10 d-flex align-items-center pe-0">
                        {selectedFloor.id > 0 ? (
                          <div class="d-flex align-items-center pe-3">
                            <div class="c-font color-white-80 f-12">
                              {_l("l_type_of_space_label")}:
                            </div>
                            <div class="c-font f-14 fw-semibold title-fonts ps-2">
                              {getTypeOfSpaceCount(selectedFloor.id)}
                            </div>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        <div class="d-flex align-items-center pe-3">
                          <div class="c-font color-white-80 f-12">
                            {_l("l_checklist")}:
                          </div>
                          <div class="c-font f-14 fw-semibold title-fonts ps-2">
                            {getFloorwiseChecklistCount(selectedFloor.id)}
                          </div>
                        </div>
                      </div>
                      <a
                        href="#/"
                        onClick={clearFloorFilter}
                        className="with_separator_10 before-dnone pe-0"
                      >
                        <XIcon className="HW16" />
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <></>
              )}
              {showChecklistTemplateModal ? (
                <CreateChecklistTemplate
                  handleClose={() => {
                    setShowChecklistTemplateModal(false);
                  }}
                  handleSave={saveTemplate}
                  show={showChecklistTemplateModal}
                  scheduleDetail={{
                    categoryTitle: "",
                    benchmarkId: checklistItems[0].description,
                  }}
                  loder={templateLoder}
                />
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
          {createTaskStep === "first" ? <>          
            {/* view button start  */}
            <div className="d-flex flex-column flex-grow-1 p-15 px-0">
              <div className="d-flex gap-3 justify-content-between flex-wrap flex-grow-1 h-100">
                <div className="space-select-box select-input-cox bg-transparent col-lg-6 flex-grow-1 p-0 "
                  onClick={(e) => {
                    switchToClassicView(e);
                    setShowCheckListArea(true);
                    dispatch(setCreateTaskModalIsopen(true));
                    dispatch(isBackDrop(true))
                    setCreateTaskStep("second")
                  }}
                >
                  <input className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent light-theme-border" name="tenant-type" type="radio" />
                  <a href="#/" className="check-image">
                    <div className="check-image-box">
                      <CheckIcon className="HW14 color-white-03-solid" 
                        type="radio"
                        label={_l("l_checklist_classic")}
                        id="classic"
                        name="classicview"
                        value={"classic"}
                        checked={checklistMode === "classic"}
                      />
                    </div>
                  </a>
                  <div className='h-100'>
                    <div className="d-flex flex-column justify-content-center align-items-center  bg-white-03 m-auto h-100 radius_3">
                      <CubeIcon className="HW50 stroke-width-3px m-0" />
                      <div className="text-center c-font f-14 color-white-60 fw-light mt-15px">{_l("l_simple_view")}</div>
                    </div>
                  </div>
                </div>
                <div className={`space-select-box select-input-cox bg-transparent col-lg-6 flex-grow-1 p-0 ${leftPanelTab !== "my-space" ? 'd-none' : ''} ${window.location.pathname == "/dashboard" ? "for-Disbabled" : ""}`}
                  onClick={(e) => {
                    if(window.location.pathname != "/dashboard"){
                    setChecklistMode("floor"); 
                    setShowFloorList(true);
                    dispatch(isBackDrop(true));
                    setShowCheckListArea(true);
                    dispatch(setCreateTaskModalIsopen(true));
                    setCreateTaskStep("second")
                    }
                  }}
                >
                  <input className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent light-theme-border" name="tenant-type" type="radio" />
                  <a href="#/" className="check-image">
                    <div className="check-image-box">
                      <CheckIcon className="HW14 color-white-03-solid" 
                        type="radio"
                        label={_l("l_checklist_floorwise")}
                        id="floor"
                        name="floorview"
                        value={"floor"}
                        checked={checklistMode === "floor"}
                        disabled={project_id == 0 || project_id == null || project_id == undefined && !Object.keys(selectedSpace).length > 0}/>
                    </div>
                  </a>
                  <div className='h-100'>
                    <div className="d-flex flex-column justify-content-center align-items-center bg-white-03 m-auto h-100 radius_3">
                      <BuildingsIcon className="HW50 stroke-width-3px m-0" />
                      <div className="text-center c-font f-14 color-white-60 fw-light mt-15px">{_l("l_floor_view")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* view button end  */}
            </> 
            : createTaskStep === "second" ? <>
            {/* checklist and flore view start */}
              <div
                className={`${
                  checklistItems.length > 0 && isFromLiteVersion
                    ? " border-0"
                    : ""
                }`}
              >
                {showFloorList ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <div
                    className={`checklist-inputbox ${
                      isFromLiteVersion ? "" : ""
                    }`}
                    onClick={() => {
                      if (isBackdrop == false) {
                        dispatch(isBackDrop(true));
                      }
                    }}
                  >
                    {isFromLiteVersion && checklistMode != "floor" ? (
                      <div className="comment-input-box d-flex ">
                        {
                          showCheckListArea || createTaskModalIsopen ? (
                            <>
                              <div
                                className={` ${
                                  isFromLiteVersion ? "p-15 pt-0" : ""
                                } ${
                                  checklistItems.length > 0 && isFromLiteVersion
                                    ? "pb-0"
                                    : ""
                                } w-100`}
                              >
                                <div className="form-control  d-flex align-items-center border-0 comman_action_icon pe-1 py-1">
                                  <textarea
                                    className="line-height-1-8 max-h20vh border-0 bg-transparent p-0 pe-2"
                                    rows="1"
                                    ref={taskCheckListInputHeight}
                                    onPaste={handleAddChecklistItem}
                                    onKeyDown={(e) => {
                                      if (
                                        e.keyCode === 13 &&
                                        !e.ctrlKey &&
                                        !e.shiftKey
                                      ) {
                                        e.preventDefault();
                                        handleAddChecklistItem(e);
                                      }
                                    }}
                                    onInput={(e) => {
                                      adjustDynamicInputHeight(
                                        taskCheckListInputHeight,
                                        e
                                      );
                                    }}
                                    placeholder={`${_l(
                                      "l_create_checklist_item"
                                    )}`}
                                  />
                                  <UncontrolledDropdown
                                    setActiveFromChild={true}
                                    direction="end"
                                    className=""
                                  >
                                    <DropdownToggle className="border-0  me-2 p-0 d-flex">
                                      <a
                                        href="#/"
                                        className="d-flex action_icon with_bg h32w32 d-none"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-bs-auto-close="true"
                                        onClick={() => {
                                          setOpenSpaceListDropdown(true);
                                        }}
                                      >
                                        <SelectionPlusIcon
                                          className="HW18"
                                        />
                                      </a>
                                    </DropdownToggle>
                                  <DropdownItem toggle={false} className="p-0">
                                    <CheckListTemplates
                                      openCheckListTemplate={
                                        openCheckListTemplate
                                      }
                                      setopenCheckListTemplate={
                                        setopenCheckListTemplate
                                      }
                                      copyTemplateChecklist={
                                        copyTemplateChecklist
                                      }
                                      templatesList={templatesList}
                                      setShowChecklistTemplateModal={
                                        setShowChecklistTemplateModal
                                      }
                                      cheklistLength={checklistItems.length}
                                      setChecklistMode={setChecklistMode}
                                      setShowFloorList={setShowFloorList}
                                      switchToClassicView={switchToClassicView}
                                      showFloorList={showFloorList}
                                      checklistMode={checklistMode}
                                      setSelectedFloor={setSelectedFloor}
                                    />
                                    </DropdownItem>
                                  </UncontrolledDropdown>
                                  <a
                                    href="#/"
                                    className={`action_icon with_bg h32w32`}
                                    onClick={handleAddChecklistItem}
                                  >
                                    <ArrowRightIcon
                                      className="HW18"
                                    />
                                  </a>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    ) : (
                      <></>
                    )}
                    {isRequest || is_task ? (
                      <div className="comment-input-box">
                        {checklistMode != "floor" ? (
                          <>
                            <div className=" p-15 px-0 w-100 pb-0">
                              <div className="form-control border d-flex align-items-center border-0 comman_action_icon pe-1 py-1">
                                <textarea
                                  className="line-height-1-8 max-h20vh border-0 bg-transparent p-0 pe-2"
                                  rows="1"
                                  ref={taskCheckListInputHeight}
                                  onInput={(e) => {
                                    adjustDynamicInputHeight(
                                      taskCheckListInputHeight,
                                      e
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    if (
                                      e.keyCode === 13 &&
                                      !e.ctrlKey &&
                                      !e.shiftKey
                                    ) {
                                      e.preventDefault();
                                      handleAddChecklistItem(e);
                                    }
                                  }}
                                  placeholder={`${_l("l_create_checklist_item")}`}
                                  onClick={() => setShowCheckListArea(true)}
                                />
                                <a
                                  href="#/"
                                  className={`action_icon with_bg h32w32`}
                                  onClick={handleAddChecklistItem}
                                >
                                  <ArrowRightIcon
                                    className="HW18"
                                  />
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
                {/* z-index-minus-1 */}
                {selectedFloor.id >= 0 ? (
                  <React.Fragment>
                    <div
                      className={`d-flex justify-content-between border-bottom ps-2 p-2 bg-white-03-solid position-sticky top-0 z-index-2 d-none ${
                        isFromLiteVersion ? "mx-2 " : "w-100"
                      } `}
                    >
                      <div className="title-fonts fw-semibold">
                        {selectedFloor.title}
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="with_separator_10 d-flex align-items-center pe-0">
                          {selectedFloor.id > 0 ? (
                            <div class="d-flex align-items-center pe-3">
                              <div class="c-font color-white-80 f-12">
                                {_l("l_type_of_space_label")}:
                              </div>
                              <div class="c-font f-14 fw-semibold title-fonts ps-2">
                                {getTypeOfSpaceCount(selectedFloor.id)}
                              </div>
                            </div>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          <div class="d-flex align-items-center pe-3">
                            <div class="c-font color-white-80 f-12">
                              {_l("l_checklist")}:
                            </div>
                            <div class="c-font f-14 fw-semibold title-fonts ps-2">
                              {getFloorwiseChecklistCount(selectedFloor.id)}
                            </div>
                          </div>
                        </div>
                        <a
                          href="#/"
                          onClick={clearFloorFilter}
                          className="with_separator_10 before-dnone pe-0"
                        >
                          <XIcon className="HW16" />
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <></>
                )}
                {showChecklistTemplateModal ? (
                  <CreateChecklistTemplate
                    handleClose={() => {
                      setShowChecklistTemplateModal(false);
                    }}
                    handleSave={saveTemplate}
                    show={showChecklistTemplateModal}
                    scheduleDetail={{
                      categoryTitle: "",
                      benchmarkId: checklistItems[0].description,
                    }}
                    loder={templateLoder}
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            {/* checklist and flore view end */}
            </> : <></>}
          {(checklistItems.length > 0 || checklistMode == "floor") && createTaskStep === "second" ? (
            <div
              className={`${
                isFromLiteVersion
                  ? "p-20 pt-0 flex-grow-1 overflow-auto"
                  : "p-20 pt-0 flex-grow-1 overflow-auto  pb-0 "
              }  ${
                selectedFloor.id >= 0
                  ? "d-flex flex-grow-1 overflow-hidden "
                  : "pb-0"
              }`}
            >
              <div
                className={`left-content-part w-240 p-0 res-width-100 d-flex flex-column bg-transparent ${
                  selectedFloor.id >= 0 ? "" : "d-none"
                }`}
              >
                <div className="comman-content-scroll-wrapper overflow-hiiden-web">
                  <div className="comman-content-scroll comman-verticle-tab  Type-space-verticle-tab">
                    <li
                      className={`w-100 comman-list p-0 after-none position-sticky top-0 bg-base-header-color z-index-2`}
                    >
                      <a
                        href="#/"
                        className={`position-relative tab_link border-base-header-color task-list-card p-20pximp h75w75 d-flex align-items-center no-hover light-theme-grey-light-bg-imp`}
                        onClick={clearFloorFilter}
                      >
                        <div className="d-flex align-items-center text-truncate w-100">
                          <div className="comman-list-left text-truncate flex-grow-1">
                            <div className="upload-image-preview">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="comman-list-right color-green">
                                  <CaretLeftIcon className="HW18" />
                                </div>
                                <div class="image-text w100minus30 text-truncate">
                                  <div class="text-truncate c-font f-18 fw-semibold title-fonts">
                                    {selectedFloor.title != ""
                                      ? selectedFloor.title
                                      : _l("l_floor_name")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                    {selectedFloor.id > 0 &&
                      floorDetails.type_of_space
                        .filter(
                          (w) =>
                            w.is_default == 1 ||
                            w.details.find(
                              (x) => x.floor_id == selectedFloor.id
                            )
                        )
                        .map((item, index) => {
                          let checklistCount = 0;
                          checklistCount = checklistItems.filter(
                            (ch) => ch.item_id == item.id
                          ).length;
                          return (
                            <React.Fragment>
                              <li
                                key={index}
                                className={`w-100 comman-list p-0 after-none`}
                              >
                                <a
                                  className={`position-relative tab_link border-base-header-color task-list-card p-20pximp ${
                                    selectedTypeOfSpace.id == item.id
                                      ? "active"
                                      : ""
                                  } `}
                                  onClick={() => {
                                    setSelectedTypeOfSpace(item);
                                    setSelectedTypeOfSpaceItem();
                                    jquery("#checklistCreate").focus();
                                  }}
                                >
                                  <div className="d-flex align-items-center text-truncate">
                                    <div className="comman-list-left text-truncate flex-grow-1">
                                      <div className="upload-image-preview">
                                        <div className="d-flex align-items-center justify-content-start">
                                          <div class="image-text w100minus30 text-truncate">
                                            <div class="text-truncate c-font f-14">
                                              {item.title}
                                            </div>
                                            <div class="text-truncate c-font f-11 color-white-60 "></div>
                                          </div>
                                          {checklistCount > 0 ? (
                                            <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1">
                                              <span className={` p-0 `}>
                                                {checklistCount > 99
                                                  ? `99+`
                                                  : checklistCount}
                                              </span>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="comman-list-right">
                                      <CaretRightIcon className="HW18" />
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </React.Fragment>
                          );
                        })}
                  </div>
                </div>
              </div>
              {showFloorList ? (
                <React.Fragment>
                  <div className={`${isFromLiteVersion ? "" : "pt-2"}`}>
                    <FloorPlanSummary
                      showGeneralAreaAsFloor={true}
                      hideFloorDetails={true}
                      kpiData={[
                        [
                          {
                            field: "l_type_of_space_label",
                            value: getTypeOfSpaceCount,
                          },
                          {
                            field: "l_checklist",
                            value: getFloorwiseChecklistCount,
                          },
                        ],
                      ]}
                      onFloorSelect={(floor) => {
                        applyFloorbaseFilter(floor);
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <>
                  {showCheckListArea || createTaskModalIsopen ? (
                    <div
                      className={`d-flex flex-column w-100  ${
                        checklistMode == "floor" ? "p-10 border" : ""
                      } ${isFromLiteVersion ? "pb-3" : ""}  ${
                        selectedFloor.id != 0 ? "" : ""
                      }`}
                    >
                      {(is_task || isRequest || isFromLiteVersion) &&
                      checklistMode == "floor" &&
                      selectedTypeOfSpace.id < 1 ? (
                        <>
                          <div className="comment-input-box ">
                            <textarea
                              className="form-control p-10 border-0"
                              rows={1}
                              id="checklistCreate"
                              ref={taskCheckListInputHeight}
                              onPaste={handleAddChecklistItem}
                              onKeyDown={(e) => {
                                if (
                                  e.keyCode === 13 &&
                                  !e.ctrlKey &&
                                  !e.shiftKey
                                ) {
                                  e.preventDefault();
                                  handleAddChecklistItem(e);
                                }
                              }}
                              placeholder={`${_l("l_create_checklist_item")}`}
                            />
                            <a
                              href="#/"
                              className={`send-arrow-btn right-5px`}
                              onClick={handleAddChecklistItem}
                            >
                              <ArrowRightIcon
                                className="HW18"
                              />
                            </a>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div
                        className={`checklist-listing-wrapper pt-2 ${
                          selectedFloor.id >= 0
                            ? "right-content-part width-calc-240 ps-0 overflow-hiiden-auto checklist-with-content-part"
                            : "w-100"
                        }`}
                      >
                        <React.Fragment>
                          {checklistItems.length > 0 &&
                            selectedTypeOfSpace.id === -1 &&
                            checklistItems.map((checklist, index) => {
                              defaultTopics && defaultTopics.filter((topic) => topic.main_key === "equipments")[0] && defaultTopics.filter((topic) => topic.main_key === "equipments")[0].categories.forEach((category) => {
                                checklist.equipment_quantity && checklist.equipment_quantity.forEach((equip) => {
                                if (category.filter_key == equip.equipment_id) {
                                category.sub_category.forEach((sub) => {
                                      if (sub.id == equip.equipment_quantity_id) {
                                        equip.name = sub.name
                                      }
                                    })
                                  }
                                })
                              })
                              if (
                                checklist.floor_id == selectedFloor.id &&
                                selectedTypeOfSpace.id == -1
                              ) {
                                return (
                                  <div
                                    key={index}
                                    className="align-items-center border-bottom d-flex form-check p-10 px-0 w-100 with-span"
                                  >
                                    <div className="d-flex align-items-center justify-content-between  w-100">
                                      <label className="form-check-label text_wrap flex-grow-1  form-label mb-0 d-flex">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={(e) => {
                                            handleCheckUncheckedCheckListItem(
                                              e,
                                              index,
                                              checklist
                                            );
                                          }}
                                        />
                                        <input
                                          className="mx-2 w100minus20 color-white bg-transparent border-0 text-truncate pt-0 checklist_textarea"
                                          type="text"
                                          value={checklist.description}
                                          onChange={(e) => {
                                            setChecklistItems(
                                              Object.values({
                                                ...checklistItems,
                                                [index]: {
                                                  ...checklist,
                                                  description: e.target.value,
                                                },
                                              })
                                            );
                                          }}
                                        />
                                      </label>
                                      <div
                                        className={`right-checklist-action dropend `}
                                      >
                                        <div className="d-flex align-items-center">
                                          {showCheckListIcons == index ? (
                                            <div
                                              className={`bottom-left-checklist`}
                                            >
                                              <div className="d-flex align-items-center table-list-attechment">
                                                <div
                                                  className={`bottom-right-checklist d-flex align-items-center comman_action_icon for-accessible`}
                                                >
                                                  {/* status start */}
                                                  <div
                                                    className={` me-10px`}
                                                  >
                                                    <a
                                                      className={`white-border-box color-white-60 action_icon with_bg h32w32`}
                                                      id="TaskListStatusDropdown"
                                                      aria-expanded="false"
                                                      onClick={() => {
                                                        handleTaskStatus(
                                                          checklist.id,
                                                          checklist.description,
                                                          checklist.finished,
                                                          "",
                                                          index,
                                                          checklist.priority,
                                                          checklist.status == 0
                                                            ? 2
                                                            : 0
                                                        );
                                                      }}
                                                    >
                                                      <span
                                                        className={`c-dots c-10 ${
                                                          checklist.finished ==
                                                          0
                                                            ? checklist.status ==
                                                              0
                                                              ? "in-progress-white"
                                                              : "in-progress-blue"
                                                            : "done"
                                                        } rounded-circle`}
                                                      ></span>
                                                    </a>{" "}
                                                  </div>
                                                  {/* status end */}
                                                  <div
                                                    className={`comman_action_icon me-10px`}
                                                  >
                                                    <div
                                                      className={` white-border-box d-flex align-items-center h32w32 action_icon with_bg `}
                                                    >
                                                      <div className="priority-checkbox text_wrap position-relative">
                                                        <input
                                                          type="checkbox"
                                                          className="absolute-input"
                                                          id="priority"
                                                          onClick={() => {
                                                            itemPriority(
                                                              checklist.id,
                                                              checklist.finished,
                                                              checklist.description,
                                                              checklist.priority ==
                                                                1
                                                                ? 0
                                                                : 1
                                                            );
                                                          }}
                                                          checked={
                                                            checklist.priority ==
                                                            1
                                                          }
                                                        />
                                                        <div
                                                          className="check_box"
                                                        >
                                                          <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                          <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={`dropend d-flex align-items-center me-10px`}
                                                  >
                                                    <UncontrolledDropdown
                                                      className={`w-100 d-flex align-items-center `}
                                                      direction="right"
                                                    >
                                                      <DropdownToggle
                                                        data-toggle="dropdown d-flex align-items-center"
                                                        tag="div"
                                                      >
                                                        <a
                                                          className="comman_action_icon d-flex align-items-center"
                                                          type="button"
                                                          id="writeComMentDropdown"
                                                          data-bs-toggle="dropdown"
                                                          aria-expanded="false"
                                                          data-bs-auto-close="outside"
                                                          onClick={() =>
                                                            setOpenCommentTab(
                                                              true
                                                            )
                                                          }
                                                        >
                                                          <div className="d-flex align-items-center">
                                                            <div className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img">
                                                              <ChatCircleIcons
                                                                className="HW18"
                                                              />
                                                            </div>
                                                            <span
                                                              className={`rounded-pill badge-custom p-0 ${
                                                                checklist.unread_comment_count >
                                                                0
                                                                  ? ""
                                                                  : "d-none"
                                                              }`}
                                                              data-unread-cmt-id={
                                                                checklist.id
                                                              }
                                                            >
                                                              {
                                                                checklist.unread_comment_count
                                                              }
                                                            </span>
                                                          </div>
                                                        </a>
                                                      </DropdownToggle>
                                                      <DropdownItem
                                                        toggle={false}
                                                        className="p-0"
                                                      >
                                                        {
                                                          <CommentDropdown
                                                            docType={
                                                              "imageview"
                                                            }
                                                            key={index}
                                                            openCommentTab={
                                                              openCommentTab
                                                            }
                                                            setOpenCommentTab={
                                                              setOpenCommentTab
                                                            }
                                                            projectId={
                                                              selectedSpace.project_id
                                                            }
                                                            updatedCommentCount={(
                                                              count
                                                            ) => {
                                                              setCommentCount({
                                                                ...commentCount,
                                                                [checklist.id]:
                                                                  count,
                                                              });
                                                            }}
                                                            checklistId={
                                                              checklist.id
                                                            }
                                                            allComment={1}
                                                            unContolList={true}
                                                            is_liteversion={
                                                              true
                                                            }
                                                            checklistItems={
                                                              checklistItems
                                                            }
                                                            setChecklistItems={
                                                              setChecklistItems
                                                            }
                                                            CommentFile={
                                                              checklistFiles
                                                            }
                                                            setCommentFile={
                                                              setchecklistFiles
                                                            }
                                                          />
                                                        }
                                                      </DropdownItem>
                                                    </UncontrolledDropdown>
                                                  </div>
                                                  {/* assinees start */}
                                                  <div className="d-flex align-items-center">
                                                    {checklist.assigned_details &&
                                                    (checklist.assigned_details
                                                      .full_name ||
                                                      (checklist.assigned_details &&
                                                        checklist
                                                          .assigned_details
                                                          .name)) ? (
                                                      <div className="assignee-img-with-remove pe-3">
                                                        <div
                                                          className="h20w20 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle"
                                                        >
                                                          <div
                                                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                            style={{
                                                              backgroundImage: `url('${
                                                                checklist
                                                                  .assigned_details
                                                                  .profile_image_url ||
                                                                checklist
                                                                  .assigned_details
                                                                  .profile_image
                                                              }')`,
                                                            }}
                                                          ></div>
                                                          <a
                                                            className="remove-img"
                                                            onClick={() => {
                                                              handleChecklistAssignee(
                                                                "",
                                                                checklist.id
                                                              );
                                                            }}
                                                          >
                                                            <XIcon
                                                              className="HW10"
                                                            />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                  {/* assinees end */}
                                                  {/* assigne start */}
                                                  <div
                                                    className={`dropdown me-10px `}
                                                  >
                                                    <a
                                                      className="action_icon with_bg h32wauto calendarpadding justify-content-center align-items-center"
                                                      type="button"
                                                      id="dropdownMenuButton1"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                      onClick={() => {
                                                        setOpenFollowersDropdown(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      <div className="on-hover-active-toggle-img text-on-hover-active-green color-white-80 text-nowrap">
                                                        {checklist.assigned >
                                                        0 ? (
                                                          <>
                                                            <UserCircleGearIcon
                                                              className={`HW18 `}
                                                            />
                                                            <span className="c-font f-12 ps-2 text-uppercase  text-on-hover-active-green">
                                                              {_l(
                                                                "l_assign_to"
                                                              )}
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <UserCircleGearIcon
                                                              className={`HW18 `}
                                                            />
                                                            <span className="c-font f-12 ps-2 text-uppercase  text-on-hover-active-green">
                                                              {_l(
                                                                "l_assign_to"
                                                              )}
                                                            </span>
                                                          </>
                                                        )}
                                                      </div>
                                                    </a>
                                                    <FollowersDropdown
                                                      openFollowersDropdown={
                                                        openFollowersDropdown
                                                      }
                                                      setOpenFollowersDropdown={
                                                        setOpenFollowersDropdown
                                                      }
                                                      checklistId={checklist.id}
                                                      handler={
                                                        handleChecklistAssignee
                                                      }
                                                      checklistAssigneesList={
                                                        checklistItemAssigneeList
                                                      }
                                                      is_task_checklist={1}
                                                      selectedId={
                                                        checklist.assigned_details !=
                                                        ""
                                                          ? localStorage.getItem(
                                                              "user_type"
                                                            ) == "contact"
                                                            ? checklist.assigned_details &&
                                                              checklist
                                                                .assigned_details
                                                                .id
                                                            : checklist.assigned_details &&
                                                              checklist
                                                                .assigned_details
                                                                .staffid
                                                          : 0
                                                      }
                                                    />
                                                  </div>
                                                  {/* assigne end */}
                                                  <div
                                                    className={`dropdown dropdown-center d-flex align-items-center px-0  `}
                                                  >
                                                    <a
                                                      className={`line-height-0 action_icon with_bg h32w32 me-10px `}
                                                      type="button"
                                                      id="deLeteDropdown"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <DotsThreeVerticalIcon
                                                        className="HW18"
                                                      />
                                                    </a>
                                                    <DeleteDropdown
                                                      handleDelete={() => {
                                                        handleDeleteChecklist(
                                                          checklist.description
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div
                                            className={`comman_action_icon`}
                                            onClick={() => {
                                              setShowCheckListIcons(
                                                showCheckListIcons == index
                                                  ? -1
                                                  : index
                                              );
                                            }}
                                          >
                                            <a
                                              className={`action_icon h32w32 with_bg with-border-active on-hover-active-toggle-img`}
                                            >
                                              <DotsNineIcon
                                                className="HW18"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (
                                (checklistMode === "floor" &&
                                  selectedTypeOfSpace.id == -1 &&
                                  checklist.type_of_space_item_id != 0) ||
                                (checklistMode === "floor" &&
                                  selectedTypeOfSpace.id !== -1 &&
                                  checklist.type_of_space_item_id !=
                                    selectedTypeOfSpace.id)
                              ) {
                                return <React.Fragment></React.Fragment>;
                              }
                              const attechmentCount = checklist.comments && checklist.comments.filter((comment) => comment.attachments)
                              return (
                                <div
                                  key={index}
                                  className="align-items-center border-bottom d-flex form-check p-10 px-0 w-100 with-span"
                                >
                                  <div className="d-flex align-items-center justify-content-between  w-100">
                                    <label className="form-check-label text_wrap flex-grow-1  form-label mb-0 d-flex">
                                      <input
                                        className="form-check-input 1"
                                        type="checkbox"
                                        checked={checklist.finished}
                                        onChange={(e) => {
                                          if (checklist.equipment_quantity && checklist.equipment_quantity.length > 0) {
                                            if (attechmentCount && attechmentCount.length > 0) {
                                              if (!checklist.finished) {
                                                handleCheckUncheckedCheckListItem(
                                                  e,
                                                  index,
                                                  checklist
                                                );
                                              }
                                            }else{
                                              dispatch(showMessage("unsucess",_l("l_error"),_l("l_please_add_ attachments")));
                                            }
                                          }else{
                                            handleCheckUncheckedCheckListItem(
                                              e,
                                              index,
                                              checklist
                                            );
                                          }
                                        }}
                                      />
                                      <input
                                        className="mx-2 w100minus20 color-white bg-transparent border-0 text-truncate pt-0 checklist_textarea"
                                        type="text"
                                        value={checklist.description}
                                        onChange={(e) => {
                                          setChecklistItems(
                                            Object.values({
                                              ...checklistItems,
                                              [index]: {
                                                ...checklist,
                                                description: e.target.value,
                                              },
                                            })
                                          );
                                        }}
                                      />
                                    </label>
                                    <div
                                      className={`right-checklist-action dropend `}
                                    >
                                      <div className="d-flex align-items-center">
                                        {showCheckListIcons == index ? (
                                          <div
                                            className={`bottom-left-checklist`}
                                          >
                                            <div className="d-flex align-items-center table-list-attechment">
                                              <div
                                                className={`bottom-right-checklist d-flex align-items-center comman_action_icon for-accessible`}
                                              >
                                                {/* status start */}
                                                <div
                                                  className={` me-10px`}
                                                >
                                                  <a
                                                    href="#/"
                                                    className={`white-border-box color-white-60 action_icon with_bg h32w32`}
                                                    id="TaskListStatusDropdown"
                                                    aria-expanded="false"
                                                    onClick={() => {
                                                      handleTaskStatus(
                                                        checklist.id,
                                                        checklist.description,
                                                        checklist.finished,
                                                        "",
                                                        index,
                                                        checklist.priority,
                                                        checklist.status == 0
                                                          ? 2
                                                          : 0
                                                      );
                                                    }}
                                                  >
                                                    <span
                                                      className={`c-dots c-10 ${
                                                        checklist.finished == 0
                                                          ? checklist.status ==
                                                            0
                                                            ? "in-progress-white"
                                                            : "in-progress-blue"
                                                          : "done"
                                                      } rounded-circle`}
                                                    ></span>
                                                  </a>{" "}
                                                </div>
                                                {/* status end */}
                                                <div
                                                  className={`comman_action_icon me-10px`}
                                                >
                                                  <div
                                                    className={` white-border-box d-flex align-items-center h32w32 action_icon with_bg `}
                                                  >
                                                    <div className="priority-checkbox text_wrap position-relative">
                                                      <input
                                                        type="checkbox"
                                                        className="absolute-input"
                                                        id="priority"
                                                        onClick={() => {
                                                          itemPriority(
                                                            checklist.id,
                                                            checklist.finished,
                                                            checklist.description,
                                                            checklist.priority ==
                                                              1
                                                              ? 0
                                                              : 1
                                                          );
                                                        }}
                                                        checked={
                                                          checklist.priority ==
                                                          1
                                                        }
                                                      />
                                                      <div
                                                        className="check_box"
                                                      >
                                                        <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                        <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className={`dropend d-flex align-items-center me-10px`}
                                                >
                                                  <UncontrolledDropdown
                                                    className={`w-100 d-flex align-items-center `}
                                                    direction="right"
                                                  >
                                                    <DropdownToggle
                                                      data-toggle="dropdown d-flex align-items-center"
                                                      tag="div"
                                                    >
                                                      <a
                                                        href="#/"
                                                        className="comman_action_icon d-flex align-items-center"
                                                        type="button"
                                                        id="writeComMentDropdown"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                        data-bs-auto-close="outside"
                                                        onClick={() =>
                                                          setOpenCommentTab(
                                                            true
                                                          )
                                                        }
                                                      >
                                                        <div className="d-flex align-items-center">
                                                          <div className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img">
                                                            <ChatCircleIcons
                                                              className="HW18"
                                                            />
                                                          </div>
                                                          <span
                                                            className={`rounded-pill badge-custom p-0 ${
                                                              checklist.unread_comment_count >
                                                              0
                                                                ? ""
                                                                : "d-none"
                                                            }`}
                                                            data-unread-cmt-id={
                                                              checklist.id
                                                            }
                                                          >
                                                            {
                                                              checklist.unread_comment_count
                                                            }
                                                          </span>
                                                        </div>
                                                      </a>
                                                    </DropdownToggle>
                                                    <DropdownItem
                                                      toggle={false}
                                                      className="p-0"
                                                    >
                                                      {
                                                        <CommentDropdown
                                                          docType={"imageview"}
                                                          key={index}
                                                          openCommentTab={
                                                            openCommentTab
                                                          }
                                                          setOpenCommentTab={
                                                            setOpenCommentTab
                                                          }
                                                          projectId={
                                                            selectedSpace.project_id
                                                          }
                                                          updatedCommentCount={(
                                                            count
                                                          ) => {
                                                            setCommentCount({
                                                              ...commentCount,
                                                              [checklist.id]:
                                                                count,
                                                            });
                                                          }}
                                                          checklistId={
                                                            checklist.id
                                                          }
                                                          allComment={1}
                                                          unContolList={true}
                                                          is_liteversion={true}
                                                          checklistItems={
                                                            checklistItems
                                                          }
                                                          setChecklistItems={
                                                            setChecklistItems
                                                          }
                                                          CommentFile={
                                                            checklistFiles
                                                          }
                                                          setCommentFile={
                                                            setchecklistFiles
                                                          }
                                                        />
                                                      }
                                                    </DropdownItem>
                                                  </UncontrolledDropdown>
                                                </div>
                                                {/* equipment Assigness start */}
                                                <div
                                                    className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex with_separator_10 with_minus ${
                                                      checklist.equipment_quantity && checklist.equipment_quantity.length >= 1
                                                        ? ""
                                                        : "d-none"
                                                    }`}
                                                  >
                                                    {checklist.equipment_quantity &&
                                                    checklist.equipment_quantity.length >= 1 ? (
                                                      checklist.equipment_quantity.map((assignee, index) => {
                                                        if (index < 1)
                                                          return (
                                                            <div
                                                              className="badge rounded-pill text-nowrap list-toggle-view-none c-font f-12 d-flex align-items-center  max-width75"
                                                              key={index}
                                                            >
                                                              <span className={` p-0 text-truncate c-font f-10`}>
                                                                {assignee.main_key == "my_task"
                                                                  ? "#"
                                                                  : ""}
                                                                {_l(assignee.name)}
                                                              </span>
                                                            </div>
                                                          );
                                                      })
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {checklist.equipment_quantity && checklist.equipment_quantity.length > 1 ? (
                                                      <a
                                                        href="#/"
                                                        className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px c-font f-12 d-flex align-items-center p-0"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <div className="unread_count m-auto c-font f-12
                                                        ">
                                                          +{checklist.equipment_quantity.length - 1}
                                                        </div>
                                                      </a>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {checklist.equipment_quantity && checklist.equipment_quantity.length ? (
                                                      <GeneralListing
                                                        handler={() => {}}
                                                        list={
                                                          checklist.equipment_quantity &&
                                                          checklist.equipment_quantity.length &&
                                                          checklist.equipment_quantity.map((item, index) => {
                                                            return {
                                                              name: _l(item.name),
                                                              id: index,
                                                            };
                                                          })
                                                        }
                                                        isHash={false}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                <UncontrolledDropdown className={` comman_action_icon  `}>
                                                  <div className="d-flex align-items-center m-auto light-theme-img shadow-none comman_action_icon justify-content-center">
                                                    <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                                                      <a href="#/"
                                                        className={`d-flex on-hover-active-toggle-img h32w32 with_bg action_icon me-10px 'activeicon'`}
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-auto-close="outside"
                                                        aria-expanded="false"
                                                        onClick={() => seteuipmentModals(index)}
                                                        >
                                                        <WrenchIcon
                                                        className="HW18"
                                                        /> 
                                                      </a>
                                                    </DropdownToggle>
                                                    <DropdownItem toggle={false} className="px-0"> 
                                                    {euipmentModals === index ? 
                                                      <UnControlMainFiltersDropDown 
                                                        type={"isCreateTaskCkecklist"}
                                                        data= {checklist}
                                                        addEuipmentchecklists = {handleEquipmentQuantity}
                                                        />
                                                      :<></>}
                                                    </DropdownItem>
                                                  </div>
                                                </UncontrolledDropdown>
                                                {/* equipment Assigness end */}
                                                {/* assinees start */}
                                                <div className="d-flex align-items-center">
                                                  {checklist.assigned_details &&
                                                  (checklist.assigned_details
                                                    .full_name ||
                                                    (checklist.assigned_details &&
                                                      checklist.assigned_details
                                                        .name)) ? (
                                                    <div className="assignee-img-with-remove pe-3">
                                                      <div
                                                        className="h20w20 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle"
                                                      >
                                                        <div
                                                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                          style={{
                                                            backgroundImage: `url('${
                                                              checklist
                                                                .assigned_details
                                                                .profile_image_url ||
                                                              checklist
                                                                .assigned_details
                                                                .profile_image
                                                            }')`,
                                                          }}
                                                        ></div>
                                                        <a
                                                          href="#/"
                                                          className="remove-img"
                                                          onClick={() => {
                                                            handleChecklistAssignee(
                                                              "",
                                                              checklist.id
                                                            );
                                                          }}
                                                        >
                                                          <XIcon
                                                            className="HW10"
                                                          />
                                                        </a>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                                {/* assinees end */}
                                                {/* assigne start */}
                                                <div
                                                  className={`dropdown me-10px `}
                                                >
                                                  <a
                                                    href="#/"
                                                    className="action_icon with_bg h32wauto calendarpadding justify-content-center align-items-center"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    onClick={() => {
                                                      setOpenFollowersDropdown(
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    <div className="on-hover-active-toggle-img text-on-hover-active-green color-white-80 text-nowrap">
                                                      {checklist.assigned >
                                                      0 ? (
                                                        <>
                                                          <UserCircleGearIcon
                                                              className={`HW18 active`}
                                                            />
                                                          <span className="c-font f-12 ps-2 text-uppercase text-on-hover-active-green">
                                                            {_l("l_assign_to")}
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <UserCircleGearIcon
                                                              className={`HW18 `}
                                                            />
                                                          <span className="c-font f-12 ps-2 text-uppercase text-on-hover-active-green">
                                                            {_l("l_assign_to")}
                                                          </span>
                                                        </>
                                                      )}
                                                    </div>
                                                  </a>
                                                  <FollowersDropdown
                                                    openFollowersDropdown={
                                                      openFollowersDropdown
                                                    }
                                                    setOpenFollowersDropdown={
                                                      setOpenFollowersDropdown
                                                    }
                                                    checklistId={checklist.id}
                                                    handler={
                                                      handleChecklistAssignee
                                                    }
                                                    checklistAssigneesList={
                                                      checklistItemAssigneeList
                                                    }
                                                    is_task_checklist={1}
                                                    selectedId={
                                                      checklist.assigned_details !=
                                                      ""
                                                        ? localStorage.getItem(
                                                            "user_type"
                                                          ) == "contact"
                                                          ? checklist.assigned_details &&
                                                            checklist
                                                              .assigned_details
                                                              .id
                                                          : checklist.assigned_details &&
                                                            checklist
                                                              .assigned_details
                                                              .staffid
                                                        : 0
                                                    }
                                                  />
                                                </div>
                                                {/* assigne end */}
                                                <div
                                                  className={`dropdown dropdown-center d-flex align-items-center px-0  `}
                                                >
                                                  <a
                                                    href="#/"
                                                    className={`line-height-0 action_icon with_bg h32w32 me-10px `}
                                                    type="button"
                                                    id="deLeteDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <DotsThreeVerticalIcon
                                                      className="HW18"
                                                    />
                                                  </a>
                                                  <DeleteDropdown
                                                    handleDelete={() => {
                                                      handleDeleteChecklist(
                                                        checklist.description
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div
                                          className={`comman_action_icon`}
                                          onClick={() => {
                                            setShowCheckListIcons(
                                              showCheckListIcons == index
                                                ? -1
                                                : index
                                            );
                                          }}
                                        >
                                          <a
                                            href="#/"
                                            className={`action_icon h32w32 with_bg with-border-active on-hover-active-toggle-img`}
                                          >
                                            <DotsNineIcon
                                              className="HW18"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {selectedTypeOfSpace.id > -1 ? (
                            <React.Fragment>
                              {selectedTypeOfSpace.is_default == 1 ? (
                                <React.Fragment>
                                  <div className="comment-input-box ">
                                    <textarea
                                      className="form-control p-10 mt-2"
                                      rows={1}
                                      id="checklistCreate"
                                      ref={taskCheckListInputHeight}
                                      onPaste={handleAddChecklistItem}
                                      onKeyDown={(e) => {
                                        if (
                                          e.keyCode === 13 &&
                                          !e.ctrlKey &&
                                          !e.shiftKey
                                        ) {
                                          e.preventDefault();
                                          handleAddChecklistItem(e);
                                        }
                                      }}
                                      placeholder={`${_l(
                                        "l_create_checklist_item"
                                      )}`}
                                    />
                                    <a
                                      className={`send-arrow-btn right-5px`}
                                      onClick={handleAddChecklistItem}
                                    >
                                      <ArrowRightIcon
                                        className="HW18"
                                      />
                                    </a>
                                  </div>
                                  {checklistItems
                                    .filter(
                                      (w) => w.item_id == selectedTypeOfSpace.id
                                    )
                                    .map((checklist, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="align-items-center border-bottom d-flex form-check p-10 px-0 w-100 with-span"
                                        >
                                          <div className="d-flex align-items-center justify-content-between  w-100">
                                            <label className="form-check-label text_wrap flex-grow-1  form-label mb-0 d-flex">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => {
                                                  handleCheckUncheckedCheckListItem(
                                                    e,
                                                    index,
                                                    checklist
                                                  );
                                                }}
                                              />
                                              <input
                                                className="mx-2 w100minus20 color-white bg-transparent border-0 text-truncate pt-0 checklist_textarea"
                                                type="text"
                                                value={checklist.description}
                                                onChange={(e) => {
                                                  setChecklistItems(
                                                    Object.values({
                                                      ...checklistItems,
                                                      [index]: {
                                                        ...checklist,
                                                        description:
                                                          e.target.value,
                                                      },
                                                    })
                                                  );
                                                }}
                                              />
                                            </label>
                                            <div
                                              className={`right-checklist-action dropend `}
                                            >
                                              <div className="d-flex align-items-center">
                                                {showCheckListIcons == index ? (
                                                  <div
                                                    className={`bottom-left-checklist`}
                                                  >
                                                    <div className="d-flex align-items-center table-list-attechment">
                                                      <div
                                                        className={`bottom-right-checklist d-flex align-items-center comman_action_icon for-accessible`}
                                                      >
                                                        {/* status start */}
                                                        <div
                                                          className={` me-10px`}
                                                        >
                                                          <a
                                                            className={`white-border-box color-white-60 action_icon with_bg h32w32`}
                                                            id="TaskListStatusDropdown"
                                                            aria-expanded="false"
                                                            onClick={() => {
                                                              handleTaskStatus(
                                                                checklist.id,
                                                                checklist.description,
                                                                checklist.finished,
                                                                "",
                                                                index,
                                                                checklist.priority,
                                                                checklist.status ==
                                                                  0
                                                                  ? 2
                                                                  : 0
                                                              );
                                                            }}
                                                          >
                                                            <span
                                                              className={`c-dots c-10 ${
                                                                checklist.finished ==
                                                                0
                                                                  ? checklist.status ==
                                                                    0
                                                                    ? "in-progress-white"
                                                                    : "in-progress-blue"
                                                                  : "done"
                                                              } rounded-circle`}
                                                            ></span>
                                                          </a>{" "}
                                                        </div>
                                                        {/* status end */}
                                                        <div
                                                          className={`comman_action_icon me-10px`}
                                                        >
                                                          <div
                                                            className={` white-border-box d-flex align-items-center h32w32 action_icon with_bg `}
                                                          >
                                                            <div className="priority-checkbox text_wrap position-relative">
                                                              <input
                                                                type="checkbox"
                                                                className="absolute-input"
                                                                id="priority"
                                                                onClick={() => {
                                                                  itemPriority(
                                                                    checklist.id,
                                                                    checklist.finished,
                                                                    checklist.description,
                                                                    checklist.priority ==
                                                                      1
                                                                      ? 0
                                                                      : 1
                                                                  );
                                                                }}
                                                                checked={
                                                                  checklist.priority ==
                                                                  1
                                                                }
                                                              />
                                                              <div
                                                                className="check_box"
                                                              >
                                                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                                <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className={`dropend d-flex align-items-center me-10px`}
                                                        >
                                                          <UncontrolledDropdown
                                                            className={`w-100 d-flex align-items-center `}
                                                            direction="right"
                                                          >
                                                            <DropdownToggle
                                                              data-toggle="dropdown d-flex align-items-center"
                                                              tag="div"
                                                            >
                                                              <a
                                                                className="comman_action_icon d-flex align-items-center"
                                                                type="button"
                                                                id="writeComMentDropdown"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                data-bs-auto-close="outside"
                                                                onClick={() =>
                                                                  setOpenCommentTab(
                                                                    true
                                                                  )
                                                                }
                                                              >
                                                                <div className="d-flex align-items-center">
                                                                  <div className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img">
                                                                  <ChatCircleIcons
                                                                      className="HW18"
                                                                    />
                                                                  </div>
                                                                  <span
                                                                    className={`rounded-pill badge-custom p-0 ${
                                                                      checklist.unread_comment_count >
                                                                      0
                                                                        ? ""
                                                                        : "d-none"
                                                                    }`}
                                                                    data-unread-cmt-id={
                                                                      checklist.id
                                                                    }
                                                                  >
                                                                    {
                                                                      checklist.unread_comment_count
                                                                    }
                                                                  </span>
                                                                </div>
                                                              </a>
                                                            </DropdownToggle>
                                                            <DropdownItem
                                                              toggle={false}
                                                              className="p-0"
                                                            >
                                                              {
                                                                <CommentDropdown
                                                                  docType={
                                                                    "imageview"
                                                                  }
                                                                  key={index}
                                                                  openCommentTab={
                                                                    openCommentTab
                                                                  }
                                                                  setOpenCommentTab={
                                                                    setOpenCommentTab
                                                                  }
                                                                  projectId={
                                                                    selectedSpace.project_id
                                                                  }
                                                                  updatedCommentCount={(
                                                                    count
                                                                  ) => {
                                                                    setCommentCount(
                                                                      {
                                                                        ...commentCount,
                                                                        [checklist.id]:
                                                                          count,
                                                                      }
                                                                    );
                                                                  }}
                                                                  checklistId={
                                                                    checklist.id
                                                                  }
                                                                  allComment={1}
                                                                  unContolList={
                                                                    true
                                                                  }
                                                                  is_liteversion={
                                                                    true
                                                                  }
                                                                  checklistItems={
                                                                    checklistItems
                                                                  }
                                                                  setChecklistItems={
                                                                    setChecklistItems
                                                                  }
                                                                  CommentFile={
                                                                    checklistFiles
                                                                  }
                                                                  setCommentFile={
                                                                    setchecklistFiles
                                                                  }
                                                                />
                                                              }
                                                            </DropdownItem>
                                                          </UncontrolledDropdown>
                                                        </div>
                                                        {/* assinees start */}
                                                        <div className="d-flex align-items-center">
                                                          {checklist.assigned_details &&
                                                          (checklist
                                                            .assigned_details
                                                            .full_name ||
                                                            (checklist.assigned_details &&
                                                              checklist
                                                                .assigned_details
                                                                .name)) ? (
                                                            <div className="assignee-img-with-remove pe-3">
                                                              <div
                                                                className="h20w20 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle"
                                                              >
                                                                <div
                                                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                                  style={{
                                                                    backgroundImage: `url('${
                                                                      checklist
                                                                        .assigned_details
                                                                        .profile_image_url ||
                                                                      checklist
                                                                        .assigned_details
                                                                        .profile_image
                                                                    }')`,
                                                                  }}
                                                                ></div>
                                                                <a
                                                                  className="remove-img"
                                                                  onClick={() => {
                                                                    handleChecklistAssignee(
                                                                      "",
                                                                      checklist.id
                                                                    );
                                                                  }}
                                                                >
                                                                  <XIcon
                                                                    className="HW10"
                                                                  />
                                                                </a>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                        {/* assinees end */}
                                                        {/* assigne start */}
                                                        <div
                                                          className={`dropdown me-10px `}
                                                        >
                                                          <a
                                                            className="action_icon with_bg h32wauto calendarpadding justify-content-center align-items-center"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            onClick={() => {
                                                              setOpenFollowersDropdown(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <div className="on-hover-active-toggle-img text-on-hover-active-green color-white-80 text-nowrap">
                                                              {checklist.assigned >
                                                              0 ? (
                                                                <>
                                                                  <UserCircleGearIcon
                                                                    className={`HW18 active`}
                                                                  />
                                                                  <span className="c-font f-12 ps-2 text-uppercase  text-on-hover-active-green">
                                                                    {_l(
                                                                      "l_assign_to"
                                                                    )}
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <UserCircleGearIcon
                                                                    className={`HW18`}
                                                                  />
                                                                  <span className="c-font f-12 ps-2 text-uppercase  text-on-hover-active-green">
                                                                    {_l(
                                                                      "l_assign_to"
                                                                    )}
                                                                  </span>
                                                                </>
                                                              )}
                                                            </div>
                                                          </a>
                                                          <FollowersDropdown
                                                            openFollowersDropdown={
                                                              openFollowersDropdown
                                                            }
                                                            setOpenFollowersDropdown={
                                                              setOpenFollowersDropdown
                                                            }
                                                            checklistId={
                                                              checklist.id
                                                            }
                                                            handler={
                                                              handleChecklistAssignee
                                                            }
                                                            checklistAssigneesList={
                                                              checklistItemAssigneeList
                                                            }
                                                            is_task_checklist={
                                                              1
                                                            }
                                                            selectedId={
                                                              checklist.assigned_details !=
                                                              ""
                                                                ? localStorage.getItem(
                                                                    "user_type"
                                                                  ) == "contact"
                                                                  ? checklist.assigned_details &&
                                                                    checklist
                                                                      .assigned_details
                                                                      .id
                                                                  : checklist.assigned_details &&
                                                                    checklist
                                                                      .assigned_details
                                                                      .staffid
                                                                : 0
                                                            }
                                                          />
                                                        </div>
                                                        {/* assigne end */}
                                                        <div
                                                          className={`dropdown dropdown-center d-flex align-items-center px-0  `}
                                                        >
                                                          <a
                                                            className={`line-height-0 action_icon with_bg h32w32 me-10px `}
                                                            type="button"
                                                            id="deLeteDropdown"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                          >
                                                            <DotsThreeVerticalIcon
                                                              className="HW18"
                                                            />
                                                          </a>
                                                          <DeleteDropdown
                                                            handleDelete={() => {
                                                              handleDeleteChecklist(
                                                                checklist.description
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                <div
                                                  className={`comman_action_icon`}
                                                  onClick={() => {
                                                    setShowCheckListIcons(
                                                      showCheckListIcons ==
                                                        index
                                                        ? -1
                                                        : index
                                                    );
                                                  }}
                                                >
                                                  <a
                                                    className={`action_icon h32w32 with_bg with-border-active on-hover-active-toggle-img`}
                                                  >
                                                    <DotsNineIcon
                                                      className="HW18"
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </React.Fragment>
                              ) : (
                                <div className="custom-accordian-main res-padding-right0 h-100 task-list-accordian ">
                                  <Accordion
                                    className="task-list-accordian-main mt-0"
                                    defaultActiveKey="0"
                                  >
                                    {selectedTypeOfSpace.details.map(
                                      (space, index2) => {
                                        if (
                                          selectedTypeOfSpaceItem &&
                                          selectedTypeOfSpaceItem.id != space.id
                                        ) {
                                          return (
                                            <React.Fragment></React.Fragment>
                                          );
                                        }
                                        return (
                                          <Accordion.Item
                                            eventKey={index2}
                                            className="with-pb-15"
                                          >
                                            <div className="accordian-header-box d-flex align-items-center justify-content-between  border-0 ">
                                              <Accordion.Header className="commanTaskCount bg-white-03">
                                                <div
                                                  className="row w-100"
                                                  onClick={() =>
                                                    typeOfSpaceItemToggle(space)
                                                  }
                                                >
                                                  <div className="accordion-header-wrapper w-100 ">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                      <div className="left-task-header">
                                                        <div className="title-font c-font f-12 fw-semibold ">
                                                          <span className="task-title me-2">
                                                            {space.title}
                                                          </span>
                                                          {checklistItems.filter(
                                                            (w) =>
                                                              w.type_of_space_item_id ==
                                                              space.id
                                                          ).length ? (
                                                            <div className="badge rounded-pill text-nowrap list-toggle-view-none d-inline-block me-1 h-auto">
                                                              <span className=" p-0 ">
                                                                {
                                                                  checklistItems.filter(
                                                                    (w) =>
                                                                      w.type_of_space_item_id ==
                                                                      space.id
                                                                  ).length
                                                                }
                                                              </span>
                                                            </div>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                      </div>
                                                      {selectedTypeOfSpaceItem ? (
                                                        <div className="d-flex justify-content-end">
                                                          <a className="remove-img">
                                                            <XIcon className="HW18"
                                                            />
                                                          </a>
                                                        </div>
                                                      ) : (
                                                        <div className="d-flex justify-content-end">
                                                          <a className="remove-img">
                                                            <CaretRightIcon
                                                              className="HW18"
                                                            />
                                                          </a>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </Accordion.Header>
                                            </div>
                                            <Accordion.Body className="taskCountToggle border-0">
                                              {selectedTypeOfSpaceItem ? (
                                                <div className="comment-input-box ">
                                                  <textarea
                                                    className="form-control p-10 border-0 mt-2"
                                                    rows={1}
                                                    id="checklistCreate"
                                                    ref={
                                                      taskCheckListInputHeight
                                                    }
                                                    onPaste={
                                                      handleAddChecklistItem
                                                    }
                                                    onKeyDown={(e) => {
                                                      if (
                                                        e.keyCode === 13 &&
                                                        !e.ctrlKey &&
                                                        !e.shiftKey
                                                      ) {
                                                        e.preventDefault();
                                                        handleAddChecklistItem(
                                                          e
                                                        );
                                                      }
                                                    }}
                                                    placeholder={`${_l(
                                                      "l_create_checklist_item"
                                                    )}`}
                                                  />
                                                  <a
                                                    className={`send-arrow-btn right-5px`}
                                                    onClick={
                                                      handleAddChecklistItem
                                                    }
                                                  >
                                                    <ArrowRightIcon
                                                      className="HW18"
                                                    />
                                                  </a>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                              {checklistItems
                                                .filter(
                                                  (w) =>
                                                    selectedTypeOfSpaceItem &&
                                                    w.type_of_space_item_id ==
                                                      selectedTypeOfSpaceItem.id
                                                )
                                                .map((checklist, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className="align-items-center border-bottom d-flex form-check p-10 px-0 w-100 with-span"
                                                    >
                                                      <div className="d-flex align-items-center justify-content-between  w-100">
                                                        <label className="form-check-label text_wrap flex-grow-1  form-label mb-0 d-flex">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                              handleCheckUncheckedCheckListItem(
                                                                e,
                                                                index,
                                                                checklist
                                                              );
                                                            }}
                                                          />
                                                          <input
                                                            className="mx-2 w100minus20 color-white bg-transparent border-0 text-truncate pt-0 checklist_textarea"
                                                            type="text"
                                                            value={
                                                              checklist.description
                                                            }
                                                            onChange={(e) => {
                                                              setChecklistItems(
                                                                Object.values({
                                                                  ...checklistItems,
                                                                  [index]: {
                                                                    ...checklist,
                                                                    description:
                                                                      e.target
                                                                        .value,
                                                                  },
                                                                })
                                                              );
                                                            }}
                                                          />
                                                        </label>
                                                        <div
                                                          className={`right-checklist-action dropend `}
                                                        >
                                                          <div className="d-flex align-items-center">
                                                            {showCheckListIcons ==
                                                            index ? (
                                                              <div
                                                                className={`bottom-left-checklist`}
                                                              >
                                                                <div className="d-flex align-items-center table-list-attechment">
                                                                  <div
                                                                    className={`bottom-right-checklist d-flex align-items-center comman_action_icon for-accessible`}
                                                                  >
                                                                    {/* status start */}
                                                                    <div
                                                                      className={` me-10px`}
                                                                    >
                                                                      <a
                                                                        className={`white-border-box color-white-60 action_icon with_bg h32w32`}
                                                                        id="TaskListStatusDropdown"
                                                                        aria-expanded="false"
                                                                        onClick={() => {
                                                                          handleTaskStatus(
                                                                            checklist.id,
                                                                            checklist.description,
                                                                            checklist.finished,
                                                                            "",
                                                                            index,
                                                                            checklist.priority,
                                                                            checklist.status ==
                                                                              0
                                                                              ? 2
                                                                              : 0
                                                                          );
                                                                        }}
                                                                      >
                                                                        <span
                                                                          className={`c-dots c-10 ${
                                                                            checklist.finished ==
                                                                            0
                                                                              ? checklist.status ==
                                                                                0
                                                                                ? "in-progress-white"
                                                                                : "in-progress-blue"
                                                                              : "done"
                                                                          } rounded-circle`}
                                                                        ></span>
                                                                      </a>{" "}
                                                                    </div>
                                                                    {/* status end */}
                                                                    <div
                                                                      className={`comman_action_icon me-10px`}
                                                                    >
                                                                      <div
                                                                        className={` white-border-box d-flex align-items-center h32w32 action_icon with_bg `}
                                                                      >
                                                                        <div className="priority-checkbox text_wrap position-relative">
                                                                          <input
                                                                            type="checkbox"
                                                                            className="absolute-input"
                                                                            id="priority"
                                                                            onClick={() => {
                                                                              itemPriority(
                                                                                checklist.id,
                                                                                checklist.finished,
                                                                                checklist.description,
                                                                                checklist.priority ==
                                                                                  1
                                                                                  ? 0
                                                                                  : 1
                                                                              );
                                                                            }}
                                                                            checked={
                                                                              checklist.priority ==
                                                                              1
                                                                            }
                                                                          />
                                                                          <div
                                                                            className="check_box"
                                                                          >
                                                                            <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                                            <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      className={`dropend d-flex align-items-center me-10px`}
                                                                    >
                                                                      <UncontrolledDropdown
                                                                        className={`w-100 d-flex align-items-center `}
                                                                        direction="right"
                                                                      >
                                                                        <DropdownToggle
                                                                          data-toggle="dropdown d-flex align-items-center"
                                                                          tag="div"
                                                                        >
                                                                          <a
                                                                            className="comman_action_icon d-flex align-items-center"
                                                                            type="button"
                                                                            id="writeComMentDropdown"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                            data-bs-auto-close="outside"
                                                                            onClick={() =>
                                                                              setOpenCommentTab(
                                                                                true
                                                                              )
                                                                            }
                                                                          >
                                                                            <div className="d-flex align-items-center">
                                                                              <div className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img">
                                                                                <ChatCircleIcons
                                                                                  className="HW18"
                                                                                />
                                                                              </div>
                                                                              <span
                                                                                className={`rounded-pill badge-custom p-0 ${
                                                                                  checklist.unread_comment_count >
                                                                                  0
                                                                                    ? ""
                                                                                    : "d-none"
                                                                                }`}
                                                                                data-unread-cmt-id={
                                                                                  checklist.id
                                                                                }
                                                                              >
                                                                                {
                                                                                  checklist.unread_comment_count
                                                                                }
                                                                              </span>
                                                                            </div>
                                                                          </a>
                                                                        </DropdownToggle>
                                                                        <DropdownItem
                                                                          toggle={
                                                                            false
                                                                          }
                                                                          className="p-0"
                                                                        >
                                                                          {
                                                                            <CommentDropdown
                                                                              docType={
                                                                                "imageview"
                                                                              }
                                                                              key={
                                                                                index
                                                                              }
                                                                              openCommentTab={
                                                                                openCommentTab
                                                                              }
                                                                              setOpenCommentTab={
                                                                                setOpenCommentTab
                                                                              }
                                                                              projectId={
                                                                                selectedSpace.project_id
                                                                              }
                                                                              updatedCommentCount={(
                                                                                count
                                                                              ) => {
                                                                                setCommentCount(
                                                                                  {
                                                                                    ...commentCount,
                                                                                    [checklist.id]:
                                                                                      count,
                                                                                  }
                                                                                );
                                                                              }}
                                                                              checklistId={
                                                                                checklist.id
                                                                              }
                                                                              allComment={
                                                                                1
                                                                              }
                                                                              unContolList={
                                                                                true
                                                                              }
                                                                              is_liteversion={
                                                                                true
                                                                              }
                                                                              checklistItems={
                                                                                checklistItems
                                                                              }
                                                                              setChecklistItems={
                                                                                setChecklistItems
                                                                              }
                                                                              CommentFile={
                                                                                checklistFiles
                                                                              }
                                                                              setCommentFile={
                                                                                setchecklistFiles
                                                                              }
                                                                            />
                                                                          }
                                                                        </DropdownItem>
                                                                      </UncontrolledDropdown>
                                                                    </div>
                                                                    {/* assinees start */}
                                                                    <div className="d-flex align-items-center">
                                                                      {checklist.assigned_details &&
                                                                      (checklist
                                                                        .assigned_details
                                                                        .full_name ||
                                                                        (checklist.assigned_details &&
                                                                          checklist
                                                                            .assigned_details
                                                                            .name)) ? (
                                                                        <div className="assignee-img-with-remove pe-3">
                                                                          <div
                                                                            className="h20w20 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle"
                                                                          >
                                                                            <div
                                                                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                                              style={{
                                                                                backgroundImage: `url('${
                                                                                  checklist
                                                                                    .assigned_details
                                                                                    .profile_image_url ||
                                                                                  checklist
                                                                                    .assigned_details
                                                                                    .profile_image
                                                                                }')`,
                                                                              }}
                                                                            ></div>
                                                                            <a
                                                                              className="remove-img"
                                                                              onClick={() => {
                                                                                handleChecklistAssignee(
                                                                                  "",
                                                                                  checklist.id
                                                                                );
                                                                              }}
                                                                            >
                                                                              <XIcon className="HW10"
                                                                              />
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </div>
                                                                    {/* assinees end */}
                                                                    {/* assigne start */}
                                                                    <div
                                                                      className={`dropdown me-10px `}
                                                                    >
                                                                      <a
                                                                        className="action_icon with_bg h32wauto calendarpadding justify-content-center align-items-center"
                                                                        type="button"
                                                                        id="dropdownMenuButton1"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                        onClick={() => {
                                                                          setOpenFollowersDropdown(
                                                                            true
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="on-hover-active-toggle-img text-on-hover-active-green color-white-80 text-nowrap">
                                                                          {checklist.assigned >
                                                                          0 ? (
                                                                            <>
                                                                              <UserCircleGearIcon
                                                                                className={`HW18 active`}
                                                                              />
                                                                              <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">
                                                                                {_l(
                                                                                  "l_assign_to"
                                                                                )}
                                                                              </span>
                                                                            </>
                                                                          ) : (
                                                                            <>
                                                                              <UserCircleGearIcon
                                                                                className={`HW10`}
                                                                              />
                                                                              <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">
                                                                                {_l(
                                                                                  "l_assign_to"
                                                                                )}
                                                                              </span>
                                                                            </>
                                                                          )}
                                                                        </div>
                                                                      </a>
                                                                      <FollowersDropdown
                                                                        openFollowersDropdown={
                                                                          openFollowersDropdown
                                                                        }
                                                                        setOpenFollowersDropdown={
                                                                          setOpenFollowersDropdown
                                                                        }
                                                                        checklistId={
                                                                          checklist.id
                                                                        }
                                                                        handler={
                                                                          handleChecklistAssignee
                                                                        }
                                                                        checklistAssigneesList={
                                                                          checklistItemAssigneeList
                                                                        }
                                                                        is_task_checklist={
                                                                          1
                                                                        }
                                                                        selectedId={
                                                                          checklist.assigned_details !=
                                                                          ""
                                                                            ? localStorage.getItem(
                                                                                "user_type"
                                                                              ) ==
                                                                              "contact"
                                                                              ? checklist.assigned_details &&
                                                                                checklist
                                                                                  .assigned_details
                                                                                  .id
                                                                              : checklist.assigned_details &&
                                                                                checklist
                                                                                  .assigned_details
                                                                                  .staffid
                                                                            : 0
                                                                        }
                                                                      />
                                                                    </div>
                                                                    {/* assigne end */}
                                                                    <div
                                                                      className={`dropdown dropdown-center d-flex align-items-center px-0  `}
                                                                    >
                                                                      <a
                                                                        className={`line-height-0 action_icon with_bg h32w32 me-10px `}
                                                                        type="button"
                                                                        id="deLeteDropdown"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                      >
                                                                        <DotsThreeVerticalIcon
                                                                          className="HW18"
                                                                        />
                                                                      </a>
                                                                      <DeleteDropdown
                                                                        handleDelete={() => {
                                                                          handleDeleteChecklist(
                                                                            checklist.description
                                                                          );
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <></>
                                                            )}
                                                            <div
                                                              className={`comman_action_icon`}
                                                              onClick={() => {
                                                                setShowCheckListIcons(
                                                                  showCheckListIcons ==
                                                                    index
                                                                    ? -1
                                                                    : index
                                                                );
                                                              }}
                                                            >
                                                              <a
                                                                className={`action_icon h32w32 with_bg with-border-active on-hover-active-toggle-img`}
                                                              >
                                                                <DotsNineIcon
                                                                  className="HW18"
                                                                />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        );
                                      }
                                    )}
                                  </Accordion>
                                </div>
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </React.Fragment>
                        {checklistItems.filter((ch) =>
                          selectedTypeOfSpace.id == -1
                            ? ch.type_of_space_item_id == 0
                            : ch.type_of_space_item_id == selectedTypeOfSpace.id
                        ).length == 0 &&
                        isFromLiteVersion == false &&
                        checklistItems.length == 0 ? (
                          <CommanPlaceholder
                            imgType="no-checklist"
                            mainSpacing="py-4"
                            placeholderText={_l("l_no_checklist")}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          ) : (
            <>
            {(checklistItems && checklistItems.length == 0) && createTaskStep === "second" ? (
                <CommanPlaceholder
                  imgWidth="small-image"
                  imgType="no-images"
                  mainSpacing="m-auto"
                  placeholderText={_l("l_no_checklist")}
                />
              ) : (
                <></>
              )} 
            </>
          )}
            {/* Description textarea start  */}
            <div className="p-15 pt-0 w-100 flex-grow-1 d-flex flex-column d-none">
              <textarea placeholder="Description" className="base-body-color radius_3 border-0 p-15 flex-grow-1"></textarea>
            </div>
            {/* Description textarea end  */}
          {/* File upload start */}
          {showCheckListArea || createTaskModalIsopen ? (
            <div className="mt-auto p-15 py-0">
              <div className="file-parent-box border-top position-sticky bottom-0 p-15 px-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="align-items-center bottom-0 d-flex ">
                    <div className={`file-image-list pe10px pt-0 pb-0 `}>
                      <label className="c-list-icon w-100 position-relative">
                        <div className="comman-round-box with-bg radius_3 text-center d-flex w-100 bg-white-03 upload-box align-items-center justify-content-center p-10-15">
                          <input
                            type="file"
                            className="absolute-input "
                            multiple
                            onChange={(event) => {
                              let commentsfiles = [];
                              Array.from(event.target.files).map((file) => {
                                let tempImg = file;
                                commentsfiles.push(tempImg);
                              });
                              setTaskAttachments(
                                taskAttachments.concat(commentsfiles)
                              );
                              setallAttachmentsOfTask(
                                allAttachmentsOfTask.concat(commentsfiles)
                              );
                            }}
                          />
                          <CloudArrowUpIcon
                            className="HW18 my-0 opacity70"
                          />
                          <div className="ps-10px c-font f-12 color-white-60">
                            {" "}
                            {_l("l_upload_file")}
                          </div>
                        </div>
                        <div className="custom_file d-flex align-items-center justify-content-between w-100 d-none">
                          <div className="btn btn-sm btn-white-10">
                            <span className="c-font f-10 color-white-60">
                              {" "}
                              {_l("l_upload_file")}
                            </span>
                          </div>
                          <PaperclipIcon
                            className="c-upload_icon HW16"
                          />
                        </div>
                      </label>
                    </div>
                    <div className="file-list-wrapper task-checklist-wrapper d-flex flex-column h-100 flex-grow-1">
                      <div className="d-flex flex-wrap">
                        {allAttachmentsOfTask &&
                        allAttachmentsOfTask.length > 0 ? (
                          <>
                            {allAttachmentsOfTask
                              .filter((attachment, key) => key < 4)
                              .map((img, index) => {
                                return (
                                  <>
                                    <div className="file-image-list p-0 h32w32 uploadimages flex-grow-1 me-10px">
                                      <div className="c-list-icon w-100 position-relative  dropend">
                                        <a
                                          href="#/"
                                          className="h32w32 comman-round-box with-bg radius_3 w-100 with-react-fancybox"
                                          onClick={() => {
                                            setOpenpdfGallery(true);
                                            if (getFileType(img.name) === "pdf") {
                                              setPreviewSrc([{ src : URL.createObjectURL(img), type : "pdf"}])
                                            } else {
                                              setPreviewSrc([{ src : URL.createObjectURL(img), type : "image"}]);
                                            }
                                          }}
                                        >
                                          <div
                                            className={`comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex ${
                                              index == 3 &&
                                              allAttachmentsOfTask.length > 4
                                                ? "with-overlay-img"
                                                : ""
                                            }`}
                                            style={{
                                              backgroundImage: `url('${URL.createObjectURL(
                                                img
                                              )}')`,
                                            }}
                                          >
                                            <a href="#/" className="remove-img" 
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setallAttachmentsOfTask(
                                                  allAttachmentsOfTask.filter(
                                                    (dltfile) =>
                                                      dltfile.name != img.name
                                                  )
                                                );
                                                setCommentFile(
                                                  CommentFile.filter(
                                                    (file) =>
                                                      file.name != img.name
                                                  )
                                                );
                                                setchecklistFiles(
                                                  checklistFiles.filter(
                                                    (file) =>
                                                      file.name != img.name
                                                  )
                                                );
                                              }}
                                            >
                                              <XIcon className="HW14 m-auto" />
                                            </a>
                                            {index == 3 &&
                                            allAttachmentsOfTask.length > 4 ? (
                                              <span className="m-auto position-relaive z-index-2 c-font fw-semibold title-fonts f-18 span-color">
                                                +
                                                {
                                                  +(
                                                    allAttachmentsOfTask.length -
                                                    4
                                                  )
                                                }
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${loder ? "for-disabled" : ""}`}>
                    <Button
                      className="h32wauto text-uppercase"
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        if (leftPanelTab !== "my-space") {
                          createNewTaskForSingleSpace();
                        }else{
                          handleCreateTask();
                        }
                      }}
                      disabled={createTaskStep == "second" ? false : true}
                    >
                      {loder ? _l("l_please_wait") : _l("l_create")}
                      {loder ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={`ms-2 `}
                          aria-hidden="true"
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* File upload over */}
          </div>
        </div> : <></>}
      </div>
      {openGallery && Object.keys(attachmentDetails).length > 0 ? (
        <CommanOffCanvas
          show={openGallery}
          handleClose={() => {
            setOpenGallery(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: taskDetails.id,
            projectId: localStorage.getItem("selectedOffice"),
            attachmentDetails: attachmentDetails,
            taskDetails: taskDetails,
            allAttachments: previewImages,
          }}
          docType={"imageview"}
          setSelectedId={() => {}}
          commentList={commentsList}
          selectedTaskId={taskDetails.id}
          handleParentComponentAction={() => {}}
          CloseCommentModal={() => {}}
          isChat={true}
          pageName={pageName}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default CreateTaskOffCanvasNew;
