import moment from "moment";
import { dataURItoBlob, formateDate, getBenchmarkProjectId, getClientIdByProject, getContactIdBySpace, isArrayofObjects, newFilterFormate } from "../hooks/utilities";
import store from "../store";
import jquery from "jquery";
import apiService from "./api-service/api-service";
import { clearDatabase } from "../hooks/indexed_db_helpers";

const API_URL = process.env.REACT_APP_API_URL;
const MYR_BASE_URL = process.env.REACT_APP_MYR_WEB_URL;
const CHAT_WEB_BASE_URL = process.env.REACT_APP_CHAT_WEB_BASE_URL;
const user_type = localStorage.getItem("user_type");

const getCustomerSpaces = (client_id, contact_id, pageName = "") => {
  const endPoint = API_URL + "mod=space&act=get_spaces";
  let form = new FormData();
  if (pageName != "") {
    form.append("page_name", pageName);
  }
  if (pageName == "premium_subscription") {
    form.append("is_deleted", 1);
  }
  if (
    localStorage.getItem("user_type") != "contact" &&
    localStorage.getItem("staff_id") &&
    parseInt(localStorage.getItem("staff_id")) > 0
  ) {
    form.append("staff_id", localStorage.getItem("staff_id"));
  } else if (localStorage.getItem("user_type") == "contact") {
    form.append("contact_id", contact_id);
    form.append("client_id", client_id);
  }
  form.append("is_topic", 1);

  return apiService(endPoint, "POST", true, form);
};

const getTaskCount = (
  client_id,
  contact_id,
  project_id,
  startDate = "",
  filters = [],
  last_filter = "",
  statusFilters = [],
  action = "",
  removefiltervalue = "", pageName,
  globalview = "",
  FloorId,
) => {
  let endPoint = window.location.pathname == '/MyEmail' ? API_URL + "mod=mails&act=get_mail_count_week_calendar" : API_URL + "mod=task&act=get_task_count_week_calendar";
  const leftPanelTab = store.getState().customer.leftPanelTab

  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];

  const is_init_space = localStorage.getItem("space_setup_pending");
 
  let form = new FormData();
  if (window.location.pathname != "/spaces-dashboard" && window.location.pathname != "/dashboard") {
    form.append("project_id", project_id == "noData" ? 0 : project_id);
  }
  form.append("selected_date", startDate);
  if (statusFilters && statusFilters.length) {
    statusFilters.forEach((item, index) => {
      if (item == 0) {
        form.append("is_requested", 1);
        
      } else {
        form.append(`status[${index}]`, item);
      }

    });
  }
  if (globalview == "spaceView" && FloorId > 0) {
    form.append("is_scan_3d_page", 1);
    form.append("floor_id", FloorId);
  }
  if (filters || ((!filters || !filters.length) && last_filter)) {
    newFilterFormate(filters).forEach((dd) => {
      if(localStorage.getItem("contact_role") == 3 && localStorage.getItem("currentLeftPanelTab") != "my-request"){
        if (dd.main_key == "date_range_filter") {
        form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
        }
      }else{
        form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
      }
    })
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });
   
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
            form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
          }
          form.delete("startdate");
        
        } 
      });
    });
    if (filters.length > 0 && filters.some((filter)=> filter.main_key == "unread_comments")) {
      form.delete("filters[from_date]");
      form.delete("filters[to_date]");
      // form.delete("selected_date");      
    }
  }
  if (localStorage.getItem('selectedVirtualSpace') && pageName != 'dashboard') {
    form.append(`is_virtual_space`, 1);
  }
  if (pageName == 'dashboard') {
    form.append("page_name", "dashboard_burger_menu");
  }else{
  form.append("page_name",leftPanelTab == "my-request" ? "my_task" : pageName);
  }
  if(project_id > 0 && window.location.pathname != "/spaces-dashboard"){
    form.append("is_dashboard", 0);
  }else if(spaces.length || window.location.pathname != "/spaces-dashboard") {
    form.append("is_dashboard", 1);
  }
  form.append("is_spam",localStorage.getItem("email_filter_key") == "spam" ? 1 : 0 )

  if(is_init_space > 0)
  {
    form.append("is_init_space", is_init_space);
  }

  return apiService(endPoint, "POST", true, form);
};

const getTaskList = (
  client_id,
  contact_id,
  project_id,
  startDate = "",
  page = false,
  status = [],
  filters = [],
  searchString = "",
  viewAll = 0,
  isDateActive = 1,
  start_Date = new Date(),
  endDate = new Date(),
  is_mobile = 0,
  globalView = "",
  FloorId,
  pageName
) => {
  let endPoint = window.location.pathname == '/tasks' ? API_URL + "mod=task&act=get_admin_page_tasks"  : API_URL + "mod=task&act=get_task_list";

  //Please don't remove this code it is related to slack and discord filters :) thanks
  if (filters.length) {
    if (filters[0].main_key == 'discord_channels') {
      endPoint = API_URL + "mod=discord_integration&act=get_channel_tasks";
    } else if (filters[0].main_key == 'slack_channel') {
      endPoint = API_URL + "mod=slack_api&act=get_channel_tasks";
    }
  }
  const unread_tasks =  store.getState().customer.isAppliedUnreadComment
  /////////////////////////

  let form = new FormData();
  if(window.location.pathname == "/tasks")
  {
    form.append("full_calendar", 1);
  } else {
    form.append("full_calendar", 0);
  }
  if( (project_id > 0 )){
    form.append("is_dashboard", 0);
  }else{
    form.append("is_dashboard", 1);

  }
  // if (unread_tasks == true) { 
  //   form.append("unread_tasks", 1)
  // }
  // "/aichat" 
  if((globalView == "AichatView" ||( globalView == "calendarView" && isDateActive == 0)) && ( filters.length == 0)){
    form.append(`filters[from_date]`, moment(start_Date).format("YYYY-MM-DD"));
    form.append(`filters[to_date]`, moment(endDate).format("YYYY-MM-DD"));
  }
  form.append("project_id", project_id ? project_id  : 0);
  // form.append("is_debugg_task", 1);
  if (globalView == "spaceView" && FloorId > 0) {
    form.append("floor_id", FloorId)
  }
  if (status && status.length) {
    status.forEach((item, index) => {
      if (item == 0) {
       
        form.append("is_requested", 1);
      } else {
        form.append(`status[${index}]`, item);
      }
    });
    form.append("page", page);
    // form.append("view_all", 1);
  }

  if (isDateActive == 1) {
    form.append("startdate", startDate);
  }
  if (filters && filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key] && formattedList[filter.main_key] != undefined) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
            form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
          }
          form.delete("startdate");
        }
      });
    });
    newFilterFormate(filters).forEach((fil) => {
      if(localStorage.getItem("contact_role") == 3 && localStorage.getItem("currentLeftPanelTab") != "my-request"){
        if (fil.main_key == "date_range_filter") {
        form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
        }
      }else{
        form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
      }
    })
    if (filters.length > 0 && filters.some((filter)=> filter.main_key == "unread_comments")) {
      form.delete("filters[from_date]");
      form.delete("filters[to_date]");
      
    }
  }
  if (searchString) {
    form.append(`filters[search]`, searchString);
  }
  if (localStorage.getItem('selectedVirtualSpace')) {
    form.append(`is_virtual_space`, 1);
  }
  // form.append(`is_mobile`, is_mobile);
  form.append("page_name",pageName == "my-request" ? "my_task" : pageName)

  if (globalView == "AichatView") {
    form.append("page_name","ChatAi")
    // form.append('filters[date_range_filter]','[{"main_key":"date_range_filter","categories":[{"filter_key":"all", "sub_category":[]}]}]')
  }

  let leftPanelTab = store.getState().customer.leftPanelTab;
  let userType = localStorage.getItem("user_type");
  const currPage = ["my-tenants", "my-staff"];
  const currUserType = ["contact", "staff"];
  if (currPage.includes(leftPanelTab) && currUserType.includes(userType)) {
    form.append('is_tenent', 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const getCountLockTask = (project_id, startDate, is_dashboard) => {
  // let endPoint = API_URL + "mod=task&act=get_lock_task_count";
  const globalView = store.getState().customer.globalView
  const endPoint = API_URL + "mod=task&act=get_project_task_count";

  let form = new FormData();
  if (window.location.pathname != "/spaces-dashboard") {
    form.append("project_id", project_id);
  }
  form.append("startdate", startDate);
  if (project_id > 0 ) {
    form.append("is_dashboard", 0);
  }else {
    form.append("is_dashboard", is_dashboard);
  }
  if(globalView == "AichatView"){
    form.append("page_name","ChatAi")
  }
  return apiService(endPoint, "POST", true, form)
}
const getLockScreenTaskList = (
  client_id,
  contact_id,
  project_id,
  startDate = "",
  page = false,
  status = [],
  filters = [],
  searchString = "",
  viewAll = 0,
  isDateActive = 1,
  is_dashboard
) => {
  let endPoint = API_URL + "mod=task&act=get_lock_task_list";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("is_dashboard", window.location.pathname != "/spaces-dashboard" ? 1 : is_dashboard);

  if (status && status.length) {
    status.forEach((item, index) => {
      if (item == 0) {
        form.append("is_requested", 1);
      } else {
        form.append(`status[${index}]`, item);
      }
    });
    form.append("page", page);
  }

  if (isDateActive == 1) {
    form.append("startdate", startDate);
  }

  if (filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
      // form.append(`filters[${filter.main_key}][${index}]`, filter.filter_key);
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value, index) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.append(`filters[from_date]`, localStorage.getItem("fromDate"));
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
          }
          form.delete("startdate");
        } else {
          form.append(`filters[${category}][${index}]`, value);
        }
      });
    });
  }
  if (searchString) {
    form.append(`filters[search]`, searchString);
  }
  if (localStorage.getItem('selectedVirtualSpace')) {
    form.append(`is_virtual_space`, 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const getCompanyProfile = () => {
  const endPoint = API_URL + "mod=company_profile&act=get";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getCompanyUpdate = (
  companyName,
  companyAddress,
  companyType,
  taxNumber,
  aditionalData,
  companyIdNumber,
  regionvalue,
  selectedCategories
) => {
  const endPoint = API_URL + "mod=company_profile&act=update_company_profile";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("company_name", companyName);
  form.append("address", companyAddress);
  form.append("v_t_number", taxNumber);
  form.append("company_type", "industry_type_" + companyType);
  form.append("aditional_data", aditionalData);
  form.append("company_id_number", companyIdNumber);
   selectedCategories && selectedCategories.map((category, i) => {
  form.append(`category_ids[${i}]`, category.value);
  })
  regionvalue && regionvalue.map((region, i) => {
    form.append(`region_ids[${i}]`, region.value);
    })
  return apiService(endPoint, "POST", true, form);
};

const updateCompanyLogo = ( 
  Files
  ) => {
  const endPoint = API_URL + "mod=company_profile&act=update_profile_icon&logo";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("logo",Files);
  return apiService(endPoint, "POST", true, form);
};

const getCompanyEmployee = (is_pilot_onboarding = 0, include_current_user, projectId, providerId) => {
  const endPoint = API_URL + "mod=company_profile&act=get_employees";
  const project_id = projectId || localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  if (is_pilot_onboarding > 0) { 
    form.append("is_pilot_onboarding", is_pilot_onboarding);
  }
  if(providerId)
  {
    form.append("provider_id", providerId);
  }
  if(include_current_user)
  {
    form.append("include_current_user", include_current_user);
  }
  return apiService(endPoint, "POST", true, form);
};

const addCompanyEmployee = (
  first_name,
  last_name,
  email,
  password,
  mobile_number,
  role,
  employee_type,
  location,
  post,
  joining_date,
  tab_access,
  invitee_id = 0,
  team_id = 0,
  is_pilot_onboarding,
  images,
  selectedSpace
) => {
  const endPoint = API_URL + "mod=company_profile&act=add_employee";
  let form = new FormData();
  form.append("project_id", localStorage.getItem('selectedOffice'));
  form.append("first_name", first_name);
  form.append("last_name", last_name);
  form.append("email", email);
  form.append("mobile_number", mobile_number);
  form.append("password", password);
  form.append("role", role);

  form.append("post", post);
  if (is_pilot_onboarding > 0) { 
    form.append("is_pilot_onboarding", is_pilot_onboarding);
  }
  if (selectedSpace && selectedSpace.length > 0) {
    selectedSpace.map((space,i) => {
      form.append(`project_ids[${i}]`, space.value ? space.value : space.project_id);
    })
  }
  if(images && Object.keys(images).length)
  {
    Object.keys(images).forEach((image) => {
      if(images[image].file)
      {
        switch(image)
        {
          case "l_profile_image_upload":
            form.append("profile_image", images[image].file);
            break;
          case "l_id_card_upload":
            form.append("id_card", images[image].file);
            break;
          case "l_company_id_upload":
            form.append("company_id", images[image].file);
            break;
          case "l_payment_upload":
            form.append("payment", images[image].file);
            break;
          case "l_insurance_upload":
            form.append("insurance", images[image].file);
            break;
          default:
            break;
        }
      }
    })
  }
  return apiService(endPoint, "POST", true, form);
};

const editCompanyEmployees = (
  first_name,
  last_name,
  email,
  mobile_number,
  role,
  employee_type,
  location,
  post,
  joining_date,
  tab_access,
  invitee_id = 0,
  team_id = 0,
  project_id,
  contact_id,
  staff_id , 
  password = '',
  selectedSpace,
  images, 
  project_ids, 
  client_id, 
  is_external,
  admin,
  first_message,
  system_prompt,
  topic = [],
  mobile_key,
  files = [],
  gender = '',
  assistant_role = ''
) => {
  const endPoint = API_URL + "mod=company_profile&act=edit_employee";
  let form = new FormData();
  form.append("employee_contact_id", contact_id);
  form.append("employee_staff_id", staff_id);
  form.append("project_id", localStorage.getItem('selectedOffice'));
  form.append("first_name", first_name);
  form.append("last_name", last_name);
  form.append("email", email);
  form.append("mobile_number", mobile_number);
  form.append("role", role);
  form.append("client_id", client_id);
  form.append("post", post);
  if(admin){
    form.append("is_admin", admin);
  }
  if(is_external)
  {
    form.append("is_external", is_external);
  }
  if(first_message)
  {
    form.append("first_message", first_message);
  }
  if(system_prompt)
  {
    form.append("system_prompt", system_prompt);
  }
  if(mobile_key)
  {
    form.append("mobile_key", mobile_key);
  }
  if(topic && topic.length > 0)
  {
    form.append("topic", JSON.stringify(topic));
  }
  if (password != '') {
    form.append("password", password);
  }
  if (selectedSpace && selectedSpace.length > 0) {
    selectedSpace.map((space,i) => {
      form.append(`project_ids[${i}]`, space.value ? space.value : space.project_id ? space.project_id : space);
    })
  }
  if(images && Object.keys(images).length)
  {
    Object.keys(images).forEach((image) => {
      if(images[image].file)
      {
        switch(image)
        {
          case "l_id_card_upload":
            form.append("id_card", images[image].file);
            form.append("is_active_id_card", 1);
            break;
          case "l_company_id_upload":
            form.append("company_id", images[image].file);
            form.append("is_active_company_id", 1);
            break;
          case "l_payment_upload":
            form.append("payment", images[image].file);
            form.append("is_active_payment", 1);
            break;
          case "l_insurance_upload":
            form.append("insurance", images[image].file);
            form.append("is_active_insurance", 1);
            break;
          default:
            break;
        }
      }
    })
  }
  if(project_ids && project_ids.length && typeof project_ids !== "string")
  {
    project_ids.forEach((id, index) => {
      form.append(`project_ids[${index}]`, id);
    });
  }
  if (files && files.length > 0) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if(gender){
    form.append("gender", gender);
  }
  if (assistant_role) {
    form.append("assistant_role", assistant_role);
  }
  return apiService(endPoint, "POST", true, form);
};

const getSelectedEmployeeDetails = (id) => {
  const endPoint = API_URL + "mod=company_profile&act=get_employee_data";
  let form = new FormData();
  form.append("employee_id", id);
  return apiService(endPoint, "POST", true, form);
};

const getSpaceManagerList = () => {
  const endPoint = API_URL + "mod=company_profile&act=contact_list_for_assign";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const assignSpaceManager = (project_id, managers = []) => {
  const endPoint = API_URL + "mod=company_profile&act=assign_manager";
  let form = new FormData();
  form.append("project_id", project_id);
  if (managers && managers.length) {
    managers.forEach((item, index) => {
      form.append(`managers[${index}]`, item);
    });
  }

  return apiService(endPoint, "POST", true, form);
};

const getCheckList = (task_id, project_id, hash_id = false, withAttach = false) => {
  const endPoint = API_URL + "mod=task&act=get_checklist";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("task_id", task_id);
    form.append("project_id", project_id);
  }
  if(withAttach){
    form.append("is_checklist_attachment", 1);
  }

  return apiService(endPoint, "POST", true, form);
};

const getAttachments = (task_id, project_id, checklist_id = "", hash_id = false) => {
  const endPoint = API_URL + "mod=task&act=get_attachments";
  let form = new FormData();

  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("task_id", task_id);
    form.append("project_id", project_id);
    if (checklist_id) {
      form.append("checklist_id", checklist_id);
    }
  }
  form.append("all_comments", 1);
  return apiService(endPoint, "POST", true, form);
};

const getLinks = (task_id, project_id, checklist_id = "", hash_id = false) => {
  const endPoint = API_URL + "mod=task&act=get_links";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("task_id", task_id);
    form.append("project_id", project_id);
    if (checklist_id) {
      form.append("checklist_id", checklist_id);
    }
  }

  return apiService(endPoint, "POST", true, form);
};

const getComments = (
  task_id,
  project_id,
  checklist_id = "",
  pending = 0,
  all = 0,
  rel_id = 0,
  rel_type = "",
  hash_id = 0,
  mark_as_read = 0,
  parent_comment_id = 0,
  attachment_id = 0,
) => {
  const endPoint = API_URL + "mod=comment&act=get_comments";
  const guest_id = localStorage.getItem("guest_id") ? localStorage.getItem("guest_id") : -1;
  let form = new FormData();

  form.append("task_id", task_id);
  form.append("rel_type", "task");
  form.append("rel_id", task_id);
  if (checklist_id) {
    form.append("checklist_id", checklist_id);
  }

  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);

  } else {
    form.append("project_id", project_id);
    form.append("mark_as_read", 1);
    if (pending) {
      form.append("pending_comments", 1);
    }
    if (all) {
      form.append("all_comments", 1);
    }
    if(parent_comment_id > 0){
      form.append("parent_comment_id", parent_comment_id);
    }
    if(attachment_id > 0){
      form.append("attachment_id", attachment_id);
    }


    form.append("provider_id", rel_id);
    form.append("provider_type", rel_type);
    form.append("mark_as_read", mark_as_read);
  }

  return apiService(endPoint, "POST", true, form, 0, false, guest_id);
};

const getAssignees = (task_id, checklist_id = 0) => {
  const endPoint = API_URL + "mod=task&act=get_assignees";
  let form = new FormData();
  form.append("task_id", task_id);
  if (checklist_id) {
    form.append("checklist_id", checklist_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const changePriority = (task_id, project_id, provider = '') => {
  let endPoint = API_URL + "mod=task&act=set_priority";
  if(provider === 'one_drive')
  {
    endPoint = API_URL + "mod=onedrive_integration&act=set_priority";
  }
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("provider", provider);
  return apiService(endPoint, "POST", true, form);
};

const addChecklistItem = (task_id, project_id,checklistItems) => {
  const endPoint = API_URL + "mod=task&act=add_checklist_item_bulk";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  if (checklistItems.length > 0) {
    form.append(`checklist`, JSON.stringify(checklistItems));
  }
  return apiService(endPoint, "POST", true, form);
};

const pinTask = (task_id, project_id, provider = '', file_id = "", file_name = "") => {
  let endPoint = API_URL + "mod=task&act=pin_task";
  let form = new FormData();
  if(provider === 'one_drive')
  {
    endPoint = API_URL + "mod=onedrive_integration&act=pin_document";
    form.append("file_id", file_id);
    form.append("file_name", file_name);
  }
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("provider", provider);
  return apiService(endPoint, "POST", true, form);
};

const pinProject = (project_id) => {
  const endPoint = API_URL + "mod=space&act=pin_project";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteTask = (task_id, project_id) => {
  const endPoint = API_URL + "mod=task&act=remove_task";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const addSpaceTimeSpaceTable = (date, space, typeDate) => {
  const endPoint = API_URL + "mod=services&act=add_space_time";
  let form = new FormData();
  form.append("provider_company_id", space.companyID);
  form.append("project_id", space.project_id);
  if (typeDate == "startDate") {
    form.append("start_date", moment(date).format("YYYY-MM-DD"));
  }else{
    form.append("end_date", moment(date).format("YYYY-MM-DD"));
  }
  return apiService(endPoint, "POST", true, form);
};

const addLinkComment = (
  task_id,
  project_id,
  content,
  is_link = "",
  checklist_id,
  parent_comment_id,
  is_document = 0,
  files = undefined,
  rel_id = 0,
  rel_type = "",
  hash_id = 0,
  attachment_id = 0,
  documentName = "",
  provider = "",
  meeting_link_flag = 0,
  isExternal = false,
  commentId
) => {
  const endPoint = API_URL + "mod=task&act=add_comment";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("content", content);
  form.append("is_link", is_link);
  if(meeting_link_flag == 1)
  {
    form.append("meeting_link_flag", meeting_link_flag);
  }
  if(jquery.isNumeric(task_id) === false)
  {
    form.append("documentName", documentName);
    form.append("provider", provider);
  }
  if (checklist_id) {
    form.append("checklist_id", checklist_id);
  }
  if (is_document) {
    form.append("is_document", is_document);
  }
  if (files) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
    form.append("guest_id", localStorage.getItem('guest_id'));
  }
  form.append("provider_id", rel_id);
  form.append("provider_type", rel_type);
  if(parent_comment_id > 0){
    form.append("parent_comment_id", parent_comment_id);
  }
  if(attachment_id > 0){
    form.append("attachment_id", attachment_id);
  }
  if(commentId > 0){
    form.append("comment_id", commentId);
  }

  return apiService(endPoint, "POST", true, form);
};

const updateTaskStatus = (task_id, project_id, status, provider, is_requested_task = 0, file_id = "", file_name = "") => {
  let endPoint = API_URL + "mod=task&act=change_task_status";
  let form = new FormData();
  if(provider === 'one_drive')
  {
    endPoint = API_URL + "mod=onedrive_integration&act=update_file_status";
    form.append("file_id", file_id);
    form.append("file_name", file_name);
  }
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("provider", provider);
  if (is_requested_task) {
    form.append("action", 'task_convert_to_request');
  } else {
    form.append("status", status);
  }
  return apiService(endPoint, "POST", true, form);
};

const setTag = (task_id, project_id, tag_name = "", assignTag = 0) => {
  const endPoint = API_URL + "mod=task&act=set_tag";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  if (tag_name != "") {
    form.append("tag_name", tag_name);
  } else {
    form.append("tag_id", assignTag);
  }
  return apiService(endPoint, "POST", true, form);
};

const removeTag = (task_id, project_id, tag_id) => {
  const endPoint = API_URL + "mod=task&act=remove_tag";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("tag_id", tag_id);
  return apiService(endPoint, "POST", true, form);
};

const setNote = (task_id, project_id, note) => {
  const endPoint = API_URL + "mod=task&act=set_note";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("note", note);
  return apiService(endPoint, "POST", true, form);
};

const editNote = (task_id, project_id, note, note_id) => {
  const endPoint = API_URL + "mod=task&act=set_note";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("note", note);
  form.append("note_id", note_id);
  return apiService(endPoint, "POST", true, form);
};

const removeNote = (task_id, project_id, note_id) => {
  const endPoint = API_URL + "mod=task&act=remove_note";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("note_id", note_id);
  return apiService(endPoint, "POST", true, form);
};

const getTopic = (project_id) => {
  const endPoint = API_URL + "mod=task&act=get_topic";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getDefaultTopic = (project_id, pageName,filters = [],client_id, contact_id, user_role, is_external , ) => { 
  // const endPoint = API_URL + " mod=task&act=get_default_topics";
  const leftPanelTab = store.getState().customer.leftPanelTab
  const endPoint = API_URL + "mod=task&act=get_burger_menu_topics_DEV";
  let form = new FormData();

  if(project_id) {
    form.append("project_id", project_id);
  }else{
    form.append("project_id", 0);

  }
  form.append("is_active", 0);
  form.append("page_name", leftPanelTab == "my-request" ? "homepage" : leftPanelTab);

  if (
    (localStorage.getItem("virtualSpaceClientId") &&
      localStorage.getItem("virtualSpaceClientId") == 0) ||
    localStorage.getItem("selectedVirtualSpace")
  ) {
    form.append("is_virtual_space", 1);
  }
  if (is_external > 0) {
    form.append("is_external", is_external);
    form.append("client_id", client_id);
    if (user_role == "staff") {
      form.append("staff_id", contact_id);     
    } else {
      form.append("contact_id", contact_id);
    }
  }
  if (filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
      // form.append(`filters[${filter.main_key}][${index}]`, filter.filter_key);
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value, index) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.append(`filters[from_date]`, localStorage.getItem("fromDate"));
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
          }
          form.delete("startdate");
        } else {
          form.append(`filters[${category}][${index}]`, value);
        }
      });
    });
  }

  if((project_id > 0 || localStorage.getItem('selectedOffice') > 0 ) && (project_id != null) && pageName !== "edit-space") {
    form.append("is_dashboard", 0);
  }else{
    form.append("is_dashboard", 1);
  }
  return apiService(endPoint, "POST", true, form, undefined, is_external > 0 ? true : false ,0,user_role);
};

const setTopic = (project_id, topics) => {
  const endPoint = API_URL + "mod=task&act=change_topic_status_DEV";
  let form = new FormData();
  form.append("project_id", project_id);
  for (let key in topics) {
    let index = 0;
    topics[key].forEach((topic) => {
      form.append(`topics[${key}][${index++}]`, topic);
    });
  }
  if (
    (localStorage.getItem("virtualSpaceClientId") &&
      localStorage.getItem("virtualSpaceClientId") == 0) ||
    localStorage.getItem("selectedVirtualSpace")
  ) {
    form.append("is_virtual_space", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const getTaskData = (project_id, task_id, fromCalendar = null, hash_id = 0, provider = '') => {
  const endPoint = API_URL + "mod=task&act=get_task_data";
  let form = new FormData();
  if(window.location.pathname == "/full_calendar")
  {
    form.append("full_calendar", 1);
  } else {
    form.append("full_calendar", 0);
  }
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("project_id", project_id);
    form.append("provider", provider);
    form.append("task_id", task_id);
    if (fromCalendar) {
      form.append("action", "info");
    }
  }
  return apiService(endPoint, "POST", true, form);
};

const getTopicTasks = (project_id, pageName = "", is_virtual_space = "0",startDate = "",filters = []) => {
  // const endPoint = API_URL + "mod=task&act=get_topic_tasks";
  const endPoint = API_URL + "mod=task&act=get_burger_menu_topics_DEV";
  let form = new FormData();
  if (!project_id) {
    project_id = store.getState().customer.selectedProject;
  }

  if (
    (localStorage.getItem("virtualSpaceClientId") &&
      localStorage.getItem("virtualSpaceClientId") == 0) ||
    is_virtual_space == "1" ||
    localStorage.getItem("selectedVirtualSpace")
  ) {
    if (pageName != 'dashboard') {
      form.append("is_virtual_space", 1);
    }
  }
  form.append("project_id", project_id);
  if (pageName == 'dashboard') {
    form.append("page_name", 'dashboard_burger_menu');
  } else {
    form.append("page_name", pageName);
  }
  form.append("startdate", moment(startDate).format("YYYY-MM-DD"));
  if (filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
      // form.append(`filters[${filter.main_key}][${index}]`, filter.filter_key);
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value, index) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.append(`filters[from_date]`, localStorage.getItem("fromDate"));
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
          }
          form.delete("startdate");
        } else {
          form.append(`filters[${category}][${index}]`, value);
        }
      });
    });
  }
    form.append("is_active", 1);

  return apiService(endPoint, "POST", true, form);
};

const getNotes = (project_id, task_id, hash_id = false) => {
  const endPoint = API_URL + "mod=task&act=get_notes";
  let form = new FormData();

  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("project_id", project_id);
    form.append("task_id", task_id);
  }

  return apiService(endPoint, "POST", true, form);
};

const removeLink = (project_id, comment_id, isExternal = false, guest_id =0) => {
  const endPoint = API_URL + "mod=task_detail&act=remove_comment";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("comment_id", comment_id);
  form.append("isExternal", isExternal);
  form.append("guest_id", guest_id);
  return apiService(endPoint, "POST", true, form);
};

const updateChecklistItem = (
  project_id,
  task_id,
  checklist_id,
  finished,
  description, 
  priority,
  status = 0,
  time,
  data , 
  isEuipmentChecklist = false
) => {
  const endPoint = API_URL + "mod=task&act=update_checklist_item";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  form.append("checklist_id", checklist_id);
  
  if (isEuipmentChecklist) {
    let addequipmentQuantity = [];
    let subequipmentQuantity = [];
    data.forEach((item) => {
      if (item.count > item.initialEuipment) {
        const sub_categories = item.sub_category.filter((sub) => sub.task_checklist_id == 0 && sub.is_euipment == 0);
        for (let index = 0; index < item.count - item.initialEuipment; index++) {
          addequipmentQuantity.push({equipment_id : item.filter_key, equipment_quantity_id : sub_categories[index].id})
        }
      }else if (item.count < item.initialEuipment) {
          const sub_categories = item.sub_category.filter((sub) => sub.task_checklist_id != 0 && sub.is_euipment == 1);
          for (let index = 0; index < item.initialEuipment - item.count; index++) {
            subequipmentQuantity.push({equipment_id : item.filter_key, equipment_quantity_id : sub_categories[index].id})
          }
        }
      
    })
    if (addequipmentQuantity.length > 0) {
      form.append("equipment_quantity", JSON.stringify(addequipmentQuantity));
    }
    if (subequipmentQuantity.length > 0) {
      form.append("delete_equipment_quantity", JSON.stringify(subequipmentQuantity));
    }
  }else{
    form.append("finished", finished);
    form.append("description", description);
    form.append("priority", priority);
    form.append("status", status);
    form.append("time",moment(time).format("YYYY-MM-DD HH:mm:ss"))
  }
  form.append("description", description);
  return apiService(endPoint, "POST", true, form);
};

const deleteChecklistItem = (project_id, task_id, checklist_id) => {
  const endPoint = API_URL + "mod=task&act=remove_checklist";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  form.append("checklist_id", checklist_id);
  return apiService(endPoint, "POST", true, form);
};

const editLinkComment = (
  task_id,
  project_id,
  content,
  is_link = "",
  comment_id,
  checklist_id,
  parent_comment_id,
  files = [],
  rel_id = 0,
  rel_type = "",
  hash_id = 0,
  attachment_id = 0
) => {
  const endPoint = API_URL + "mod=task&act=add_comment";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
    form.append("guest_id", localStorage.getItem('guest_id'));
  }
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("content", content);
  form.append("is_link", is_link);
  form.append("comment_id", comment_id);
  if (files.length) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  form.append("provider_id", rel_id);
  form.append("provider_type", rel_type);
  if(parent_comment_id > 0){
    form.append("parent_comment_id", parent_comment_id);
  }
  if(attachment_id > 0){
    form.append("attachment_id", attachment_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const removeComment = (project_id, comment_id,isExternal = false, guest_id =0) => {
  const endPoint = API_URL + "mod=task_detail&act=remove_comment";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("comment_id", comment_id);
  form.append("isExternal", isExternal);
  form.append("guest_id", guest_id);
  return apiService(endPoint, "POST", true, form);
};

const getCustomerAdmins = (project_id) => {
  const endPoint = API_URL + "mod=task&act=get_assigned_customer_admins";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const changeTaskProject = (project_id, task_id) => {
  const endPoint = API_URL + "mod=task&act=change_task_project";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  return apiService(endPoint, "POST", true, form);
};

const setTaskData = (
  project_id,
  task_id,
  task_name = "",
  startDate = "",
  dueDate = "",
  myr_total = 0,
  description = "",
  visible_to_client,
  visible_to_provider,
  isAttachmentTask,
  attchmentId,
  attachmentTaskId
) => {
  const endPoint = API_URL + "mod=task&act=set_task_data";
  let form = new FormData();
  form.append("project_id", project_id ? project_id : 0);
  form.append("task_id", task_id);
  if (task_name) {
    form.append("task_name", task_name);
  }
  if (startDate != "") {
    form.append("startdate", moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
  }
  if (dueDate != "") {
    form.append("duedate", moment(dueDate).format("YYYY-MM-DD HH:mm:ss"));
  }
  if (myr_total) {
    form.append("myr_total", myr_total);
  }
  if (description != "") {
    form.append("description", description);
  }
  if (visible_to_client != undefined) {
    form.append("visible_to_client", visible_to_client ? 1 : 0);
  }
  if (visible_to_provider != undefined) {
    form.append("visible_to_provider", visible_to_provider ? 1 : 0);
  }
  form.append("is_attachment_task", isAttachmentTask ? 1 : 0);
  if(isAttachmentTask){
    form.append("attachment_id", attchmentId);
    form.append("attachment_task_id", attachmentTaskId);
  } 
  if(project_id > 0 ){
    form.append("is_dashboard", 0);
  }else {
    form.append("is_dashboard", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const getDefaultTaskAssignee = (project_id, task_id = 0) => {
  const endPoint = API_URL + "mod=task&act=get_default_task_assignee";
  let form = new FormData();

  form.append("project_id", project_id);
  if (task_id) {
    form.append("task_id", task_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getNotificationList = (project_id, page) => {
  const endPoint = API_URL + "mod=task&act=get_noti_list";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("page", page);
  return apiService(endPoint, "POST", true, form);
};

const setTaskRecurring = (project_id, task_id, recurringData) => {
  const endPoint = API_URL + "mod=task&act=set_task_recurring";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);

  for (let key in recurringData) {
    form.append(key, recurringData[key]);
  }
  return apiService(endPoint, "POST", true, form);
};

const filterDashboardTasks = (project_id, filters = undefined) => {
  const endPoint = API_URL + "mod=task&act=get_list";
  let form = new FormData();
  form.append("project_id", project_id);
  if (filters) {
    for (let key in filters) {
      filters[key].forEach((filterId) => {
        form.append(
          ["my_self", "from_date", "to_date"].includes(key)
            ? `filters[${key}]`
            : `filters[${key}][]`,
          filterId
        );
      });
    }
  } else {
    form.append("startdate", moment().format("YYYY-MM-DD"));
  }
  return apiService(endPoint, "POST", true, form);
};

const createNewTask = (
  project_id, 
  is_requested = 0, 
  status = 1,
  filters = [], 
  meeting_link="", 
  task_name = "" ,
  visible_to_client ,
  visible_to_provider, 
  sub_categories ,
  priority = 2, 
  is_pinned = false, 
  comments =[], 
  checklist = [],
  files =[],
  checklistFiles = [],
  startDate,
  assigneeData,
  amount,
  createBenchmark = 0,
  FloorId = 0,
  taskAttachments = [],
  parent_id,
  createTaskType = "",
  endDate = ""
  ) => {
    const globalView = store.getState().customer.globalView

  let selectedAssigneeNew = JSON.parse(JSON.stringify(assigneeData));

  const endPoint = API_URL + "mod=task&act=init_task_DEV";
  let form = new FormData();
  const contact_role = localStorage.getItem("contact_role");
  if (user_type == "staff") { 
    const client_id = localStorage.getItem("virtualSpaceClientId");
    form.append("client_id", client_id);
  }
  if (visible_to_client != undefined) {
    form.append("visible_to_client", visible_to_client);
  }
  if (createBenchmark == 1) { 
    form.append("create_benchmark", createBenchmark);
  }
  form.append("project_id", project_id);
  form.append("status", status);
  if (is_requested) {
    form.append("is_requested", is_requested);
    form.append("myr_total", amount);
    form.append("converted_date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
  }
  if (globalView == "spaceView" && FloorId > 0) {
    form.append("floor_id", FloorId);
  }
  form.append("meetingLink", meeting_link);

  if (task_name != "") {
    form.append("task_name", task_name);
  }

  if (visible_to_provider != undefined) {
    form.append("visible_to_provider", visible_to_provider);
  }
  form.append("priority", priority);
  form.append("is_pinned", is_pinned ==  true ? 1 : 0);
  form.append("comments", JSON.stringify(comments));
  form.append("checklist", JSON.stringify(checklist));
  if (files) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if (taskAttachments && taskAttachments.length > 0) {
    Array.from(taskAttachments).map((file, index) => {
      form.append(`task_file[${index}]`, file);
    });
  }
  if (checklistFiles) {
    Array.from(checklistFiles).map((file, index) => {
      form.append(`checklistFiles_file[${index}]`, file);
    });
  }
  if(project_id == 0 || project_id == null || project_id == undefined || project_id == "null"){
    form.append("is_dashboard", 1);
  }else{
    form.append("is_dashboard", 0);
  }
  form.append("start_date", moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
  if (selectedAssigneeNew && selectedAssigneeNew.length > 0) {
    selectedAssigneeNew.map((assignee) => {
      if (assignee.categories && assignee.categories.length > 0) {
        if (assignee.main_key == "equipment" && assignee.categories[0].sub_categories.length > 0) {
          form.append(`filters[${assignee.main_key}]`, JSON.stringify([assignee]));
        } else if(is_requested && assignee.main_key == "providers"){
          assignee.categories.forEach((w, i) => {
            if(w.sub_category.length && isArrayofObjects(w.sub_category))
            {
              if(w.sub_category.find((w) => w.staffid > 0))
              {
                w.sub_category = w.sub_category.map((provider) => provider.staffid || provider.id).filter((z) => z > 0);
              }else{
                if (user_type == "operator") {
                  w.sub_category.push(localStorage.getItem("staff_id"))
                }
              }
              //Remove null values
              assignee.categories.forEach((w) => {
                w.sub_category = w.sub_category.filter((z) => z && z > 0);
              });
            }
          });
          let Obj = assignee
          if(contact_role == 3)
          {
            form.delete("visible_to_provider");
          }
          else {
            form.append("visible_to_provider", 1);
          }

          if(Obj.categories[0].filter_key == "myrai_support"){
            form.append(`filters[${assignee.main_key}]`, JSON.stringify([Obj]));
          } else { 
            form.append(`filters[${assignee.main_key}]`, JSON.stringify([Obj]));
          }
        } else {
          form.append(`filters[${assignee.main_key}]`, JSON.stringify([assignee]));
        }
      }
    })
  }
  const providerKeyIndex = selectedAssigneeNew.findIndex((w) => w.main_key === "providers");

  if(user_type === "contact")
  {
    const equipmentKeyIndex = selectedAssigneeNew.findIndex((w) => w.main_key === "equipments");
    if(equipmentKeyIndex === -1 && (providerKeyIndex === -1 || !selectedAssigneeNew[providerKeyIndex].categories.filter((w) => w.filter_key !== "myrai_support").length))
    {
      form.delete("visible_to_provider");
    }
  }

  if(selectedAssigneeNew && selectedAssigneeNew[providerKeyIndex] && selectedAssigneeNew[providerKeyIndex].categories && selectedAssigneeNew[providerKeyIndex].categories.filter((w) => w.filter_key === "myrai_support").length)
  {
    form.append("visible_to_staff", 1)
  }
  if (parent_id) {
    
    form.append("parent_id", parent_id)
  }
  if (createTaskType != "") {
    form.append("create_task_type", createTaskType);
  }
  if(createTaskType == "vdi-task" && endDate != ""){
    // form.append("end_date", moment(endDate).format("YYYY-MM-DD HH:mm:ss"));
  }

  return apiService(endPoint, "POST", true, form);
};

const CreateHomepageTasksFunction = (
  homepageTaskInput
) => {

  const endPoint = API_URL + "mod=task&act=init_task_by_projects";
  let form = new FormData();  
  let currDateTime = new Date();
  const lines = homepageTaskInput.split('\n');
  
  if(lines && lines.length > 0) {
    lines.map((item, index) => {
      form.append(`content_values[${index}]`, item);
    })
    form.append("priority", 2);
    form.append("is_dashboard", 1)
    form.append("start_date", moment(currDateTime).format("YYYY-MM-DD HH:mm:ss"));  
  }

  return apiService(endPoint, "POST", true, form);
}

const addUserByEmail = (
  email
) => {

  const endPoint = API_URL + "mod=user&act=add_user_task_email";
  let form = new FormData();  

  form.append("email", email);

  return apiService(endPoint, "POST", true, form);
}

const getUserEmail = () => {
  const endPoint = API_URL + "mod=user&act=get_user_task_email";
  let form = new FormData();  

  return apiService(endPoint, "POST", true, form);
}

const createNewTaskForSingleSpaces = (
  selectedSpaceProjectId = [], 
  is_requested = 0, 
  status = 1,
  filters = [], 
  meeting_link="", 
  task_name = "" ,
  visible_to_client ,
  visible_to_provider, 
  sub_categories ,
  priority = 2, 
  is_pinned = false, 
  comments =[], 
  checklist = [],
  files =[],
  checklistFiles = [],
  startDate,
  assigneeData,
  amount,
  createBenchmark = 0,
  FloorId = 0,
  taskAttachments = [],
  parent_id,
  createTaskType = "",
  endDate = ""
  ) => {
    const globalView = store.getState().customer.globalView

  let selectedAssigneeNew = JSON.parse(JSON.stringify(assigneeData));

  const endPoint = API_URL + "mod=task&act=init_task_by_projects";
  let form = new FormData();
  const contact_role = localStorage.getItem("contact_role");
  if (user_type == "staff") { 
    const client_id = localStorage.getItem("virtualSpaceClientId");
    form.append("client_id", client_id);
  }
  if (visible_to_client != undefined) {
    form.append("visible_to_client", visible_to_client);
  }
  if (createBenchmark == 1) { 
    form.append("create_benchmark", createBenchmark);
  }
  if (selectedSpaceProjectId && selectedSpaceProjectId.length > 0) {
    selectedSpaceProjectId.map((id, index) => {
      form.append(`project_ids[${index}]`, id);
    });
  }
  form.append("status", status);
  if (is_requested) {
    form.append("is_requested", is_requested);
    form.append("myr_total", amount);
    form.append("converted_date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
  }
  if (globalView == "spaceView" && FloorId > 0) {
    form.append("floor_id", FloorId);
  }
  form.append("meetingLink", meeting_link);

  if (task_name != "") {
    form.append("task_name", task_name);
  }

  if (visible_to_provider != undefined) {
    form.append("visible_to_provider", visible_to_provider);
  }
  form.append("priority", priority);
  form.append("is_pinned", is_pinned ==  true ? 1 : 0);
  form.append("comments", JSON.stringify(comments));
  form.append("checklist", JSON.stringify(checklist));
  if (files) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if (taskAttachments && taskAttachments.length > 0) {
    Array.from(taskAttachments).map((file, index) => {
      form.append(`task_file[${index}]`, file);
    });
  }
  if (checklistFiles) {
    Array.from(checklistFiles).map((file, index) => {
      form.append(`checklistFiles_file[${index}]`, file);
    });
  }
  if(selectedSpaceProjectId && selectedSpaceProjectId.length == 0){
    form.append("is_dashboard", 1);
  }else{
    form.append("is_dashboard", 0);
  }
  form.append("start_date", moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
  if (selectedAssigneeNew && selectedAssigneeNew.length > 0) {
    selectedAssigneeNew.map((assignee) => {
      if (assignee.categories && assignee.categories.length > 0) {
        if (assignee.main_key == "equipment" && assignee.categories[0].sub_categories.length > 0) {
          form.append(`filters[${assignee.main_key}]`, JSON.stringify([assignee]));
        } else if(is_requested && assignee.main_key == "providers"){
          assignee.categories.forEach((w, i) => {
            if(w.sub_category.length && isArrayofObjects(w.sub_category))
            {
              if(w.sub_category.find((w) => w.staffid > 0))
              {
                w.sub_category = w.sub_category.map((provider) => provider.staffid || provider.id).filter((z) => z > 0);
              }else{
                if (user_type == "operator") {
                  w.sub_category.push(localStorage.getItem("staff_id"))
                }
              }
              //Remove null values
              assignee.categories.forEach((w) => {
                w.sub_category = w.sub_category.filter((z) => z && z > 0);
              });
            }
          });
          let Obj = assignee
          if(contact_role == 3)
          {
            form.delete("visible_to_provider");
          }
          else {
            form.append("visible_to_provider", 1);
          }

          if(Obj.categories[0].filter_key == "myrai_support"){
            form.append(`filters[${assignee.main_key}]`, JSON.stringify([Obj]));
          } else { 
            form.append(`filters[${assignee.main_key}]`, JSON.stringify([Obj]));
          }
        } else {
          form.append(`filters[${assignee.main_key}]`, JSON.stringify([assignee]));
        }
      }
    })
  }
  const providerKeyIndex = selectedAssigneeNew.findIndex((w) => w.main_key === "providers");

  if(user_type === "contact")
  {
    const equipmentKeyIndex = selectedAssigneeNew.findIndex((w) => w.main_key === "equipments");
    if(equipmentKeyIndex === -1 && (providerKeyIndex === -1 || !selectedAssigneeNew[providerKeyIndex].categories.filter((w) => w.filter_key !== "myrai_support").length))
    {
      form.delete("visible_to_provider");
    }
  }

  if(selectedAssigneeNew && selectedAssigneeNew[providerKeyIndex] && selectedAssigneeNew[providerKeyIndex].categories && selectedAssigneeNew[providerKeyIndex].categories.filter((w) => w.filter_key === "myrai_support").length)
  {
    form.append("visible_to_staff", 1)
  }
  if (parent_id) {
    
    form.append("parent_id", parent_id)
  }
  if (createTaskType != "") {
    form.append("create_task_type", createTaskType);
  }
  if(createTaskType == "vdi-task" && endDate != ""){
    // form.append("end_date", moment(endDate).format("YYYY-MM-DD HH:mm:ss"));
  }

  return apiService(endPoint, "POST", true, form);
};

const setTaskAssignee = (
  project_id,
  task_id,
  task_assigne_key,
  task_assigne_value,
  date = "",
  is_requested = 0
) => {
  const endPoint = API_URL + "mod=task&act=set_task_assignee";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  form.append("task_assigne_key", task_assigne_key);
  form.append("task_assigne_value", task_assigne_value);
  if (date) {
    form.append("startdate", date);
  }
  if (is_requested == 1) form.append("is_requested", is_requested);
  return apiService(endPoint, "POST", true, form);
};

const getTaskProposals = (project_id, task_id) => {
  const endPoint = API_URL + "mod=task&act=get_task_proposal_list";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  return apiService(endPoint, "POST", true, form);
};

const getProposalsComments = (project_id, proposal_id, page_id) => {
  const endPoint = API_URL + "mod=task&act=get_proposal_comments";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("proposal_id", proposal_id);
  form.append("page_id", page_id);
  return apiService(endPoint, "POST", true, form);
};

const handleProposalLike = (project_id, proposal_id) => {
  const endPoint = API_URL + "mod=task&act=like_proposal";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("proposal_id", proposal_id);
  return apiService(endPoint, "POST", true, form);
};

const getBenchmarks = (
  project_id,
  category_id = 0,
  opEmail = "",
  status = 0,
  frequency = 0,
  typeBenchamrk = 0,
  page = 1,
  limit = 10,
  is_proposal = 0,
  taskFilters,
  equipment_id,
  is_schedule
) => {
  const endPoint = API_URL + "mod=benchmark&act=get";
  let form = new FormData();
  
  form.append("page", page);
  form.append("limit", limit);
  if (status) {
    form.append("filters[status]", status);
  }
  if (frequency) {
    form.append("filters[frequency]", frequency);
  }
  if (typeBenchamrk) {
    form.append("filters[type]", typeBenchamrk);
  }
  if (opEmail != "") {
    form.append("email", opEmail);
  }
  if (taskFilters && taskFilters.length > 0) {
    for (let i = 0; i < taskFilters.length; i++) {
      form.append(`filters[${taskFilters[i].main_key}]`, JSON.stringify([taskFilters[i]]));
    }
  }
  else {
    if(typeof equipment_id !== "undefined" && equipment_id >= 0)
    {
      form.append("equipment_id", equipment_id);
    }
    else if (category_id && !is_proposal) {
      form.append("category_id", category_id);
    }
    
  }
  if(is_proposal == 1){
    form.append("is_proposal", 1);
  }
  else {
    form.append("project_id", project_id);
  }

  if(is_schedule)
  {
    form.append("is_schedule", 1);
  }
  else {
    form.append("is_schedule", 0);
  }

  return apiService(endPoint, "POST", true, form);
};

const listBenchmarks = (
  project_id,
  category_id = 0,
  opEmail = "",
  status = 0,
  frequency = 0,
  typeBenchamrk = 0,
  page = 1,
  limit = 10,
  is_proposal = 0,
  taskFilters
) => {
  const endPoint = window.location.pathname == "/schedule" ?  API_URL + "mod=benchmark&act=schedulePage" :  API_URL + "mod=benchmark&act=benchmarkPage";
  let form = new FormData();
  
  form.append("page", page);
  form.append("limit", limit);
  if (status) {
    form.append("filters[status]", status);
  }
  if (frequency) {
    form.append("filters[frequency]", frequency);
  }
  if (typeBenchamrk) {
    form.append("filters[type]", typeBenchamrk);
  }
  if (category_id && !is_proposal) {
    form.append("category_id", category_id);
  }
  if (opEmail != "") {
    form.append("email", opEmail);
  }
  let type = "";
  if (taskFilters.length) {
    let formattedList = [];
    taskFilters.forEach((filter) => {
      type = filter.main_key;
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value, index) => {
        form.append(`filters[${category}][${index}]`, value);
      });
    });
  }
    if(type == 'date_range_filter')
    {
      form.append('fromDate', localStorage.getItem('fromDate'));
      form.append('toDate', localStorage.getItem('toDate'));
    }
  //   form.append('filtrse', taskFilters);
  // }
  form.append('selectedDate', moment(localStorage.getItem('selectedDate')).format('YYYY-MM-DD'));
  if(is_proposal == 1){
    form.append("is_proposal", 1);
  }
  else {
    form.append("project_id", project_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const addNewProposalComment = (proposal_id, project_id, comment, commentId, isDelete = 0) => {
  const endPoint = API_URL + "mod=task&act=add_proposal_comment";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("proposal_id", proposal_id);
  form.append("comment", comment);
  form.append("comment_id", commentId);
  form.append("is_delete", isDelete);
  return apiService(endPoint, "POST", true, form);
};

const getSpaceDetails = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_space_detail";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getFullCalendar = (
  project_id,
  start_date,
  end_date,
  employees = [],
  providers = [],
  equipments = [],
  taskFilters = [],
  activeTaskStatusFilters = [],
  isVirtualSpace = false,
  schedule_id
) => {
  let calendarType = '';
  const endPoint = window.location.pathname == '/full_calendar' ? API_URL + "mod=task&act=get_admin_page_tasks"  : API_URL + "mod=task&act=get_task_list";
  let form = new FormData();
  if(window.location.pathname == "/full_calendar")
  {
    form.append("full_calendar", 1);
  } else {
    form.append("full_calendar", 0);
  }
  form.append("project_id", project_id);
  if (employees.length) {
    form.append("filters[my_employee]", employees.join(","));
  }
  if (providers.length) {
    form.append("filters[my_provider]", providers.join(","));
  }
  if (equipments.length) {
    form.append("filters[my_equipment]", equipments.join(","));
  }
  if (activeTaskStatusFilters && activeTaskStatusFilters.length) {
    activeTaskStatusFilters.forEach((item, index) => {
      if (item == 0) {
        form.append("is_requested", 1);
      }else{
        form.append(`status[${index}]`, item);
      }
    });
  }
  if (taskFilters.length) {
    let formattedList = [];
    taskFilters.forEach((filter) => {
      calendarType = filter.main_key;
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value, index) => {
        form.append(`filters[${category}][${index}]`, value);
        if (value.includes('__outlook__') == true) {
          form.append(`filters[${category}][${index}]`, value.replace(new RegExp("__outlook__", "g"), '='));
        }
        if (value.includes('__google__') == true) {
          form.append(`filters[${category}][${index}]`, value.replace(new RegExp("__google__", "g"), '@'));
        }

      });
    });
  }
  if (isVirtualSpace) {
    form.append("is_virtual_space", 1);
  }
  if(schedule_id)
  {
    form.append("schedule_id", schedule_id);
  }
  if (calendarType == "outlook_calendar_filter") {
    return apiService(API_URL + "mod=outlook_calendar_integration&act=get_events", "POST", true, form);
  } else if (calendarType == "google_calendar_filter") {
    form.append('provider', 'google_calendar');
    return apiService(API_URL + "mod=google_calendar&act=get_events", "POST", true, form);
  } else {
    return apiService(endPoint, "POST", true, form);
  }
};

const getRegionList = () => {
  const endPoint = API_URL + "mod=general&act=get_region_list";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const get3DPlans = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_3d_plans";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

/**THIS FUNCTION IS FOR NEW FLOW- DO NOT REMOVE THIS */
const addEditBenchmarkStep1 = (
  project_id,
  startdate,
  links,
  need_to_hire_people,
  is_furnished,
  provider_id,
  files,
  benchmark_id,
  basic_information,
  max_budget,
  isUnKnownStartDate,
  isUnKnownBudget,
  exist_attachments = [],
  operatorName = "",
  selectContractFiles = [],
  contract_end_date = "",
  clientId,
  benchamrkProposalEmails,
  proposalToAll = 0, is_schedule,
  master_id = 0,
  all_myr_provider,
  is_update,
  custom_fields,
  dashboardBenchmarkId,
  add_custom_field = []
) => {

  const endPoint = API_URL + "mod=benchmark&act=add_benchmark"; 
  let form = new FormData();
  form.append("project_id", project_id);
  if(is_schedule)
  {
    form.append("is_schedule", 1);
  }
  else {
    form.append("is_schedule", 0);
  }

  const user_type = localStorage.getItem("user_type");

  if (user_type != "operator" && operatorName) {
    form.append("proposal_email", operatorName);
  } else if(user_type == "operator") {
    form.append("proposal_email", localStorage.getItem("email"));
  }

  if (operatorName) {
    if (contract_end_date != "" && moment(contract_end_date).format("YYYY-MM-DD") != "1970-01-01" && moment(contract_end_date).isValid()) {
      form.append("contract_end_date",  moment(contract_end_date).format("YYYY-MM-DD"));
    }
  } else {
    if (user_type != "operator") {
      form.append("links", JSON.stringify(links));

      if (proposalToAll && user_type == "contact") {
        form.append("proposal_to_all", proposalToAll);
      }
    }
  }
  if (user_type !== "operator" && !is_schedule) {
    if (benchamrkProposalEmails.length) {
      benchamrkProposalEmails.forEach((email, index) => {
        form.append(`benchamrk_proposal_emails[${index}]`, email);
      });
    }
  }
  form.append("provider_id", provider_id);
  form.append("basic_information", basic_information);
  form.append("max_budget", max_budget);
  if(isUnKnownStartDate == 1)
  {
    form.append("startdate", "");
  }
  else {
    if(moment(startdate).format("YYYY-MM-DD") != "1970-01-01")
    {
      form.append("startdate", moment(startdate).format("YYYY-MM-DD"));
    }
    else {
      form.append("startdate", "");
    }
  }

  if (user_type != "operator") {
    form.append("need_to_hire_people", need_to_hire_people ? 1 : 0);
    form.append("is_furnished", is_furnished ? 1 : 0);
  } else {
    form.append("schedule_client_id", getClientIdByProject(project_id));
    form.append("schedule_contact_id", getContactIdBySpace(project_id));
  }

  if(user_type === "staff")
  {
    form.append("client_id", clientId);
  }

  form.append("recursive", 1);
  if (benchmark_id) {
    form.append("benchmark_id", benchmark_id);
    form.append("is_update", is_update);
  } else {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
    Array.from(selectContractFiles).map((file, index) => {
      form.append(`contract_file[${index}]`, file);
    });
  }
  exist_attachments.forEach((attachment, index) => {
    form.append(`exist_attachments[${index}][file_name]`, attachment.file_name);
    form.append(`exist_attachments[${index}][file_id]`, attachment.file_id);
  });
  form.append("start_date_is_empty", isUnKnownStartDate ? 1 : 0);
  form.append("budget_is_empty", isUnKnownBudget ? 1 : 0);
  form.append("step", 1);
  if(all_myr_provider)
  {
    form.append("all_myr_provider", all_myr_provider);
  }
  if(master_id > 0)
  {
    form.append("master_id", master_id)
  }

  if(["/connect"].includes(window.location.pathname))
  {
    form.append("is_external", 1)
  }

  if(custom_fields && custom_fields.length)
  {
    form.append("custom_values", JSON.stringify(custom_fields.filter((w) => w.benchmark_value).map((field) => {
      return {
        field_id: field.id,
        value: field.benchmark_value
      }
    })));
  }

  if(!isNaN(Number(dashboardBenchmarkId)) && Number(dashboardBenchmarkId) > 0)
  {
    form.append("group_id", dashboardBenchmarkId);
  }
  if (add_custom_field && add_custom_field.length > 0) {
    form.append("custom_field", JSON.stringify(add_custom_field));
  }

  return apiService(endPoint, "POST", true, form);
};



const addEditBenchmarkStep2 = (
  project_id,
  benchmark_id,
  week_schedule,
  startdate,
  isUnKnownStartDate,
  provider_id,
  clientId
) => {
  const endPoint = API_URL + "mod=benchmark&act=add_benchmark";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_id", provider_id);
  form.append("recursive", 1);
  form.append("startdate", moment(startdate).format("YYYY-MM-DD"));
  form.append("week_schedule", JSON.stringify(week_schedule));
  if (clientId > 0 && localStorage.getItem("user_type") == "operator") {
    form.append("client_id", clientId);
  }
  if (benchmark_id) {
    form.append("benchmark_id", benchmark_id);
  }
  form.append("start_date_is_empty", isUnKnownStartDate ? 1 : 0);
  form.append("step", 3);
  return apiService(endPoint, "POST", true, form);
};

const addEditBenchmarkAccessories = (
  project_id,
  benchmark_id,
  accessories = [],
  startdate,
  isUnKnownStartDate,
  provider_id,
  clientId, save_and_send, step = 2, is_update, is_final_step
) => {
  const endPoint = API_URL + "mod=benchmark&act=add_benchmark";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_id", provider_id);
  form.append("recursive", 1);
  form.append("is_update", is_update);
  form.append("startdate", moment(startdate).format("YYYY-MM-DD"));
  if (accessories.length) {

    accessories.forEach((accessory, index) => {
      Object.keys(accessory).forEach((key) => {
        form.append(`accessories[${index}][${key}]`, accessory[key]);
      });
    });
  }
  if (clientId > 0) {
    form.append("client_id", clientId);
  }
  form.append("start_date_is_empty", isUnKnownStartDate ? 1 : 0);
  if (benchmark_id) {
    form.append("benchmark_id", benchmark_id);
  }
  form.append("step", 2);
  form.append("save_and_send", save_and_send);
  form.append("is_final_step", is_final_step);
  if(["/connect"].includes(window.location.pathname))
  {
    form.append("is_external", 1)
  }
  return apiService(endPoint, "POST", true, form);
};

/**THIS FUNCTION IS FOR NEW FLOW- DO NOT REMOVE THIS */

const addEditBenchmarkStep3 = (
  project_id,
  benchmark_id,
  startdate,
  isUnKnownStartDate,
  provider_id,
  list,
  save_and_send,
  clientId, is_final_step = 0,
  is_update = 0
) => {
  const endPoint = API_URL + "mod=benchmark&act=add_benchmark";
  let form = new FormData();
  let formattedChecklist = [];
  let file_counts = 0;
  list.forEach((item) => {
    const itemObj = {
      slot_id: isNaN(item.id) ? 0 : item.id,
      item_name: item.checklist[0].name,
      item_slot: moment(item.slot).format('HH:mm:ss'),
      room_id: item.room_id,
      days: item.days,
      frequency: item.frequency,
      priority: item.priority,
      comments: [],
      type_of_space_items: item.type_of_space_items,
      photo_required: item.photo_required
    };
    if(item.comments && item.comments.length)
    {
      let comments = [];
      item.comments.forEach((comment) => {
        let commentMetaData = {...comment, attachments: []};
        if(comment.attachments)
        {
          Array.from(comment.attachments).forEach((attachment) => {
            form.append(`file[${file_counts}]`, attachment);
            file_counts += 1;
            commentMetaData.attachments.push(attachment.name);
          });
        }
        comments.push(commentMetaData);
      });
      itemObj.comments = comments;
    }
    formattedChecklist.push(itemObj);
  });
  form.append("project_id", project_id);
  form.append("provider_id", provider_id);
  form.append("benchmark_id", benchmark_id);
  form.append("new_list", JSON.stringify(formattedChecklist));
  if (user_type == "operator") {
    form.append("schedule_client_id", getClientIdByProject(project_id));
    form.append("schedule_contact_id", getContactIdBySpace(project_id));
  } 
  if (is_final_step == 1) {
    form.append("is_final_step", 1);
    form.append("save_and_send", save_and_send);
  }

  if (clientId > 0) {
    form.append("client_id", clientId);
  }
  if(is_update)
  {
    form.append("is_update", is_update);
  }
  form.append("start_date_is_empty", isUnKnownStartDate ? 1 : 0);
  if (!isUnKnownStartDate) {
    form.append("startdate", moment(startdate).format("YYYY-MM-DD"));
  }
  form.append("step", 4);
  return apiService(endPoint, "POST", true, form);
};


const updateTaskSchedule = (
  project_id,
  benchmark_id,
  startdate,
  max_budget,
  is_schedule,
  contract_end_date,
  start_date_is_empty,
  budget_is_empty
) => {
  const endPoint = API_URL + "mod=benchmark&act=update_task_schedule";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("max_budget", max_budget);
  form.append("startdate", moment(startdate).format("YYYY-MM-DD"));
  form.append("benchmark_id", benchmark_id);
  form.append("contract_end_date", contract_end_date);
  form.append("is_schedule", is_schedule);
  form.append("start_date_is_empty", start_date_is_empty);
  form.append("budget_is_empty", budget_is_empty);
  return apiService(endPoint, "POST", true, form);
};

const getBenchmarkInfo = (project_id, benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_info_and_docs";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};


const pendingCommentAction = (
  project_id,
  task_id,
  comment_id,
  comment_status
) => {
  const endPoint = API_URL + "mod=task_detail&act=get_names_for_mentions";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  form.append("comment_id", comment_id);
  form.append("comment_status", comment_status);
  return apiService(endPoint, "POST", true, form);
};

const updateChecklistAssignee = (
  project_id,
  task_id,
  checklist_id,
  assignee_id,

) => {
  const endPoint = API_URL + "mod=task_detail&act=update_checklist_assignee";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  form.append("checklist_id", checklist_id);
  form.append("assignee_id", assignee_id);
  return apiService(endPoint, "POST", true, form);
};

const getOperatorsByCategory = (
  project_id,
  category_id,
  is_boss_operator,
  flag = "", all_operator = 0, all_myr_provider
) => {
  const endPoint = API_URL + "mod=benchmark&act=get_operators_by_category_new_flow";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("category_id", category_id);
  form.append("is_boss_operator", is_boss_operator);
  if (flag != "") {
    form.append("all", 1);
  }
  if (all_operator) {
    form.append("all_operator", 1);
  }
  if (all_myr_provider) {
    form.append("all_myr_provider", 1);
  }
  
  return apiService(endPoint, "POST", true, form);
};

const getOperatorDetail = (project_id, email) => {
  const endPoint = API_URL + "mod=benchmark&act=operator_full_detail";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("email", email);
  return apiService(endPoint, "POST", true, form);
};

const changeTopicStatusBulk = (project_id, topicData) => {
  const leftPanelTab = store.getState().customer.leftPanelTab
  const endPoint = API_URL + "mod=task&act=change_topic_status_DEV";
  let form = new FormData();
  
  if(project_id) {
    form.append(`project_id`, project_id);
  } 
  form.append(`topic_data`, JSON.stringify(topicData));
  form.append(`page_name`, leftPanelTab == "my-request" ? "homepage" : leftPanelTab);

  if (
    (localStorage.getItem("virtualSpaceClientId") &&
      localStorage.getItem("virtualSpaceClientId") == 0) ||
    localStorage.getItem("selectedVirtualSpace")
  ) {
    form.append("is_virtual_space", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const changeMainTopicStatus = (
  project_id,
  main_key,
  filter_key,
  is_active,
  main_sub_key,
  provider_id = 0,
) => {
  const endPoint = API_URL + "mod=task&act=change_topic_status_DEV";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append(`main_key`, main_key);
  form.append(`filter_key`, filter_key);
  form.append(`is_active`, is_active ? 1 : 0);
  form.append(`main_sub_key`, main_sub_key);
  if (main_key == "category_provider") {
    form.append(`provider_id`, provider_id);
  }
  if (
    (localStorage.getItem("virtualSpaceClientId") &&
      localStorage.getItem("virtualSpaceClientId") == 0) ||
    localStorage.getItem("selectedVirtualSpace")
  ) {
    form.append("is_virtual_space", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const changeCustomTopicStatus = (
  ) => { };

const addCustomTopic = (
  project_id,
  main_key,
  filter_key,
  custom_key,
  topicId
) => {
  const endPoint = API_URL + "mod=task_detail&act=add_custom_topic";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("main_key", main_key);
  form.append("filter_key", filter_key);
  form.append("custom_key", custom_key);
  form.append("topicId", topicId);
  return apiService(endPoint, "POST", true, form);
};

const getClientTeamData = (project_id) => {
  const endPoint = API_URL + "mod=user&act=get_staff_team_operator";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getFilteredProjectCount = (
  project_id,
  filters = [],
  last_filter = "",
  status_filters = [], pageName = ''
) => {
  const endPoint = API_URL + "mod=task&act=get_project_task_count";
  const taskFilters = store.getState().customer.taskFilters
  const globalView = store.getState().customer.globalView
  const leftPanelTab = store.getState().customer.leftPanelTab
  let newFilter = filters.length > 0 ? filters : taskFilters
  let form = new FormData();
  if(window.location.pathname == "/full_calendar" || window.location.pathname == "/tasks")  
  {
    form.append("full_calendar", 1);
  } else {
    form.append("full_calendar", 0);
  }
  if (project_id == 0 || project_id == null || project_id == undefined  || window.location.pathname == "/spaces-dashboard") {
    form.append("is_dashboard", 1);
  }
  if (window.location.pathname != "/dashboard" && window.location.pathname != "/spaces-dashboard") { 
  form.append("project_id", project_id);
  }
  if(globalView == "AichatView"){
    form.append("page_name","ChatAi")
  }
  let isAllfilterApplied = filters && filters.length > 0 && filters.filter((key)=> key.filter_key == "all").length > 0 ? true : false
  if (isAllfilterApplied == false) {
    form.append("startdate",formateDate(localStorage.getItem("selectedDate") ? localStorage.getItem("selectedDate")  :  new Date() ))
  }
  if (
    (newFilter && newFilter.length != 0) ||
    ((!newFilter || !newFilter.length) && last_filter)
  ) {
    const pos = newFilter.findIndex(
      (x) =>
        x.main_key === last_filter.main_key &&
        x.filter_key === last_filter.filter_key
    );
    if (last_filter != "") {
      if (pos === -1) {
        newFilter.push(last_filter);
      } else if (pos > -1 && newFilter.findIndex((x) => x.title == last_filter.title) !== -1) {
        newFilter.splice(pos, 1);
      }
    }

    let formattedList = [];
    newFilter.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });

    if (Object.keys(formattedList).length > 1) {
      Object.keys(formattedList).forEach((category) => {
        if (last_filter != "") {
          if (
            category !== last_filter.main_key &&
            last_filter.main_key != "date_range_filter" &&
            category != "date_range_filter"
          ) {
            delete formattedList[category];
          }
        }
      });
    }

    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            if (localStorage.getItem("fromDate")) {
              form.append(`filters[from_date]`,formateDate(localStorage.getItem("fromDate")));
              form.append(`filters[to_date]`, formateDate(localStorage.getItem("toDate")));
              form.delete("startdate");
            }
          }
        }
      });
    });
  }
  if (newFilter && newFilter.length > 0) { 
    newFilterFormate(newFilter).forEach((dd) => {
      if(localStorage.getItem("contact_role") == 3){
        if (dd.main_key == "date_range_filter") {
        form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
        }
      }else{
      form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
      }
    })
    if (newFilter.length > 0 && newFilter.some((filter)=> filter.main_key == "unread_comments")) {
      form.delete("filters[from_date]");
      form.delete("filters[to_date]");
      form.delete("startdate");      
    }
  }
  if (
    (localStorage.getItem("virtualSpaceClientId") &&
      localStorage.getItem("virtualSpaceClientId") == 0) ||
    localStorage.getItem("selectedVirtualSpace")
  ) {
    if (pageName != 'dashboard') {
      form.append("is_virtual_space", 1);
    }
  }
  if (pageName == 'dashboard') {
    form.append("page_name", "dashboard_burger_menu");
  }else{
  form.append("page_name",leftPanelTab == "my-request" ? "my_task" : pageName)

  }
  return apiService(endPoint, "POST", true, form);
};

const convertToTask = (project_id, task_id) => {
  const endPoint = API_URL + "mod=task_detail&act=convert_to_task";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("task_id", task_id);
  return apiService(endPoint, "POST", true, form);
};
const getBenchmarkSummary = (project_id, benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_benchmark_summary";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};
const getBenchmarkWeekSchedule = (benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_benchmark_week_schedule";
  let form = new FormData();
  const project_id = getBenchmarkProjectId(benchmark_id);
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  if(["/connect"].includes(window.location.pathname))
  {
    form.append("is_external", 1)
  }
  return apiService(endPoint, "POST", true, form);
};
const getBenchmarkInvoices = (project_id, benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_invoices";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};
const getBenchmarkInfoAttchment = (project_id, benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_info_and_docs";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};
const getBenchmarkLog = (project_id, benchmark_id, page = 1) => {
  const endPoint = API_URL + "mod=benchmark&act=get_activity_log";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  if (page > 1) {
    form.append("page", page);
  }
  return apiService(endPoint, "POST", true, form);
};
const convertToChecklist = (project_id, note_id, task_id) => {
  const endPoint = API_URL + "mod=task&act=note_convert_checklist";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("note_id", note_id);
  form.append("task_id", task_id);
  return apiService(endPoint, "POST", true, form);
};

const pinNotes = (note_id) => {
  const endPoint = API_URL + "mod=task&act=pin_notes";
  let form = new FormData();
  form.append("note_id", note_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteTaskDocument = (
  project_id,
  comment_id,
  is_attachment = 0,
  file_id = 0
) => {
  const endPoint = API_URL + "mod=task_detail&act=remove_comment";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("comment_id", comment_id);
  form.append("is_attachment", is_attachment);
  if (file_id) {
    form.append("file_id", file_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const addCategory = (category_name, projectId = 0, id = 0, languages = {}, people_count, cost_amount, tools = [], is_external) => {
  const endPoint = API_URL + "mod=benchmark&act=add_category";
  let form = new FormData();
  const project_id = localStorage.getItem("selectedOffice");
  form.append("project_id", projectId == 0 ? project_id : projectId);
  form.append("languages[english]", languages.english);
  form.append("languages[french]", languages.french);
  form.append("category_name", category_name);
  if(is_external > 0)
  {
    form.append("is_external", is_external)
  }
  if (id > 0) {
    form.append("category_id", id);
  }
  return apiService(endPoint, "POST", true, form);
};

const addProviderToCategory = (
  email,
  category_id,
  project_id,
  name = "",
  phonenumber = "",
  cost, people, postalAddress, tools,
) => {
  const endPoint = API_URL + "mod=benchmark&act=add_provider_to_category";
  let form = new FormData();
  // const project_id = localStorage.getItem("selectedOffice");
  form.append("project_id", project_id);
  form.append("category_id", category_id);
  form.append("invitee_email", email);
  if (cost != "" || cost > 0) { 
    form.append("cost_amount", cost);
  }
  if (people != "" || people > 0) { 
    form.append("people_count", people);
  }
  if (tools && tools.length > 0) {
    for (let i = 0; i < tools.length; i++) {
      form.append(`tools[${i}]`, tools[i].label);
    }
  }
  if (postalAddress != "") { 
    form.append("address", postalAddress);
  }
  if (name != "") {
    form.append("type_name", name);
  }
  if (phonenumber != "") {
    form.append("phonenumber", phonenumber);
  }

  return apiService(endPoint, "POST", true, form);
};

const updateBenchmark = (
  id,
  date = "",
  status = 0,
  total = "",
) => {
  const endPoint = API_URL + "mod=benchmark&act=updateBenchmarks";
  let form = new FormData();
  // const project_id = localStorage.getItem("selectedOffice");
  form.append("id", id);
  if(date != "")
  {
    form.append("date", date);
  }
  if (status != 0) {
    form.append("status", status);
  }
  if (total != "") {
    form.append("total", total);
  }

  return apiService(endPoint, "POST", true, form);
};

const getBenchmarkDetail = (benchmark_id, clientid, spaceId) => {
  const endPoint = (window.location.pathname == '/benchmark' || window.location.pathname == '/schedule') ? API_URL + "mod=benchmark&act=benchmark_detail" : API_URL + "mod=benchmark&act=get_benchmark_details";
  let form = new FormData();
  const project_id = Number(getBenchmarkProjectId(benchmark_id));
  const userType = localStorage.getItem("user_type");
  let clientId = 0;

  form.append("benchmark_id", benchmark_id);
  form.append("project_id",spaceId ? spaceId : project_id);
  if (!project_id > 0 || !project_id) {
    form.append("is_job_proposal", 1);    
  }

  switch(userType)
  {
    case "staff":
      const spaces = JSON.parse(localStorage.getItem("spaces"));
      let currentSpace = spaces.find(w => w.project_id == project_id);
      if (currentSpace) {
        clientId = currentSpace.client_id;
      }
      clientId = 0;
      break;
    default:
      clientId = localStorage.getItem("client_id");
      break;
  }

  if (clientId > 0) {
    form.append("client_id", clientId);
  }

  if(["/connect"].includes(window.location.pathname))
  {
    form.append("is_external", 1)
  }

  return apiService(endPoint, "POST", true, form);
};

const editBenchmarkChecklist = (
  project_id,
  parent_id,
  startdate,
  benchmark_id,
  budget,
  week_schedule,
  list
) => {
  const endPoint = API_URL + "mod=benchmark&act=copy_benchmark_checklist";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  form.append("project_id", project_id);
  form.append("parent_id", parent_id);
  form.append("startdate", moment(startdate).format("YYYY-MM-DD"));
  form.append("budget", budget);
  form.append("is_recursive", 1);
  form.append("week_schedule", JSON.stringify(week_schedule));
  form.append("list", JSON.stringify(list));
  return apiService(endPoint, "POST", true, form);
};

const removeBenchmarkAttachment = (project_id, file_id, benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=benchmark_attachment_remove";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("file_id", file_id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};

const addtask3dplan = (data) => {
  const endPoint = API_URL + "mod=task&act=add_task";
  let form = new FormData();
  const project_id = localStorage.getItem("selectedOffice");
  form.append("project_id", project_id);
  if (data) {
    form.append("contact_name", data.name3dplan);
    form.append("country_code", data.country_code_3dplan);
    form.append("phonenumber", data.phonenumber_3dplan);
    form.append("task_datetime", data.date);
    form.append("instruction_to_access", data.access_description);
    form.append("indoor_scan", data.indoor_scan ? 1 : 0);
    form.append("outdoor_scan", data.outdoor_scan ? 1 : 0);
    form.append("address", data.address_3dplan);
    form.append("area", data.surface_3dplan);
    form.append("wifi_code", data.wifi_code);
    form.append("access_code", data.access_code);
  }
  return apiService(endPoint, "POST", true, form);
};

const editBenchmarkTitle = (id, title) => {
  const endPoint = API_URL + "mod=benchmark&act=edit_benchmark_checklist_title";
  let form = new FormData();
  form.append("checklist_id", id);
  form.append("title", title);
  return apiService(endPoint, "POST", true, form);
};

const externalProviderInvite = (email) => {
  const endPoint = API_URL + "mod=benchmark&act=external_provider_invite";
  let form = new FormData();
  const project_id = localStorage.getItem("selectedOffice");
  const benchmark_id = localStorage.getItem("benchmarkId");
  form.append("project_id", project_id);
  form.append("invite_email", email);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};

const benchmarkAttachmentRemove = (id) => {
  const endPoint = API_URL + "mod=benchmark&act=benchmark_attachment_remove";
  let form = new FormData();
  const project_id = localStorage.getItem("selectedOffice");
  const benchmark_id = localStorage.getItem("benchmarkId");
  form.append("project_id", project_id);
  form.append("file_id", id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};

const getPremiumEstimateDetails = () => {
  const endPoint = API_URL + "mod=user&act=get_estimate_plan";
  let form = new FormData();
  const project_id = localStorage.getItem("selectedOffice");
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const spaceInvoices = (type = 0) => {
  const endPoint = API_URL + "mod=space&act=invoice";
  let form = new FormData();
  const project_id = localStorage.getItem("selectedOffice");
  form.append("project_id", project_id);
  if (type) {
    form.append("type", type);
  }

  return apiService(endPoint, "POST", true, form);
};

const premiumEstimatePlan = (project_id = 0) => {
  const endPoint = API_URL + "mod=space&act=premium_estimate";
  let form = new FormData();
  if(project_id > 0)
  {
    form.append("project_id", project_id);
    form.append("premium_type_flag", 2);
  }
  return apiService(endPoint, "POST", true, form);
};
const stopBenchmark = (
  project_id,
  benchmark_id,
  schedule_end_date,
  upcoming_benchmark
) => {
  const endPoint = API_URL + "mod=benchmark&act=stop_benchmark";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  form.append(
    "schedule_end_date",
    moment(schedule_end_date).format("YYYY-MM-DD")
  );
  form.append("upcoming_benchmark", upcoming_benchmark);
  return apiService(endPoint, "POST", true, form);
};
const getRequestedTaskData = (project_id, task_id, hash_id = 0) => {
  const endPoint = API_URL + "mod=task_detail&act=get_requested_task_details";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("project_id", project_id);
    form.append("task_id", task_id);
  }

  return apiService(endPoint, "POST", true, form);
};

const addNewAttachmentToBenchmark = (
  project_id,
  benchmark_id,
  files,
  contractFiles) => {
  const endPoint = API_URL + "mod=benchmark&act=add_benchmark_attachment";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  if (files && Array.from(files).length > 0) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if (contractFiles && Array.from(contractFiles).length > 0) {
    Array.from(contractFiles).map((file, index) => {
      form.append(`contract_file[${index}]`, file);
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const deleteBenchMark = (project_id, benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=delete_benchmark";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
};

const changeBenchmarOperator = (
  project_id,
  benchmark_id,
  budget,
  startDate
) => {
  const endPoint = API_URL + "mod=benchmark&act=change_benchmark_operator";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  form.append("max_budget", budget);
  form.append("startdate", moment(startDate).format("YYYY-MM-DD"));
  return apiService(endPoint, "POST", true, form);
};

const getProviderList = (project_id) => {
  const endPoint = API_URL + "mod=benchmark&act=provider_list";
  let form = new FormData();
  form.append(
    "project_id",
    project_id ? project_id : localStorage.getItem("selectedOffice")
  );
  return apiService(endPoint, "POST", true, form);
};

const getAllRoles = () => {
  const endPoint = API_URL + "mod=company_profile&act=get_roles";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getTeams = () => {
  const endPoint = API_URL + "mod=company_profile&act=get_teams";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const createTeam = (team, manager_id, team_name, team_project_id, team_id = 0, is_user_journy = 0) => {
  const endPoint = API_URL + "mod=company_profile&act=create_update_team";
  let form = new FormData();
  form.append("manager_id", manager_id);
  form.append("team_name", team_name);
  if (localStorage.getItem('selectedVirtualSpace')) {
    form.append("project_id", JSON.parse(localStorage.getItem('selectedVirtualSpace')).id);
    form.append("is_virtual_space", 1);
  } else {
    form.append("project_id", team_project_id);
  }
  if (is_user_journy > 0) {
    form.append("is_user_journy", 1);
  }
  team.forEach((member, index) => {
    form.append(`emp_id[${index}]`, parseFloat(member));
  });
  if (team_id) {
    form.append("team_id", team_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const deleteTeam = (team_id) => {
  const endPoint = API_URL + "mod=company_profile&act=delete_team";
  let form = new FormData();
  form.append("team_id", team_id);
  return apiService(endPoint, "POST", true, form);
};

const addEditSpaces = (
  address,
  title,
  region_id,
  superfice,
  number_of_desk,
  office_id = 0,
  country_code,
  space_type,
  typeOfSpace,
  spaceId,
  is_edit,
  single_iban_for_all_spaces = null,
  individual_iban_for_premium_estimate = null,
  spaceLogo,
  floors,
  floor_count,
  region_name,
  lat,
  lng,
  place_id
) => {
  const endPoint = API_URL + "mod=space&act=add_space_new";
  let form = new FormData();
  let floorArrayFormmated = floors;
  let typeOfSpaceFormattedArray = typeOfSpace.slice();
  form.append("address", address);
  form.append("title", title);
  form.append("region_id", region_id);
  form.append("office_id", spaceId);
  form.append("superfice", superfice);
  form.append("number_of_desk", number_of_desk != 0 ? number_of_desk : 10 );
  form.append("country_id", country_code);
  form.append("is_edit", is_edit);
  form.append("logo", spaceLogo);
  form.append("place_id", place_id);

  if (space_type) {
    form.append("space_type", "industry_type_" + space_type.value);
  }
  if (office_id) {
    form.append("office_id", office_id);
  }

  if(floors && floors.length)
  {
    floorArrayFormmated = floors.slice();
    floorArrayFormmated.forEach((floor, floorIndex) => {
      typeOfSpaceFormattedArray.forEach((space) => {
        space.details.forEach((item) => {
          if(item.floorIndex == (floorIndex + 1))
          {
            item.floorIndex = floorIndex + 1;
            item.floor_id = floor.id || 0;
          }
        });
      });
      floor.index = floorIndex + 1;
    });
  }

  if(floor_count)
  {
    form.append("quantity", floor_count);
  }

  if(lat)
  {
    form.append("lat", lat);
  }
  
  if(lng)
  {
    form.append("lng", lng);
  }

  form.append("single_iban_for_all_spaces", single_iban_for_all_spaces);
  form.append("individual_iban_for_premium_estimate", individual_iban_for_premium_estimate);
  form.append("floors", JSON.stringify(floorArrayFormmated));
  form.append("type_of_space", JSON.stringify(typeOfSpaceFormattedArray));
  if(region_name)
  {
    form.append("region_name", region_name);
  }
  return apiService(endPoint, "POST", true, form);
};

const addNewRole = (
  role_name_french,
  role_name_english,
  permissions,
  role_id = 0
) => {
  const endPoint = API_URL + "mod=company_profile&act=add_edit_role";
  let form = new FormData();
  if (role_id > 0) {
    form.append("role_id", role_id);
  }
  form.append("role_name[french]", role_name_french);
  form.append("role_name[english]", role_name_english);
  form.append("permissions", JSON.stringify(permissions));
  return apiService(endPoint, "POST", true, form);
};

const projectAssignTopeople = (projectIds, employee_id, staff_id) => {
  const endPoint =
    API_URL + "mod=company_profile&act=project_assignement_to_my_people";
  let form = new FormData();
  form.append("employee_contact_id", employee_id);
  form.append("employee_staff_id", staff_id);
  for (let i = 0; i < projectIds.length; i++) {
    form.append(`project_ids[${i}]`, projectIds[i].value || projectIds[i]);
  }
  if(!projectIds || !projectIds.length)
  {
    form.append("remove_all", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const getContactPermissions = () => {
  const endPoint = API_URL + "mod=company_profile&act=get_contact_permissions";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const deleteRole = (roleId) => {
  const endPoint = API_URL + "mod=company_profile&act=remove_role";
  let form = new FormData();
  form.append("role_id", roleId);
  return apiService(endPoint, "POST", true, form);
};

const getSubscriptionList = (estimate_id = 0) => {
  const endPoint = API_URL + "mod=space&act=subscriptions_list";
  let form = new FormData();
  const project_id = localStorage.getItem("selectedOffice");
  form.append("project_id", project_id);
  form.append("page_name", "premium_estimate");
  return apiService(endPoint, "POST", true, form);
};

const getTodoTaskList = (provider_id = 0, filters, is_request ) => {
  const endPoint = API_URL + "mod=task&act=get_to_do_task_list";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  if (is_request  > 0) {
    form.append("is_request ", is_request );
  }
  if (provider_id != 0) {
    form.append("provider_id", provider_id);
  } else if (filters && Object.keys(filters).length) {
    Object.keys(filters).forEach((key) => {
      if (key == "status" && filters[key] != 0 && is_request == 0) {
        form.append(`filters[status]`, filters[key]);
      } else if (key == "date" && filters[key] != "") {
        form.append(`filters[date]`, filters[key]);
      } else if (key == "categories" && filters[key] && filters[key].length) {
        filters[key].forEach((item) => {
          item.categories && item.categories.forEach((cat, k) => {
            form.append(`filters[${item.main_key}][${k}]`,cat.filter_key);
          })
        });
      }
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const convertRequestTask = (
  slug,
  description,
  request_task_id,
  task_id = ""
) => {
  const endPoint = API_URL + "mod=task&act=convert_request";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();

  form.append("project_id", project_id);
  form.append("slug", slug.trim());
  // form.append("description", description.trim());
  form.append("request_task_id", request_task_id);
  if (task_id != "") {
    form.append("task_id", task_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getChecklistCompayprofile = (provider_id = 0) => {
  const endPoint = API_URL + "mod=company_profile&act=get_checklist";
  let form = new FormData();
  if (provider_id != 0) {
    form.append("provider_id", provider_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const addChecklistTemplate = (
  title,
  frequency,
  category_id,
  template_id,
  checklist_items
) => {
  const endPoint = API_URL + "mod=company_profile&act=add_new_template";
  let form = new FormData();
  form.append("title", title);
  form.append("frequency", frequency);
  form.append("category", category_id);
  checklist_items.forEach((item, index) => {
    form.append(`checklist_items[${index}]`, item);
  });

  if (template_id) {
    form.append("template_id", template_id);
  }
  return apiService(endPoint, "POST", true, form);
};


const estimateCreate = (
  estimate_id,
  firstname,
  lastname,
  email,
  signature,
  package_id = 2,
  external,
  hash_id,
  date,
  account_iban,
  proposal_id
) => {
  const endPoint = API_URL + "mod=estimate&act=accept_premium_estimate_with_auto_subscribe";
  const file = dataURItoBlob(signature);
  let form = new FormData();
  form.append("estimate_id", estimate_id);
  form.append("firstname", firstname);
  form.append("lastname", lastname);
  form.append("email", email);
  form.append("date", date);
  form.append("signature", file, "signature.png");
  form.append("package_id", package_id);
  if(account_iban)
  {
    form.append("account_iban", account_iban);
  }
  if (external) {
    form.append("is_external", 1);
    form.append("hash_id", hash_id);
  }
  if(proposal_id)
  {
    form.append("proposal_id", proposal_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const upgradePremiumEstimate = (estimate_id, package_id = 2) => {
  // const endPoint = API_URL + "mod=estimate&act=upgrade_premium_estimate";
  const endPoint = API_URL + "mod=estimate&act=accept_premium_estimate_with_auto_subscribe";
  let form = new FormData();
  form.append("estimate_id", estimate_id);
  form.append("package_id", package_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteTemplate = (template_id) => {
  const endPoint = API_URL + "mod=company_profile&act=delete_checklist";
  let form = new FormData();
  form.append("template_id", template_id);
  return apiService(endPoint, "POST", true, form);
};

const getDefaultAccessories = () => {
  const endPoint = API_URL + "mod=benchmark&act=default_accessories_list";
  let form = new FormData();
  if(["/connect"].includes(window.location.pathname))
  {
    form.append("is_external", 1)
  }
  return apiService(endPoint, "POST", true, form);
};

const getWalletSettings = () => {
  const endPoint = API_URL + "mod=user&act=get_wallet_settings";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const setWalletSettings = (
  wallet_type = 0,
  is_active = 0,
  account_type = 0,
  systemInvoices = 1,
  invoices_iban = "",
  termsAndConditions = '',
  vatNumber = "",
  siren = "",
  capitalSocial = "",
  tax = ''
) => {
  const endPoint = API_URL + "mod=wallet_configuration&act=activation";
  let form = new FormData();
  form.append("wallet_id", wallet_type);
  form.append("is_active", is_active);
  form.append("account_type", account_type);
  form.append("provider_id", localStorage.getItem("staff_id"));
  form.append("system_invoices", systemInvoices);
  form.append("invoices_iban", invoices_iban);
  form.append("terms_and_conditions", termsAndConditions);
  if (localStorage.getItem("user_type") == "operator") {
    form.append("vat_number", vatNumber);
    form.append("siren", siren);
    form.append("capital_social", capitalSocial);
  }
  form.append("tax", tax);
  return apiService(endPoint, "POST", true, form, 1);
};

const onBoardingProcess = (
  provider_id,
  siren,
  tax_id,
  date_of_birth,
  country,
  currency,
  iban,
  front,
  back,
  city,
  address,
  postal_code,
  phonenumber,
  providerCountry,
  firstName,
  lastName,
  company,
  email
) => {
  const endPoint = API_URL + "mod=wallet_configuration&act=onboarding";
  let form = new FormData();
  form.append("provider_id", provider_id);
  form.append("siren", siren);
  form.append("tax_id", tax_id);
  form.append("date_of_birth", date_of_birth);
  form.append("country", country);
  form.append("staff_country", providerCountry);
  form.append("currency", currency);
  form.append("iban", iban);
  form.append("front", front);
  form.append("back", back);
  form.append("phonenumber", phonenumber);
  form.append("firstname", firstName);
  form.append("lastname", lastName);
  form.append("email", email);
  form.append("company", company);
  if (city) {
    form.append("city", city);
  }
  if (address) {
    form.append("address", address);
  }
  if (postal_code) {
    form.append("postal_code", postal_code);
  }

  return apiService(endPoint, "POST", true, form, 1);
};

const loginToMyrAdmin = (username, password) => {
  const endPoint = MYR_BASE_URL + "/orb/admin/Authentication/admin";
  let form = new FormData();
  form.append("email", username);
  form.append("password", password);
  return apiService(endPoint, "POST", true, form);
};

const getProviderTransactions = () => {
  const endPoint = API_URL + "mod=stripe_integration&act=get_transactions";
  let form = new FormData();
  form.append("provider_id", localStorage.getItem("staff_id"));
  return apiService(endPoint, "POST", true, form, 1);
};

const getStripeTransactionById = (transactionId) => {
  const endPoint =
    API_URL + "mod=stripe_integration&act=get_single_transaction";
  let form = new FormData();
  form.append("transaction_id", transactionId);
  return apiService(endPoint, "POST", true, form, 1);
};
const addCustomTopicFilter = (category_name, main_key, tag_id = 0, pathname, tagEmail = [], is_dashboard) => {
  const leftPanelTab = store.getState().customer.leftPanelTab
  const endPoint = API_URL + "mod=task&act=add_topic_tag";
  let form = new FormData();
  form.append("tag_name", category_name);
  form.append("main_key", main_key);

  form.append("page_name", leftPanelTab == "my-request" ? "homepage" : leftPanelTab);
  if (is_dashboard) {
    form.append("is_dashboard", leftPanelTab == "my-request" ? 0 : is_dashboard);
  }

  const project_id = localStorage.getItem("selectedOffice");
  if (project_id) {
    form.append("project_id", project_id);
  }
  if (tag_id > 0) {
    form.append("tag_id", tag_id);
  }
  if (
    (localStorage.getItem("virtualSpaceClientId") &&
      localStorage.getItem("virtualSpaceClientId") == 0) ||
    localStorage.getItem("selectedVirtualSpace")
  ) {
    form.append("is_virtual_space", 1);
  }
  if (tagEmail && tagEmail.length > 0) {
    tagEmail.map((item, i) => {
      form.append(`emails[`+i+`]`, item);
    });;
  }

  return apiService(endPoint, "POST", true, form);
};
const removeTopicTag = (id, main_key = '') => {
  const endPoint = API_URL + "mod=task&act=remove_topic_tag";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("tag_id", id);
  if (main_key !== '') {
    form.append("main_key", main_key);
  }
  return apiService(endPoint, "POST", true, form);
};

const getTransactions = (
  selectedSpaceFilters,
  selectedProviderFilters,
  dateFilterFrom,
  dateFilterTo
) => {
  const endPoint = API_URL + "mod=user_wallet&act=get_transaction_list";
  let form = new FormData();
  if (selectedSpaceFilters.length > 0) {
    selectedSpaceFilters.map((item, i) => {
      form.append(`filters[by_space][` + i + `]`, item);
    });
  }
  if (selectedProviderFilters.length > 0) {
    selectedProviderFilters.map((item, i) => {
      form.append(`filters[by_provider][` + i + `]`, item);
    });
  }
  if (dateFilterFrom != "" && dateFilterTo != "") {
    form.append("start_date", dateFilterFrom);
    form.append("end_date", dateFilterTo);
  }
  return apiService(endPoint, "POST", true, form);
};

const setIBANSetting = (key, account_details) => {
  const endPoint = API_URL + "mod=space&act=update_iban";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  if (key == "single") {
    form.append("account_details[0][account_number]", account_details[0]);
    form.append("account_details[0][title]", account_details[1]);
    form.append("account_details[0][billing_address]", account_details[2]);
  } else {
    account_details.forEach((details, index) => {
      form.append(
        `account_details[${index}][account_number]`,
        details["account_iban"]
      );
      form.append(
        `account_details[${index}][billing_address]`,
        details["billing_address"]
      );
      form.append(`account_details[${index}][title]`, details["title"]);
      form.append(`account_details[${index}][id]`, details["project_id"]);
      form.append(
        `account_details[${index}][user_iban_for_premium]`,
        details["user_iban_for_premium"]
      );
    });
  }
  form.append("key", key);
  return apiService(endPoint, "POST", true, form);
};
const getIbanList = () => {
  const endPoint = API_URL + "mod=space&act=get_iban_list";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};
const deleteOrCancelSpace = (id, project_id, estimateId, status) => {
  const endPoint = API_URL + "mod=space&act=remove_space";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("office_id", id);
  form.append("estimate_id", estimateId);
  form.append("status", status);
  return apiService(endPoint, "POST", true, form);
};

const subScriptionCancel = (id) => {
  const endPoint = API_URL + "mod=space&act=subscription_cancel";
  let form = new FormData();
  form.append("subscription_id", id);
  return apiService(endPoint, "POST", true, form);
};

const setUserTheme = (theme) => {
  const endPoint = API_URL + "mod=user&act=update_theme";
  let form = new FormData();
  form.append("user_theme", theme);
  return apiService(endPoint, "POST", true, form);
};
const emailCheckInvitees = (email, category_id, project_id, only_invite_customer) => {
  const endPoint = API_URL + "mod=benchmark&act=email_check";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("category_id", category_id);
  form.append("invitee_email", email);
  form.append("only_invite_customer", only_invite_customer ? 1 : 0);
  return apiService(endPoint, "POST", true, form);
};
const addOperator = (
  first_name,
  last_name,
  email,
  password,
  mobile_number,
  region,
  jobs,
  profile_image = "",
  description,
  source,
  id_card = "",
  company_id = "",
  operatorId = 0, role
) => {
  const endPoint = API_URL + "mod=company_profile&act=add_employee_operator";
  let form = new FormData();
  // form.append("contact_name", first_name + ' ' + last_name);
  form.append("email", email);
  form.append("first_name", first_name);
  form.append("last_name", last_name);

  form.append("mobile_number", mobile_number);
  form.append("boss_operator_id", localStorage.getItem("staff_id"));
  if (operatorId != 0) {
    form.append("employee_id", operatorId);
  }
  if (password != '') {
    form.append("password", password);
  }
  form.append("role", role);
  return apiService(endPoint, "POST", true, form);
};
const getCountryCodes = (all = false) => {
  const endPoint = API_URL + "mod=general&act=country_codes";
  let form = new FormData();
  if (all) {
    form.append("all", 1);
  }
  return apiService(endPoint, "POST", true, form);
};
const benchmarkSlotAssignees = (
  schedule_id,
  time_slot,
  template_id,
  assigneesIds
) => {
  const endPoint = API_URL + "mod=benchmark&act=benchmark_slot_assignees";
  let form = new FormData();
  form.append("schedule_id", schedule_id);
  form.append("week_schedule[0][time_slot]", time_slot);
  form.append("week_schedule[0][template_id]", template_id);
  if (assigneesIds.length) {
    form.append("week_schedule[0][assignees]", assigneesIds.join(","));
  } else {
    form.append("week_schedule[0][assignees]", "");
  }
  return apiService(endPoint, "POST", true, form);
};

const inviteCustomerToSpace = (company, customer_data, project_id) => {
  const endPoint = API_URL + "mod=space&act=add_client";
  project_id = project_id ? project_id : store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("company_name", company);
  customer_data.forEach((customer, index) => {
    form.append(`customer_data[${index}][contact_name]`, customer.name);
    form.append(`customer_data[${index}][email]`, customer.email);
    form.append(`customer_data[${index}][phonenumber]`, customer.phonenumber);
  });
  return apiService(endPoint, "POST", true, form);
}

const addClientToSpace = (
  company,
  contact_name,
  email,
  phonenumber,
  project_id
) => {
  const endPoint = API_URL + "mod=space&act=add_client";
  project_id = project_id ? project_id : store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("company", company);
  form.append("contact_name", contact_name);
  form.append("email", email);
  form.append("phonenumber", phonenumber);
  return apiService(endPoint, "POST", true, form);
};

const getBenchmarkSlots = (selectedProject) => {
  const endPoint =
    API_URL + "mod=benchmark&act=benchmarks_with_slots_and_assignees";
  let form = new FormData();
  form.append("project_id", selectedProject);
  return apiService(endPoint, "POST", true, form);
};

const assignSlotToOperator = (schedule_id, templates, staff_id) => {
  const endPoint = API_URL + "mod=benchmark&act=employee_slot_assignees";
  let form = new FormData();
  form.append("schedule_id", schedule_id);
  templates.forEach((template, index) => {
    form.append(`template_id[${index}]`, template);
  });
  form.append("staff_assignee_id", staff_id);
  return apiService(endPoint, "POST", true, form, 0, true);
};

const getChecklistTemplates = (provider_id) => {
  const endPoint = API_URL + "mod=benchmark&act=template_with_checklist";
  let form = new FormData();
  form.append("provider_id", provider_id);
  return apiService(endPoint, "POST", true, form);
};
const getDetailForLoginAsUser = (client_id, project_id) => {
  const endPoint = API_URL + "mod=space&act=get_detail_for_login_as_user_new"; 
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("client_id", client_id);
  return apiService(endPoint, "POST", true, form, undefined, true);
};
const userLogout = () => {
  const endPoint = API_URL + "mod=login&act=logout";
  let form = new FormData();
  clearDatabase();
  return apiService(endPoint, "POST", true, form);
};
function getEmails(taskFilters, all = 0, token = '', is_spam = 0) {
  const endPoint = API_URL + "mod=gmail_integration&act=threads";
  let form = new FormData(); 
  form.append("max_result", 10);
  form.append("is_spam",localStorage.getItem("email_filter_key") == "spam" ? 1 : 0 )
  form.append("type_filter_key", localStorage.getItem("email_filter_key"));
  form.append(
    "isAppliedUnread",
    store.getState().customer.isAppliedUnreadComment ? 1 : 0
  );
  form.append("email_filter_email", localStorage.getItem("email_filter_email"));
  const dataselected = taskFilters.filter((date) => date.main_key == "date_range_filter");
    let to_datetime = new Date() ;
    let from_datetime = new Date();
  if (dataselected && dataselected.length > 0) {
    var fromDate = new Date(localStorage.getItem("fromDate"));
    var toDate = new Date(localStorage.getItem("toDate"));
    from_datetime = formateDate(fromDate);
    to_datetime = formateDate(toDate);
  }else{
    var event = new Date(localStorage.getItem("selectedDate"));

    let date = JSON.stringify(event);
    date = date.slice(1, 11);
    to_datetime = date;
    from_datetime = date;
    form.append("pagination", store.getState().customer.isAppliedUnreadComment ? 1 : 0);
  }
  // if (fromDate && toDate) {
  //   to_datetime = toDate;
  //   from_datetime = fromDate;
  //   form.append("pagination", 1);
  // } else {
  //   var event = new Date(localStorage.getItem("selectedDate"));

  //   let date = JSON.stringify(event);
  //   date = date.slice(1, 11);
  //   to_datetime = date;
  //   from_datetime = date;
  //   form.append("pagination", store.getState().customer.isAppliedUnreadComment ? 1 : 0);
  // }

  // form.append("to_datetime", "2024-04-25" + "T24:00");
  // form.append("from_datetime", "2024-04-25" + "T00:00");
  if (dataselected && dataselected[0] && dataselected[0].filter_key == "all") {
    form.append("all", 1);
  }else{
    form.append("to_datetime", to_datetime + "T24:00");
    form.append("from_datetime", from_datetime + "T00:00");
    form.append("all", all);
  }

  form.append("next_token", token);

  return apiService(endPoint, "POST", true, form);
}
const sendMailReply = (emailReply, message_id, files, senderEmail, selectedId) => {
  const endPoint = API_URL + "mod=gmail_integration&act=send_reply";
  let form = new FormData();
  form.append("message_body", emailReply);
  form.append("message_id", message_id);
  // form.append("cc", mailCc);
  form.append("selectedId", selectedId);
  if (files) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if (senderEmail && senderEmail.length > 0) {
    senderEmail.map((item, i) => {
      form.append(`sender_emails[`+i+`]`, item);
    });;
  }
  return apiService(endPoint, "POST", true, form);
};
const closeEmail = (message_id) => {
  const endPoint =
    API_URL + "mod=gmail_integration&act=close_importent_message";
  let form = new FormData();
  form.append("message_id", message_id);

  var event = new Date(localStorage.getItem("selectedDate"));

  let date = JSON.stringify(event);
  date = date.slice(1, 11);

  form.append("date", date);

  return apiService(endPoint, "POST", true, form);
};
const markImportantEmail = (message_id, messageDate) => {
  const endPoint = API_URL + "mod=gmail_integration&act=add_important";
  let form = new FormData();
  form.append("message_id", message_id);
  form.append("message_date", messageDate);

  var event = new Date(localStorage.getItem("selectedDate"));

  let date = JSON.stringify(event);
  date = date.slice(1, 11);

  form.append("date", date);

  return apiService(endPoint, "POST", true, form);
};

const UpdateMessage = (message_id, status, column) => {
  const endPoint = API_URL + "mod=gmail_integration&act=UpdateMessage";
  let form = new FormData();
  form.append("message_id", message_id);
  form.append("status", status);
  form.append("column", column);

  return apiService(endPoint, "POST", true, form);
};
const updateGoogleDriveStatus = (status, project_id) => {
  const endPoint = API_URL + "mod=google_drive_integration&act=updateStatus";
  let form = new FormData();
  form.append("status", status);
  form.append("project_id", project_id);
  form.append("provider", "google_drive");

  return apiService(endPoint, "POST", true, form);
};
const updateGmailStatus = (status, project_id) => {
  const endPoint = API_URL + "mod=google_drive_integration&act=updateStatus";
  let form = new FormData();
  form.append("status", status);
  form.append("project_id", project_id);
  form.append("provider", "gmail");

  return apiService(endPoint, "POST", true, form);
};
const setMailSettings = (status, provider) => {
  const endPoint = API_URL + "mod=gmail_integration&act=setMailSettings";
  let form = new FormData();
  form.append("status", status);
  form.append("provider", provider);

  return apiService(endPoint, "POST", true, form);
};
const exchangeGoogleCode = (code, provider = 'google_drive') => {
  let endPoint = API_URL + "mod=google_drive_integration&act=exachangecode";
  if(provider == 'outlook_mail') {
    endPoint = API_URL + "mod=outlook_integration&act=user_authentication";
  }
  let form = new FormData();
  form.append("code", code);
  form.append("provider", provider);
  form.append("project_id", localStorage.getItem("selectedOffice"));

  return apiService(endPoint, "POST", true, form);
};
const getMailSettings = () => {
  const endPoint = API_URL + "mod=user&act=getMailSettings";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};
const getDrivesSettings = () => {
  const endPoint = API_URL + "mod=user&act=getDrivesSettings";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};
const setUserLanguage = (language) => {
  const endPoint = API_URL + "mod=user&act=set_language";
  let form = new FormData();
  form.append("language", language);
  return apiService(endPoint, "POST", true, form);
};
const saverFromInbox = (message_id, message_date) => {
  const endPoint = API_URL + "mod=gmail_integration&act=saverFromInbox";
  let form = new FormData();
  form.append("provider", "gmail");
  form.append("message_id", message_id);
  form.append("message_date", message_date);

  return apiService(endPoint, "POST", true, form);
};
const revokeMailSync = (synchronizedMail) => {
  const endPoint = API_URL + "mod=user&act=revokeMailSync";
  let form = new FormData();
  form.append("provider", synchronizedMail);
  return apiService(endPoint, "POST", true, form);
};
const getEmployeeReportList = (fromDate, toDate, unReadFilter = false, filters = []) => {
  const endPoint = API_URL + "mod=user&act=get_employee_report_list";
  let form = new FormData();
  form.append("project_id", localStorage.getItem("selectedOffice"));
  let tempFromDate =
    fromDate != "" ? fromDate : localStorage.getItem("selectedDate");
  let tempToDate = toDate != "" ? toDate : "";
  if (unReadFilter) {
    form.append("is_unread", 1);
  }
  form.append("from_date", formateDate(tempFromDate));
  if (toDate != "") {
    form.append("to_date", formateDate(tempToDate));
  }
  if (filters && filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key] && formattedList[filter.main_key] != undefined) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }})
    newFilterFormate(filters).forEach((fil) => {
      form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
    })
    }
  return apiService(endPoint, "POST", true, form);
};
const getOperatorReportList = (fromDate, toDate, unReadFilter = false, filters = []) => {
  const endPoint = API_URL + "mod=user&act=get_operator_report_list";
  let form = new FormData();
  form.append("project_id", localStorage.getItem("selectedOffice"));
  let tempFromDate =
    fromDate != "" ? fromDate : localStorage.getItem("selectedDate");
  let tempToDate = toDate != "" ? toDate : "";
  if (unReadFilter) {
    form.append("is_unread", 1);
  } else {
    form.append("from_date", formateDate(tempFromDate));
    if (toDate != "") {
      form.append("to_date", formateDate(tempToDate));
    }
  }
  if (filters && filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key] && formattedList[filter.main_key] != undefined) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }})
    newFilterFormate(filters).forEach((fil) => {
      form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
    })
    }
  return apiService(endPoint, "POST", true, form);
};

const getProviderListing = (fromDate, toDate, unReadFilter = false) => {
  const endPoint = API_URL + "mod=user&act=provider_listing";
  let form = new FormData();
  form.append("project_id", localStorage.getItem("selectedOffice"));
  let tempFromDate =
    fromDate != "" ? fromDate : localStorage.getItem("selectedDate");
  let tempToDate = toDate != "" ? toDate : "";
  if (unReadFilter) {
    form.append("is_unread", 1);
  } else {
    form.append("from_date", tempFromDate);
    if (toDate != "") {
      form.append("to_date", tempToDate);
    }
  }

  return apiService(endPoint, "POST", true, form);
};

const getEmployeeTask = (
  status,
  operator_id,
  is_unread = false,
  from_date,
  end_date = ""
) => {
  const endPoint = API_URL + "mod=task&act=get_task_for_employee";
  let form = new FormData();
  let tempFromDate =
    from_date != "" ? from_date : localStorage.getItem("selectedDate");
  let tempToDate = end_date != "" ? end_date : "";

  form.append("project_id", localStorage.getItem("selectedOffice"));
  form.append("operator_id", operator_id);
  form.append("status", status);
  if (is_unread) {
    form.append("is_unread", 1);
  } else {
    form.append("from_date", tempFromDate);
    if (end_date != "") {
      form.append("to_date", tempToDate);
    }
  }

  return apiService(endPoint, "POST", true, form);
};
const createRequestTask = (
  taskName,
  files = [],
  filters = [],
  description,
  status = 1,
  checklistItems = [],
  visible_to_client = false,
  link="",
  visible_to_provider = false,
  sub_categories
) => {
  const endPoint = API_URL + "mod=benchmark&act=create_benchmark_proposal_based_on_request";
  // const endPoint = API_URL + `${CUSTOM_CLIENT_IDS.includes(client_id) ? "mod=benchmark&act=create_benchmark_proposal_based_on_request" : "mod=task&act=create_requested_task"}`;
  let form = new FormData();
  form.append("project_id", localStorage.getItem("selectedOffice"));
  form.append("task_name", taskName);
  form.append("description", description);
  form.append("status", status);
  form.append("visible_to_client", visible_to_client ? 1 : 0);
  form.append("visible_to_provider", visible_to_provider ? 1 : 0);
  if (sub_categories) { 
    form.append("filters[sub_category]",JSON.stringify(sub_categories));
  }
  if (files.length) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if(link !="")
  {
    form.append("link", link);
  }
  if (filters.length) {
    filters.forEach((filter) => {
      filter.categories.forEach((category,key)=>{
        form.append(`filters[${filter.main_key}][${key}]`, category.filter_key);
      })
    });
  }
  if (checklistItems.length) {
    form.append(`checklist`, JSON.stringify(checklistItems));
  }
  return apiService(endPoint, "POST", true, form);
};
const setRequestTaskAssignee = (task_id, assignees = [],isRequest) => {
  const filters = JSON.parse(JSON.stringify(assignees));
  const endPoint = API_URL + "mod=task&act=set_request_task_assignee_DEV";
  let form = new FormData();
  let project_id =  localStorage.getItem("selectedOffice") ? localStorage.getItem("selectedOffice") : 0;
  
  form.append("project_id", project_id);
 
  if (filters && filters.length > 0) {
    filters.map((assignee) => {
      if (assignee.categories && assignee.categories.length > 0) {
        if (assignee.main_key == "equipment" && assignee.categories[0].sub_categories.length > 0) {
          form.append(`filters[${assignee.main_key}]`,JSON.stringify([assignee]));
        } else if(isRequest && assignee.main_key == "providers"){
          let Obj = {...assignee};
          if(!Obj.categories[0].sub_category)
          {
            Obj.categories[0] = {sub_category: []};
          }
          Obj.categories[0].sub_category = Obj.categories[0].sub_category.length ? Obj.categories[0].sub_category.map((ss) => ss.id) : [];
          if(Obj.categories.length)
          {
            Obj.categories.forEach((w, i) => {
              if(w.sub_category.length && isArrayofObjects(w.sub_category))
              {
                if(w.sub_category.find((w) => w.staffid > 0))
                {
                  w.sub_category = w.sub_category.map((provider) => provider.staffid).filter((z) => z > 0);
                }
              }
            });
            //Remove null values
            Obj.categories.forEach((w) => {
              w.sub_category = w.sub_category.filter((z) => z && z > 0);
            });
            Obj.categories = Obj.categories.filter((w) => w.filter_key > 0 || w.filter_key == "myrai_support");
          }
          form.append(`filters[${assignee.main_key}]`, JSON.stringify([Obj]));
        } else {
          form.append(`filters[${assignee.main_key}]`,JSON.stringify([assignee]));
        }
      }
    })
  }
  form.append("task_id", task_id);    
  
  if((project_id == 0 || project_id == null )){
    form.append("is_dashboard", 1);
  }else {
    form.append("is_dashboard", 0);
  }
  return apiService(endPoint, "POST", true, form);
};

const deleteCustomTopic = (id) => {
  const endPoint = API_URL + "mod=task_detail&act=deleteCustomTopic";
  let form = new FormData();
  form.append("id", id);
  return apiService(endPoint, "POST", true, form);
};

const markAsRead = (id) => {
  const endPoint = API_URL + "mod=gmail_integration&act=markAsRead";
  let form = new FormData();
  form.append("threadId", id);
  return apiService(endPoint, "POST", true, form);
};
const bulkAddCustomTopic = (topics, project_id) => {
  const endPoint = API_URL + "mod=user&act=bulkAddCustomTopic";
  let form = new FormData();
  form.append("topics", topics);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getLeadsCountWeekCalendar = (
  startDate = "",
  filters = [],
  last_filter = "",
  statusFilters = [],
  action = "",
  removefiltervalue = "",
  leadsFilters,
  globalSearchString
) => {
  const endPoint = API_URL + "mod=leads&act=get_leads_count_week_calendar";
  let form = new FormData();
  if (globalSearchString !== '') {
    form.append(`search`, globalSearchString);
  }
  if (statusFilters && statusFilters.length) {
    statusFilters.forEach((item, index) => {
        form.append(`status[${index}]`, item);
    });
  }
  const taskFilters = store.getState().customer.taskFilters
  let newFilter = filters.length > 0 ? filters : taskFilters

  let isAllfilterApplied = filters && filters.length > 0 && filters.filter((key)=> key.filter_key == "all").length > 0 ? true : false
  if (isAllfilterApplied == false) {
    form.append("filters[from_date]",formateDate(localStorage.getItem("selectedDate")))
  }
  if (
    (newFilter && newFilter.length != 0) ||
    ((!newFilter || !newFilter.length) && last_filter)
  ) {
    const pos = newFilter.findIndex(
      (x) =>
        x.main_key === last_filter.main_key &&
        x.filter_key === last_filter.filter_key
    );
    if (last_filter != "") {
      if (pos === -1) {
        newFilter.push(last_filter);
      } else if (pos > -1 && newFilter.findIndex((x) => x.title == last_filter.title) !== -1) {
        newFilter.splice(pos, 1);
      }
    }

    let formattedList = [];
    newFilter.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });

    if (Object.keys(formattedList).length > 1) {
      Object.keys(formattedList).forEach((category) => {
        if (last_filter != "") {
          if (
            category !== last_filter.main_key &&
            last_filter.main_key != "date_range_filter" &&
            category != "date_range_filter"
          ) {
            delete formattedList[category];
          }
        }
      });
    }

    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.delete("filters[from_date]");
            if (localStorage.getItem("fromDate")) {
              if(moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD") == moment(localStorage.getItem("toDate")).format("YYYY-MM-DD")){
                form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
              }else{
                form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
                form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
              }
            }
          }
        }
      });
    });
  }
  if (newFilter && newFilter.length > 0) { 
    newFilterFormate(newFilter).forEach((dd) => {
      if(localStorage.getItem("contact_role") == 3){
        if (dd.main_key == "date_range_filter") {
        form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
        }
      }else{
      form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
      }
    })
    if (newFilter.length > 0 && newFilter.some((filter)=> filter.main_key == "unread_comments")) {
      form.delete("filters[from_date]");
      form.delete("filters[to_date]");
      form.delete("startdate");      
    }
  }

  leadsFilters && Object.keys(leadsFilters).forEach((key) => {
    const values = leadsFilters[key];
    if (values && values.length > 0) {
        form.append(`filters[${key}]`, leadsFilters[key]);
    }
  })
  return apiService(endPoint, "POST", true, form);
};
const getLeadsCount = (
  project_id,
  filters = [],
  last_filter = "",
  status_filters = [],
  leadsFilters,
  globalSearchString
) => {
  const endPoint = API_URL + "mod=leads&act=get_leads_count";
  let form = new FormData();
  const taskFilters = store.getState().customer.taskFilters
  let newFilter = filters.length > 0 ? filters : taskFilters

  let isAllfilterApplied = filters && filters.length > 0 && filters.filter((key)=> key.filter_key == "all").length > 0 ? true : false
  if (isAllfilterApplied == false) {
    form.append("filters[from_date]",formateDate(localStorage.getItem("selectedDate")))
  }
  if (globalSearchString !== '') {
    form.append(`search`, globalSearchString);
  }
  if (
    (newFilter && newFilter.length != 0) ||
    ((!newFilter || !newFilter.length) && last_filter)
  ) {
    const pos = newFilter.findIndex(
      (x) =>
        x.main_key === last_filter.main_key &&
        x.filter_key === last_filter.filter_key
    );
    if (last_filter != "") {
      if (pos === -1) {
        newFilter.push(last_filter);
      } else if (pos > -1 && newFilter.findIndex((x) => x.title == last_filter.title) !== -1) {
        newFilter.splice(pos, 1);
      }
    }

    let formattedList = [];
    newFilter.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });

    if (Object.keys(formattedList).length > 1) {
      Object.keys(formattedList).forEach((category) => {
        if (last_filter != "") {
          if (
            category !== last_filter.main_key &&
            last_filter.main_key != "date_range_filter" &&
            category != "date_range_filter"
          ) {
            delete formattedList[category];
          }
        }
      });
    }

    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.delete("filters[from_date]");
            if (localStorage.getItem("fromDate")) {
              if(moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD") == moment(localStorage.getItem("toDate")).format("YYYY-MM-DD")){
                form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
              }else{
                form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
                form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
              }
            }
          }
        }
      });
    });
  }
  if (newFilter && newFilter.length > 0) { 
    newFilterFormate(newFilter).forEach((dd) => {
      if(localStorage.getItem("contact_role") == 3){
        if (dd.main_key == "date_range_filter") {
        form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
        }
      }else{
      form.append(`filters[${dd.main_key}]`, JSON.stringify([dd]));
      }
    })
    if (newFilter.length > 0 && newFilter.some((filter)=> filter.main_key == "unread_comments")) {
      form.delete("filters[from_date]");
      form.delete("filters[to_date]");
      form.delete("startdate");      
    }
  }

  leadsFilters && Object.keys(leadsFilters).forEach((key) => {
    const values = leadsFilters[key];
    if (values && values.length > 0) {
        form.append(`filters[${key}]`, leadsFilters[key]);
    }
  })
  return apiService(endPoint, "POST", true, form);
};
const pinDocuments = (id, type) => {
  const endPoint = API_URL + "mod=invoices&act=pin_documents";
  let form = new FormData();
  form.append("document_id", id);
  form.append("document_type", type);
  return apiService(endPoint, "POST", true, form);
};
const getMentionlist = (rel_id, rel_type, is_external = 0, project_id, is_added_from_contact = 0, addedfrom = 0, user_type = "", companyId = 0, page_name = '') => {
  const endPoint = API_URL + "mod=comment&act=get_names_for_mentions";
  let form = new FormData();
  const guest_id = localStorage.getItem("guest_id") ? localStorage.getItem("guest_id") : -1;
  if (rel_type == "leaseDocument") {
    form.append("rel_type", "lease_document");
  }else{
    form.append("rel_type", rel_type);
  }
  form.append("rel_id", rel_id);
  form.append("project_id", is_external == 0 && window.location.pathname != "/my-drive" ? localStorage.getItem("selectedOffice") : project_id);

  if (companyId > 0) {
   form.append("provider_company_id", companyId);
  }
  if (page_name !== "") {
   form.append("page_name", page_name);
  }
  if (is_external) {
    form.append("is_external", 1);
    if (is_added_from_contact == 1) {
      form.append("contact_id", addedfrom);
      }else {
        form.append("staff_id", addedfrom);
      }
  }
  if (is_external == 1) {
    return apiService(endPoint, "POST", true, form, 0, true, 0, user_type);
  }else{
    return apiService(endPoint, "POST", true, form, 0, false, guest_id);
  }
};
const getMailAttachments = (threadId, attachmentIds) => {
  const endPoint = API_URL + "mod=gmail_integration&act=getMailAttachments";
  let form = new FormData();
  form.append("threadId", threadId);
  form.append("attachmentIds", attachmentIds);
  return apiService(endPoint, "POST", true, form);
};
const getTypeOfSpacePerSpace = (spaceId) => {
  const endPoint = API_URL + "mod=space&act=get_type_of_space_per_space";
  let form = new FormData();
  form.append("rel_id", spaceId || localStorage.getItem('selectedOffice'));
  form.append("rel_type", 'space');
  return apiService(endPoint, "POST", true, form);
};
const createAccessories = (description, is_external, item_id) => {
  const endPoint = API_URL + "mod=benchmark&act=add_benchmark_accessories";
  let form = new FormData();
  form.append("description", description);
  if(is_external)
  {
    form.append("is_external", is_external);
  }
  if(item_id > 0)
  {
    form.append("item_id", item_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const uploadInvoice = (id, file) => {
  const endPoint = API_URL + "mod=user_wallet&act=upload_invoice";
  let form = new FormData();
  form.append("id", id);
  form.append("file[0]", file);
  return apiService(endPoint, "POST", true, form);
}
const createChatChannels = (projectId = 0) => {
  const endPoint = API_URL + "mod=general&act=create_chat_channels";
  let form = new FormData();
  form.append("project_id", projectId);
  return apiService(endPoint, "POST", true, form);
}
const getProfileDetails = () => {
  const endPoint = API_URL + "mod=user&act=get_profile_details";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const userEditProfile = (first_name, last_name, email, role = '', position = '', password = '', mobile_number,images) => {
  const endPoint = API_URL + "mod=user&act=edit_profile";
  let form = new FormData();
  form.append("first_name", first_name);
  form.append("last_name", last_name);
  form.append("email", email);
  form.append("mobile_number", mobile_number);
  form.append("position", position);
  if (role != '') {
    form.append("role", role);
  }
  if (password != '') {
    form.append("password", password);
  }
  if(images && Object.keys(images).length)
  {
    Object.keys(images).forEach((image) => {
      if(images[image].file)
      {
        switch(image)
        {
          case "l_id_card_upload":
            form.append("id_card", images[image].file);
            break;
          case "l_company_id_upload":
            form.append("company_id", images[image].file);
            break;
          case "l_payment_upload":
            form.append("payment", images[image].file);
            break;
          case "l_insurance_upload":
            form.append("insurance", images[image].file);
            break;
          default:
            break;
        }
      }
    })
  }
  return apiService(endPoint, "POST", true, form);
}
const getRoles = () => {
  const endPoint = API_URL + "mod=user&act=get_staff_role";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const userUpdateEditProfile = (file, user_staff_id) => {
  const endPoint = API_URL + "mod=user&act=update_profile_image";
  let form = new FormData();
  form.append("file", file);
  if (user_staff_id > 0) {
     form.append("user_staff_id", user_staff_id);
  }
  return apiService(endPoint, "POST", true, form);
}

const userProfileRemove = (user_staff_id = 0) => {
  const endPoint = API_URL + "mod=user&act=remove_profile";
  let form = new FormData();
  if (user_staff_id > 0) {
     form.append("user_staff_id", user_staff_id);
  }
  
  return apiService(endPoint, "POST", true, form);
}

const getTypeOfJobs = () => {
  const endPoint = API_URL + "mod=task&act=get_type_of_job";
  let form = new FormData();

  return apiService(endPoint, "POST", true, form);
}
const addMyrStaff = (
  first_name,
  last_name,
  email,
  password,
  mobile_number,
  role) => {
  const endPoint = API_URL + "mod=user&act=add_myr_staff";
  let form = new FormData();
  if (localStorage.getItem('selectedVirtualSpace')) {
    form.append("project_id", JSON.parse(localStorage.getItem('selectedVirtualSpace')).id);
    form.append("is_virtual_space", 1);
  } else {
    form.append("project_id", localStorage.getItem('selectedOffice'));
  }

  form.append("first_name", first_name);
  form.append("last_name", last_name);
  form.append("email", email);
  form.append("mobile_number", mobile_number);
  form.append("password", password);
  if (role) {
    form.append("role", role);
  }
  // form.append("post", post);
  return apiService(endPoint, "POST", true, form);
}
const generateToken = () => {
  const endPoint = API_URL + "mod=json_api&act=generate_token";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const getJsonTokenDetails = () => {
  const endPoint = API_URL + "mod=json_api&act=get_json_api_token_details";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const removeVirtualSpace = (virtualspace_id) => {
  const endPoint = API_URL + "mod=space&act=delete_virtual_space";
  let form = new FormData();
  form.append("project_id", virtualspace_id);
  return apiService(endPoint, "POST", true, form);
}
const getSpaceCountDetails = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_space_details";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
}
const getDashboardDetails = (status, filters) => {
  const endPoint = API_URL + "mod=space&act=get_dashboard_details";
  let form = new FormData();
  if (window.location.pathname != "/dashboard" ) { 
    form.append("project_id", localStorage.getItem('selectedOffice'));
  }
  if (status && status.length) {
    status.forEach((item, index) => {
      if (item == 0) {
        form.append("is_requested", 1);
      } else {
        form.append(`status[${index}]`, item);
      }

    });
  } else {
    form.append(
      "startdate",
      localStorage.getItem("selectedDate")
        ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
        : ""
    );
  }
  if (filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key]) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
      // form.append(`filters[${filter.main_key}][${index}]`, filter.filter_key);
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value, index) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.append(`filters[from_date]`, localStorage.getItem("fromDate"));
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
          }
          form.delete("startdate");
        } else {
          form.append(`filters[${category}][${index}]`, value);
        }
      });
    });
  }
  return apiService(endPoint, "POST", true, form);
}
const addEditEquipment = (equipmentId, name, quantity, contact_assignee, provider_assignee, is_schedule_allowed = false) => { 
  const endPoint = API_URL + "mod=equipments&act=add";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("name", name);
  form.append("quantity", quantity);
  form.append("id", equipmentId);
  if (contact_assignee) {
    form.append("contact_assignee", contact_assignee);
  }
  if (provider_assignee) {
    form.append("provider_assignee", provider_assignee);
  }
  form.append("is_schedule_allowed", is_schedule_allowed ? 1 : 0 );
  return apiService(endPoint, "POST", true, form);
};
const getEquipmentQuantity = (equipment_id) => {
  const endPoint = API_URL + "mod=equipments&act=get_equipment_quantity";
  let form = new FormData();
  form.append("equipment_id", equipment_id);
  return apiService(endPoint, "POST", true, form);
};
const EditEquipmentQuantity = (equipment_id, quantity_id, quantity_name, project_id,device_id) => {
  const endPoint = API_URL + "mod=equipments&act=add_equipment_quantity";
  let form = new FormData();
  form.append("equipment_id", equipment_id);
  if (quantity_id && quantity_id > 0 && quantity_id != null) {
    form.append("quantity_id", quantity_id);
  }
  form.append("quantity_name", quantity_name);
  form.append("project_id", project_id);
  if (device_id != null && device_id != undefined && device_id != "") {
    form.append("device_id", device_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const DeleteEquipment = (equipment_id, project_id) => {
  const endPoint = API_URL + "mod=equipments&act=delete_equipment";
  let form = new FormData();
  form.append("id", equipment_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const SetRobotSchedule = (equipment_id, sub_category_id, project_id, schedule) => {
  const endPoint = API_URL + "mod=equipments&act=set_robot_schedule";
  let form = new FormData();
  form.append("equipment_id", sub_category_id);
  // form.append("sub_category_id", sub_category_id);
  form.append("project_id", project_id);
  if (schedule && schedule.length > 0) {
    schedule.forEach((day) => {
      form.append(`${day.day_key}`, JSON.stringify([day.start ? moment(day.startTime).format("hh:mm A") : '', day.stop ? moment(day.endTime).format("hh:mm A") : '']));
    })
  }
  return apiService(endPoint, "POST", true, form);
};
const GetRobotSchedule = (equipment_id) => {
  const endPoint = API_URL + "mod=equipments&act=get_robot_schedule";
  let form = new FormData();
  form.append("equipment_id", equipment_id);
  return apiService(endPoint, "POST", true, form);
};

const taskBulkAction = (taskIds, action, status = '', files = [], is_dashboard) => {
  const endPoint = API_URL + "mod=task&act=bulk_action";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("slug", 'task');
  form.append("project_id", project_id);
  form.append("action", action);
  if (action == 'status_change') {
    form.append("status", status);
  }
  if (action == 'set_due_date') {
    form.append("duedate", status);
  }
  if (action == 'comment') {
    form.append("comment", status);
    if(files)
    {
      Array.from(files).forEach((file, key) => {
        form.append(`file[${key}]`, file);
      });
    }
  }
  if (action == 'set_assignee' && status.length) {
    status.forEach((item) => {
      item.categories.forEach((category,key) => {
        form.append(`filters[${item.main_key}][${key}]`, category.filter_key);
      })
    })
  }
  if (taskIds.length) {
    taskIds.forEach((id, index) => {
      form.append(`item_id[${index}]`, id);
    })
  }
  form.append("is_dashboard", is_dashboard);

  return apiService(endPoint, "POST", true, form);
};


const getSpaceFloor = (isExternal, hash_id) => {
  const endPoint = API_URL + "mod=space&act=get_floor_and_space_data_by_project";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  if (isExternal) {
    form.append("hash_id", hash_id);
    form.append("is_external", isExternal ? "1" : "0");
  }else{
    form.append("project_id", project_id);
  }
  return apiService(endPoint, "POST", true, form);
};


const addSpaceDocument = (floor_id, title, description, files, space_document_id, sub_floor_id, is_onboarding, project_id) => {
  const endPoint = API_URL + "mod=space&act=add_space_document";
  project_id = project_id || store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("floor_id", floor_id);
  form.append("title", title);
  form.append("description", description);
  if (space_document_id > 0) { 
    form.append("space_document_id", space_document_id);
  }
  // form.append("sub_floor_id", sub_floor_id);
  if(is_onboarding && Number(is_onboarding))
  {
    form.append("is_onboarding", is_onboarding);
    form.append("file[0]", files)
  }
  if (files.length) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const getSpaceDocument = (floor_id, is_external) => {
  const endPoint = API_URL + "mod=space&act=get_space_document";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  if (is_external) {
    form.append("hash_id", floor_id);
    form.append("is_external", is_external);
  } else {
    form.append("floor_id", floor_id);
  }
  return apiService(endPoint, "POST", true, form);
};


const removeSpaceDocumentAttachment = (documentId, fileId) => {
  const endPoint = API_URL + "mod=space&act=delete_space_attachment";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("space_document_id", documentId);
  form.append("attachment_id", fileId);
  return apiService(endPoint, "POST", true, form);
};


const addEditFloor = (name, id) => {
  const endPoint = API_URL + "mod=android_ar&act=add_floor";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("name", name);
  form.append("project_id", project_id);
  if (id) {
    form.append("floor_id", id);
  }
  return apiService(endPoint, "POST", true, form);
}

const deleteFloor = (id) => {
  const endPoint = API_URL + "mod=android_ar&act=delete_floor";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("floor_id", id);
  return apiService(endPoint, "POST", true, form);
}

const createCreditNote = (id, amount) => {
  const endPoint = API_URL + "mod=creditnotes&act=createCreditNote";
  let form = new FormData();
  form.append("id", id);
  form.append("amount", amount);
  return apiService(endPoint, "POST", true, form);
}
const declineCreditNote = (id) => {
  const endPoint = API_URL + "mod=creditnotes&act=declineCreditNote";
  let form = new FormData();
  form.append("id", id);
  return apiService(endPoint, "POST", true, form);
}
const createCredit = (id, amount) => {
  const endPoint = API_URL + "mod=creditnotes&act=createCredit";
  let form = new FormData();
  form.append("id", id);
  form.append("amount", amount);
  return apiService(endPoint, "POST", true, form);
}
const getTeamDetails = (id) => {
  const endPoint = API_URL + "mod=company_profile&act=get_team_details";
  let form = new FormData();
  form.append("team_id", id);
  return apiService(endPoint, "POST", true, form);
}
const getIBANStatus = () => {
  const endPoint = API_URL + "mod=space&act=get_iban_status";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const getAssingees = (type,id) => {
  const endPoint = API_URL + "mod=mails&act=get_assingees";
  let form = new FormData();
  form.append("type", type);
  form.append("id", id);
  return apiService(endPoint, "POST", true, form);
}
const getContactsByClient = () => {
  const endPoint = API_URL + "mod=subscriptions&act=get_contact_list_by_client_id";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
}
const sendEmailMessage = (rel_id, rel_type, message, emails, is_internal_shared) => {
  const endPoint = API_URL + "mod=general&act=send_email";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("rel_id", rel_id);
  form.append("rel_type", rel_type);
  form.append("message", message);
  form.append("is_internal_shared", is_internal_shared);
  emails.map((item, i) => {
    form.append(`emails[`+i+`]`, item);
  });
  if(is_internal_shared) {
    form.append("is_internal_shared", Number(is_internal_shared)); 
  }
  return apiService(endPoint, "POST", true, form);
}
const addEditProposalAmount = (id, amount, description, accessory_items, accessory_amount = [], custom_invoice, invoice, is_external_client, proposal_type, is_added_by_myr, item_code, wallet_id, unitTasks, custom_field =[]) => {
  const endPoint = API_URL + "mod=proposal&act=update_proposal";
  let form = new FormData();
  form.append("proposal_id", id);
  form.append("amount", amount);
  form.append("description", description);
  form.append("accessory_items", accessory_items);
  form.append("custom_invoice", custom_invoice);
  form.append("item_code", item_code);
  if(is_external_client > 0)
  {
    form.append("is_external_client", is_external_client);
  }
  form.append("file[0]", invoice);
  if(is_added_by_myr > 0)
  {
    form.append("is_added_by_myr", is_added_by_myr);
  }
  if(proposal_type)
  {
    form.append("proposal_type", proposal_type);
  }
  if(accessory_amount.length)
  {
    accessory_amount.forEach((amount, index) => {
      form.append(`accessory_items[${index}][rel_id]`, amount.id);
      form.append(`accessory_items[${index}][amount]`, amount.amount);
    });
  }
  if(unitTasks && unitTasks.length)
  {
    unitTasks.forEach((task, i) => {
      form.append(`unit_task[${i}][id]`, task.id);
      form.append(`unit_task[${i}][amount]`, task.amount);
    });
  }
  if(wallet_id > 0)
  {
    form.append("wallet_id", wallet_id);
  }
  if (custom_field && custom_field.length > 0)  {
  form.append("custom_field", JSON.stringify(custom_field));
  }
  return apiService(endPoint, "POST", true, form);
};

const scheduleMail = (emailReply, message_id, files, mailCc, selectedId,date) => {
  const endPoint = API_URL + "mod=mails&act=schedule_mail";
  let form = new FormData();
  form.append("message_body", emailReply);
  form.append("message_id", message_id);
  form.append("cc", mailCc);
  form.append("selectedId", selectedId);
  form.append("date", date);
  if (files) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const getAttendance = (attendance_date) => {
  const endPoint = API_URL + "mod=dashboard&act=get_attendance";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("attendance_date", attendance_date);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getAttendanceList = (fromDate,toDate) => {
  const endPoint = API_URL + "mod=dashboard&act=get_attendance_list";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("from_date", fromDate);
  form.append("to_date", toDate);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const addEditAttendance = (attendance_type,attendance_date) => {
  const endPoint = API_URL + "mod=dashboard&act=add_attendance";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("attendance_date", attendance_date);
  form.append("attendance_type", attendance_type);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getDefaultTopicList = (isDefault = 0) => {
  const endPoint = API_URL + "mod=general&act=get_default_category";
  let form = new FormData();
  if(isDefault == 1){
    form.append("is_default", isDefault);
  }
  return apiService(endPoint, "POST", true, form);
};
const getSpaceWorkScore = () => {
  const endPoint = API_URL + "mod=space&act=get_space_work_score";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};
const fetchProviderList = (project_id) => {
  const endPoint = API_URL + "mod=space&act=fetch_provider_list";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};
const fetchEquipmentList = (project_id) => {
  const endPoint = API_URL + "mod=space&act=fetch_equipment_list";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};
const fetchTenetsList = (project_id) => {
  const endPoint = API_URL + "mod=space&act=fetch_tenents_list";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const convertMessage = (slug, task_id, message) => {
  const endPoint = API_URL + "mod=task&act=convert_message";
  const project_id = localStorage.getItem("selectedOffice");
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("slug", slug.trim());
  form.append("message[0]", message);
  form.append("task_id", task_id);
  return apiService(endPoint, "POST", true, form);
};

const projectAttendanceCount = (project_id)=>{
  const endPoint = API_URL + "mod=dashboard&act=get_project_attendance_count";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getAttendanceCountWeekCalendar = (project_id,date)=>{
  const endPoint = API_URL + "mod=dashboard&act=get_attendance_count_week_calendar";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("selected_date", date);
  return apiService(endPoint, "POST", true, form);
};

const deleteFloorItems = (project_id, slug, record_id, key) => {
  const endPoint = API_URL + "mod=space&act=remove_floor";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("slug", slug);
  if(Array.isArray(record_id))
  {
    record_id.forEach((x, index) => {
      form.append(`${key}[${index}]`, x);
    })
  }
  else {
    form.append(key, record_id);
  }
  return apiService(endPoint, "POST", true, form);
}
const addProjectMember = (staffIds, project_id) => {
  const endPoint = API_URL + "mod=space&act=add_project_members";
  let form = new FormData();
  form.append("project_id", project_id);
  if (staffIds && staffIds.length > 0) {
    staffIds.forEach((staff, key) => {
      form.append(`staff_ids[${key}]`, staff.staffid);
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const scan3dPlan = ( project_id, contact_name, mobileNumber, scan_date_time, floorSurface, address, wifiCode = "", accessCode = "", accessDescription = "",indoorScan = false ,outdoorScan =false
) => {
  const endPoint = API_URL + "mod=task&act=schedule_scan3d";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("phonenumber", mobileNumber);
  form.append("contact_name", contact_name);
  form.append("scan_date_time", scan_date_time);
  form.append("space_area", floorSurface);
  form.append("address", address);
  form.append("indoor_scan", indoorScan == true ? 1 : 0);
  form.append("outdoor_scan", outdoorScan == true ? 1 : 0);
  form.append("wifi_code", wifiCode);
  form.append("access_code", accessCode);
  form.append("acceess_description", accessDescription);
  return apiService(endPoint, "POST", true, form);
}  
const addEditRobotTask = (projectId, task_id, is_map = 1, superficy, duration, files = undefined) => {
  const endPoint = API_URL + "mod=task&act=add_robot_task";
  let form = new FormData();
  // form.append("project_id", projectId);
  form.append("surface", superficy);
  form.append("task_id", task_id);
  form.append("is_map", is_map);
  form.append("duration", duration);
  if (files && files.length > 0) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const getEquipmentReportList = (fromDate, toDate, unReadFilter = false, filters= []) => {
  const endPoint = API_URL + "mod=equipments&act=get_equipment_report_list";
  let form = new FormData();
  form.append("project_id", localStorage.getItem("selectedOffice"));
  let tempFromDate =
    fromDate != "" ? fromDate : localStorage.getItem("selectedDate");
  let tempToDate = toDate != "" ? toDate : "";
  if (unReadFilter) {
    form.append("is_unread", 1);
  } else {
    form.append("from_date", formateDate(tempFromDate));
    if (toDate != "") {
      form.append("to_date", formateDate(tempToDate));
    }
  }
  if (filters && filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key] && formattedList[filter.main_key] != undefined) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }})
    newFilterFormate(filters).forEach((fil) => {
      form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
    })
    }
  return apiService(endPoint, "POST", true, form);
};

const robotCommandAction = (action, equipment_id, device_id, taskId,project_id) => {
  const endPoint = API_URL + "mod=equipments&act=robot_command";
  let form = new FormData();
  form.append("device_id", device_id);
  form.append("equipment_id", equipment_id);
  form.append("action", action);
  form.append("project_id", project_id);
  if (taskId > 0) { 
    form.append("task_id", taskId);
  }
  return apiService(endPoint, "POST", true, form);
}

const addUpdateFloorSettings = (floor, type_of_space, project_id) => {
  const endPoint = API_URL + "mod=space&act=add_edit_floor_data";
  let form = new FormData();
  form.append("floor", JSON.stringify(floor));
  form.append("type_of_space", JSON.stringify(type_of_space));
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
}

const addFloorItem = (project_id, quantity, title, slug, floor_id, previous_quantity, sub_floor_id) => {
  const endPoint = API_URL + "mod=space&act=add_new_floor_item";
  let form = new FormData();
  form.append("quantity", quantity);
  if(slug == "type_of_space")
  {
    Array.isArray(title) ? title.forEach((x, index) => {
      if(x && x.trim().length)
      {
        form.append(`title[${index}]`, x);
      }
      
    })
    : form.append("title", [title]);
  }
  else {
    form.append("title", title);
  }
  form.append("slug", slug);
  form.append("project_id", project_id);
  form.append("previous_quantity", previous_quantity);
  if(floor_id)
  {
    form.append("floor_id", floor_id);
  }
  if(sub_floor_id)
  {
    form.append("sub_floor_id", sub_floor_id);
  }
  return apiService(endPoint, "POST", true, form);
}

const getOverDueTaskCOunt = ()=>{
  const endPoint = API_URL + "mod=space&act=get_overdue_task_count";
  let form = new FormData();

  return apiService(endPoint, "POST", true, form);
}

const getArPOIs = (floor_id,not_set_for_web = 1) => {
  const endPoint = API_URL + "mod=android_ar&act=get_ar_poi_web";
  // const endPoint = API_URL + "mod=android_ar&act=get_ar_pois";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("not_set_for_web", not_set_for_web);
  form.append("floor_id", floor_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const setArPOIs = (poi_id, floor_id, x, y, z ) => {
  const endPoint = API_URL + "mod=android_ar&act=set_ar_pois_web";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  if (poi_id > 0) { 
    form.append("poi_id", poi_id );
  }
  form.append("x", x.toFixed(8));
  form.append("y", y.toFixed(8));
  form.append("z", z.toFixed(8));
  form.append("direction",'entry');
  form.append("floor_id", floor_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const createArPOIs = (floor_id, x = 0, y = 0, z = 0, title, files, poiId = 0) => {
  const endPoint = API_URL + "mod=android_ar&act=create_ar_pois_web";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  if ((x != 0 || y != 0 || z != 0) && poiId == 0) {
  form.append("x", x.toFixed(8));
  form.append("y", y.toFixed(8));
  form.append("z", z.toFixed(8));
  }
  if (poiId > 0) { 
    form.append("poi_id", poiId);
  }
  form.append("direction",'entry');
  form.append("title",title);
  if(files)
  {
    Array.from(files).forEach((file, file_index) => {
      form.append(`attachment[${file_index}]`, file);
    });
  }
  form.append("floor_id", floor_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getArLocalAnchors = (floor_id ,taskId = 0) => {
  const endPoint = API_URL + "mod=android_ar&act=get_ar_local_anchors";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("floor_id", floor_id);
  form.append("for_web",1);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const setArLocalAnchors = (floor_id ,taskId = 0, pointId, x, y, z,equipment_id = 0, equipment_quantity_id= 0) => {
  const endPoint = API_URL + "mod=android_ar&act=set_ar_local_anchors_web";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("floor_id", floor_id);
  form.append("point_id", pointId);
  if (taskId > 0) { 
    form.append("task_id", taskId);
  }
  if (equipment_id > 0) {
    form.append("equipment_id", equipment_id);
    form.append("equipment_quantity_id", equipment_quantity_id);
  }
  form.append("x", x.toString());
  form.append("y", y.toString());
  form.append("z", z.toString());
  return apiService(endPoint, "POST", true, form);
};

const setDirection = (floor_id , x, y, z) => {
  const endPoint = API_URL + "mod=android_ar&act=set_entry_for_web_plan";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("floor_id", floor_id);
  form.append("x", x.toString());
  form.append("y", y.toString());
  form.append("z", z.toString());
  return apiService(endPoint, "POST", true, form);
};

const setDirectionNew = (floor_id , x, y, z,documentId) => {
  const endPoint = API_URL + "mod=android_ar_v2&act=set_north_for_web";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("floor_id", floor_id);
  form.append("document_id", documentId);
  form.append("x", x.toString());
  form.append("y", y.toString());
  form.append("z", z.toString());
  return apiService(endPoint, "POST", true, form);
};

const getSpaceDataOhover = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_detail_for_project";
  let form = new FormData();
  form.append("project_id", project_id);  
  return apiService(endPoint, "POST", true, form);
}

const deleteCompanyProfileUser = (project_id,userId) => {
  const endPoint = API_URL + "mod=company_profile&act=remove_employee";
  let form = new FormData();
  form.append("project_id", project_id);  
  form.append("employee_id", userId);  
  return apiService(endPoint, "POST", true, form);
}

const editAccessOfficeDetail = (accese_code, wifi_code, phonenumber,description) => {
  const endPoint = API_URL + "mod=space&act=edit_access_office_detail";
  let form = new FormData();
  const project_id = store.getState().customer.selectedProject;
  form.append("project_id", project_id);
  form.append("description", description);
  form.append("accese_code", accese_code);
  form.append("wifi_code", wifi_code);
  form.append("phonenumber", phonenumber);
  return apiService(endPoint, "POST", true, form);
}
const openAiSpace = (project_id,staff_id, description) => {
  const endPoint = API_URL + "mod=space&act=add_openai_content_space";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("staff_id", staff_id);
  form.append("content", description);

  return apiService(endPoint, "POST", true, form);
}
const getNewCustomer = (project_id,staff_id) => {
  const endPoint = API_URL + "mod=user&act=get_new_client_data";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("staff_id", staff_id);

  return apiService(endPoint, "POST", true, form);
}
const createNewClient = (company, vat = "", website="", state="", country=0, group="", phone="", currency="", zipCode="", address="", city="", d_lang="") => {
  const endPoint = API_URL + "mod=user&act=create_client";
  let form = new FormData();
  form.append("company", company);
  form.append("vat", vat);
  form.append("website", website);
  form.append("state", state);
  form.append("country", country);
  form.append("group", group);
  form.append("phone", phone);
  form.append("currency", currency);
  form.append("zipCode", zipCode);
  form.append("address", address);
  form.append("d_lang", d_lang);
  form.append("city", city);

  return apiService(endPoint, "POST", true, form);
}

const favoriteDdashboard = (project_id, dashboardName) => {
  const endPoint = API_URL + "mod=user&act=set_favorite_dashboard";
  let form = new FormData();
  form.append("project_id", project_id);

  form.append("dashboard_name", dashboardName);
  return apiService(endPoint, "POST", true, form);
}

const addOperatorAvaibility = (availability_data,is_active,userId) => {
  const endPoint = API_URL + "mod=company_profile&act=add_operator_avaibility";
  let form = new FormData();
  let tempData = []
  availability_data.map((w)=>{
    if (w.start_time != "" && w.end_time !=  "") {
      tempData.push({...w , "start_time" : moment(w.start_time).format("HH:mm"), "end_time" : moment(w.end_time).format("HH:mm")})    
    }
  })
  form.append("availability_data", JSON.stringify(tempData));
  form.append("is_active", is_active);
  if (userId > 0) { 
    form.append("operator_id",userId);
  }
  return apiService(endPoint, "POST", true, form);
}

const getOperatorAvaibility = (userId = 0) => {
  const endPoint = API_URL + "mod=company_profile&act=get_operator_avaibility";
  let form = new FormData();
  if (userId > 0) { 
    form.append("operator_id",userId);
  }
  return apiService(endPoint, "POST", true, form);
}

const getAllUserForStaff = (search_text, page) => {
  const endPoint = API_URL + "mod=user&act=get_user_list";
  let form = new FormData();
  form.append("search_text", search_text);
  form.append("page", page);
  return apiService(endPoint, "POST", true, form);
}

const changeTaskspace = (staff_id, contact_id, client_id, project_id, task_id) => {
  const endPoint = API_URL + "mod=task&act=change_task_project"
  let form = new FormData();
  form.append("staff_id",staff_id);
  form.append("contact_id", contact_id);
  form.append("client_id", client_id );
  form.append("project_id", project_id );
  form.append("task_id", task_id );

  return apiService(endPoint, "POST", true, form);
  
}

const addTenants = (tenants, project_ids, provider_id) => {

  const staff_id = localStorage.getItem("staff_id");
  const contact_id = localStorage.getItem("contact_id");
  const client_id = localStorage.getItem("client_id");

  const endPoint = API_URL + "mod=user&act=add_tenants";
  let form = new FormData();
  form.append("staff_id",staff_id);
  form.append("contact_id", contact_id);
  form.append("client_id", client_id );
  project_ids.forEach((project, index) => {
    form.append(`assigned_project_ids[${index}]`, project);
  });
  if(provider_id > 0)
  {
    form.append("provider_id", provider_id );
    tenants.forEach((user) => {
      let name = user.full_name.split(" ");
      user.firstname = name.length ? name[0] : "";
      user.lastname = name.length > 1 ? name.slice(1).join(" ") : "";
      user.role = 3;

    })
  }

  form.append("tenants", JSON.stringify(tenants) );

  return apiService(endPoint, "POST", true, form);
  
}

const getStaffIBANData = () => {
  const endPoint = API_URL + "mod=space&act=get_staff_iban";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}

const setupStaffIBANData = (unique_iban, project_data) => {
  const endPoint = API_URL + "mod=space&act=update_myr_iban";
  let form = new FormData();
  if(unique_iban)
  {
    form.append("unique_iban",unique_iban);
  }
  else {
    form.append("project_data", JSON.stringify(project_data));
  }

  return apiService(endPoint, "POST", true, form);
}

const updateSpaceData = (project_id, key, value) => {
  const endPoint = API_URL + "mod=space&act=update_space_info";
  let form = new FormData();
  form.append("project_id",project_id);
  form.append(key, value);
  return apiService(endPoint, "POST", true, form);
}
const getAllCompany = (user_type) => {
  const endPoint = API_URL + "mod=registration&act=get_all_company";
  let form = new FormData();
  if(user_type && user_type > 0)
  {
    form.append("user_type",user_type);
  }
  
  return apiService(endPoint, "POST", true, form);
}

const changePeojectStatus = (project_id ,status) => {
  const endPoint = API_URL + "mod=space&act=update_project_status";
  let form = new FormData();
    form.append("project_id",project_id);
    form.append("status",status);
  
  return apiService(endPoint, "POST", true, form);
}

const createChatGroup = (project_id,main_key , main_sub_key, filter_key) => {
  const endPoint = API_URL + "mod=chat_creation&act=chat_group_create";
  let form = new FormData();
  if (project_id > 0) {
    form.append("project_id", project_id);
  }else{
    form.append("is_registration", 1);
  }
  form.append("main_key", main_key);
  form.append("main_sub_key", main_sub_key);
  form.append("filter_key", filter_key);

  
  return apiService(endPoint, "POST", true, form);
}
const readSpaceInvitationAlert = (project_id) => {
  const endPoint = API_URL + "mod=space&act=read_space_invitation_alert";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getProviderCompanyAccounts = () => {
  const endPoint = API_URL + "mod=wallet&act=get_wallet";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getProviderCategoriesForWallet = () => {
  const endPoint = API_URL + "mod=space&act=get_provider_category";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const updateProviderCategoryWallet = (wallet_id, projects, categories, regions, wallet_title) => {
  const endPoint = API_URL + "mod=space&act=update_company_account";
  let form = new FormData();
  form.append("wallet_id", wallet_id);
  if(projects && projects.length)
  {
    projects.forEach((project, index) => {
      form.append(`project_ids[${index}]`, project);
    });
  }

  if(categories && categories.length)
  {
    categories.forEach((category, index) => {
      form.append(`category_ids[${index}]`, category);
    });
  }

  if(regions && regions.length)
  {
    regions.forEach((region, index) => {
      form.append(`region_ids[${index}]`, region);
    });
  }

  form.append("wallet_title", wallet_title);

  return apiService(endPoint, "POST", true, form);
};

const activeInactivateWallet = (wallet_id) => {
  const endPoint = API_URL + "mod=wallet&act=active_inactive_wallet";
  let form = new FormData();
 
  form.append("wallet_id", wallet_id);
  return apiService(endPoint, "POST", true, form);
};

const sendCommentMailNotification = (emails, project_id, rel_id, comment_id, content, subject, rel_type, attachments) => {
  const endPoint = API_URL + "mod=task&act=send_comment_as_email";
  let form = new FormData();
  emails.forEach((email, index) => {
    form.append(`email[${index}]`, email);
  });
  form.append("project_id", project_id);
  form.append("document_id", rel_id);
  form.append("document_type", rel_type);
  form.append("comment_id", comment_id);
  form.append("content", content);
  form.append("subject", subject);
  if(attachments)
  {
    Array.from(attachments).forEach((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  return apiService(endPoint, "POST", true, form);
};
const setTypeOfSapcePOI = (typeOfSpaceId, roomTitle, poiSet, floorId) => {
  const endPoint = API_URL + "mod=android_ar&act=set_type_of_space_poi";
  const project_id = store.getState().customer.selectedProject;
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("poi_set", JSON.stringify(poiSet));
  form.append("type_of_space_id", typeOfSpaceId);
  form.append("title", roomTitle);
  form.append("floor_id", floorId);
  return apiService(endPoint, "POST", true, form);
};

const setupProviderWallet = (walletData) => {
  const endPoint = API_URL + "mod=wallet&act=setup_wallet";
  let form = new FormData();
  form.append("account_iban", walletData.account_iban);
  form.append("company_name", walletData.company_name);
  form.append("email", walletData.email);
  form.append("company_address", walletData.company_address);
  form.append("terms", walletData.terms);
  form.append("vat_number", walletData.vat_number);
  form.append("invoice_tax", walletData.invoice_tax);
  form.append("tax_id", walletData.tax_id);
  form.append("siren_number", walletData.siren_number);
  form.append("bank_country", walletData.bank_country);
  form.append("bank_currency", walletData.bank_currency);
  form.append("birthdate", walletData.birthdate);
  form.append("country", walletData.country);
  form.append("city", walletData.city);
  form.append("phone_number", walletData.phone_number);
  form.append("postal_code", walletData.postal_code);
  form.append("first_name", walletData.first_name);
  form.append("last_name", walletData.last_name);
  form.append("region_id", walletData.region_id);
  form.append("region_name", walletData.region_name);
  form.append("front", walletData.front);
  form.append("back", walletData.back);
  walletData.categories.forEach((category, index) => {
    form.append(`category_ids[${index}]`, category);
  });
  if(walletData.proposal_id > 0 && walletData.benchmark_project_id > 0)
  {
    form.append("proposal_id", walletData.proposal_id);
    form.append("benchmark_project_id", walletData.benchmark_project_id);
  }
  if(walletData.region_ids && walletData.region_ids.length)
  {
    walletData.region_ids.forEach((w, i) => {
      form.append(`region_ids[${i}]`, w);
    })
  }
  if(walletData.wallet_title)
  {
    form.append("wallet_title", walletData.wallet_title);
  }
  return apiService(endPoint, "POST", true, form);
};

const getProviderWallets = (project_id, proposal_id) => {

  const endPoint = API_URL + "mod=benchmark&act=get_user_wallet_by_schedule";

  const operator_client_id = localStorage.getItem("client_id");
  const operator_staff_id = localStorage.getItem("staff_id");

  let form = new FormData();
  form.append("operator_client_id", operator_client_id);
  form.append("operator_staff_id", operator_staff_id);
  form.append("project_id", project_id);
  form.append("proposal_id", proposal_id);
  return apiService(endPoint, "POST", true, form);
};

const getMetricsForProvider = (project_id, provider_id, startdate, filterList) => {
  const endPoint = API_URL + "mod=space&act=get_service_metrics";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_id", provider_id);
  form.append("startdate", moment(startdate).format("YYYY-MM-DD"));
  if (filterList && filterList.length > 0) {
    filterList.forEach((fi) => {
    form.append(`filters[${fi.slug}]`, 1);
    })
  }

  return apiService(endPoint, "POST", true, form);
}
const getUserPlans = () => {
  const endPoint = API_URL + "mod=general&act=get_membership_plan";

  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const getDashboardDetailsWthAllSpace = (dateFilter,filterList,taskFilters, statusFilters)=>{
  const endPoint = API_URL + "mod=dashboard&act=get_dashboard_details";
  let form = new FormData();
  if (dateFilter == "dateRang") {
    form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
    form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
  }else if(dateFilter != "allfilter"){
    form.append("startdate", moment(localStorage.getItem('selectedDate')).format('YYYY-MM-DD'));
  }
  if (filterList && filterList.length > 0) {
    filterList.forEach((fi) => {
    form.append(`filters[${fi.slug}]`, 1);
    })
  }
  if (taskFilters && taskFilters.length) {
    let formattedList = [];
    taskFilters.forEach((filter) => {
      if (formattedList[filter.main_key] && formattedList[filter.main_key] != undefined) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
            form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
          }
          form.delete("startdate");
        }
      });
    });
    newFilterFormate(taskFilters.filter((fd)=> fd.main_key != "date_range_filter")).forEach((fil) => {
      form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
    })
  }
  if (statusFilters && statusFilters.length) {
    statusFilters.forEach((item, index) => {
      if (item == 0) {
        form.append("is_requested", 1);
        
      } else {
        form.append(`status[${index}]`, item);
      }

    });
  }
  return apiService(endPoint, "POST", true, form);
};
const getDashboardFilter = ()=>{
  const endPoint = API_URL + "mod=dashboard&act=get_dashboard_filters";
  let form = new FormData();
  // form.append("filter_id", filter_id);
  return apiService(endPoint, "POST", true, form);
};
const changeDashboardFilter = (items)=>{
  const endPoint = API_URL + "mod=dashboard&act=active_inactive_dashboard_filter";
  let form = new FormData();
  if (items && items.length > 0) {
    items.forEach((fi,i) => {
    form.append(`filter_ids[${i}]`, fi.id);
    })
  }else{
    form.append(`filter_ids[0]`, "0")
  }
  return apiService(endPoint, "POST", true, form);
};

const addEditChecklistRating = (project_id,checklist_id,task_id,rating = 0,review = "")=>{
  const endPoint = API_URL + "mod=task&act=add_checklist_rating";
  let form = new FormData();
  form.append("checklist_id", checklist_id);
  form.append("task_id", task_id);
  form.append("rating", rating);
  form.append("review", review);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};
const dashboardChart = (dateFilter, id, slug) =>{
  const endPoint = API_URL + "mod=dashboard&act=get_dashboard_topic_chart" ;
  let form = new FormData()
  form.append(`filters[${slug}]`, 1)
  form.append("project_id", id)
  if (dateFilter == "dateRang") {
    form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
    form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
  }else if(dateFilter != "allFilter"){
    form.append("startdate", moment(localStorage.getItem('selectedDate')).format('YYYY-MM-DD'));
  }
  return apiService(endPoint, "POST", true, form)
}

const getPotentialSavingsData = (project_id, is_all = 0)=>{
  const endPoint = API_URL + "mod=dashboard&act=get_credit_note_info";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("is_all", is_all);
  const startdate = moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD");
  const from_date = moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD");
  const to_date = moment(localStorage.getItem("toDate")).format("YYYY-MM-DD");

  const {daterangeFilterApplied} = store.getState().customer;
  if(daterangeFilterApplied)
  {
    form.append("filters[from_date]", from_date);
    form.append("filters[to_date]", to_date);
  }
  else 
  {
    form.append("startdate", startdate);
  }
  return apiService(endPoint, "POST", true, form);
};

const getSpaceContextData = (project_id, provider_id, is_general_context, ai_assistant_id)=>{
  const endPoint = API_URL + "mod=space&act=get_category_open_ai_content";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_id", provider_id);
  if(ai_assistant_id > 0)
  {
    form.append("ai_assistant_id", ai_assistant_id);
  }
  if(is_general_context > 0)
  {
    form.append("is_general_context", is_general_context);
  }
  return apiService(endPoint, "POST", true, form);
};

const setSpaceContextData = (project_id, provider_id, general_content, custom_content, is_general_context, ai_assistant_id, attachment_content)=>{
  const endPoint = API_URL + "mod=space&act=add_openai_content_from_customer_side";
  let form = new FormData();
  if(project_id > 0)
  {
    form.append("project_id", project_id);
  }
  form.append("provider_id", provider_id);
  
  if(is_general_context > 0)
  {
    form.append("is_general_context", is_general_context);
  }
  
  if(ai_assistant_id > 0)
  {
    form.append("ai_assistant_id", ai_assistant_id);
  }

  if(general_content && general_content.trim().length)
  {
    form.append("is_general_set", 1);
    form.append("general_content", general_content);
  }
  if(custom_content && custom_content.trim().length) {
    form.append("custom_content", custom_content);
  }
  if(attachment_content && attachment_content.trim().length) {
    form.append("attachment_context", attachment_content);
  }
  return apiService(endPoint, "POST", true, form);
};

const contactForm = (fullName,companyName,contactEmail,phoneNumber,selectedCountryCodes, startDate) => {
  const endPoint = API_URL + "mod=leads&act=add_lead";
  let form = new FormData();
  form.append("company", companyName);
  form.append("name", fullName);
  form.append("email",contactEmail);
  form.append("phonenumber", selectedCountryCodes + " " + phoneNumber);
  // form.append("appointment_date", startDate);
  return apiService(endPoint, "POST", true, form);
}

const fetchTaskAIContext = (task_id, project_id, attachment_id, parent_comment_id) => {
  const endPoint = API_URL + "mod=space&act=get_task_open_ai_context";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  if(attachment_id > 0)
  {
    form.append("attachment_id", attachment_id);
  }
  if(parent_comment_id > 0)
  {
    form.append("parent_comment_id", parent_comment_id);
  }
  return apiService(endPoint, "POST", true, form);
}

const saveTaskAIContext = (task_id, project_id, content, message, save_and_send, is_refresh_context, attachment_id, parent_comment_id, image_url, new_comment, AiContextResponse,is_analysing) => {

  const endPoint = API_URL + "mod=space&act=task_open_ai_request";
  let form = new FormData();
  form.append("task_id", task_id);
  form.append("project_id", project_id);
  form.append("context", content);
  form.append("refresh_task_context", is_refresh_context);
  form.append("user_description", message);
  form.append("messages", JSON.stringify(AiContextResponse))
  if(save_and_send)
  {
    form.append("save_and_send", save_and_send);
  }
  if(attachment_id > 0)
  {
    form.append("attachment_id", attachment_id);
  }
  if(parent_comment_id > 0)
  {
    form.append("parent_comment_id", parent_comment_id);
  }
  if(image_url && image_url.trim().length)
  {
    form.append("image_url", image_url);
    form.append("is_3d_scan", 1);
  }
  if(new_comment)
  {
    form.append("new_comment", new_comment);
  }
  if(is_analysing)
  {
    form.append("is_analysing", is_analysing);
  }
  return apiService(endPoint, "POST", true, form);
}

const aiGetDataFromScan3d = (contact_id, client_id, staff_id, project_id, floor_id, image_url) => {
  const endPoint = API_URL + "mod=open_ai&act=get_data_from_scan_3d";
  let form = new FormData();
  form.append("contact_id", contact_id);
  form.append("client_id", client_id);
  form.append("staff_id", staff_id);
  form.append("project_id", project_id);
  form.append("floor_id", floor_id);
  if(image_url && image_url.trim().length)
  {
    form.append("image_url", image_url);
  }
  return apiService(endPoint, "POST", true, form);
}

const toggleProviderOpenAIStatus = (provider_id, project_id, status, is_general_context, ai_assistant_id) => {
  const endPoint = API_URL + "mod=space&act=manage_open_ai_status";
  let form = new FormData();
  form.append("provider_id", provider_id);
  form.append("project_id", project_id);
  form.append("status", status);
  if(ai_assistant_id > 0)
  {
    form.append("ai_assistant_id", ai_assistant_id);
  }
  if(is_general_context > 0)
  {
    form.append("is_general_context", is_general_context);
  }
  return apiService(endPoint, "POST", true, form);
}

const changeNotificationPreference = (slug, key, setting_type) => {
  let endPoint;
  if(setting_type === "user_profile")
  {
    endPoint = API_URL + "mod=user&act=update_user_notification";
  }
  else if(setting_type === "company_profile") {
    endPoint = API_URL + "mod=company_profile&act=update_company_notification";
  }
  let form = new FormData();
  form.append("slug", slug);
  form.append("key", key);
  return apiService(endPoint, "POST", true, form);
}

const getAIMergeFields = () => {
  const endPoint = API_URL + "mod=space&act=get_ai_merge_fields";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}

const getAiContextForChat = (clientId, projectId, selectedDate) => {
  const endPoint = API_URL + "mod=open_ai&act=get_ai_context_for_chat";
  let form = new FormData();
  form.append("client_id", clientId );
  form.append("project_id", projectId );
  form.append("selected_date", selectedDate);
  return apiService(endPoint, "POST", true, form);
}

const taskOpenAiRequest = (context, projectId) => {
  const endPoint = API_URL + "mod=space&act=task_open_ai_request";
  let form = new FormData();
  // form.append("client_id", clientId );
  form.append("project_id", projectId);
  // form.append("contact_id", clientId );
  // form.append("staff_id", clientId );
  form.append("context", context);
  form.append("is_chat", 1 );
  return apiService(endPoint, "POST", true, form);
}
const getLeadTools = () => {
  const endPoint = API_URL + "mod=leads&act=get_tools";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getServiceList = () => {
  const endPoint = API_URL + "mod=leads&act=get_lead_services";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getTenantsServices = () => {
  const endPoint = API_URL + "mod=leads&act=get_tenants_services";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getProviderServices = () => {
  const endPoint = API_URL + "mod=general&act=get_default_category";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const getEquipmentsServices = () => {
  const endPoint = API_URL + "mod=leads&act=get_equipment_services";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const addLead = (leadData) => {
  const endPoint = API_URL + "mod=leads&act=add_user_lead";
  let form = new FormData();
  form.append(`company`, leadData.company_name);
  form.append(`name`, leadData.name);
  form.append(`email`, leadData.email);
  form.append(`phonenumber`, leadData.phonenumber);
  form.append(`position`, leadData.position);
  form.append(`password`, leadData.password);
  form.append(`total_no_space`, leadData.total_no_space);
  form.append(`total_no_sqm`, leadData.total_no_sqm);
  form.append(`total_employee`, leadData.total_employee);
  if(leadData.tenantsList.length)
  {
    form.append(`tenent_management_budget`, leadData.number_of_hours);
    form.append(`tenent_management_hours`, leadData.budget);
  }
  else if(leadData.providerList.length)
  {
    form.append(`provider_budget`, leadData.number_of_hours);
    form.append(`provider_hours`, leadData.budget);
  }
  else if(leadData.equipmentList.length)
  {
    form.append(`equipment_budget`, leadData.number_of_hours);
    form.append(`equipment_hours`, leadData.budget);
  }
  leadData.toolsList.forEach((tool, index) => {
    form.append(`tools[${index}][id]`, tool.value);
    form.append(`tools[${index}][name]`, tool.label);
  });
  leadData.serviceList.forEach((tool, index) => {
    form.append(`service_management[${index}][id]`, tool.value);
    form.append(`service_management[${index}][name]`, tool.label);
  });
  leadData.tenantsList.forEach((tool, index) => {
    form.append(`tenants_management[${index}][id]`, tool.value);
    form.append(`tenants_management[${index}][name]`, tool.label);
  });
  leadData.providerList.forEach((tool, index) => {
    form.append(`provider[${index}][id]`, tool.value);
    form.append(`provider[${index}][name]`, tool.label);
  });
  leadData.equipmentList.forEach((tool, index) => {
    form.append(`equipment[${index}][id]`, tool.value);
    form.append(`equipment[${index}][name]`, tool.label);
  });

  return apiService(endPoint, "POST", true, form);
};
const raiseGlobalCreditnote = (credit_total,item_array , is_preview) => {
  const endPoint = API_URL + "mod=creditnotes&act=raise_global_credit_note";
  let form = new FormData();
  form.append("credit_total", credit_total);
  form.append("item_array", item_array);
  form.append("is_preview", is_preview);
  form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
  form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
  return apiService(endPoint, "POST", true, form);
};
const checkSpaceEmailExists = (email) => {
  const endPoint = API_URL + "mod=space&act=check_email_for_space";
  let form = new FormData();
  form.append("email", email)
  return apiService(endPoint, "POST", true, form);
}

const addSpaceEmail = (email, project_id) => {
  const endPoint = API_URL + "mod=space&act=add_space_email";
  let form = new FormData();
  form.append("email", email);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
}


const messageIntoThread = (projectId, message, threadWithEnter, taskFilters, isFile = 0, file = {}, fileUrl = "") => {
  const endPoint = API_URL + "mod=open_ai&act=add_message_into_thread";
  const selectedTopic = JSON.parse(localStorage.getItem("selectedTopic"));
  let form = new FormData();
  if (localStorage.getItem("newMessageId")) {
    form.append("message_id", localStorage.getItem("newMessageId"));
  }            
  if (taskFilters && taskFilters.filter((data) => data.filter_key == "date_range_filter").length > 0) {
    form.append("from_date", formateDate(localStorage.getItem("fromDate")))
    form.append("to_date", formateDate(localStorage.getItem("toDate")))
  }else{
    form.append("from_date", formateDate(localStorage.getItem("selectedDate")))
    form.append("to_date", formateDate(localStorage.getItem("selectedDate")))
  }
  if (selectedTopic && selectedTopic.contact_role == 4) {
    // form.append("threadId", "thread_il7cFlV3YKrrDOPRwHPiGojA")
    form.append("threadId", selectedTopic.thread_id)
  }else if ((selectedTopic && selectedTopic.thread_id && selectedTopic.thread_id !== '')) {
    form.append("threadId", selectedTopic.thread_id)
    if (threadWithEnter == "threadWithEnter"){
      form.append("is_run", 0)
    }
  }
  if (localStorage.getItem("contact_role") == 3 && selectedTopic.filter_key == localStorage.getItem("staff_id")) {
    form.append("threadId", selectedTopic.thread_id);
  }
  if(isFile){
    form.append("file[0]", file);
    form.append("file_url[0]", fileUrl);
  }
  form.append("message", message)
  form.append("project_id", projectId)
  return apiService(endPoint, "POST", true, form);
}

const checkRunStatus = (runId) => {
  const endPoint = API_URL + "mod=open_ai&act=check_run_status";
  const selectedTopic = localStorage.getItem("selectedTopic");
  let form = new FormData();
  form.append("runId", runId);
  if (selectedTopic && JSON.parse(selectedTopic).contact_role == 4) {
    // form.append("threadId", "thread_il7cFlV3YKrrDOPRwHPiGojA")
    form.append("threadId", JSON.parse(selectedTopic).thread_id)
  }else if ((selectedTopic && JSON.parse(selectedTopic).thread_id && JSON.parse(selectedTopic).thread_id !== '')) {
    form.append("threadId", JSON.parse(selectedTopic).thread_id)
  }
  return apiService(endPoint, "POST", true, form);
}
const addMessageIntoTreadRunId = (thread_data_id) => {
  const endPoint = CHAT_WEB_BASE_URL + '/orb/cron/add_message_into_thread_and_run/'+thread_data_id;
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}

const generateAIThread = (project_id, main_key, filter_key, main_sub_key) => {
  const endPoint = API_URL + "mod=open_ai&act=create_thread_for_topic";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("main_key", main_key);
  form.append("filter_key", filter_key);
  form.append("main_sub_key", main_sub_key);
  return apiService(endPoint, "POST", true, form);
};

const handleUserActiveInactive = (employee_contact_id, employee_staff_id, active) => {
  const endPoint = API_URL + "mod=user&act=change_contact_status";
  let form = new FormData();
  form.append("employee_contact_id", employee_contact_id);
  form.append("employee_staff_id", employee_staff_id);
  form.append("active", active);
  return apiService(endPoint, "POST", true, form);
};

const addTypeofSpaceUsingAi = (project_id, floor_id) =>{
  const endPoint = API_URL + "mod=Open_ai&act=add_type_of_space_using_ai";
  let form = new FormData();
  form.append("project_id", project_id)
  form.append("floor_id", floor_id);
  return apiService(endPoint, "POST", true, form);
}

const setSpaceCoefficient = (staff_id, contact_id, client_id, project_id, space_id, coeffcient_value) => {
  const endPoint = API_URL + "mod=space&act=set_space_coeffcient";
  let form = new FormData();
  form.append("staff_id", staff_id);
  form.append("contact_id", contact_id);
  form.append("client_id", client_id);
  form.append("project_id", project_id);
  form.append("space_id", space_id);
  form.append("coeffcient_value", coeffcient_value);
  return apiService(endPoint, "POST", true, form);
};

const getInitialIDBData = (staff_id) =>{
  const endPoint = API_URL + "mod=user&act=get_all_task_by_user";
  let form = new FormData();
  form.append("staff_id", staff_id);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", true, form);
};

export const getTasklistForChat = (projectId,ids,provider_page = 0) =>{
  const endPoint = API_URL + "mod=task&act=get_task_list";
  let form = new FormData();
  if (projectId && projectId > 0) {
    form.append("project_id", projectId)
  }else{
    form.append("is_dashboard", 1);
  }
  form.append("task_ids", JSON.stringify(ids));
  form.append("provider_page", provider_page);
  return apiService(endPoint, "POST", true, form);
}

const checkAiResponseStatus = (projectId, threadDataId) =>{
  const endPoint = API_URL + "mod=open_ai&act=check_ai_response_status";
  let form = new FormData();
  form.append("project_id", projectId)
  form.append("thread_data_id", threadDataId);
  return apiService(endPoint, "POST", true, form);
}

const createAiAssistantForCustomer = (projectId, context,firstname, lastname) => {
  const endPoint = API_URL + "mod=open_ai&act=create_ai_assistant_for_customer";
  let form = new FormData();
  form.append("project_id", projectId);
  form.append("contact_role", localStorage.getItem("contact_role"));
  form.append("context", context);
  form.append("company_name", localStorage.getItem("company_name"))
  form.append("firstname", firstname)
  form.append("lastname", lastname)

  return apiService(endPoint, "POST", true, form);
}
const createTranscription = (file_url,task_id) => {
  const endPoint = API_URL + "mod=open_ai&act=create_transcription";
  let form = new FormData();
  form.append("file_url", file_url)
  form.append("task_id", task_id)

  return apiService(endPoint, "POST", true, form);
}
const addClientMembership = (package_id) => {
  const endPoint = API_URL + "mod=registration&act=add_client_membership_from_chat";
  let form = new FormData();
  form.append("package_id", package_id)

  return apiService(endPoint, "POST", true, form);
}
const getSpacemembarshipPackageID = () => {
  const endPoint = API_URL + "mod=space&act=get_user_package_id";
  let form = new FormData();

  return apiService(endPoint, "POST", true, form);
}
const getInviteUserDetails = (hashId) => {
  const endPoint = API_URL + "mod=registration&act=get_invite_details";
  let form = new FormData();
  form.append("hash", hashId)

  return apiService(endPoint, "POST", true, form);
}
const getCompanyInvitation = () => {
  const endPoint = API_URL + "mod=user&act=get_company_invitetion";
  let form = new FormData();

  return apiService(endPoint, "POST", true, form);
}
const getLeadEmailTemplate = () => {
  const endPoint = API_URL + "mod=leads&act=get_email_template";
  let form = new FormData();

  return apiService(endPoint, "POST", true, form);
}
const acceptCompanyInvitation = (invitetion_id,invitetion_client_id) => {
  const endPoint = API_URL + "mod=user&act=accept_company_invitation";
  let form = new FormData();
  form.append("invitetion_id", invitetion_id);
  form.append("invitetion_client_id", invitetion_client_id);

  return apiService(endPoint, "POST", true, form);
}
const rejectCompanyInvitation = (invitetion_id,invitetion_client_id) => {
  const endPoint = API_URL + "mod=user&act=reject_company_invitation";
  let form = new FormData();
  form.append("invitetion_id", invitetion_id);
  form.append("invitetion_client_id", invitetion_client_id);

  return apiService(endPoint, "POST", true, form);
}
const createChatbetweenAgentandOperator = (first_user_id,second_user_id) => {
  const endPoint = API_URL + "mod=chat_creation&act=create_chat_one_to_one";
  let form = new FormData();
  form.append("first_user_id", first_user_id);
  form.append("second_user_id", second_user_id);
  return apiService(endPoint, "POST", true, form);
}
const leadsSendEmail = (lead_id, from_email, to_email, reminder_date, template_slug, emailtemplateid, subject, email_template_custom, is_lead_contact) => {
  const endPoint = API_URL + "mod=leads&act=send_to_email";
  let form = new FormData();
  form.append("lead_id", lead_id);
  form.append("emailtemplateid", emailtemplateid);
  form.append("template_slug", template_slug);
  form.append("subject", subject);
  form.append("email_template_custom", JSON.stringify(email_template_custom));
  form.append("from_email", from_email);
  form.append("to_email", to_email);
  form.append("is_lead_contact", is_lead_contact);
  form.append("reminder_date", reminder_date);

  return apiService(endPoint, "POST", true, form);
}
const setPrimaryLeadContact = (lead_contact_id) => {
  const endPoint = API_URL + "mod=leads&act=set_primary_lead_contact";
  let form = new FormData();
  form.append("lead_contact_id", lead_contact_id);
  return apiService(endPoint, "POST", true, form);
};

const saveUserGoCardless = ({iban, email, first_name, last_name, company_name, billing_address, country, city, postal_code, phonenumber}) => {
  const endPoint = API_URL + "mod=Payment_check&act=set_gocardless_stripe_for_user";
  let form = new FormData();
  form.append("account_iban", iban);
  form.append("email", email);
  form.append("first_name", first_name);
  form.append("last_name", last_name);
  form.append("company_name", company_name);
  form.append("company_address", billing_address);
  form.append("country_code", country);
  form.append("city", city);
  form.append("postal_code", postal_code);
  form.append("phonenumber", phonenumber);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", true, form);
}

const getCustomerEstimate= (project_id) => {
  const endPoint = API_URL + "mod=registration&act=get_all_company";
  let form = new FormData();
  if (project_id) {
    form.append("project_id", project_id);    
  } else {
    form.append("all", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const getAllTaxName= () => {
  const endPoint = API_URL + "mod=general&act=get_all_tax_name";
  let form = new FormData();
    form.append("all", 1);
  return apiService(endPoint, "POST", true, form);
};

const getEstimateTerms= (staff_id) => {
  const endPoint = API_URL + "mod=general&act=get_terms_and_number_to_create_estimate";
  let form = new FormData();
  if (staff_id) {
    form.append("staff_id", staff_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const estimateSendEmails= (staff_id, client_id) => {
  const endPoint = API_URL + "mod=estimate&act=get_estimate_send_as_email_detail";
  let form = new FormData();
  if (staff_id) {
    form.append("staff_id", staff_id);
  }
  if (client_id) {
    form.append("customer_client_id", client_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const updateLocalAnchorsDirection = (webX, webY, webZ, anchorId) => {
  const endPoint = API_URL + "mod=android_ar&act=update_ar_local_anchors_direction";
  let form = new FormData();
  form.append("webX", webX);
  form.append("webY", webY);
  form.append("webZ", webZ);
  form.append("local_anchor_id", anchorId);
  return apiService(endPoint, "POST", true, form);
}

const addClientTopicLogo = (main_key, main_sub_key, filter_key, staff_id, contact_id, client_id, icon_type, icon_name) => {
  const endPoint = API_URL + "mod=task&act=add_client_topic_logo";
  let form = new FormData();
  form.append("main_key", main_key);
  form.append("main_sub_key", main_sub_key);
  form.append("filter_key", filter_key);
  form.append("staff_id", staff_id);
  form.append("contact_id", contact_id);
  form.append("client_id", client_id);
  if (icon_type == "icon") {
    form.append("icon_type", icon_type);
    form.append("icon_name", icon_name);
  } else {
    form.append("icon_type", icon_type);
    Array.from(icon_name).forEach((file, index) => {
      form.append(`icon_name[${index}]`, file);
    });
  }
  return apiService(endPoint, "POST", true, form);
}
const addToSpam = (threadId) =>{
  const endPoint = API_URL + "mod=gmail_integration&act=add_to_spam";
  let form = new FormData();
  form.append("threadId", threadId); 

  return apiService(endPoint, "POST", true, form);
}
const setPriorityOfMail = (threadId, thread_type = "gmail") =>{
  const endPoint = API_URL + "mod=gmail_integration&act=add_remove_priority_in_email";
  let form = new FormData();
  form.append("threadId", threadId); 
  form.append("thread_type", thread_type); 

  return apiService(endPoint, "POST", true, form);
}

const addCustomTopicTags = (threadId, custom_topic = []) => {
  const endPoint = API_URL + "mod=gmail_integration&act=add_topics_to_email";
  let form = new FormData();
  form.append("threadId", threadId); 
  form.append("thread_type", "gmail");
  for (let i = 0; i < custom_topic.length; i++) {
    form.append(`custom_topic[${i}]`, custom_topic[i].tag_id);
  }
  return apiService(endPoint, "POST", true, form);
}
const fetchProviderlistByCatgory = (category_ids) =>{
  const endPoint = API_URL + "mod=services&act=get_provider_company_list";
  let form = new FormData();
  if (category_ids && category_ids.length > 0) {
    category_ids.map((id,i) => {
      if(id > 0){
        form.append(`category_ids[${i}]`, id);
      }
    });
  }

  return apiService(endPoint, "POST", true, form);
}
const getSelectedProviderDetails = (id, tag_name) =>{
  const endPoint = API_URL + "mod=services&act=get_provider_company_detail";
  let form = new FormData();
    form.append(`provider_company_id`, id)
    form.append(`tab_name`, tag_name)

  return apiService(endPoint, "POST", true, form);
}

const addRiskData = (id, risk_list, risk_id, title,risk_score) =>{
  const endPoint = API_URL + "mod=services&act=add_edit_client_risk_DEV";
  let form = new FormData();
    form.append(`provider_company_id`, id)
    form.append(`risk_list`, JSON.stringify(risk_list))
    if (risk_id && risk_id > 0) {
      form.append(`risk_id`, risk_id )
    }
    form.append(`title`, title)
    form.append(`score`, risk_score)

  return apiService(endPoint, "POST", true, form);
}

const removeRiskData = (id, rating, coefficient, title, notes, indicator, risk_id) =>{
  const endPoint = API_URL + "mod=services&act=remove_risk";
  let form = new FormData();
    form.append(`provider_company_id`, id)
    form.append(`risk_id`, risk_id )
    

  return apiService(endPoint, "POST", true, form);
}

const editRiskData = (id, risk_list, risk_id, title) =>{
  const endPoint = API_URL + "mod=services&act=add_edit_client_risk_DEV";
  let form = new FormData();
  form.append(`provider_company_id`, id)
  form.append(`risk_list`, JSON.stringify(risk_list))
  if (risk_id) {
    form.append(`risk_id`, risk_id )
  }
  form.append(`title`, title )
  return apiService(endPoint, "POST", true, form);
}

const addRiskDataAsTemplate = (risk_list, staff_id, contact_id, client_id, provider_company_id, title, template_id ) =>{
  const endPoint = API_URL + "mod=services&act=add_edit_risk_template";
  let form = new FormData();

  if (template_id) {
    form.append(`template_id`, template_id )
  }
  form.append(`staff_id`, staff_id)
  form.append(`contact_id`, contact_id)
  form.append(`contact_id`, contact_id)
  form.append(`client_id`, client_id)
  form.append(`provider_company_id`, provider_company_id)
  form.append(`title`, title)
  form.append(`risk_list`, JSON.stringify(risk_list))
  form.append(`provider_page`, 1)
  return apiService(endPoint, "POST", true, form);
}

const createToDos = (title = [] , selectedAssigneeNew = []) =>{
  const endPoint = API_URL + "mod=task&act=add_task_from_provider_page";
  let form = new FormData();
  if (title && title.length) {
    title.forEach((t,i)=>{
      form.append(`title[${i}]`,t.title)
    })
  }
  if (selectedAssigneeNew && selectedAssigneeNew.length > 0) {
    selectedAssigneeNew.map((assignee) => {
      if (assignee.categories && assignee.categories.length > 0) {
        form.append(`filters[${assignee.main_key}]`, JSON.stringify([assignee])); 
      }
    })
  }
  form.append(`visible_to_client`, 1)
  form.append(`visible_to_provider`, 1)
  form.append(`status`, 1)
  form.append(`priority`, 2)
  form.append(`start_date`, moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))

  return apiService(endPoint, "POST", true, form);
}

const getRiskDataTemplate = (provider_company_id) =>{
  const endPoint = API_URL + "mod=services&act=get_risk_template";
  let form = new FormData();

  form.append(`provider_company_id`, JSON.stringify(provider_company_id))

  return apiService(endPoint, "POST", true, form);
}

const getProviderCompanyTabsCount = (provider_company_id) => {
  const endPoint = API_URL + "mod=services&act=get_provider_company_tabs_count";
  let form = new FormData();
  form.append(`provider_company_id`, provider_company_id)

  return apiService(endPoint, "POST", true, form);
}
const sendCreateEmail = (subject, message_body, senderEmail, files) => {
  const endPoint = API_URL + "mod=gmail_integration&act=send_email";
  let form = new FormData();
  form.append(`subject`, subject);
  form.append(`message_body`, message_body);
  if (senderEmail && senderEmail.length > 0) {
    senderEmail.map((item, i) => {
      form.append(`sender_emails[`+i+`]`, item);
    });;
  }
  if (files && files.length > 0) {
    files.map((item, i) => {
      form.append(`file[`+i+`]`, item);
    });;
  }
  return apiService(endPoint, "POST", true, form);
}

const sendCreateScheduleEmail = (subject, message_body, senderEmail, files, date = '', messageId = '', is_reply = false) => {
  const endPoint = API_URL + "mod=gmail_integration&act=schedule_mail";
  let form = new FormData();
  form.append(`subject`, subject);
  form.append(`message_body`, message_body);
  if (is_reply) {
    form.append(`is_reply`, 1);
  }
  if (messageId !== '') {
    form.append(`message_id`, messageId);
  }
  if (date !== '') {
    form.append(`schedule_date`, new Date(date).toISOString());
  }
  if (senderEmail && senderEmail.length > 0) {
    senderEmail.map((item, i) => {
      form.append(`sender_emails[`+i+`]`, item);
    });;
  }
  if (files && files.length > 0) {
    files.map((item, i) => {
      form.append(`file[`+i+`]`, item);
    });;
  }
  return apiService(endPoint, "POST", true, form);
}

const uploadAiAssistantFiles = (assistant_id, files) =>{
  const endPoint = API_URL + "mod=open_ai&act=upload_file_in_assistant";
  let form = new FormData();
form.append(`assistant_id`, assistant_id)
if (files) {
  Array.from(files).map((file, index) => {
    form.append(`file`, file);
  });
}

  return apiService(endPoint, "POST", true, form);
}
const getuploadAiAssistantFiles = (assistant_id) =>{
  const endPoint = API_URL + "mod=open_ai&act=get_assistant_files";
  let form = new FormData();
form.append(`assistant_id`, assistant_id)


  return apiService(endPoint, "POST", true, form);
}
const getEmailTrackingDetail = (threadId) => {
  const endPoint = API_URL + "mod=gmail_integration&act=get_email_tracking_detail";
  let form = new FormData();
  form.append("thread_type", "gmail");
  form.append("threadId", threadId);
  return apiService(endPoint, "POST", true, form);
};
const setCategoryStatusForAi = (project_id,filter_key,status,) =>{
  const endPoint = API_URL + "mod=space&act=manage_topic_json_status"
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("filter_key", filter_key);
  form.append("main_sub_key", "providers");
  form.append("status", status);
  form.append("main_key", "providers");
  return apiService(endPoint, "POST", true, form);
}
const deleteShedualeEmail = (id) =>{
  const endPoint = API_URL + "mod=gmail_integration&act=cancel_schedule_mail"
  let form = new FormData();
  form.append("email_id", id);
  return apiService(endPoint, "POST", true, form);
}

const deleteAssistantFile = ( file_id ) =>{
  const endPoint = API_URL + "mod=task&act=remove_ai_file"
  let form = new FormData();
  form.append("file_id", file_id);
  return apiService(endPoint, "POST", true, form);
}

const updateEmailTemplate = (theme_id ) =>{
  const endPoint = API_URL + "mod=company_profile&act=update_email_template_theme"
  let form = new FormData();
  form.append("theme_id", theme_id);
  return apiService(endPoint, "POST", true, form);
}
const assigneProviderCompany = (companyId, ids = [] ) =>{
  const endPoint = API_URL + "mod=services&act=assigne_provider_company"
  let form = new FormData();
  ids.forEach((id, i) => {
    form.append(`assignee_ids[`+i+`]`, id);
  });
  form.append("provider_company_id", companyId);
  return apiService(endPoint, "POST", true, form);
}

const emailAutoReplyChat = (sender, content) =>{
  const endPoint = API_URL + "mod=open_ai&act=email_auto_reply"
  let form = new FormData();
  
  form.append(`sender`, sender);
  form.append("content", content);
  return apiService(endPoint, "POST", true, form);
}
const getProvidersAgents = () =>{
  const endPoint = API_URL + "mod=clients&act=get_agent_list"
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const getWalletListOfProvider = (sender, content) =>{
  const endPoint = API_URL + "mod=wallet&act=get_wallet"
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}

const getProvidersClients = () =>{
  const endPoint = API_URL + "mod=Clients&act=get_customer_company_list"
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const getEmailTokenActive = () =>{
  const endPoint = API_URL + "mod=gmail_integration&act=is_gmail_token_active"
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const getLeaseLists = (projectId) =>{
  const endPoint = API_URL + "mod=lease&act=get_lease_list"
  let form = new FormData();
  if (projectId != undefined && projectId != '') {
    form.append("project_id", projectId)
  }
  return apiService(endPoint, "POST", true, form);
}
const registrationNewVersion = (contact_name, phonenumber, email, company, region_id, company_address) =>{
  const endPoint = API_URL + "mod=registration&act=registration_new_version"
  let form = new FormData();
  
    form.append("contact_name", contact_name);
    form.append("phonenumber", phonenumber);
    form.append("email", email);
    form.append("company", company);
    form.append("region_id", region_id);
    form.append("company_address", company_address);
    form.append("user_type", 1);
    form.append("package_id", 1);
    form.append("is_client_invitee", 1);
    
    return apiService(endPoint, "POST", true, form);
  }
  const updateTaskRating = (task_id, rating ) =>{
    const endPoint = API_URL + "mod=task&act=update_task_rating"
    let form = new FormData();
  
    const contact_role = localStorage.getItem("contact_role");
    const contact_id = localStorage.getItem("contact_id");
    const staff_id = localStorage.getItem("staff_id");
    const client_id = localStorage.getItem("client_id");
  
    form.append(`contact_role`, contact_role);
    form.append(`staff_id`, staff_id);
    form.append(`contact_id`, contact_id);
    form.append(`client_id`, client_id);
    form.append(`task_id`, task_id);
    form.append(`rating`, rating);
  
    return apiService(endPoint, "POST", true, form);
  }

  const getEquipmentList = () =>{
    const endPoint = API_URL + "mod=equipments&act=get_list_of_equipments"
    let form = new FormData();
    
    return apiService(endPoint, "POST", true, form);
  }

  const getNotificationDashboard  = () =>{
    const endPoint = API_URL + "mod=comment&act=get_comments"
    const guest_id = localStorage.getItem("guest_id") ? localStorage.getItem("guest_id") : -1;
  
    let form = new FormData();
    form.append(`rel_id`, localStorage.getItem("client_id"));
    form.append(`rel_type`, 'dashboard');
    
    return apiService(endPoint, "POST", true, form, 0, false, guest_id);
  }

  const getTaskIdOfVapiAssistantCall  = (
    project_id ,
    call_id
  ) =>{
    const endPoint = API_URL + "mod=task&act=get_vapi_call_task"
  
    let form = new FormData();
    form.append(`project_id`, project_id);
    form.append(`call_id`, call_id);
    
    return apiService(endPoint, "POST", true, form,);
  }
  
  const addSpacePhoneNumber = (phone_number, project_id) => {
    const endPoint = API_URL + "mod=space&act=add_space_phone_number";
    let form = new FormData();
    form.append("phone_number", phone_number);
    form.append("project_id", project_id);
    return apiService(endPoint, "POST", true, form);
  }

  const getAiPhoneNoList = () => {
    const endPoint = API_URL + "mod=space&act=get_mobileNumbers_from_vapi";
    let form = new FormData();
    return apiService(endPoint, "POST", true, form);
  }

  const getDashboardTasks = () => {
    const endPoint = API_URL + "mod=task&act=get_task_list";
    let form = new FormData();
    form.append("is_home_page", 1)
    form.append("page_name", "home_page")
    form.append("startdate", moment(new Date()).format("YYYY-MM-DD"))
    return apiService(endPoint, "POST", true, form);
  }

  const getInvoicesTerms= (staff_id) => {
    const endPoint = API_URL + "mod=general&act=get_terms_and_number_to_create_invoice";
    let form = new FormData();
    if (staff_id) {
      form.append("staff_id", staff_id);
    }
    return apiService(endPoint, "POST", true, form);
  };

  const getCreditNoteTerms= (staff_id) => {
    const endPoint = API_URL + "mod=general&act=get_terms_and_number_to_create_credit_note";
    let form = new FormData();
    if (staff_id) {
      form.append("staff_id", staff_id);
    }
    return apiService(endPoint, "POST", true, form);
  };
  
  const setCheckListOrder= (task_id, checklist_items) => {
    const endPoint = API_URL + "mod=task&act=update_checklist_item_order";
    let form = new FormData();
      form.append("task_id", task_id);
      form.append("checklist_items", JSON.stringify(checklist_items));
      form.append("type_of_space_item_id", 0);
    return apiService(endPoint, "POST", true, form);
  };

  const updateIDBJson = ()=>{
    
    const endPoint = API_URL + "mod=task&act=update_json_task_files";
    let form = new FormData();
    return apiService(endPoint, "POST", true, form);



  }

  const addEditProfileCompany= (company_name, company_id_number, company_tax_id, company_address, company_type, provider_client_id, additional_data, category_ids, region_ids) => {
  const endPoint = API_URL + "mod=services&act=edit_invitee_provider";
    let form = new FormData();
      form.append("company_name", company_name);
      form.append("company_id_number", company_id_number);
      form.append("company_tax_id", company_tax_id);
      form.append("company_address", company_address);
      form.append("company_type", company_type);
      form.append("provider_client_id", provider_client_id);
      form.append("additional_data", additional_data);
      if (category_ids && category_ids.length > 0) {
        form.append("category_ids[0]", category_ids[0].value);
      }
      if (region_ids && region_ids.length > 0) {
        form.append("region_ids[0]", region_ids[0].value);
      }
    return apiService(endPoint, "POST", true, form);
  };

  const addEditProfileCntact= (provider_client_id, first_name, last_name, contact_mobile, contact_email, contact_role, is_edit, contact_staff_id = '') => {
    const endPoint = API_URL + (is_edit ? "mod=services&act=edit_provider_profile_contact" : "mod=services&act=create_provider_profile_contact");
      let form = new FormData();
        form.append("provider_client_id", provider_client_id);
        form.append("first_name", first_name);
        form.append("last_name", last_name);
        form.append("contact_mobile", contact_mobile);
        form.append("contact_email", contact_email);
        form.append("provider_contact_role", contact_role);
        if (contact_staff_id != '') {
          form.append("contact_staff_id", contact_staff_id);
        }

        return apiService(endPoint, "POST", true, form);
    };

  const deleteIBANBilling = (payment_id) => {
    const endPoint = API_URL + "mod=user_payment&act=delete_payment_method";
    let form = new FormData();
    
    form.append("payment_id", payment_id);

    return apiService(endPoint, "POST", true, form);
  };

  const getClientCounts = () => {
    const endPoint = API_URL + "mod=space&act=getClientCount";
    let form = new FormData();
    return apiService(endPoint, "POST", true, form);
  };


  const creatProviderDocument = (payload, updateNameOnly = false) => {
    const endPoint = API_URL + "mod=dashboard&act=add_edit_provider_document";
    let form = new FormData();
    form.append("project_id", payload.project_id);
    form.append("provider_client_id", payload.operator_id);
    form.append("category_id", payload.category_id);
    form.append("custom_fields", payload.custom_fields);
    form.append("document_name", payload.document_name);
    form.append(
      "start_date",
      moment(payload.start_date).format("YYYY-MM-DD")
    );
    form.append(
      "end_date",
      moment(payload.end_date).format("YYYY-MM-DD")
    );
  
    // Handle notice dates
    var noticeDatesArray = (payload.notice_dates || []).map((nd) => ({
      assignee: nd.assignee ? nd.assignee.staff_id || nd.assignee.id : "",
      date: moment(nd.date).format("YYYY-MM-DD"),
    }));
    if(updateNameOnly){
      noticeDatesArray = (payload.notice_dates || []).map((nd) => ({
        assignee: nd.assignee_id ? nd.assignee_id : "0",
        date: nd.notice_date,
      }));
    }
    form.append("notice_dates", JSON.stringify(noticeDatesArray));
  
    // Handle lease files
    (payload.lease_files || []).forEach((leaseObj, index) => {
      if (leaseObj.file) {
        form.append(`provider_contract[${index}]`, leaseObj.file);
      } 
    });
  
    // Handle other files
    (payload.other_files || []).forEach((otherObj, index) => {
      if (otherObj.file) {
        form.append(`provider_document[${index}]`, otherObj.file);
      } 
    });
  
    // Append termination conditions if provided
    if (payload.termination_conditions) {
      form.append("description", payload.termination_conditions);
    }
  
    // Include provider_document_id for edits
    if (payload.provider_document_id > 0) {
      form.append("provider_document_id", payload.provider_document_id);
    }
    if(payload.is_sub_document == 1){
      form.append("is_sub_document", 1);
    }
    if(payload.provider_sub_document_id > 0){
      form.append("provider_sub_document_id", payload.provider_sub_document_id);
    }
  
    return apiService(endPoint, "POST", true, form);
  };

  const removeProviderDocument =(project_id,document_id) => {
    console.log("Removing provider document",project_id,document_id)
    const endPoint = API_URL + "mod=dashboard&act=remove_provider_document";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("document_id", document_id);
    return apiService(endPoint, "POST", true, form);
  };

export default {
  activeInactivateWallet,
  assignSpaceManager,
  assignSlotToOperator,
  getSpaceManagerList,
  updateCompanyLogo,
  getSelectedEmployeeDetails,
  editCompanyEmployees,
  addCompanyEmployee,
  getCompanyUpdate,
  getCompanyEmployee,
  getCompanyProfile,
  getCustomerSpaces,
  getTaskCount,
  getTaskList,
  getLockScreenTaskList,
  getCheckList,
  getAttachments,
  getLinks,
  getComments,
  getChecklistTemplates,
  getBenchmarkSlots,
  getAssignees,
  changePriority,
  addChecklistItem,
  pinTask,
  pinProject,
  deleteTask,
  addLinkComment,
  updateTaskStatus,
  setTag,
  removeTag,
  setNote,
  editNote,
  removeNote,
  getTopic,
  getDefaultTopic,
  setTopic,
  getTaskData,
  getTopicTasks,
  getNotes,
  removeLink,
  updateChecklistItem,
  deleteChecklistItem,
  editLinkComment,
  removeComment,
  getCustomerAdmins,
  changeTaskProject,
  setTaskData,
  getDefaultTaskAssignee,
  getNotificationList,
  setTaskRecurring,
  filterDashboardTasks,
  createNewTask,
  setTaskAssignee,
  getTaskProposals,
  getProposalsComments,
  handleProposalLike,
  getBenchmarks,
  addNewProposalComment,
  getSpaceDetails,
  getFullCalendar,
  getRegionList,
  get3DPlans,
  addEditBenchmarkStep1,
  addEditBenchmarkStep2,
  addEditBenchmarkAccessories,
  addEditBenchmarkStep3,
  getBenchmarkInfo,
  getMentionlist,
  pendingCommentAction,
  updateChecklistAssignee,
  getOperatorsByCategory,
  getOperatorDetail,
  changeMainTopicStatus,
  changeCustomTopicStatus,
  addCustomTopic,
  getClientTeamData,
  getFilteredProjectCount,
  convertToTask,
  getBenchmarkSummary,
  getBenchmarkWeekSchedule,
  getBenchmarkInvoices,
  getBenchmarkInfoAttchment,
  getBenchmarkLog,
  pinNotes,
  convertToChecklist,
  deleteTaskDocument,
  addCategory,
  addProviderToCategory,
  getBenchmarkDetail,
  editBenchmarkChecklist,
  removeBenchmarkAttachment,
  addtask3dplan,
  editBenchmarkTitle,
  externalProviderInvite,
  benchmarkAttachmentRemove,
  getPremiumEstimateDetails,
  spaceInvoices,
  premiumEstimatePlan,
  stopBenchmark,
  getRequestedTaskData,
  addNewAttachmentToBenchmark,
  deleteBenchMark,
  changeBenchmarOperator,
  getProviderList,
  getAllRoles,
  getTeams,
  createTeam,
  deleteTeam,
  addEditSpaces,
  addNewRole,
  getContactPermissions,
  deleteRole,
  getSubscriptionList,
  getTodoTaskList,
  convertRequestTask,
  getChecklistCompayprofile,
  addChecklistTemplate,
  estimateCreate,
  deleteTemplate,
  getDefaultAccessories,
  getWalletSettings,
  setWalletSettings,
  onBoardingProcess,
  loginToMyrAdmin,
  getProviderTransactions,
  getStripeTransactionById,
  addCustomTopicFilter,
  removeTopicTag,
  getTransactions,
  setIBANSetting,
  getIbanList,
  deleteOrCancelSpace,
  subScriptionCancel,
  addClientToSpace,
  upgradePremiumEstimate,
  getDetailForLoginAsUser,
  userLogout,
  updateTaskSchedule,
  setUserTheme,
  emailCheckInvitees,
  addOperator,
  getCountryCodes,
  benchmarkSlotAssignees,
  getEmails,
  sendMailReply,
  sendMailReply,
  closeEmail,
  markImportantEmail,
  UpdateMessage,
  updateGoogleDriveStatus,
  exchangeGoogleCode,
  updateGmailStatus,
  setMailSettings,
  getMailSettings,
  getDrivesSettings,
  setUserLanguage,
  projectAssignTopeople,
  saverFromInbox,
  revokeMailSync,
  getEmployeeReportList,
  getOperatorReportList,
  getEmployeeTask,
  createRequestTask,
  setRequestTaskAssignee,
  deleteCustomTopic,
  markAsRead,
  bulkAddCustomTopic,
  getLeadsCountWeekCalendar,
  getLeadsCount,
  pinDocuments,
  getMailAttachments,
  createAccessories,
  getTypeOfSpacePerSpace,
  uploadInvoice,
  createChatChannels,
  getProfileDetails,
  userEditProfile,
  userUpdateEditProfile,
  getRoles,
  getTypeOfJobs,
  addMyrStaff,
  generateToken,
  getJsonTokenDetails,
  addEditEquipment,
  getEquipmentQuantity,
  EditEquipmentQuantity,
  DeleteEquipment,
  removeVirtualSpace,
  getSpaceCountDetails,
  getDashboardDetails,
  getSpaceFloor,
  taskBulkAction,
  addSpaceDocument,
  getSpaceDocument,
  removeSpaceDocumentAttachment,
  deleteFloor,
  addEditFloor,
  createCreditNote,
  declineCreditNote,
  createCredit,
  getTeamDetails,
  getIBANStatus,
  getAssingees,
  getContactsByClient,
  sendEmailMessage,
  addEditProposalAmount,
  scheduleMail,
  getDefaultTopicList,
  getCountLockTask,
  getSpaceWorkScore,
  fetchProviderList,
  fetchEquipmentList,
  getProviderListing,
  listBenchmarks,
  getAttendance,
  getAttendanceList,
  addEditAttendance,
  fetchTenetsList,
  convertMessage,
  getAttendanceCountWeekCalendar,
  projectAttendanceCount,
  deleteFloorItems,
  addProjectMember,
  updateBenchmark,
  scan3dPlan,
  addEditRobotTask,
  SetRobotSchedule,
  GetRobotSchedule,
  getEquipmentReportList,
  robotCommandAction,
  updateBenchmark,
  addUpdateFloorSettings,
  addFloorItem,
  setArPOIs,
  getArPOIs,
  getArLocalAnchors,
  setArLocalAnchors,
  setDirection,
  getOverDueTaskCOunt,
  deleteCompanyProfileUser,
  editAccessOfficeDetail,
  getSpaceDataOhover,
  openAiSpace,
  inviteCustomerToSpace,
  favoriteDdashboard,
  getNewCustomer,
  addOperatorAvaibility,
  getOperatorAvaibility,
  getAllUserForStaff,
  createNewClient,
  changeTaskspace,
  addTenants,
  getStaffIBANData,
  setupStaffIBANData,
  updateSpaceData,
  setDirectionNew,
  createArPOIs,
  getAllCompany,
  changePeojectStatus,
  createChatGroup,
  userProfileRemove,
  readSpaceInvitationAlert,
  sendCommentMailNotification,
  getMetricsForProvider,
  readSpaceInvitationAlert,
  getProviderCompanyAccounts,
  userProfileRemove,
  readSpaceInvitationAlert,
  getProviderCategoriesForWallet,
  updateProviderCategoryWallet,
  sendCommentMailNotification,
  setupProviderWallet,
  getProviderWallets,
  setTypeOfSapcePOI,
  getUserPlans,
  getDashboardDetailsWthAllSpace,
  changeDashboardFilter,
  getDashboardFilter,
  addEditChecklistRating,
  changeTopicStatusBulk,
  dashboardChart,
  getPotentialSavingsData,
  getSpaceContextData,
  setSpaceContextData,
  contactForm,
  fetchTaskAIContext,
  saveTaskAIContext,
  aiGetDataFromScan3d,
  toggleProviderOpenAIStatus,
  changeNotificationPreference,
  getAIMergeFields,
  getAiContextForChat,
  taskOpenAiRequest,
  changeTopicStatusBulk,
  getLeadTools,
  getServiceList,
  getTenantsServices,
  getProviderServices,
  getEquipmentsServices,
  addLead,
  raiseGlobalCreditnote,
  messageIntoThread,
  checkRunStatus,
  checkSpaceEmailExists,
  addSpaceEmail,
  generateAIThread,
  handleUserActiveInactive,
  addTypeofSpaceUsingAi,
  setSpaceCoefficient,
  getInitialIDBData,
  getTasklistForChat,
  checkAiResponseStatus,
  createAiAssistantForCustomer,
  addMessageIntoTreadRunId,
  createTranscription,
  addClientMembership,
  getSpacemembarshipPackageID,
  getInviteUserDetails,
  getCompanyInvitation,
  acceptCompanyInvitation,
  createChatbetweenAgentandOperator,
  getLeadEmailTemplate,
  leadsSendEmail,
  setPrimaryLeadContact,
  saveUserGoCardless,
  getCustomerEstimate,
  getEstimateTerms,
  getAllTaxName,
  estimateSendEmails,
  updateLocalAnchorsDirection,
  addClientTopicLogo,
  addToSpam,
  setPriorityOfMail,
  addCustomTopicTags,
  fetchProviderlistByCatgory,
  getSelectedProviderDetails,
  addRiskData,
  removeRiskData,
  editRiskData,
  addRiskDataAsTemplate,
  createToDos,
  getRiskDataTemplate,
  getProviderCompanyTabsCount,
  sendCreateEmail,
  uploadAiAssistantFiles,
  getuploadAiAssistantFiles,
  getEmailTrackingDetail,
  setCategoryStatusForAi,
  sendCreateScheduleEmail,
  deleteShedualeEmail,
  deleteAssistantFile,
  updateEmailTemplate,
  addSpaceTimeSpaceTable,
  assigneProviderCompany,
  emailAutoReplyChat,
  getProvidersAgents,
  getProvidersClients,
  getEmailTokenActive,
  getWalletListOfProvider,
  getLeaseLists,
  registrationNewVersion,
  getWalletListOfProvider,
  updateTaskRating,
  getEquipmentList,
  createNewTaskForSingleSpaces,
  CreateHomepageTasksFunction,
  getNotificationDashboard,
  getTaskIdOfVapiAssistantCall,
  addSpacePhoneNumber,
  getAiPhoneNoList,
  getDashboardTasks,
  getInvoicesTerms,
  getCreditNoteTerms,
  setCheckListOrder,
  updateIDBJson,
  addEditProfileCompany,
  addEditProfileCntact,
  addUserByEmail,
  getUserEmail,
  deleteIBANBilling,
  getClientCounts,
  creatProviderDocument,
  removeProviderDocument
};
