import React, { useEffect, useState } from "react";
import { _l, showError } from "../../hooks/utilities";
import { Button } from "react-bootstrap";
import ProviderProfileTable from "../Providertables/ProviderProfileTable";
import customerServices from "../../services/customer-services";
import UploadDocument from "../Modals/UploadDocument";
import { Spinner } from "react-bootstrap";
import { showMessage } from "../../actions/messages";
import ProviderContractTable from "../Providertables/ProviderContractTable";
import { setProviderContract } from "../../actions/documents-actions/documents-actions"; 
import OperatorContract from "../Modals/OperatorContract";
import { useDispatch, useSelector } from "react-redux";

const ProviderContractOffcanvas = ({ data }) => {
    const dispatch = useDispatch();
    const { all_contract_data } = useSelector((state) => state.documentsReducer)
    const [loader, setloader] = useState(false);
    const [selectedTab, setSelectedTab] = useState({});
    const [contractTabs, setContractTabs] = useState([]);
    const [showDocumentCreate, setShowDocumentCreate] = useState(false);
    const [mainContarct, setMainContract] = useState(data);
    useEffect(() => {
       var cTabs = [];
       data.isSubContract = 0;
       cTabs.push({id: data.id, name: data.document_name, isSubContract: 0, documentData: data});
       if(data && data.sub_documents && data.sub_documents.length > 0){
            data.sub_documents.map((item) => {
                item.isSubContract = 1;
                cTabs.push({id: item.id, name: item.document_name, isSubContract: 1, documentData: item});
            })
       }
       setSelectedTab({id: data.id, name: data.document_name, isSubContract: 0, documentData: data});
       setContractTabs(cTabs);
    }, [data]);

    useEffect(() => {
        if(selectedTab.documentData){
            dispatch(setProviderContract(selectedTab.documentData));
        }
    }, [selectedTab]);

    useEffect(()=>{
        const matchedDocument = Array.isArray(all_contract_data) 
                    ? all_contract_data.find(doc => doc.id === data.id) 
                    : null;
        setMainContract(matchedDocument);
    },[all_contract_data, selectedTab]);

    useEffect(() => {
        var cTabs = [];
        mainContarct.isSubContract = 0;
        cTabs.push({id: mainContarct.id, name: mainContarct.document_name, isSubContract: 0, documentData: mainContarct});
        if(mainContarct && mainContarct.sub_documents && mainContarct.sub_documents.length > 0){
             mainContarct.sub_documents.map((item) => {
                 item.isSubContract = 1;
                 cTabs.push({id: item.id, name: item.document_name, isSubContract: 1, documentData: item});
             })
        }
        setSelectedTab({id: mainContarct.id, name: mainContarct.document_name, isSubContract: 0, documentData: mainContarct});
        setContractTabs(cTabs);
     }, [mainContarct]);
 
    
    return (
        <>
            <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian position-relative px20py20 bgpatternCommanImg">
                <div className="comman-tab-wrapper  pb-20px">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            {contractTabs.map((tData) => {
                                return (
                                    <div className={`tab-wrapper d-flex align-items-center lightthemebg ${selectedTab.id === tData.id ? "active" : ""}`}>
                                        <a 
                                            href="#/" 
                                            className="tab-name d-flex align-items-center bg-white-03"
                                            onClick={() => {
                                                setSelectedTab(tData);
                                            }}
                                        >
                                            <span>{_l(tData.name)}</span>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="d-flex align-items-center gap10">
                            <Button variant={"primary"} className="" size="sm" onClick={() => { setShowDocumentCreate(true); }}>
                                {_l("l_add_new")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="comman_vertical_btn_h  comman-white-box-shadow light-theme-white-bg">
                    <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
                        <ProviderContractTable data={selectedTab} />
                    </div>
                </div> 
            </div> 
            { showDocumentCreate ? 
            <OperatorContract
                show={showDocumentCreate}
                handleClose={()=> setShowDocumentCreate(false)}
                data={{}}
                mainDocId={data.id}
                fetchDocumentsFromIDB = {() => {}}
            />
            : <></>
      }
        </>
    );
};

export default ProviderContractOffcanvas;