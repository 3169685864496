import React, {useEffect, useState} from "react";
import Lightbox from "yet-another-react-lightbox";
import LogoGreenDark from "../../assets/icons/LogoGreenDark";
import LogoGreenLight from "../../assets/icons/LogoGreenLight";
import CloudArrowDownIcon from "../../assets/icons/CloudArrowDownIcon";

const RenderCustomDocument = ({ slide }) => {
  console.log("Pdf image url", slide.image_url);
  const [isLoading, setIsLoading] = useState(false); 
  const getFileType = (url) => {
    if (url) {
      const fileExtension = url.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        return "pdf";
      } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
        return "image";
      } else if (["txt"].includes(fileExtension)) {
        return "text";
      } else if (["doc", "docx"].includes(fileExtension)) {
        return "doc";
      } else {
        return "other";
      }
    }
  };

  const fileType = getFileType(slide.image_url);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const handleIframeLoad = () => {
    console.log("calling to laod iframe")
    setIsLoading(false);
  };
  
  const getIframeSource = (fileType) => {
    return slide.blobUrl || `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(slide.image_url)}`;
  };

  if (fileType === "pdf" || slide.type === "pdf") {
    return isLoading ? (
      <div>Loading PDF...</div> 
    ) : (
      <iframe
        src={getIframeSource(fileType)}
        width="100%"
        height="100%"
        title="PDF Preview"
        className="border-0"
        onLoad={handleIframeLoad}
      />
    );
  } else if (fileType === "doc" || slide.type === "doc") {
    return isLoading ? (
      <div>Loading DOC...</div> 
    ) : (
      <iframe
        src={getIframeSource(fileType)}
        width="100%"
        height="100%"
        title="DOC Preview"
        className="border-0"
        onLoad={handleIframeLoad}
      />
    );
  } else if (fileType === "image" || slide.type === "image") {
    return (
      <>
        {isLoading && (
          <div>Loading Image...</div> 
        )}
        <img
          className="w-100 h-auto"
          src={slide.blobUrl || slide.image_url}
          alt={slide.file ? slide.file.name : "Document Preview"}
          onLoad={handleImageLoad}
        />
      </>
    );
  } else {
    return null; 
  }
};
const DocumentPreview = ({ slides, open, setOpen }) => {
  const selectedTheme = localStorage.getItem("selectedTheme");
  return (
    <>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={slides}
        render={{
          slide: ({ slide }) => (
            <>
              <div className="header-logo position-absolute top-0 start-0 z-index-2 w-100 ms-1">
                <div className="d-flex align-items-center justify-content-between position-relative">
                  {selectedTheme === "light" ? (
                    <LogoGreenLight className="header-logo-img p-2 max-w-130px h-auto" />
                  ) : (
                    <LogoGreenDark className="header-logo-img p-2 max-w-130px h-auto" />
                  )}
                  {slide.download_url && (
                    <a
                      href="#/"
                      className="downloadarrow position-absolute end-0 h32w32"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(slide.download_url, "_blank");
                      }}
                    >
                      <CloudArrowDownIcon className="HW28" />
                    </a>
                  )}
                </div>
              </div>
              <RenderCustomDocument slide={slide} />
            </>
          ),
        }}
        carousel={{ preload: 1, padding: 0 }}
      />
    </>
  );
};

export default DocumentPreview;
