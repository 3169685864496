import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import { _l, getFileType, showError } from "../../hooks/utilities";
import Select from "react-select";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { showMessage } from "../../actions/messages";
import DocumentPreview from "../Documents/DocumentPreview";
import benchmarkServices from "../../services/benchmark-services";
import {getproviderDoc} from "../../actions/customer";
import XIcon from "../../assets/icons/XIcon";
import PaperclipIcon from "../../assets/icons/PaperclipIcon";
import FilePdfIcon from "../../assets/icons/FilePdfIcon";
import UserCircleIcon from "../../assets/icons/UserCircleIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import PencilSimpleLineIcon from "../../assets/icons/PencilSimpleLineIcon";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import HashIcon from "../../assets/icons/HashIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
import CreatableSelect from 'react-select/creatable';

import moment from "moment";
import { readCollectionFromDB } from "../../hooks/indexed_db_helpers";
import customerServices from "../../services/customer-services";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Table,
} from "reactstrap";
import PlusIcon from "../../assets/icons/PlusIcon";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import FollowersDropdown from "../Dropdowns/FollowersDropdown";
import documentsServices from "../../services/documents-services";
import MagnifyingGlassIcon from "../../assets/icons/MagnifyingGlassIcon";

const predefinedCustomFields = [
  { field_name: "Consommation prévisionnelle", field_value: "" },
  { field_name: "Consommation annuelle", field_value: "" },
  { field_name: "Abonnement mensuel", field_value: "" },
  { field_name: "Été - Heure pleine", field_value: "" },
  { field_name: "Été - Heure creuse", field_value: "" },
  { field_name: "Hiver - Heure pleine", field_value: "" },
  { field_name: "Hiver - Heure creuse", field_value: "" },
  { field_name: "Pointe", field_value: "" },
  { field_name: "HPH/BASE/HP", field_value: "" },
  { field_name: "Horme", field_value: "" },
  { field_name: "HPE", field_value: "" },
  { field_name: "H", field_value: "" },
  { field_name: "Electricité verte", field_value: "" },
  { field_name: "CEE", field_value: "" },
  { field_name: "Option Service de gestion répartie", field_value: "" },
  { field_name: "Puissance souscrite", field_value: "" },
  { field_name: "Référence d'acheminement", field_value: "" },
  { field_name: "Prix applicable", field_value: "" },
  { field_name: "Prix définitif à compter", field_value: "" },
  { field_name: "Prix des garanties d'origine", field_value: "" },
];

/**
 * OperatorContract - a modal/form to create or edit "provider documents" (leases, etc.)
 */
const OperatorContract = ({
  show,
  handleClose = () => {},
  data,
  mainDocId = 0,
  selectedDocument = {},
}) => {
  const { defaultTopics, companyEmployeeData } = useSelector((state) => state.customer);
  const staffId = localStorage.getItem("staff_id") || 0;
  const contactId = localStorage.getItem("contact_id") || 0;
  const clientId = localStorage.getItem("client_id") || 0;
  const projectId = localStorage.getItem("selectedOffice");

  const dispatch = useDispatch();

  const [openGallery, setOpenGallery] = useState(false);
  const [file, setFile] = useState(null);
  const [fileBolbUrl, setFileBolbUrl] = useState("");
  const [loader, setLoader] = useState(false);

  // For the new "Operator Contract" workflow
  const [spaces, setSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [leaseStartDate, setLeaseStartDate] = useState(null);
  const [leaseEndDate, setLeaseEndDate] = useState(null);
  const [noticeDate, setNoticeDate] = useState(null);
  const [noticeDates, setNoticeDates] = useState([]);

  const [leaseFiles, setLeaseFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [providerCategories, setProviderCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [providerOperators, setProviderOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isOperatorOpen, setIsOperatorOpen] = useState(false);
  const [terminationConditions, setTerminationConditions] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchOperator, setSearchOperator] = useState("");
  const [userContactList, setUserContactList] = useState([]);
  const [customFieldRow, setCustomFiledRow] = useState([
    { id: Date.now(), field_name: "", field_value: "" },
  ]);
  const [options, setOptions] = useState([]);
  const [selectedSlide, setSelectedSlide] = useState(null);
  useEffect(() => {
    const selectOptions = predefinedCustomFields.map((field) => ({
      value: field.field_name,
      label: field.field_name,
    }));
    setOptions(selectOptions);
  }, []);

  const addRow = () => {
    setCustomFiledRow((prevRows) => {
      if (prevRows.length > 0 &&prevRows[prevRows.length - 1].field_name &&prevRows[prevRows.length - 1].field_name !== "") {
        return [
          ...prevRows,
          { id: Date.now(), field_name: "", field_value: "" },
        ];
      } else if (prevRows.length === 0) {
        return [{ id: Date.now(), field_name: "", field_value: "" }];
      } else {
        return prevRows;
      }
    });
  };

  const handleFieldNameChange = (selectedOption, rowIndex) => {
    const updatedRows = [...customFieldRow];
    updatedRows[rowIndex].field_name = selectedOption;
    setCustomFiledRow(updatedRows);
    addRow();
  };

  const handleCreateOption = (inputValue, rowIndex) => {
    const newOption = { value: inputValue, label: inputValue };
    setOptions((prev) => [...prev, newOption]);
    const updatedRows = [...customFieldRow];
    updatedRows[rowIndex].field_name = newOption;
    setCustomFiledRow(updatedRows);
  };


  const handleFieldValueChange = (e, rowIndex) => {
    const updatedRows = [...customFieldRow];
    updatedRows[rowIndex].field_value = e.target.value;
    setCustomFiledRow(updatedRows);
  };

  const handleDeleteRow = (rowIndex) => {
    if (customFieldRow.length > 1) {
      const updatedRows = [...customFieldRow];
      updatedRows.splice(rowIndex, 1);
      setCustomFiledRow(updatedRows);
    } else {
      setCustomFiledRow([{ id: Date.now(), field_name: null, field_value: "" }]);
    }
  };

  const handleEditOption = (updatedOption) => {
    const isDuplicate = options.some(
      (option) =>
        option.value.toLowerCase() === updatedOption.value.toLowerCase() &&
        option.value !== updatedOption.value
    );

    if (isDuplicate) {
      showError("This field name already exists.");
      return;
    }

    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.value === updatedOption.value || option.value === updatedOption.label
          ? updatedOption
          : option
      )
    );

    // Update customFieldRow that have this option
    setCustomFiledRow((prevRows) =>
      prevRows.map((row) =>
        row.field_name && row.field_name.value === updatedOption.value
          ? { ...row, field_name: updatedOption }
          : row
      )
    );
  };


  // ---- Setup topics & user contacts from IndexedDB
  useEffect(() => {
    async function fetchData() {
      const response = await customerServices.getProviderServices();
      if (response && response.data) {
        setProviderCategories(response.data);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const spacesData = localStorage.getItem("spaces");
    if (spacesData) {
      setSpaces(JSON.parse(spacesData));
    }
  }, []);

  // ---- Build the spaceOptions for the dropdown
  const spaceOptions = spaces.map((space) => ({
    value: space.id,
    label: space.office_title,
    project_id: space.project_id,
  }));

  // ---- Toggling Category/Operator
  const toggleCategory = () => setIsCategoryOpen((prev) => !prev);
  const toggleOperator = () => setIsOperatorOpen((prev) => !prev);

  const handleCategorySelect = (cat) => {
    setSelectedCategory(cat);
    setSelectedOperator(null);
    setIsCategoryOpen(false);
    setIsOperatorOpen(true);
  };

  useEffect(() => {
    if (selectedCategory || (data && data.category_id)) {
      setSelectedCategoryId((data && data.category_id) ? data.category_id : selectedCategory.id);
      const categoryId = (data && data.category_id) ? data.category_id : selectedCategory.id;
      benchmarkServices
        .getOperatorsByCategory(0, categoryId, 1, 1, 1)
        .then((res) => {
          if (res.status && res.data) {
            setProviderOperators(res.data);
          } else {
            setProviderOperators([]);
          }
        });
    } else {
      setProviderOperators([]);
    }
  }, [selectedCategory, data]);

  const handleOperatorSelect = (operator) => {
    setSelectedOperator(operator);
    setIsOperatorOpen(false);
    setIsCategoryOpen(false);
  };

  // ---- Notice Dates & Assignees
  const handleDateChange = (date) => {
    setNoticeDate(date);
  };

  // Add a New Notice Date
  const handleAddDate = () => {
    if (noticeDate) {
      setNoticeDates((prevDates) => [
        ...prevDates,
        { date: noticeDate, assignee: {} },
      ]);
      setNoticeDate(null);
    }
  };

  // Delete a date from the list
  const handleDeleteDate = (indexToDelete) => {
    setNoticeDates((prev) => prev.filter((_, idx) => idx !== indexToDelete));
  };

  // Edit an existing date
  const handleEditDate = (newDate, indexToEdit) => {
    setNoticeDates((prev) =>
      prev.map((item, idx) => {
        if (idx === indexToEdit) {
          return { ...item, date: newDate };
        }
        return item;
      })
    );
    setShowDate(!showDate);
  };

  // Assign or change the user for a given date
  const handleSelectFollower = (user, index) => {
    setNoticeDates((prevDates) =>
      prevDates.map((item, idx) => {
        if (idx === index) {
          return { ...item, assignee: user };
        }
        return item;
      })
    );
  };

  const handleRemoveAssignee = (index) => {
    setNoticeDates((prevDates) =>
      prevDates.map((item, idx) => {
        if (idx === index) {
          return { ...item, assignee: null };
        }
        return item;
      })
    );
  };

  // ---- Files
  const handleLeaseFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files).map((file) => {
        const blobUrl = URL.createObjectURL(file); // Create Blob URL once
        return {
          file,
          blobUrl,
          src: blobUrl, // Assign the same Blob URL to src
          type: getFileType(file.name),
          download_url: blobUrl, // Assign the same Blob URL to download_url
        };
      });
      setLeaseFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const handleOtherFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files).map((file) => {
        const blobUrl = URL.createObjectURL(file); // Create Blob URL once
        return {
          file,
          blobUrl,
          src: blobUrl, // Assign the same Blob URL to src
          type: getFileType(file.name),
          download_url: blobUrl, // Assign the same Blob URL to download_url
        };
      });
      setOtherFiles((prev) => [...prev, ...newFiles]);
    }
  };

  useEffect(() => {
    return () => {
      leaseFiles.forEach((file) => URL.revokeObjectURL(file.blobUrl));
      otherFiles.forEach((file) => URL.revokeObjectURL(file.blobUrl));
    };
  }, [leaseFiles, otherFiles]);

    const removeLeaseFile = (index) => {
      setLeaseFiles((prev) => {
        const updated = [...prev];
        URL.revokeObjectURL(updated[index].blobUrl); // Revoke URL
        updated.splice(index, 1);
        return updated;
      });
    };

    // Remove Other File
    const removeOtherFile = (index) => {
      setOtherFiles((prev) => {
        const updated = [...prev];
        URL.revokeObjectURL(updated[index].blobUrl); // Revoke URL
        updated.splice(index, 1);
        return updated;
      });
    };


  useEffect(() => {
    if (data && Object.keys(data).length > 0 && data.project_id) {
      const spaceData = spaces.find((s) => s.project_id === data.project_id);
      if (spaceData) {
        setSelectedSpace({
          value: spaceData.id,
          label: spaceData.office_title,
          project_id: spaceData.project_id,
        });
      }
      setDocumentTitle(data.document_name);
      setLeaseStartDate(data.start_date ? new Date(data.start_date) : null);
      setLeaseEndDate(data.end_date ? new Date(data.end_date) : null);
      const operator = providerOperators.find(
        (op) => op.userid === data.provider_client_id
      );
      if (operator) {
        setSelectedOperator(operator);
      }

      if (data.provider_notice_detail &&Array.isArray(data.provider_notice_detail)) {
        const notices = data.provider_notice_detail.map((notice) => {
          const assignee = userContactList.find((user) => {
            return String(user.staff_id) === String(notice.assignee_id);
          });
          return {
            date: notice.notice_date ? new Date(notice.notice_date) : null,
            assignee: assignee || {},
          };
        });
        setNoticeDates(notices);
      }
      if (data.documents && Array.isArray(data.documents)) {
        const lease = [];
        const other = [];
        data.documents.forEach((doc) => {
          const fileObj = {
            file: null,
            blobUrl: doc.image_url,
            file_name: doc.file_name,
            // id: doc.id,
            // isNew: false,
          };
          if (doc.is_contract_file === "1") {
            lease.push(fileObj);
          } else {
            other.push(fileObj);
          }
        });
        setLeaseFiles(lease);
        setOtherFiles(other);
      }
      setTerminationConditions(data.description || "");

      if (data.custom_fields && data.custom_fields.trim() !== "") {
        try {
          const parsedCustomFields = JSON.parse(data.custom_fields);
          const initializedCustomFields = parsedCustomFields.map((fields) => ({
            field_name:
              options.find((option) => option.value === fields.field_name) || {
                value: fields.field_name,
                label: fields.field_name,
              },
            field_value: fields.field_value || "",
          }));
          setCustomFiledRow(initializedCustomFields);
          addRow();
        } catch (error) {
          showMessage("unsucess", _l("l_error"), `Error parsing custom_fields.`)
          console.error("Error parsing custom_fields:", error);
        }
      }
      const category = providerCategories.find(
        (cat) => cat.filter_key === data.category_id
      );
      // if (category) setSelectedCategory(category);
    }
  }, [data, spaces, providerOperators, userContactList, providerCategories]);


  // ---- Final Submit / Save
  const handleSave = async () => {
    try {
      setLoader(true);
      // You can add more validation here as needed
      if (!selectedSpace) {
        dispatch(
          showMessage("unsucess", _l("l_error"), `Please select a space`)
        );
        setLoader(false);
        return;
      }
      if (!selectedOperator) {
        dispatch(
          showMessage("unsucess", _l("l_error"), `Please select an operator`)
        );
        setLoader(false);
        return;
      }
      const formattedCustomFields = customFieldRow
      .filter((field) => field.field_name )  
      .map((field) => ({
        field_name: field.field_name&& field.field_name.value, 
        field_value: field.field_value, 
      }));
      
      const DocumentName =
        documentTitle && documentTitle.trim() !== ""
          ? documentTitle.trim()
          : `${selectedSpace.label} ${selectedOperator.company}`.trim();
      // Prepare the request payload
      const payload = {
        project_id: selectedSpace.project_id,
        operator_id: selectedOperator.userid,
        category_id: selectedCategoryId,
        start_date: leaseStartDate,
        end_date: leaseEndDate,
        notice_dates: noticeDates,
        lease_files: leaseFiles,
        other_files: otherFiles,
        termination_conditions: terminationConditions,
        custom_fields: JSON.stringify(formattedCustomFields),
        document_name: DocumentName,
      };
      if (data) {
        if (mainDocId > 0) {
          payload.provider_sub_document_id = data.id;
        }else{
          payload.provider_document_id = data.id;
        }
      }
      if (mainDocId > 0) {
        payload.is_sub_document = 1;
        payload.provider_document_id = mainDocId;
      }

      const response = await customerServices.creatProviderDocument(payload);
      if (response.status) {
        dispatch(getproviderDoc(true));
        handleClose(1);
        dispatch(
          showMessage(
            "sucess",
            _l("l_success"),
            _l("l_provider_contract_created")
          )
        );
      } else {
        console.error("Error creating provider document", response);
        dispatch(showMessage("unsucess", _l("l_error"), _l(response.message)));
      }
    } catch (error) {
      console.error("Exception creating provider document:", error);
      dispatch(
        showMessage("unsucess", _l("l_error"), `Could not create the document.`)
      );
    } finally {
      setLoader(false);
    }
  };


    useEffect(()=>{
      setUserContactList(companyEmployeeData);
    },[])

    const handlePreviewClick = (file) => {
      console.log("Preview clicked", file);
      setSelectedSlide(file);
      setOpenGallery(true);
    };

  // ---- RENDER
  return (
    <Modal
      show={show}
      onHide={() => handleClose(0)}
      className="custom-modal-style add-topic-search w1300px"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* If you have a DocumentPreview for a selected file */}
      {/* {file !== null && openGallery ? (
        <DocumentPreview
          open={openGallery}
          setOpen={setOpenGallery}
          slides={[{ src: fileBolbUrl, type: getFileType(file?.name) }]}
        />
      ) : null} */}
      <DocumentPreview
        open={openGallery}
        setOpen={setOpenGallery}
        slides={[selectedSlide]}
      />

      <Modal.Header closeButton>
        <Modal.Title>{_l("l_create_provider_document")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="with-custom-height80vh pt-0  overflow-hiiden-auto">
        <div className="form-wrapper-main">
          <div className="row pb-1">
            {/* <div className="row"> */}
            <Form.Group className="col-4 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk">
                {_l("l_document_title")}
              </Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder={_l("l_enter_document_title")}
                  value={documentTitle}
                  onChange={(e) => setDocumentTitle(e.target.value)}
                  className="form-control custom-input"
                />
              </div>
            </Form.Group>
            {/* </div> */}

            {/* Select Space */}
            <Form.Group className="col-4 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_space_link")}
              </Form.Label>
              <Select
                className="custom-select-menu"
                classNamePrefix="react-select"
                placeholder={_l("l_space_link")}
                options={spaceOptions}
                value={selectedSpace}
                onChange={(val) => setSelectedSpace(val)}
              />
            </Form.Group>

            {/* Category/Operator dropdowns */}

            <Form.Group className="col-lg-4 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk">
                {_l("l_type_of_services")}
              </Form.Label>
              <div className="custom-datepicker w-100">
                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                  {/* First Dropdown for Categories */}
                  <UncontrolledDropdown
                    isOpen={isCategoryOpen}
                    toggle={toggleCategory}
                    className="w-100"
                  >
                    <DropdownToggle tag="div" className="w-100">
                      <a href="#/" className="w-100">
                        <div
                          className="d-flex align-items-center text-truncate dropdown-item custom-assigneesMain"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <div className="ps-2 w100minus40 text_wrap">
                            <div className="c-list-detail c-font f-14 text-truncate">
                              {selectedOperator && selectedOperator.company
                                ? selectedOperator.company
                                : _l("l_type_of_services")}
                            </div>
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>

                    <DropdownMenu className="overflowscroll">
                      <DropdownItem className="p-0" toggle={false}>
                        <div
                          className="comman-search-dropdown"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <div className="d-flex  w-100">
                            <div className="comment-input-box flex-grow-1">
                              <Form.Control
                                type="text"
                                placeholder="Search Categories..."
                                value={searchCategory}
                                onChange={(e) =>
                                  setSearchCategory(e.target.value)
                                }
                                className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font"
                              />
                            </div>
                            <a href="#/" className="comman_action_icon">
                              <div className="d-flex h-100">
                                <div className="action_icon h25w25 h-100">
                                  <MagnifyingGlassIcon className="HW14" />
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </DropdownItem>
                      {providerCategories.length > 0 ? (
                        providerCategories
                          .filter((category) =>
                            category.name
                              .toLowerCase()
                              .includes(searchCategory.toLowerCase())
                          )
                          .map((category, idx) => (
                            <DropdownItem
                              key={idx}
                              onClick={() => handleCategorySelect(category)}
                              className="text-truncate"
                            >
                              {category.name}
                            </DropdownItem>
                          ))
                      ) : (
                        <DropdownItem disabled>
                          No Categories Found
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {/* Second Dropdown for Operators */}
                  <UncontrolledDropdown
                    isOpen={isOperatorOpen}
                    toggle={toggleOperator}
                    className="w-100 mt-2"
                    // data-bs-auto-close
                    style={{
                      position: "absolute",
                      left: "-272px",
                      top: "40px",
                    }}
                  >
                    <DropdownMenu className="overflowscroll">
                      {providerOperators.length > 0 && (
                        <DropdownItem className="p-0" toggle={false}>
                          <div
                            className="comman-search-dropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                          >
                            <div className="d-flex  w-100">
                              <div className="comment-input-box flex-grow-1">
                                <Form.Control
                                  type="text"
                                  placeholder="Search Company..."
                                  value={searchOperator}
                                  onChange={(e) =>
                                    setSearchOperator(e.target.value)
                                  }
                                  className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font"
                                />
                              </div>
                              <a href="#/" className="comman_action_icon">
                                <div className="d-flex h-100">
                                  <div className="action_icon h25w25 h-100">
                                    <MagnifyingGlassIcon className="HW14" />
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </DropdownItem>
                      )}
                      {providerOperators.length > 0 ? (
                        providerOperators
                          .filter((operator) =>
                            operator.company
                              .toLowerCase()
                              .includes(searchOperator.toLowerCase())
                          )
                          .map((operator, idx) => (
                            <DropdownItem
                              key={idx}
                              onClick={() => handleOperatorSelect(operator)}
                              className="text-truncate"
                            >
                              {operator.company}
                            </DropdownItem>
                          ))
                      ) : (
                        <DropdownItem disabled>No Operators Found</DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </Form.Group>

            {/* Lease Start Date */}
            <Form.Group className="col-4 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_start_date")}
              </Form.Label>
              <div className="custom-datepicker w-100">
                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                  <div className="action_icon h32w32 with_bg">
                    <CalendarBlankIcon className="HW18" />
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText={_l("l_select_date_placeholder")}
                    className="ps-2 w-100"
                    selected={leaseStartDate}
                    onChange={(date) => setLeaseStartDate(date)}
                  >
                    <div className="datepicker-label">
                      {_l("l_select_date_and_time")}
                    </div>
                  </DatePicker>
                </div>
              </div>
            </Form.Group>

            {/* Lease End Date */}
            <Form.Group className="col-4 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_end_date")}
              </Form.Label>
              <div className="custom-datepicker w-100">
                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                  <div className="action_icon h32w32 with_bg">
                    <CalendarBlankIcon className="HW18" />
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText={_l("l_select_date_placeholder")}
                    className="ps-2 w-100"
                    selected={leaseEndDate}
                    onChange={(date) => setLeaseEndDate(date)}
                    minDate={leaseStartDate} // ensure end date can't be before start date
                  >
                    <div className="datepicker-label">
                      {_l("l_select_date_and_time")}
                    </div>
                  </DatePicker>
                </div>
              </div>
            </Form.Group>

            {/* Notice (multiple) Dates */}
            <Form.Group className="col-lg-4 c-input-box position-relative">
              <Form.Label className="input-label no-asterisk">
                {_l("l_notice_date")}
              </Form.Label>
              <div className="d-flex justify-content-between gap10">
                <div className="w88per">
                  <div className="custom-datepicker w-10 =">
                    <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                      <div className="action_icon h32w32 with_bg">
                        <CalendarBlankIcon className="HW18" />
                      </div>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        placeholderText={_l("l_select_date_placeholder")}
                        className="ps-2 w-100"
                        selected={noticeDate}
                        onChange={handleDateChange}
                      >
                        <div className="datepicker-label">
                          {_l("l_select_date_and_time")}
                        </div>
                      </DatePicker>
                    </div>
                  </div>
                </div>
                <div className="w15per comman_action_icon">
                  <div className="w-100 light-theme-blue-bg radius_5">
                    <a
                      data-toggle="dropdown"
                      tag="a"
                      className="action_icon with_bg h53w53 d-flex align-items-center justify-content-center w-100"
                      // onClick={handleAddDate}
                      onClick={() => {
                        handleAddDate();
                        setShowDate(!showDate);
                      }}
                    >
                      <PlusIcon className="HW16 mx-0" />
                      {noticeDates.length > 0 && (
                        <span className="c-font f-14 title_text ms-1">
                          {noticeDates.length}
                        </span>
                      )}
                    </a>
                    <div
                      className={`Datedropdown  ${
                        showDate && noticeDates.length > 0 ? "show" : ""
                      }`}
                    >
                      {/* overflowscroll */}
                      {noticeDates.map((item, index) => (
                        <li className="hr_sepretr l_50px p-10" key={index}>
                          <div toggle={false} className="">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div className="d-flex align-items-center w-100">
                                <div className="c-list-icon comman_action_icon">
                                  <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                                    <CalendarBlankIcon className="HW18" />
                                  </div>
                                </div>
                                <div className="ps-2 w100minus20 d-flex align-items-center justify-content-between">
                                  <div className="c-list-detail c-font f-14 text-truncate color-white">
                                    {item.date
                                      ? moment(item.date).format("DD/MM/YYYY")
                                      : "No date selected"}
                                  </div>

                                  <div className="d-flex align-items-center gap10">
                                    {/* Assign user */}
                                    <div
                                      className="custom-datepicker d-flex align-items-center"
                                      data-bs-toggle="tooltip"
                                    >
                                      <a
                                        href="#/"
                                        className="h32w32 justify-content-center align-items-center d-flex"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        {item.assignee &&
                                        (item.assignee.profile_image ||
                                          item.assignee.profile_image) ? (
                                          <div className="c-list-icon position-relative  comman-round-box">
                                            <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                              <div
                                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                style={{
                                                  backgroundImage: `url('${
                                                    item.assignee.profile_image ||
                                                    ""
                                                  }')`,
                                                }}
                                              />
                                            </div>
                                            <a
                                              href="#/"
                                              className="remove-img"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleRemoveAssignee(index);
                                              }}
                                            >
                                              <XIcon className="HW10" />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className="action_icon h32w32 with_bg d-flex align-items-center">
                                            <UserCircleGearIcon className="HW18" />
                                          </div>
                                        )}
                                      </a>

                                      <ul className="dropdown-menu w-200">
                                        <li>
                                          <div className="dropdown-header">
                                            <div className="d-flex align-items-center justify-content-between">
                                              <div className="c-list-detail c-font f-14 title-fonts text-truncate color-white">
                                                {_l("l_assignees")}
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <hr className="dropdown-divider m-0" />
                                        </li>
                                        <div className="overflowscroll">
                                          {userContactList.slice(1).map(
                                            (assignee, uIndex) => (
                                              <React.Fragment key={uIndex}>
                                                <li>
                                                  <a
                                                    href="#/"
                                                    className="dropdown-item text_wrap"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleSelectFollower(
                                                        { staff_id: assignee.staff_id, profile_image: assignee.profile_image },index);
                                                    }}
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <div className="c-list-icon">
                                                        <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                                          <div
                                                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                            style={{
                                                              backgroundImage: `url('${
                                                                assignee.profile_image ||
                                                                ""
                                                              }')`,
                                                            }}
                                                          ></div>
                                                        </div>
                                                      </div>
                                                      <div className="ps-2 w100minus20 text_wrap">
                                                        <div className="c-list-detail c-font f-14 text-truncate color-white">
                                                          {assignee.full_name}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </a>
                                                </li>
                                                <li>
                                                  <hr className="dropdown-divider my-0" />
                                                </li>
                                              </React.Fragment>
                                            )
                                          )}
                                        </div>
                                      </ul>

                                      {/* Pencil => Edit date */}
                                      <div className="comman_action_icon custom-datepicker w-330px withinputabsolute">
                                        <div className="action_icon with_bg h32w32 custom-datepicker position-relative light-theme-img ms-2">
                                          <PencilSimpleLineIcon className="HW18" />
                                          {/* We wrap a DatePicker to edit the date in place */}
                                          <DatePicker
                                            popperClassName="z-index-9999"
                                            calendarClassName="custom-datepicker"
                                            dateFormat="dd/MM/yyyy"
                                            portalId="custom_datepicker"
                                            // selected={
                                            //   item.date
                                            //     ? new Date(item.date)
                                            //     : null
                                            // }
                                            onChange={(newDate) =>
                                              handleEditDate(newDate, index)
                                            }
                                          >
                                            <div className="datepicker-label">
                                              {_l("l_select_date_and_time")}
                                            </div>
                                          </DatePicker>
                                        </div>
                                      </div>
                                    </div>

                                    {/* Trash => Remove this notice date */}
                                    <a
                                      href="#/"
                                      className="action_icon h32w32 with_bg"
                                      onClick={() => handleDeleteDate(index)}
                                    >
                                      <TrashIcon className="HW18" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>

            {/* Lease Contracts (file upload) */}
            <Form.Group className="col-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk">
                {"Contracts"}
              </Form.Label>
              <div className="upload-inputbox position-relative">
                <div className="d-flex align-items-center">
                  <div className="position-relative flex-grow-1">
                    <input
                      className="hidden-input"
                      type="file"
                      accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                      onChange={handleLeaseFileChange}
                      multiple
                    />
                    <input className="form-control" type="text" disabled />
                    <PaperclipIcon className="HW18 clip-img" />
                    <div className="uploadbox-text c-font f-12">
                      {"Upload Contracts"}
                    </div>
                  </div>
                </div>
                {/* Lease files preview */}
                <div className="d-flex flex-wrap ms-10px">
                  {leaseFiles.map((item, index) => {
                    const fileName = item.file
                      ? item.file.name
                      : item.file_name;
                    const fileType = getFileType(fileName);
                    return (
                      <div
                        className="upload-image-preview cursor-pointer col-auto me-2 mb-2"
                        key={index}
                        onClick={() => handlePreviewClick(item)}
                      >
                        <div className="comman-image-box h50w50 radius_3 upload-image with-border active">
                          {fileType === "pdf" ? (
                            <div
                              onClick={() => setOpenGallery(true)}
                              className="h-100 w-100 d-flex align-items-center justify-content-center"
                            >
                              <FilePdfIcon className="h-50 w-50" />
                            </div>
                          ) : fileType === "image" ? (
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                              style={{
                                backgroundImage: `url(${item.blobUrl})`,
                              }}
                            />
                          ) : (
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                              style={{
                                backgroundImage: `url(${item.blobUrl})`,
                              }}
                              onClick={() => setOpenGallery(true)}
                            />
                          )}
                          <a
                            href="#/"
                            className="upload-close"
                            onClick={() => removeLeaseFile(index)}
                          >
                            <XIcon className="HW10" />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Form.Group>

            {/* Other Documents (file upload) */}
            <Form.Group className="col-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk">
                {"Other Documents"}
              </Form.Label>
              <div className="upload-inputbox position-relative">
                <div className="d-flex align-items-center">
                  <div className="position-relative flex-grow-1">
                    <input
                      className="hidden-input"
                      type="file"
                      accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                      onChange={handleOtherFileChange}
                      multiple
                    />
                    <input className="form-control" type="text" disabled />
                    <PaperclipIcon className="HW18 clip-img" />
                    <div className="uploadbox-text c-font f-12">
                      {"Upload Documents"}
                    </div>
                  </div>
                </div>
                {/* Other files preview */}
                <div className="d-flex flex-wrap ms-10px">
                  {otherFiles.map((item, index) => {
                    const fileName = item.file
                      ? item.file.name
                      : item.file_name;
                    const fileType = getFileType(fileName);
                    return (
                      <div
                        className="upload-image-preview cursor-pointer col-auto me-2 mb-2"
                        key={index}
                        onClick={() => handlePreviewClick(item)}
                      >
                        <div className="comman-image-box h50w50 radius_3 upload-image with-border active">
                          {fileType === "pdf" ? (
                            <div
                              onClick={() => setOpenGallery(true)}
                              className="h-100 w-100 d-flex align-items-center justify-content-center"
                            >
                              <FilePdfIcon className="h-50 w-50" />
                            </div>
                          ) : (
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                              style={{
                                backgroundImage: `url(${item.blobUrl})`,
                              }}
                              onClick={() => setOpenGallery(true)}
                            />
                          )}

                          {/* Remove */}
                          <a
                            href="#/"
                            className="upload-close"
                            onClick={() => removeOtherFile(index)}
                          >
                            <XIcon className="HW10" />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Form.Group>
          </div>

          {/* Termination Conditions */}
          <Form.Group className="col-12 pb-3">
            <Form.Label className="input-label no-asterisk">
              Termination conditions
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Details"
              className="border border-border rounded-lg p-2"
              value={terminationConditions}
              onChange={(e) => setTerminationConditions(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="my-4 border-top"></div>
          </div>
          <div className="col-12">
            <div className="fw-semibold mb-15px">{_l("l_custom_fields")}</div>
          </div>

          <Form.Group className="col-lg-6">
            <Form.Label className="input-label no-asterisk no-asterisk">
            {_l("l_field_name")}
            </Form.Label>
          </Form.Group>
          <Form.Group className="col-lg-6 ">
            <div className="d-flex justify-content-between gap-2">
              <Form.Label className="input-label no-asterisk no-asterisk">
                {_l("l_field_value")}
                </Form.Label>
              {/* <div className="w-15 comman_action_icon">
                <Form.Label className="input-label no-asterisk no-asterisk h53w53 h-auto">
                  Action
                  </Form.Label>
                </div> */}
              </div>
          </Form.Group>
        </div>
        {customFieldRow && customFieldRow.length > 0 ? (
            customFieldRow.map((row, index) => (
              <div className="row" key={index}>
                <Form.Group className="col-lg-6 pb-3">
                  {/* <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_field_name")}
                  </Form.Label> */}
                
                   <CreatableSelect
                      isClearable
                      isSearchable
                      placeholder={_l("l_enter_field_name")}
                      className="custom-select-menu w-100"
                      classNamePrefix="react-select"
                      closeMenuOnSelect={true}
                      options={options}
                      onChange={(selected) => handleFieldNameChange(selected, index)}
                      onCreateOption={(inputValue) => handleCreateOption(inputValue, index)}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                      value={row.field_name}
                      onEditOption={handleEditOption}
                      onBlur={
                        (e) => addRow(e, index)
                      }
                  />
                </Form.Group>

                <Form.Group className="col-lg-6 pb-3">
                  {/* <Form.Label className="input-label no-asterisk no-asterisk">
                   {_l("l_field_value")}
                  </Form.Label> */}
                  <div className="d-flex justify-content-between gap-2">
                      <Form.Control
                        type="text"
                        value={row.field_value}
                        onChange={(e) => handleFieldValueChange(e, index)}
                        placeholder="Enter value..."
                        // onKeyPress={(e) => handleKeyPress(e, index)}
                        className=""
                      />
                   { index !== customFieldRow.length - 1 ? (
                    <div className="w-15 comman_action_icon">
                      <div className="w-100 light-theme-blue-bg radius-5">
                        <a
                          href="#/"
                          className="action_icon with_bg h53w53 d-flex align-items-center justify-content-center"
                          onClick={() => handleDeleteRow(index)}
                        >
                          <TrashIcon className="HW16 mx-0" />
                        </a>
                      </div>
                    </div>
                  ) : null }
                  </div>
                </Form.Group>
                </div>
            ))
          ) : (
            <></> 
          )}
      </Modal.Body>

      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" onClick={() => handleClose(0)}>
          {_l("l_close")}
        </Button>
        <Button
          className={`${loader ? "disabled" : ""}`}
          variant="primary"
          onClick={handleSave}
        >
          {loader ? (
            <>
              {_l("l_please_wait")}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className="ms-2"
              />
            </>
          ) : (
            _l("l_save")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OperatorContract;
