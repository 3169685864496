import React, { useState, useEffect, useRef } from "react";
import {
  CloseCommentModal,
  getAssigneeTypesForRequest,
  LeftPanelResponsiveToggle,
  _l,
  getDatePickerLocale,
  getDatePickerTimeCaption,
  removeDuplicateObjects,
  selectedAssigneeForTaskAndRequest,
  HideDropdown,
  showSuccess,
  showError,
  deleteTasksFromIDB,
  ConvertLocalTime,
  handleTaskCountsforAlltab,
} from "../hooks/utilities";
import HighPriorityIcon from "../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../assets/icons/HighPriorityActiveIcon";
import CustomerServices from "./../services/customer-services";
import DeleteDropdown from "./Dropdowns/DeleteDropdown";
import moment from "moment";
import TaskListStatusDropdown from "./Dropdowns/TaskListStatusDropdown";
import DatePicker from "react-datepicker";
import Toasts from "./Toasts/Toasts";
import Form from "react-bootstrap/Form";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import {
  getDaysBetweenDates,
  getHoursBetweenDates,
  getMintuesBetweenDates,
} from "../hooks/utilities";
import {
  updateTaskStatus,
  changePriority,
  getSelectedTask,
  getTaskCount,
  setTaskData,
  IsToastOpenOrClose,
  getTopicTasks,
  updateUnreadCommentCount,
  setTaskUnreadCmtCntAll,
  getMentionList,
  taskBulkAction,
  setVirtualSpaceNotificationCount,
  updateTaskPin,
  setSpaceNotificationCount,
  updateTaskList,
  commanoffcanvasisOpen,
  setFetchedTaskandMessages,
  updateProviderListTableData,
  getProviderListTableData,
  istodoTable,
} from "../actions/customer";
import { initCall } from "../actions/chat";
import SpaceListDropdown from "./Dropdowns/SpaceListDropdown";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import GeneralListing from "./TaskModalComponents/GeneralListing";
import * as bootstrap from "bootstrap";
import { showMessage, toastCloseAction } from "../actions/messages";
import { GET_SELECTED_TASK } from "../actions/action-type";
import CommentMinified from "./Comments/CommentMinified";
import DocumentsServices from "../services/documents-services";
import ImageViewer from "./Offcanvas/ImageViewer";
import MeetingProviderModal from "./Modals/MeetingProviderModal";
import customerServices from "./../services/customer-services";
import EditTaskModal from "./Modals/EditTaskModal";
import RobotPlanDetailModal from "./Modals/RobotPlanDetailModal";
import { CUSTOM_ASSIGNEE_CHANGE } from "../constants/constants";
import AddAssigneeMainDropdownNew from "./TaskModalComponents/AddAssigneeMainDropdownNew";
import ReactHtmlParser from "react-html-parser";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import ShareNetworkIcon from "../assets/icons/ShareNetworkIcon";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import TaskShareLinkModal from "./Modals/TaskShareLinkModal";
import UncontrolledGeneralListing from "./TaskModalComponents/UncontrolledGeneralListing";
import AddTaskIcon from "../assets/icons/AddTaskIcon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CalendarBlankIcon from "../assets/icons/CalendarBlankIcon";
import PushPinFillIcon from "../assets/icons/PushPinFillIcon";
import PushPinIcon from "../assets/icons/PushPinIcon";
import ChatCircleIcons from "../assets/icons/ChatCircleIcons";
import UserCircleGearIcon from "../assets/icons/UserCircleGearIcon";
import EyeIcon from "../assets/icons/EyeIcon";
import DownloadSimpleIcon from "../assets/icons/DownloadSimpleIcon";
import CubeIcon from "../assets/icons/CubeIcon";
import MapPinIcon from "../assets/icons/MapPinIcon";
import DotsNineIcon from "../assets/icons/DotsNineIcon";
import DotsThreeVerticalIcon from "../assets/icons/DotsThreeVerticalIcon";
import StarIcon from "../assets/icons/StarIcon";
import TrashIcon from "../assets/icons/TrashIcon";
import PhoneCallIcon from "../assets/icons/PhoneCallIcon";
import CheckCircleIcon from "../assets/icons/CheckCircleIcon";
import ListChecksIcon from "../assets/icons/ListChecksIcon";
import ListBulletsIcon from "../assets/icons/ListBulletsIcon";
window.bootstrap = bootstrap;
let showComments = false;
const TaskCard = React.memo(({
  task,
  active,
  handler = () => { },
  getTaskDetails,
  pageName = "",
  taskCheckUncheck = () => { },
  checkedTask,
  taskPinned = "false",
  setTaskPinned,
  contacts = [],
  setTaskId,
  setCallUserList = () => { },
  driveDocument,
  initReply = () => { },
  replyTaskId = 0,
  fromReply = false,
  showOptions = 0,
  setshowOptions = () => { },
  setSelectedTaskIdforIconToggle = () => { },
  selectedTaskIdforIconToggle = 0,
  showDefaultIcons = [],
  setShowDefaultIcons = () => { },
  handleToggleIcons = () => { },
  msgid = "",
  setUnreadMessage = () => { },
  isFrom = "",
}) => {
  const {
    taskUnreadCmtCntAll,
    commanoffcanvasIsOpen,
    taskFilters,
    virtualSpaceList,
    taskList,
    taskandMessages,
    globalView,
    providerlistTableData,
    leftPanelTab
  } = useSelector((state) => state.customer);
  const staff_id = localStorage.getItem("staff_id");
  const isVirtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const [isLiteVersion, setisLiteVersion] = useState(false);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [taskPriority, setTaskPriority] = useState(Array.isArray(task.task_priority_user) && task.task_priority_user.includes(staff_id) ? 3 : 2);
  const [taskHide, setTaskHide] = useState(false);
  const [updatedCommentCount, setUpdatedCommentCount] = useState(
    Number(task.comments_count)
  );
  const [task_type, setTask_type] = useState();
  const [taskProviderTitle, setTaskProviderTitle] = useState("");
  const [meetingModal, setMeetingModal] = useState(false);
  const [selectedAssigneeNew, setSelectedAssigneeNew] = useState([]);
  const [isTaskPinned, setIsTaskPinned] = useState(
    task.pinned && task.pinned == 1 ? true : false
  );
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [replyCommentData, setReplyCommentData] = useState({});
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [taskStartDate, setTaskStartDate] = useState(
    task.startdate && task.startdate != "0000-00-00 00:00:00"
      ? new Date(task.startdate)
      : null
  );
  const [taskDueDate, setTaskDueDate] = useState(
    task.duedate && task.duedate != "0000-00-00 00:00:00"
      ? new Date(task.duedate)
      : null
  );
  const showStartDate =
    (task.addedfrom == 0 &&
      task.schedule_id &&
      parseInt(task.schedule_id) > 0) ||
      task.is_requested == 1 ||
      (task.provider && !task.duedate)
      ? true
      : false;
  const [taskAssignee, setTaskAssignee] = useState([]);
  const [taskAssigneeTeam, setTaskAssigneeTeam] = useState([]);
  const [taskAllAssignee, setTaskAllAssignee] = useState([]);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [selectedTaskAssigneesId, setSelectedTaskAssigneesId] = useState([]);
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };
  const [selectedTagId, setTagId] = useState(0);
  const dispatch = useDispatch();
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const project_id = useSelector((state) => state.customer.selectedProject);
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const selectedDate =
    localStorage.getItem("calendarData") &&
      localStorage.getItem("calendarData") != "undefined" &&
      JSON.parse(localStorage.getItem("calendarData")).length
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : new Date();
  const contact_id = localStorage.getItem("contact_id");
  const userType = localStorage.getItem("user_type");
  const client_id = localStorage.getItem("client_id");
  const projectId = localStorage.getItem("selectedOffice");
  const contact_role = localStorage.getItem("contact_role");
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [requestTaskId, setRequestTaskId] = useState(0);
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [showMapInfoModal, setShowMapInfoModal] = useState(false);
  const [attachments, setAttachments] = useState({
    file_array: [],
    date: "",
    currentIndex: 0,
  });
  const [isTaskOwner, setIsTaskOwner] = useState(
    task.addedfrom > 0 &&
      task.addedfrom == (userType == "contact" ? contact_id : staff_id) &&
      task.is_added_from_contact == (userType == "contact" ? "1" : "0")
      ? true
      : false
  );
  const [taskUserAssignees, setTaskUserAssignees] = useState([]);
  const pathname = window.location.pathname;
  const toastData = useSelector((state) => state.customer.toastClose);
  const unreadCommentCount = useRef(task.unread_comment_count);
  const spaceState = useSelector((state) => state.customer.spaces);
  const [openSpaceListDropdown, setOpenSpaceListDropdown] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(task.rel_id);
  const [hideclass, sethideclass] = useState(true);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [taskTime, settaskTime] = useState("");
  const [taskCardIconToggle, setTaskCardIconToggle] = useState(true);
  const handleCloseShareLink = () => setShowLink(false);
  const handleShowShareLink = () => setShowLink(true);
  const [showLink, setShowLink] = useState(false);
  const [todoTaskTitle, setTodoTaskTitle] = useState("")
  const [isChatSkeleton, setIsChatSkeleton] = useState(false);

  useEffect(() => {
    setUpdatedCommentCount(Number(task.comments_count));
  }, [task.comments_count])
  useEffect(() => {
    unreadCommentCount.current = task.unread_comment_count
  }, [task.unread_comment_count])
  useEffect(() => {
    let tempDefaultIcons = [];
    if (isTaskPinned == true) {
      tempDefaultIcons.push("pin");
    }
    if (taskAssignee.length > 0) {
      tempDefaultIcons.push("peopels");
    }
    if (taskAssigneePills.length > 0) {
      tempDefaultIcons.push("pills");
    }
    if (taskPriority == 3) {
      tempDefaultIcons.push("priority");
    }
    if (task.comments_count > 0) {
      tempDefaultIcons.push("comment");
    }
    if (task.rating && task.rating > 0) {
      tempDefaultIcons.push("star");
    }
    if (pageName == "LiteVersion") {
      setShowDefaultIcons(tempDefaultIcons);
    } else {
      setShowDefaultIcons([
        "assigneTo",
        "pin",
        "peopels",
        "pills",
        "priority",
        "cube",
        "map",
        "status",
        "comment",
        "star",
        "delete",
        "share",
        "call",
        "template",
      ]);
    }
  }, [JSON.stringify(task), taskAssigneeTeam, taskAssignee]);
  useEffect(() => {
    if (
      task.schedule_id > 0 ||
      task.rel_type == "equipment" ||
      task.is_requested == 1
    ) {
      if (taskStartDate) {
        settaskTime(moment(taskStartDate));
      } else if (task.startdate != null && task.startdate != "") {
        settaskTime(moment(task.startdate));
      } else if (task.duedate != null && task.duedate != "") {
        settaskTime(moment(task.duedate));
      }
    } else {
      if (taskDueDate) {
        settaskTime(moment(taskDueDate));
      } else if (task.startdate != null && task.startdate != "") {
        settaskTime(moment(task.startdate));
      } else if (task.duedate != null && task.duedate != "") {
        settaskTime(moment(task.duedate));
      }
    }
    setCurrentTime(moment(new Date()));
  }, [JSON.stringify(task)]);
  useEffect(() => {
    if (taskAssigneeTeam.length > 0 || taskAssignee.length > 0) {
      setTaskAllAssignee(
        removeDuplicateObjects(taskAssignee.concat(taskAssigneeTeam))
      );
    }
  }, [taskAssigneeTeam, taskAssignee]);
  useEffect(() => {
    if (commanoffcanvasIsOpen) {
      setTaskCardIconToggle(true);
    } else {
      setTaskCardIconToggle(false);
    }
  }, [commanoffcanvasIsOpen]);
  useEffect(() => {
    if (defaultTopics && defaultTopics.length) {
      let requestAssigneeList = [];
      defaultTopics.forEach((topic) => {
        switch (topic.main_key) {
          case "my_task":
            topic.categories.forEach((my_task) => {
              if (!isNaN(parseInt(my_task.filter_key))) {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: my_task.title,
                  id: my_task.filter_key,
                  is_assigned: my_task.is_assigned || 0,
                  image: my_task.image || "",
                });
              }
            });
            break;
          case "provider_task":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;
          case "my_people":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;
          case "proposals":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;
          case "user_journey":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;
          case "myr_staff_task":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;
          case "task_topic":
            if (
              localStorage.getItem("selectedVirtualSpace") &&
              Object.keys(localStorage.getItem("selectedVirtualSpace")).length
            ) {
              topic.categories.forEach((proposal) => {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: proposal.title,
                  id: proposal.filter_key,
                  is_assigned: proposal.is_assigned || 0,
                  image: proposal.image || "",
                });
              });
            }
            break;
          default:
            break;
        }
      });
      let assigneeTypes = getAssigneeTypesForRequest();
      if (task && task.is_requested == 0) {
        assigneeTypes.push("my_task");
      }
    }
  }, [defaultTopics]);
  useEffect(() => {
    if (task.assignee_list_DEV) {
      setSelectedAssigneeNew(task.assignee_list_DEV);
    }
  }, [JSON.stringify(task.assignee_list_DEV)]);
  useEffect(() => {
    if (selectedAssigneeNew) {
      selectedAssigneeForTaskAndRequest(
        selectedAssigneeNew,
        setTaskAssigneePills,
        setTaskAssignee,
        defaultTopics
      );
    }
  }, [selectedAssigneeNew, defaultTopics]);
  useEffect(() => {
    if (pageName == "LiteVersion") {
      setisLiteVersion(true);
    } else {
      setShowDefaultIcons([
        "calendar",
        "assigneTo",
        "pin",
        "peopels",
        "pills",
        "priority",
        "cube",
        "map",
        "status",
        "comment",
        "star",
        "delete",
        "share",
        "call",
        "template",
      ]);
    }
  }, [pageName]);
  useEffect(() => {
    jquery(document).on("click", ".close-toast", function () {
      resetToast();
    });
  }, []);
  useEffect(() => {
    let officetitles = spaceState
      .concat(virtualSpaceList)
      .filter(
        (space) =>
          space.project_id == selectedSpace || space.id == selectedSpace
      );
  }, [task, selectedSpace]);
  useEffect(() => {
    setTaskStartDate(
      task.startdate && task.startdate != "0000-00-00 00:00:00"
        ? new Date(task.startdate)
        : null
    );
    setTaskDueDate(
      task.duedate && task.duedate != "0000-00-00 00:00:00"
        ? new Date(task.duedate)
        : null
    );
  }, [JSON.stringify(task)]);
  // Assignee new flow end-----------------------------------------------
  useEffect(() => {
    setTaskProviderTitle(task.provider_title);
    let task_assignee = [];
    let task_assignee_team = [];
    let task_assignee_ids = [];
    let task_assignee_pills = [];
    if (
      task.assignee_list &&
      task.assignee_list.length &&
      task.assignee_list.constructor === Array
    ) {
      task.assignee_list &&
        task.assignee_list.length &&
        task.assignee_list.map((ta) => {
          ta.categories &&
            ta.categories.map((taskAssign) => {
              defaultTopics &&
                defaultTopics.forEach((topic) => {
                  if (topic.main_key == ta.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        if (
                          ta.main_key == "equipments" ||
                          (task.is_requested == 1 &&
                            topic.main_key == "provider_task" &&
                            userType == "contact" &&
                            !CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))
                        ) {
                          task_assignee_ids.push({
                            name: ta.main_key,
                          });
                        } else {
                          task_assignee_ids.push({
                            id: taskAssign.filter_key,
                            name: ta.main_key,
                          });
                        }
                      }
                    });
                  }
                });
              if (
                ta.main_key == "myr_staff_task" ||
                ta.main_key == "my_people" ||
                ta.main_key == "task_topic"
              ) {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          task_assignee.push({
                            name: provider.title,
                            profile_image: provider.image,
                            id: provider.filter_key,
                            email: provider.email,
                            user_type: provider.user_type,
                          });
                          if (provider.filter_key < 0 && !isVirtualSpaces) {
                            task_assignee_team.push({
                              name: provider.title,
                              profile_image: provider.image,
                              id: provider.filter_key,
                              email: provider.email,
                              user_type: provider.user_type,
                            });
                          }
                          if (provider.filter_key > 0 && isVirtualSpaces) {
                            task_assignee_team.push({
                              name: provider.title,
                              profile_image: provider.image,
                              id: provider.filter_key,
                              email: provider.email,
                              user_type: provider.user_type,
                            });
                          }
                        }
                      });
                    }
                  });
              } else {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          let subCategory = [];
                          if (
                            task.is_requested == 1 &&
                            topic.main_key == "provider_task"
                          ) {
                            taskAssign.sub_category &&
                              taskAssign.sub_category.length > 0 &&
                              taskAssign.sub_category.forEach((dd) => {
                                subCategory.push({ ...dd, id: dd.staffid });
                              });
                          } else {
                            provider.sub_category &&
                              provider.sub_category.forEach((sc) => {
                                taskAssign.sub_category &&
                                  taskAssign.sub_category.length > 0 &&
                                  taskAssign.sub_category.forEach((dd) => {
                                    if (sc.id == dd) {
                                      subCategory.push(dd);
                                      task_assignee_pills.push({
                                        name: sc.name,
                                        id: sc.id,
                                        main_key: topic.main_key,
                                      });
                                    }
                                  });
                              });
                          }
                          task_assignee_ids.push({
                            id: provider.filter_key,
                            name: provider.title,
                            sub_category: subCategory,
                          });
                          if (
                            topic.main_key != "equipments" ||
                            !(
                              task.is_requested == 1 &&
                              topic.main_key == "provider_task" &&
                              userType == "contact" &&
                              !CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)
                            )
                          ) {
                            task_assignee_pills.push({
                              name: provider.title,
                              id: provider.filter_key,
                              main_key: topic.main_key,
                            });
                          }
                        }
                      });
                    }
                  });
                if (
                  (ta.main_key == "provider_task" ||
                    ta.main_key == "proposals") &&
                  userType == "staff"
                ) {
                  ta.categories.map((data) => {
                    task_assignee_pills.push({
                      name: "Myr.ai Support",
                      id: data.filter_key,
                      main_key: data.filter_key,
                    });
                  });
                }
              }
            });
        });
    }
  }, [openAssigneeDropdown, defaultTopics, JSON.stringify(task)]);
  useEffect(() => {
    jquery(document).mouseup(function (e) {
      var container = jquery(".chat-page");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setshowOptions(0);
      }
    });
  });
  const calendarData = useSelector((state) => state.customer.calendarData);
  const changeTaskPriority = (e) => {
    dispatch(
      changePriority(
        task.id,
        project_id,
        taskPriority == 3 ? 3 : 2,
        task.provider
      )
    ).then(() => {
      setTaskPriority(taskPriority == 3 ? 2 : 3);
    });
  };
  const changeTaskStatus = (statusId) => {
    dispatch(
      updateTaskStatus(
        task.id,
        project_id,
        statusId,
        task.is_requested,
        task.provider,
        task.id,
        task.name
      )
    );
    dispatch(getTaskCount(client_id, contact_id, project_id, selectedDate));
  };
  const pinTask = () => {
    setTaskPinned(!taskPinned);
    setIsTaskPinned(!isTaskPinned);
    dispatch(
      updateTaskPin(
        task.id,
        project_id,
        isTaskPinned == 1 ? 1 : 0,
        task.provider
      )
    );
  };
  const deleteTask = (request_task_id = 0) => {
    CustomerServices.deleteTask(
      request_task_id != 0 ? request_task_id : task.id,
      project_id
    ).then((res) => {
      if (res && res.status === 1) {
        if (window.location.pathname === "/providerlist") {
          dispatch(updateProviderListTableData({ ['taskId']: task.id }, "task", "delete"));
          dispatch(IsToastOpenOrClose(false));
        } else {
          let id = request_task_id != 0 ? request_task_id : task.id;
          dispatch(getTaskCount(client_id, contact_id, project_id, selectedDate));
          localStorage.setItem("is_task_delete", 1);
          dispatch(
            taskBulkAction(
              [request_task_id != 0 ? request_task_id : task.id],
              "delete_task",
              ""
            )
          );
          handler(0);
          dispatch(IsToastOpenOrClose(false));
          if (
            selectedTask &&
            (selectedTask.id || selectedTask.task_id) === task.id
          ) {
            dispatch(getSelectedTask(0, 0, null, null, 1));
          }
          let newList = taskandMessages.filter((task) => task.id != id);
          dispatch(setFetchedTaskandMessages(newList));
          let tempData = taskList.filter((task) => task.id != id);
          dispatch(updateTaskList(tempData));
          deleteTasksFromIDB([request_task_id != 0 ? request_task_id : task.id]);
          //Acknowledgement for deletion if required
        }
      } else {
        showError("l_something_went_wrong");
      }
    });
    if (window.location.pathname !== "/providerlist") {
      CloseCommentModal();
    }
  };
  const getTask = (id, is_requested = null, provider) => {
    if (!selectedTask || selectedTask.id !== id) {
      if (!task) {
        dispatch(
          getSelectedTask(project_id, id, is_requested, false, 0, provider)
        );
      } else {
        const data = { status: 1, data: task };
        dispatch({
          type: GET_SELECTED_TASK,
          payload: { data },
        });
      }
    }
  };
  const handleUpdateDate = (startDate = "", dueDate = "") => {
    let start_date = startDate && startDate != "" ? startDate : "";
    let due_date = dueDate && dueDate != "" ? dueDate : "";
    let index = taskList.findIndex((t) => t.id == task.id);
    let tempdata = taskList;
    if (index > -1) {
      tempdata[index].startdate =
        start_date != ""
          ? moment(start_date).format("YYYY-MM-DD HH:mm:ss")
          : null;
      tempdata[index].duedate =
        due_date != "" ? moment(due_date).format("YYYY-MM-DD HH:mm:ss") : null;
    }
    dispatch(updateTaskList(tempdata));
    dispatch(setTaskData(project_id, task.id, task.name, start_date, due_date));
    dispatch(getTaskCount(client_id, contact_id, project_id, selectedDate));
  };
  const resetToast = () => {
    setShowToast(false);
    setToastHeader("");
    setToastClass("");
    setToastMessage("");
    setConfirmButtonText("");
    setCancelButtonText("");
    setRequestTaskId(0);
  };
  const handleBadgeCount = (task) => {
    var taskUnreadCmtCntEl = jquery(
      "span[data-unread-cmt-id='" + task.id + "']"
    );
    var taskUnreadCmtCnt = parseInt(taskUnreadCmtCntEl.text());
    if (taskUnreadCmtCnt > 0) {
      dispatch(getTopicTasks(project_id));
      dispatch(updateUnreadCommentCount(task.id, 0));
      dispatch(setTaskUnreadCmtCntAll(taskUnreadCmtCntAll - taskUnreadCmtCnt));
      var projUnreadCntEl = jquery(
        "span[data-proj-unread-cnt-id='" + project_id + "']"
      );
      var projUnreadCnt = parseInt(projUnreadCntEl.text());
      if (projUnreadCnt > 0) {
        projUnreadCntEl.text(projUnreadCnt - 1);
        if (projUnreadCnt == taskUnreadCmtCnt) {
          projUnreadCntEl.addClass("d-none");
        }
      }
    }
  };
  const is_requested = task.is_requested == "1" ? true : null;
  const RequestTaskdays =
    task.converted_date && task.converted_date != null
      ? getDaysBetweenDates(ConvertLocalTime(task.converted_date))
      : "";
  const RequestTaskHours =
    task.converted_date && task.converted_date != null
      ? getHoursBetweenDates(ConvertLocalTime(task.converted_date))
      : "";
  const RequestTaskMintues =
    task.converted_date && task.converted_date != null
      ? getMintuesBetweenDates(ConvertLocalTime(task.converted_date))
      : "";
  const LeftPanelBaseOnScreenSize = () => { };
  const getMentionlist = (id, companyId, page_name) => {
    dispatch(getMentionList(id, "task", "", "", "", "", "", companyId, page_name));
  };
  const getTaskComments = async () => {
    showComments = true;
    setCommentsLoading(true);
    setIsChatSkeleton(true);
    try {
      const comments = await DocumentsServices.getComments(
        task.id,
        "task",
        undefined,
        undefined,
        unreadCommentCount.current > 0 ? 1 : 0
      );
      if (comments.data) {
        if (unreadCommentCount.current > 0) {
          dispatch(
            setTaskUnreadCmtCntAll(
              Number(taskUnreadCmtCntAll) - Number(unreadCommentCount.current)
            )
          );
          if (
            localStorage.getItem("selectedVirtualSpace") &&
            JSON.parse(localStorage.getItem("selectedVirtualSpace"))
          ) {
            dispatch(
              setVirtualSpaceNotificationCount(
                project_id,
                unreadCommentCount.current,
                -1
              )
            );
          } else {
            dispatch(
              setSpaceNotificationCount(
                project_id,
                unreadCommentCount.current,
                -1
              )
            );
          }
          unreadCommentCount.current = 0;
          let indexT = taskList.findIndex((t) => t.id == task.id);
          let tempdataT = taskList;
          if (indexT > -1) {
            tempdataT[indexT].unread_comment_count = 0;
          }
          dispatch(updateTaskList(tempdataT));
        }
        setCommentsList(
          comments.data.map((comment) => {
            return {
              id: comment.id,
              content: comment.content,
              contact_id: comment.contact_id,
              staffid: comment.staffid,
              full_name: comment.contact_full_name || comment.staff_full_name,
              profile_url: comment.addedfrom_details.profile_url,
              attachments: comment.attachments,
              date: comment.time,
              dateadded: comment.dateadded,
              is_allowed_to_edit: comment.is_allowed_to_edit,
              parent_comment_details: comment.parent_comment_details
                ? comment.parent_comment_details
                : "",
              parent_comment_id: comment.parent_comment_id
                ? comment.parent_comment_id
                : 0,
            };
          })
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setCommentsLoading(false);
      setIsChatSkeleton(false);
    }
  };
  const handleImagePreview = (comment) => {
    setShowImagePreview(true);
    setAttachments({
      ...attachments,
      file_array: comment.attachments,
      currentIndex: 0,
      date: comment.dateadded,
    });
  };
  const closePreview = () => {
    setAttachments({ ...attachments, file_array: [], currentIndex: 0 });
    setShowImagePreview(false);
  };
  const changeAttachment = (direction) => {
    if (direction === "prev" && attachments.currentIndex > 0) {
      setAttachments({
        ...attachments,
        currentIndex: attachments.currentIndex--,
      });
    } else if (
      direction === "next" &&
      attachments.currentIndex <= attachments.current.file_array.length
    ) {
      setAttachments({
        ...attachments,
        currentIndex: attachments.currentIndex++,
      });
    }
  };
  const handleAddEditComment = async (content, files, id) => {
    setFileUploadLoader(true);
    try {
      const response = await DocumentsServices.addDocumentComment(
        "task",
        task.id,
        content,
        0,
        id,
        files,
        0,
        0,
        replyCommentData.id
      );
      if (id > 0) {
        const commentIndex = commentsList.findIndex((w) => w.id == id);
        setCommentsList(
          Object.values({
            ...commentsList,
            [commentIndex]: { ...commentsList[commentIndex], content },
          })
        );
      } else {
        const staffid = localStorage.getItem("staff_id");
        const profile_url = localStorage.getItem("contact_image");
        const full_name = localStorage.getItem("full_name");
        let attachments = [];
        if (files) {
          Array.from(files).map((file) => {
            attachments.push({
              file_url: URL.createObjectURL(file),
              file_name: file.name,
            });
          });
        }
        var addComments = {
          id: response.data.comment_id,
          content: content,
          contact_id: contact_id,
          staffid: staffid,
          full_name: full_name,
          profile_url: profile_url,
          is_allowed_to_edit: "1",
          attachments: attachments,
          date: response.data.time,
          dateadded: new Date(),
        };
        if (
          replyCommentData &&
          replyCommentData.content != "" &&
          replyCommentData.id != ""
        ) {
          addComments.parent_comment_details = {
            id: replyCommentData.id,
            content: replyCommentData.content,
            file_id: "0",
          };
          addComments.parent_comment_id = replyCommentData.id;
        } else if (
          replyCommentData.attachments &&
          replyCommentData.attachments[0].file_url != ""
        ) {
          let replyCommentDatas = replyCommentData.attachments[0];
          addComments.parent_comment_details = {
            content: "[task_attachment]",
            file_id: replyCommentDatas.file_id,
            file_name: replyCommentDatas.file_name,
            file_url: replyCommentDatas.file_url,
          };
          addComments.parent_comment_id = replyCommentData.id;
        }
        setCommentsList(commentsList.concat([addComments]));
        setUpdatedCommentCount(Number(commentsList.length + 1));
        let iT = taskList.findIndex((t) => t.id == task.id);
        let tempdataTC = taskList;
        if (iT > -1) {
          tempdataTC[iT].comments_count = Number(tempdataTC[iT].comments_count) + 1;
        }
        dispatch(updateTaskList(tempdataTC));
        setFileUploadLoader(false);
      }
    } catch (e) {
      setFileUploadLoader(false);
      console.error(e);
    }
  };
  const handleDeleteComment = (comment) => {
    try {
      if (pageName == "task" || pageName == "LiteVersion") {
        customerServices.removeComment(projectId, comment.id).then((res) => {
          if (res.status == 1) {
            setCommentsList(commentsList.filter((x) => x.id != comment.id));
            setUpdatedCommentCount(Number(commentsList.length - 1));
          }
        });
      } else {
        const response = DocumentsServices.removeComment(
          task.id,
          "task",
          comment.id
        );
        setCommentsList(commentsList.filter((x) => x.id != comment.id));
        setUpdatedCommentCount(Number(commentsList.length - 1));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const makeCall = (myrUserId, email, myrUserType) => {
    dispatch(
      initCall({
        userId: localStorage.getItem("orgChatUserId"),
        myrUserId: myrUserId,
        email: email,
        myrUserType: myrUserType == "contact" ? "client" : "staff",
        extraData: {
          taskId: task.id,
          taskName: task.name,
        },
      })
    );
  };
  const reqTimePill = (
    <>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${RequestTaskdays == "" &&
            RequestTaskHours == "" &&
            RequestTaskMintues == ""
            ? "d-none"
            : ""
          }`}
      >
        <span className="me-1 ps-0">
          <span
            className={` c-font f-13  ${parseInt(RequestTaskdays) >= 0 ? "color-green" : "color-red"
              }`}
          >
            {Math.abs(RequestTaskdays)}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_day")}</span>
        </span>
        <span className="me-1 ps-0">
          <span
            className={` c-font f-13  ${RequestTaskHours >= 0 ? "color-green" : "color-red"
              }`}
          >
            {Math.abs(RequestTaskHours)}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
        </span>
        <span className="me-1 ps-0">
          <span
            className={` c-font f-13  ${RequestTaskHours >= 0 ? "color-green" : "color-red"
              }`}
          >
            {Math.abs(RequestTaskMintues)}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
        </span>
      </div>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${RequestTaskdays == "" &&
            RequestTaskHours == "" &&
            RequestTaskMintues == "" &&
            task.startdate
            ? ""
            : "d-none"
          }`}
      >
        <span className="me-1">
          <span className={` c-font f-13 color-green`}>{_l("l_just_now")}</span>
        </span>
      </div>
    </>
  );
  let time = task && task.total_time && task.total_time.split(":");
  const ScheduleTaskHours =
    task.total_time && task.total_time != null ? parseInt(time[0].trim()) : "";
  const ScheduleTaskMintues =
    task.total_time && task.total_time != null ? parseInt(time[1].trim()) : "";
  const scheduleTimePill = (
    <>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${ScheduleTaskHours == "" && ScheduleTaskMintues == "" ? "d-none" : ""
          }`}
      >
        <span className="me-1">
          <span
            className={` c-font f-13  ${ScheduleTaskHours >= 0 ? "color-green" : "color-red"
              }`}
          >
            {ScheduleTaskHours}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
        </span>
        <span className="me-1">
          <span
            className={` c-font f-13  ${ScheduleTaskHours >= 0 ? "color-green" : "color-red"
              }`}
          >
            {ScheduleTaskMintues}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
        </span>
      </div>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${ScheduleTaskHours == "" &&
            ScheduleTaskMintues == "" &&
            task.total_time
            ? ""
            : "d-none"
          }`}
      ></div>
    </>
  );
  const convertDateintoTimezone = (date) => {
    var utc = new Date();
    var offset = utc.getTimezoneOffset();
    var MS_PER_MINUTE = 60000;
    var myStartDate = new Date(
      new Date(date).getTime() - offset * MS_PER_MINUTE
    );
    return myStartDate;
  };
  const handleTaskSpace = (space) => {
    customerServices
      .changeTaskspace(
        staff_id,
        contact_id,
        client_id,
        space.project_id,
        task.id
      )
      .then((res) => {
        if (res.status) {
          if (window.location.pathname === "/providerlist") {
            dispatch(updateProviderListTableData({ ...space, ['taskId']: task.id }, "task", "spaces"))
          }
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      });
  };
  const isTaskAssignmentDisabled = () => {
    let flag = false;
    if (contact_role == 3) {
      flag = true;
    } else if (
      task.is_allowed_to_delete > 0 &&
      task.is_allowed_to_edit > 0 &&
      task.is_one_time_benchmark > 0
    ) {
      return flag;
    } else if (
      task.schedule_id > 0 &&
      (userType !== "operator" || contact_role == "3")
    ) {
      flag = true;
    }
    return flag;
  };
  const createTodo = () => {
    try {
      customerServices.createToDos(
        [{ title: todoTaskTitle }],
        [
          {
            "main_key": "provider_page",
            "categories": [
              {
                "filter_key": localStorage.getItem("currentCompanyID"),
                "main_sub_key": "provider",
                "sub_category": []
              }
            ]
          }
        ],
      ).then((res) => {
        if (res && res.status) {
          let ids = res.data && res.data.task_ids ? res.data.task_ids : [];
          if (ids && ids.length > 0) {
            customerServices.getTasklistForChat(undefined, ids, 1).then((res) => {
              if (res && res.status) {
                let oldTask = providerlistTableData.task
                let finalArr = oldTask.concat(res.data ? res.data : [])
                dispatch(getProviderListTableData({ ...providerlistTableData, task: finalArr }))
                setTodoTaskTitle("")
                showSuccess("l_task_created");
              } else {
                showError("l_something_went_wrong");
              }
            })
          }
        } else {
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setTaskPriority(Array.isArray(task.task_priority_user) && task.task_priority_user.includes(staff_id) ? 3 : 2);
    setIsTaskPinned(task.pinned && task.pinned == 1 ? true : false);
  }, [task])
  
  return (
    <React.Fragment>
      {meetingModal ? (
        <MeetingProviderModal
          meetingProvider={meetingModal}
          HandleClose={() => {
            setMeetingModal(false);
          }}
          selectedTask={task}
          commentSection={1}
        />
      ) : (
        <></>
      )}
      {showToast && toastData ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            dispatch(istodoTable(false));
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
            dispatch(istodoTable(false));
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            dispatch(istodoTable(false));
            deleteTask();
            if (requestTaskId && requestTaskId != 0) {
              const previousId = requestTaskId;
              setRequestTaskId(0);
              deleteTask(previousId);
              handler();
            }
            setShowToast(false);
          }}
        />
      ) : (
        <></>
      )}
      {pageName === "provider-list" || pageName === "vdi-task" ? (
        <>
          <tr>
            {pageName === "vdi-task" ? 
            <td 
              className="title-fonts text-truncate text-start max-width75 cursor-pointer"
              onClick={() => {
                if (task.name && task.name != "") {
                  dispatch(commanoffcanvasisOpen(true));
                  getTask(task.id, task.is_requested > 0, task.provider);
                  handler(
                    task.rel_id,
                    task.id,
                    true,
                    null,
                    task.request_task_id
                  );
                }
              }}
            >
              #{task.id}
            </td> : <></>}
            <td className={`${task.name == "" ? "" : "pe-0"}`}>
              <a
                href="#/"
                className="name-with-progress  d-block"
                onClick={() => {
                  if (task.name) {
                    dispatch(commanoffcanvasisOpen(true));
                    getTask(task.id, task.is_requested > 0, task.provider);
                    handler(
                      task.rel_id,
                      task.id,
                      true,
                      null,
                      task.request_task_id
                    );
                  }
                }}
              >
                <div className="d-flex align-items-center">
                  <a className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                      style={{ backgroundImage: `url('${task.creator_profile_image}')` }}></div>
                  </a>
                  <a href="#/" className="name-with-progress w100minus100per d-block ps-10px"
                    onClick={() => {
                      if (task.name && task.name != "") {
                        dispatch(commanoffcanvasisOpen(true));
                        getTask(task.id, task.is_requested > 0, task.provider);
                        handler(
                          task.rel_id,
                          task.id,
                          true,
                          null,
                          task.request_task_id
                        );
                      }
                    }
                    }>
                    {task.name && task.name != "" ?
                      <div className="d-flex align-items-center ">
                        <div className=" w100minus70 text-start flex-grow-1 pe-20pximp">
                          <div className="d-flex flex-column">
                            <div className="title-fonts text-truncate text-start  fw-semibold">{pageName == "vdi-task" ? task.creator_name : task.name}</div>
                            {pageName == "vdi-task" ? <></> :
                            <div class="c-font color-white-60 f-10 lh-1 location me-2 fw-normal">
                              <span class=" p-0 ">
                                {moment(
                                  task.duedate
                                    ? task.duedate
                                    : task.startdate
                                      ? task.startdate
                                      : task.dateadded
                                ).format("DD/MM/YY HH:mm a")}
                              </span>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                      :
                      <>
                        <Form.Group className="col-xl-12 c-input-box position-relative">
                          <Form.Control
                            className="p-10"
                            placeholder={`${_l("l_task_name")}`}
                            type="text"
                            name="task_name"
                            value={todoTaskTitle}
                            onChange={(e) => {
                              setTodoTaskTitle(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              if (e.key === "Enter" && todoTaskTitle.trim() !== "") {
                                createTodo()
                              }
                            }}
                            onBlur={() => {
                              if (todoTaskTitle.trim() != "") {
                                createTodo()
                              }
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </>}
                  </a>
                </div>
              </a>
            </td>
            {pageName === "vdi-task" ?
            <>
            <td className="title-fonts text-truncate text-start max-width75">
              <div className="d-flex align-items-center">
                <div>{task.startdate && moment(task.startdate).format("DD/MM/YY hh:mm a")}</div>
              </div>
            </td>
            <td className="title-fonts text-truncate text-start max-width75">
              <div className="d-flex align-items-center">
                <div>{task.duedate && moment(task.duedate).format("DD/MM/YY hh:mm a")}</div>
              </div>
            </td>
            </>
            : <></>}
            
            <td className="comman_action_icon">
              <div class="d-flex align-items-center ">
                <div className="dropdown ">
                  <div className="white-border-box color-white-60 action_icon h32wauto calendarpadding with_bg dropdown-center ms-10px"
                    id="TaskListStatusDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <a href="#/" className="d-flex align-items-center dropdown dropdown-center">
                      <span
                        class={`c-dots c-7 rounded-circle ${pageName == "vdi-task" ? "" :  "me-2"} ${task.is_requested == 1
                          ? "bg-warning"
                          : task.status == 1
                            ? "in-progress-white"
                            : task.status == 4
                              ? "in-progress-blue"
                              : "done"}`}></span>
                      {pageName != "vdi-task" ? 
                      <span class="c-font f-12 text-truncate text-uppercase">
                        {_l(
                          task.is_requested == 1
                            ? "l_request"
                            : task.status == 1
                              ? "l_not_started"
                              : task.status == 4
                                ? "l_inprogress"
                                : "l_done"
                        )}
                      </span>
                      : <></>}
                      <TaskListStatusDropdown setTaskStatus={changeTaskStatus} is_task={1} />
                    </a>
                  </div>
                </div>
              </div>
            </td>
            {pageName === "vdi-task" ?
            <td>
              <div
                className={`dropdown-center multi_inner_wrapper with-min-width d-flex ps-0 pe-10px with_minus before-dnone ${globalView == "kanbanView" ? "mt-10" : ""
                  } ${taskAllAssignee && taskAllAssignee.length >= 1
                    ? ""
                    : "d-none"
                  }`}
              >
                {taskAllAssignee &&
                  taskAllAssignee.length >= 1 ? (
                  taskAllAssignee.map((assignee, index) => {
                    if (index < 1)
                      return (
                        <a
                          href="#/"
                          data-bs-toggle="tooltip"
                          className="comman-image-box multi-wrapper-border position-relative h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                          key={index}
                        >
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                            style={{
                              backgroundImage: `url('${assignee.user_image ||
                                assignee.profile_image
                                }')`,
                            }}
                          ></div>
                          {Array.isArray(task.task_priority_user) && assignee.id && task.task_priority_user.includes(assignee.id) ? 
                          <div className="absoluteiconbadge withbottomright badge-custom bg-transparent border-0 d-flex light-pill">
                            <HighPriorityActiveIcon className="HW14 check_span_img active" />
                          </div>
                          : <></> }
                        </a>
                      );
                  })
                ) : (
                  <></>
                )}
                <UncontrolledDropdown>
                  <DropdownToggle tag="div">
                    {taskAllAssignee &&
                      taskAllAssignee.length > 1 ? (
                      <a
                        href="#/"
                        className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={handleTaskAssigneeData}
                      >
                        <div className="unread_count m-auto c-font f-12">
                          +{taskAllAssignee.length - 1}
                        </div>
                      </a>
                    ) : (
                      <></>
                    )}
                    {taskAllAssignee && taskAllAssignee.length ? (
                      <GeneralListing
                        unControlList={true}
                        handler={() => { }}
                        list={
                          taskAllAssignee &&
                          taskAllAssignee.length &&
                          taskAllAssignee
                            .sort((a, b) => {
                              if (a.name < b.name) return -1;
                              if (a.name > b.name) return 1;
                            })
                            .map((item, index) => {
                              return {
                                name: item.full_name || item.name,
                                image:
                                  item.user_image ||
                                  item.profile_image,
                                id: item.id,
                              };
                            })
                        }
                        customSort={true}
                        task_priority_user={task.task_priority_user}
                      />
                    ) : (
                      <></>
                    )}
                  </DropdownToggle>
                </UncontrolledDropdown>
              </div>
            </td>
            : <></>}
            {pageName === "vdi-task" ?<td className="title-fonts text-truncate text-start max-width75">
              <div className="comman_action_icon card-view-less-padding p-0 me-10px d-flex">
                    <UncontrolledDropdown className="dropdown">
                      <DropdownToggle
                        tag="a"
                        className="justify-content-center align-items-center action_icon with_bg h32w32"
                        type="button"
                        id="SapceListMainDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="true"
                        onClick={() => { }}>
                        <div className={`on-hover-active-toggle-img text-on-hover-active-green ${selectedSpace > 0 ? "active" : ""}`}>
                          <MapPinIcon className="HW18" />
                        </div>
                      </DropdownToggle>
                      <DropdownItem className="p-0 no-hover" tag="div">
                        <SpaceListDropdown
                          openSpaceListDropdown={openSpaceListDropdown}
                          SpaceList={spaceState}
                          setOpenSpaceListDropdown={setOpenSpaceListDropdown}
                          handler={handleTaskSpace}
                          selectedSpace={selectedSpace}
                          isFromTaskCard={true}
                          setSelectedSpace={setSelectedSpace}
                          unContolList={true}
                        />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </div>
            </td>: <></>}
            {/* {pageName === "vdi-task" ?<td>item</td>: <></>}
            {pageName === "vdi-task" ?<td className="">
              <div className="d-flex align-items-center gap-1">
                <div className="h32w32 comman-round-box with-bg radius_3 w-100 with-react-fancybox">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex " style={{ backgroundImage: "url('https://images.unsplash.com/photo-1739361043380-f75ae825aee0?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}></div>
                </div>
                <div className="h32w32 comman-round-box with-bg radius_3 w-100 with-react-fancybox">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex " style={{ backgroundImage: "url('https://images.unsplash.com/photo-1739361043380-f75ae825aee0?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}></div>
                </div>
                <div className="h32w32 comman-round-box with-bg radius_3 w-100 with-react-fancybox">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex " style={{ backgroundImage: "url('https://images.unsplash.com/photo-1739361043380-f75ae825aee0?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}></div>
                </div>
                </div>
            </td>: <></>} */}
            <td className="p-2  pe-20pximp">
              {/* comment icon start */}
              { pageName === "vdi-task" ?
                
                <div
                className={`${isLiteVersion ? " " : ""
                  } dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
              >
                <UncontrolledDropdown className="w-100">
                  <DropdownToggle
                    data-toggle="dropdown"
                    tag="span"
                    className="comman_action_icon w-100 d-flex close-toast border-0"
                  >
                    <a
                      href="#/"
                      onClick={(e) => {
                        getMentionlist(task.id, localStorage.getItem("currentCompanyID"), "ProviderList");
                        getTaskComments();
                      }}
                      className="on-hover-active-toggle-img text-on-hover-active-green hovertextgreen"
                    >
                      <div className="d-flex align-items-center w-100">
                        <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                          <ChatCircleIcons className="HW18" />
                          <span className={`rounded-pill badge-custom p-0 ${unreadCommentCount.current == 0
                              ? "d-none"
                              : ""
                            }`}
                            data-unread-cmt-id={task.id}>
                            {unreadCommentCount.current}
                          </span>
                        </div>
                        <span className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle" data-cmt-cnt-id={task.id}>
                          {Number(updatedCommentCount) > 0
                            ? Number(updatedCommentCount)
                            : 0}
                        </span>
                      </div>
                    </a>
                  </DropdownToggle>
                  <DropdownItem toggle={false} className="p-0">
                    {
                      <CommentMinified
                        comments={commentsList}
                        disabled={
                          task &&
                            parseInt(task.request_task_id) > 0 &&
                            task.is_requested == 1
                            ? true
                            : false
                        }
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={task.id}
                        task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id =
                            name.is_team && name.is_team == 1
                              ? "-" + name.id
                              : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        handleImagePreview={handleImagePreview}
                        unContolList={true}
                        totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                      />
                    }
                  </DropdownItem>
                </UncontrolledDropdown>
              </div> 
              
              :
              <div className="d-flex  align-items-center justify-content-end ">
                <div className={`align-items-center d-flex  ${globalView == "kanbanView" ? "flex-wrap kanbanbottomspacing" : ""} ${commanoffcanvasIsOpen ? "with-abs-position-bg-taskcard tabledots" : ""}`}>
                  {showDefaultIcons.includes("eye") ? (
                    <div className="comman_action_icon card-view-less-padding p-0 me-2"></div>
                  ) : (
                    <></>
                  )}
                  {(pathname == "/documents" &&
                    task &&
                    task.provider == "google_drive") ||
                    (task && task.provider == "one_drive") ? (
                    <>
                      <div className="comman_action_icon with_separator_10 card-view-less-padding">
                        <div className="c-font f-12 fw-semibold text-nowrap ">
                          {task.size ? Math.floor(parseInt(task.size) / 1024) + " KB" : "No Size"}
                        </div>
                      </div>
                      <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                        <div className="comman_action_icon w-100 d-flex">
                          <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                            <a className="action_icon with_bg on-hover-active-toggle-img position-relative" onClick={() => { window.open(task.webViewLink, "_blank"); }}>
                              <EyeIcon className="HW18" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                        <a className="comman_action_icon w-100 d-flex" href={task.downloadUrl}>
                          {task.fileExtension !== "form" && (
                            <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                              <DownloadSimpleIcon className="HW18" />
                            </div>
                          )}
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* call icon start */}
                  {selectedTaskIdforIconToggle == task.id ? (
                    <UncontrolledDropdown
                      direction={"down"}
                      className="comman_action_icon me-10px d-none"
                    >
                      <DropdownToggle tag="div" className="">
                        <a
                          href="#/"
                          className="dropdown-item p-0"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                          data-popper-placement="left-start"
                        >
                          <div className="action_icon with_bg h32w32">
                            <PhoneCallIcon className="HW18"  />
                          </div>
                        </a>
                        <UncontrolledGeneralListing
                          list={taskUserAssignees.map((item, index) => {
                            return {
                              name: item.name,
                              image: item.profile_image,
                              id: index,
                              isOnline: 0,
                              userId: item.id,
                              email: item.email,
                              userType: item.user_type,
                            };
                          })}
                          makeCall={makeCall}
                          forCall={true}
                        />
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  ) : (
                    <></>
                  )}
                  {/* call icon end */}
                  {/* share Icon start */}
                  {selectedTaskIdforIconToggle == task.id ? (
                    <div
                      className="comman_action_icon card-view-less-padding p-0 me-0 dropdown"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                      data-popper-placement="left-start"
                    >
                      <a href="#/" className={` white-border-box action_icon with_bg h32w32`}
                        onClick={() => {
                          setTask_type(
                            task.is_requested && task.is_requested == 1
                              ? "request-task"
                              : "task"
                          );
                        }}
                      >
                        <ShareNetworkIcon size={14} className="c-icons with-fill" weight="light" />
                      </a>
                      <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_copy_link"),
                            actionHandler: () => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_MYR_WEB_URL
                                }/${task_type}/${task ? task.hash : ""}`
                              );
                              dispatch(
                                showMessage(
                                  "sucess",
                                  _l("l_success"),
                                  _l("l_link_copied")
                                )
                              );
                              HideDropdown();
                            },
                            actionIcon: "CopyIcon",
                          },
                          {
                            actionName: _l("l_send_to_email"),
                            actionHandler: () => {
                              setShowLink(true);
                              HideDropdown();
                            },
                            actionIcon: "EnvelopeSimpleIcon",
                          },
                          {
                            actionName: _l("l_open_link"),
                            actionHandler: () => {
                              window.open(
                                `${process.env.REACT_APP_MYR_WEB_URL
                                }/${task_type}/${task ? task.hash : ""}`
                              );
                              HideDropdown();
                            },
                            actionIcon: "LinkSimpleIcon",
                          },
                        ]}
                      />
                      <TaskShareLinkModal
                        show={showLink}
                        handleClose={handleCloseShareLink}
                        handleShow={handleShowShareLink}
                        link={`${process.env.REACT_APP_MYR_WEB_URL}/${task_type}/${task.hash}`}
                        data={{ id: task.id, contacts: contacts }}
                        docType={task_type}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* share Icon end */}
                  {/* task rating start */}
                  <>
                    {/* Task temporary implementation of average rating */}
                    {selectedTaskIdforIconToggle == task.id ? (
                      <div className="  with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                        <>
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              trigger="hover"
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover
                                  className="d-none"
                                  id={`popover-positioned-${placement}`}
                                >
                                  <Popover.Body className="w-250 p-0">
                                    <div className="c-font f-12 color-white-60 p-10 pb-0">
                                      header
                                    </div>
                                    <ul className="notification-listing">
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail c-font f-14 text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail c-font f-14 text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail c-font f-14 text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail c-font f-14 text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail c-font f-14 text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <a href="#/"
                                className={`comman_action_icon before-dnone d-flex align-items-center close-toast border-0 on-hover-active-toggle-img text-on-hover-active-green hovertextgreen ${task.average_rating && Number(task.average_rating) > 0 ? 'w_100px' : ''}`}>
                                <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                  {task.average_rating &&
                                    !isNaN(Number(task.average_rating)) &&
                                    Number(task.average_rating) ? (
                                    <StarIcon className="HW18 color-green" />
                                  ) : (
                                    <StarIcon className="HW18" />
                                  )}
                                </div>
                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1 title-fonts">
                                  {task.average_rating &&
                                    !isNaN(Number(task.average_rating))
                                    ? client_id == 1080
                                      ? `${Number(task.average_rating).toFixed(
                                        1
                                      )}`
                                      : Number(task.average_rating).toFixed(1)
                                    : 0}
                                </span>
                              </a>
                            </OverlayTrigger>
                          ))}
                        </>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                  {/* task rating end */}
                  {/* assignee strat */}
                  <div className="comman_action_icon card-view-less-padding p-0 me-10px d-flex">
                    <UncontrolledDropdown className="dropdown">
                      <DropdownToggle
                        tag="a"
                        className="justify-content-center align-items-center action_icon with_bg h32w32"
                        type="button"
                        id="SapceListMainDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="true"
                        onClick={() => { }}>
                        <div className={`on-hover-active-toggle-img text-on-hover-active-green ${selectedSpace > 0 ? "active" : ""}`}>
                          <MapPinIcon className="HW18" />
                        </div>
                      </DropdownToggle>
                      <DropdownItem className="p-0 no-hover" tag="div">
                        <SpaceListDropdown
                          openSpaceListDropdown={openSpaceListDropdown}
                          SpaceList={spaceState}
                          setOpenSpaceListDropdown={setOpenSpaceListDropdown}
                          handler={handleTaskSpace}
                          selectedSpace={selectedSpace}
                          isFromTaskCard={true}
                          setSelectedSpace={setSelectedSpace}
                          unContolList={true}
                        />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </div>
                  {/* assignee end}
                  {/* Date icon strat */}
                  <div className="comman_action_icon custom-datepicker w-330px withinputabsolute me-10px">
                    <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative">
                      <CalendarBlankIcon className="HW18" />
                      <DatePicker
                        portalId="custom_datepicker"
                        calendarClassName="custom-datepicker"
                        timeCaption={getDatePickerTimeCaption()}
                        selected={
                          new Date(
                            task.duedate
                              ? task.duedate
                              : task.startdate
                                ? task.startdate
                                : task.dateadded
                          )
                        }
                        calendarStartDay={1}
                        locale={getDatePickerLocale()}
                        className="close-toast"
                        onChange={(date) => {
                          if (showStartDate) {
                            setTaskStartDate(date);
                            handleUpdateDate(date);
                          } else {
                            setTaskDueDate(date);
                            handleUpdateDate("", date);
                          }
                        }}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy hh:mm a">
                        <div className="datepicker-label">{_l("l_select_date_and_time")}</div>
                      </DatePicker>
                    </div>
                  </div>
                  {/* Date icon end */}
                  {/* Delete Icon start */}
                  {selectedTaskIdforIconToggle == task.id && parseInt(task.is_allowed_to_delete) == 1 ? (
                    <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                      <a href="#/" className="priority-checkbox span-13 white-border-box action_icon with_bg h32w32 on-hover-active-toggle-img"
                        onClick={() => {
                          resetToast();
                          dispatch(IsToastOpenOrClose(true));
                          if (window.location.pathname == "/providerlist") {
                            dispatch(istodoTable(true));
                          }
                          let deleteConfirmationText =
                            task && task.request_task_id > 0
                              ? task.is_requested == 1
                                ? _l("l_and_converted_task")
                                : _l("l_and_request_task")
                              : "";
                          setToastHeader(_l("l_are_you_sure"));
                          setToastClass("toast-with-btn");
                          setToastMessage(
                            _l("l_you_want_tot_delete_this_task") +
                            deleteConfirmationText +
                            " ?"
                          );
                          setConfirmButtonText(_l("l_delete"));
                          setCancelButtonText(_l("l_cancel"));
                          setShowToast(true);
                          setRequestTaskId(
                            task &&
                              task.request_task_id &&
                              task.request_task_id != 0
                              ? task.request_task_id
                              : 0
                          );
                        }}
                      >
                        <TrashIcon className="HW18" />
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Delete Icon end */}
                  <div className="before-dnone comman_action_icon with_separator_10 px-0">
                    <a href="#/" className="action_icon with_bg h32w32 light-theme-img close-toast">
                      {isTaskPinned && isTaskPinned == true ? (
                        <div className="m-auto " onClick={() => { pinTask(); }}>
                          <PushPinFillIcon className="Push-Pin-fill HW18" />
                        </div>
                      ) : (
                        <div className="m-auto on-hover-active-toggle-img" onClick={() => { pinTask(); }}>
                          <PushPinIcon className="HW18" />
                        </div>
                      )}
                    </a>
                  </div>
                  {/* comment icon start */}
                  <div
                    className={`${isLiteVersion ? " " : ""
                      } dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                  >
                    <UncontrolledDropdown className="w-100">
                      <DropdownToggle
                        data-toggle="dropdown"
                        tag="span"
                        className="comman_action_icon w-100 d-flex close-toast border-0"
                      >
                        <a
                          href="#/"
                          onClick={(e) => {
                            getMentionlist(task.id, localStorage.getItem("currentCompanyID"), "ProviderList");
                            getTaskComments();
                          }}
                          className="on-hover-active-toggle-img text-on-hover-active-green hovertextgreen"
                        >
                          <div className="d-flex align-items-center w-100">
                            <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                              <ChatCircleIcons className="HW18" />
                              <span className={`rounded-pill badge-custom p-0 ${unreadCommentCount.current == 0
                                  ? "d-none"
                                  : ""
                                }`}
                                data-unread-cmt-id={task.id}>
                                {unreadCommentCount.current}
                              </span>
                            </div>
                            <span className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle" data-cmt-cnt-id={task.id}>
                              {Number(updatedCommentCount) > 0
                                ? Number(updatedCommentCount)
                                : 0}
                            </span>
                          </div>
                        </a>
                      </DropdownToggle>
                      <DropdownItem toggle={false} className="p-0">
                        {
                          <CommentMinified
                            comments={commentsList}
                            disabled={
                              task &&
                                parseInt(task.request_task_id) > 0 &&
                                task.is_requested == 1
                                ? true
                                : false
                            }
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={task.id}
                            task={task}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id =
                                name.is_team && name.is_team == 1
                                  ? "-" + name.id
                                  : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            handleImagePreview={handleImagePreview}
                            unContolList={true}
                            totalUnreadCommentCount={task.unread_comment_count}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                          />
                        }
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </div>
                  {/* comment icon end */}
                  <div className="comman_action_icon card-view-less-padding p-0 d-flex align-items-center justify-content-center me-10px">
                    <div className="priority-checkbox span-13 white-border-box action_icon with_bg h32w32 close-toast">
                      <input
                        type="checkbox"
                        id="priority"
                        className="absolute-input"
                        checked={task.priority == 3 ? true : false}
                        onChange={(e) => {
                          changeTaskPriority(e);
                        }}
                      />
                      <div className="check_box">
                        <HighPriorityActiveIcon className="HW18 check_span_img active" />
                        <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className={`comman_action_icon card-view-none me-10px  ${isTaskAssignmentDisabled() ? "for-disabled" : ""}`}>
                      <div className="d-flex align-items-center comman_action_icon">
                        <div className="dropup dropup-center">
                          <UncontrolledDropdown
                            setActiveFromChild={true}
                            isOpen={openAssigneeDropdown}
                            direction="end"
                            className="w-200">
                            <DropdownToggle
                              id={`AddAssigneeMainDropdownNew_${task.id}`}
                              className="border-0  p-0 d-flex align-items-center"
                              onClick={() =>
                                setOpenAssigneeDropdown(!openAssigneeDropdown)
                              }
                            >
                              <a
                                className={`action_icon with_bg activetextgreen on-hover-color-green on-hover-active-toggle-img text-on-hover-active-green light-theme-img h32wauto  color-white-80 calendarpadding d-flex align-items-center text-truncate 
                                ${task.assignee_list_DEV && task.assignee_list_DEV.length > 0 ? "active" : ""}`}
                                id={`AssigneeMainDropdownNew_${task.id}`}
                              >
                                <UserCircleGearIcon className="HW18" />
                                <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green"> {_l("l_assign_to")}</span>
                              </a>
                            </DropdownToggle>
                            <DropdownItem toggle={false} className="p-0">
                            {openAssigneeDropdown == true ? (
                              <AddAssigneeMainDropdownNew
                                key={task.id}
                                projectId={project_id}
                                taskId={task.id}
                                openAssigneeDropdown={openAssigneeDropdown}
                                setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                                fromTaskcard="1"
                                selectedTagId={selectedTagId}
                                pageName="task"
                                selectedId={selectedTaskAssigneesId}
                                isRequest={task.is_requested == 1}
                                saveBtnToggle={true}
                                selectedAssignee={selectedAssigneeNew}
                                setSelectedAssignee={setSelectedAssigneeNew}
                              />
                            ) : (
                              <></>
                            )}
                            </DropdownItem>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`comman_action_icon  ${globalView == "kanbanView"
                      ? "absoluteDotsTask"
                      : "d-flex align-items-center justify-content-center"
                    }`}
                >
                  <a href="#/" className={`action_icon h32w32 with_bg with-border-active on-hover-active-toggle-img`}
                    onClick={() => {
                      handleToggleIcons(
                        isTaskPinned,
                        taskAssignee,
                        taskAssigneePills,
                        taskPriority,
                        task.comments_count,
                        task.rating,
                        setTaskCardIconToggle,
                        taskCardIconToggle
                      );
                      if (selectedTaskIdforIconToggle == task.id) {
                        setSelectedTaskIdforIconToggle(0);
                      } else {
                        setSelectedTaskIdforIconToggle(task.id);
                      }
                    }}
                  >
                    <DotsNineIcon className="HW18" />
                  </a>
                </div>
              </div>
              }
            </td>
          </tr>
        </>
      ) : !taskHide && pageName != "ChatAi" ? (
        <div
          className={`task-list-card list-view ${selectedTaskIdforIconToggle == task.id ? "active" : ""
            } ${active ? "active" : ""} ${isLiteVersion ? "taskcardliteversion overflow-visible" : ""
            }`}
          id={`task_${task &&
            (typeof task.id == "string"
              ? task.id.replace(new RegExp("=", "g"), "")
              : task.id)
            }`}
        >
          {isLiteVersion ? (
            <div
              className={`text-center c-font color-white-80 f-10 p-2 liteversion-date-box comman-white-box base-body-bg min-width-60 fw-semibold d-none ${commanoffcanvasIsOpen ? "d-none" : ""
                }`}
            >
              {taskFilters.length > 0 ? (
                task.startdate ? (
                  <>
                    <div>
                      {moment(convertDateintoTimezone(task.startdate)).format(
                        "DD/MM/yy hh:mm a"
                      )}
                    </div>
                    <div>
                      {moment(convertDateintoTimezone(task.startdate)).format(
                        "  hh:mm a"
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {moment(convertDateintoTimezone(task.dateadded)).format(
                        "DD/MM/yy"
                      )}
                    </div>
                    <div>
                      {moment(convertDateintoTimezone(task.dateadded)).format(
                        "hh:mm a"
                      )}
                    </div>
                  </>
                )
              ) : task.startdate ? (
                moment(convertDateintoTimezone(task.startdate)).format(
                  "hh:mm a"
                )
              ) : (
                moment(convertDateintoTimezone(task.dateadded)).format(
                  "hh:mm a"
                )
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="task-list-wrapper">
            <div className="d-flex w-100 align-items-start">
              <div className="d-flex task-list-detail">
                <div
                  className={`task-left flex-grow-1 width100minus160 ${globalView == "kanbanView" ? "w-100" : ""
                    }`}
                >
                  <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                    {(pathname == "/documents" &&
                      task &&
                      task.provider == "google_drive") ||
                      (task && task.provider == "one_drive") || isFrom == "homePage" ? (
                      <></>
                    ) : (
                      <div className="task-creator-img">
                        <div
                          className={`h20w20 d-flex align-items-center me-10px ${globalView == "kanbanView" ? "mb-10" : ""
                            }`}
                        >
                          <Form.Check
                            type="checkbox"
                            className="m-auto"
                            checked={
                              checkedTask && checkedTask.length
                                ? checkedTask.filter((id) => task.id == id)
                                  .length
                                  ? true
                                  : false
                                : false
                            }
                            onChange={(e) => {
                              taskCheckUncheck(task.id);
                            }}
                            disabled={task.is_allowed_to_edit != 1}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      id={`task-${task.id}`}
                      className={`task-top-part text-truncate flex-grow-1 d-flex ${task.request_task_id == 0
                          ? "main-calc-width-task"
                          : "main-calc-width"
                        }`}
                      onClick={() => {
                        if (driveDocument) {
                          handler();
                          return;
                        }
                        handleTaskCountsforAlltab(task, leftPanelTab, false)
                        setShowToast(false);
                        dispatch(toastCloseAction());
                        handler(
                          task.id,
                          true,
                          is_requested,
                          task.request_task_id
                        );
                        getTask(task.id, is_requested, task.provider);
                        LeftPanelBaseOnScreenSize();
                        LeftPanelResponsiveToggle();
                        setCallUserList(taskUserAssignees);
                        setSelectedTaskIdforIconToggle(0);
                      }}
                    >
                      <div
                        className={`${isLiteVersion ? "flex-wrap" : ""
                          } d-flex  w-100  `}
                      >
                        <div className="task-creator-img h32w32  rounded-5">
                          <div
                            id="deLeteDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            className={`comman-image-box h-100 w-100 rounded-circle m-auto position-relative  ${task.schedule_id > 0 ? "" : ""
                              }`}
                          >
                            {Array.isArray(task.task_priority_user) && task.addedfrom_staff_id && task.task_priority_user.includes(task.addedfrom_staff_id) ? 
                            <div className="absoluteiconbadge withbottomright badge-custom bg-transparent border-0 d-flex light-pill">
                              <HighPriorityActiveIcon className="HW14 check_span_img active" />
                            </div>
                            : <></> }
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${task.creator_profile_image}')`,
                              }}
                            ></div>
                            {task.addedfrom > 0 && !isTaskOwner ? (
                              <DeleteDropdown
                                docType={task_type}
                                pageName="task"
                                widthClass="w-140"
                                allowToCall={true}
                                makeCall={makeCall}
                                allowToDelete={false}
                                taskUserAssignees={[
                                  {
                                    name: task.creator_name,
                                    profile_image: task.creator_profile_image,
                                    id: task.addedfrom,
                                    email: task.creator_email,
                                    user_type:
                                      task.is_added_from_contact == "1"
                                        ? "contact"
                                        : "staff",
                                  },
                                ]}
                                callListPos={"right"}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {isLiteVersion ? <></> : <></>}
                        <div className="d-flex flex-column w100minus100per task-list-name text-truncate ms-10px">
                          {["left"].map((placement) => (
                            <div
                              className={`text-truncate ${isLiteVersion ? "w-100 mb-1" : ""
                                }`}
                            >
                              {task.name}
                              {task.fileExtension ? (
                                <span>
                                  {" "}
                                  [{task.fileExtension.toUpperCase()}]
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          ))}
                          {/* show space address */}
                          <div className="d-flex align-items-center">
                            {isLiteVersion ? (
                              task.schedule_id > 0 ||
                                task.rel_type == "equipment" ||
                                task.is_requested == 1 ? (
                                <div className="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                  <span className={` p-0 `}>
                                    {taskStartDate
                                      ? moment(taskStartDate).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )
                                      : _l("l_no_due_date")}
                                  </span>
                                </div>
                              ) : (
                                <div className="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                  <span className={` p-0 `}>
                                    {taskDueDate
                                      ? moment(taskDueDate).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )
                                      : _l("l_no_due_date")}
                                  </span>
                                </div>
                              )
                            ) : (
                              <>
                                <div className="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                  <span className={` p-0 `}>{_l("l_no_due_date")}</span>
                                </div>
                              </>
                            )}
                            {task.is_requested == "1" ? (
                              <>
                                {isLiteVersion ? (
                                  task.converted_date != undefined &&
                                    task.converted_date != null ? (
                                    reqTimePill
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : task.schedule_id > 0 ? (
                              <>{scheduleTimePill}</>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {/* show space address */}
                      </div>
                    </div>
                    <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon justify-content-end">
                      <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                        {task.provider == "google_drive" &&
                          task.is_synced !== "undefined" &&
                          task.shared === true ? (
                          <div key={"shared"} className="color-white-60 c-font f-12 pe-2 text-nowrap "># {_l("l_shared_to_me")}</div>
                        ) : (
                          <></>
                        )}
                        {task.provider == "google_drive" &&
                          task.is_synced !== "undefined" ? (
                          task.parents.map((parent, _index) => {
                            return (
                              <div key={_index} className="color-white-60 c-font f-12 pe-2 text-nowrap "># {parent.name}</div>
                            );
                          })
                        ) : task.provider == "one_drive" &&
                          task.is_synced !== "undefined" ? (
                          <div key={0} className="color-white-60 c-font f-12 pe-2 text-nowrap "># {task.parent}</div>
                        ) : (
                          <></>
                        )}
                        <div className="title-fonts text-truncate pill-bottom-show color-white-60 c-font f-12 pe-2 d-none">{_l("l_requested")}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`d-flex align-items-center task-bottom-part-full `}>
                  <div className="task-right-static-options">
                    <div className="d-flex align-items-center">
                      <div className={`d-flex align-items-center multi_inner_wrapper justify-content-end w-120 w-auto `}>
                        <>
                          {task.schedule_id > 0 &&
                            taskProviderTitle &&
                            taskProviderTitle != "" ? (
                            <div className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px">
                              <span className={` p-0 `}>
                                {"#"}
                                {taskProviderTitle}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                      {/* ai created task badge start */}
                      {task.parent_id > 0 ?
                        <div className="badge rounded-pill text-nowrap list-toggle-view-none me-2"><span className=" p-0 ">{_l("l_ai_task")}</span></div> : <></>}
                      {/* ai created task badge end */}
                      <div
                        className={`align-items-center d-flex with-abs-position-bg-taskcard ${globalView == "kanbanView"
                            ? "flex-wrap kanbanbottomspacing"
                            : ""
                          } ${commanoffcanvasIsOpen
                            ? "with-abs-position-bg-taskcard"
                            : ""
                          }`}
                      >
                        {showDefaultIcons.includes("eye") ? (
                          <div className="comman_action_icon card-view-less-padding p-0 me-2"></div>
                        ) : (
                          <></>
                        )}
                        {(pathname == "/documents" &&
                          task &&
                          task.provider == "google_drive") ||
                          (task && task.provider == "one_drive") ? (
                          <>
                            <div className="comman_action_icon with_separator_10 card-view-less-padding">
                              <div className="c-font f-12 fw-semibold text-nowrap ">
                                {task.size
                                  ? Math.floor(parseInt(task.size) / 1024) +
                                  " KB"
                                  : "No Size"}
                              </div>
                            </div>
                            <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                              <div className="comman_action_icon w-100 d-flex">
                                <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                  <a>
                                    <div
                                      className="action_icon with_bg on-hover-active-toggle-img position-relative"
                                      onClick={() => {
                                        window.open(task.webViewLink, "_blank");
                                      }}
                                    >
                                      <EyeIcon className="HW18" />
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                              <a
                                className="comman_action_icon w-100 d-flex"
                                href={task.downloadUrl}
                              >
                                {task.fileExtension !== "form" && (
                                  <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                    <DownloadSimpleIcon className="HW18" />
                                  </div>
                                )}
                              </a>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {
                        Number(task.unread_checklist_comment_count) > 0 &&
                        <div className="comman_action_icon me-10px"> 
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                    <ListBulletsIcon className="HW18" />
                                    <span class="rounded-pill badge-custom p-0 " >{Number(task.unread_checklist_comment_count) || 0}</span>
                          </div>
                          </div>
                        }
                        {/* status  start*/}
                         <div
                          className={` dropdown dropdown-center comman_action_icon card-view-less-padding p-0 me-10px
                            ${task &&
                              !(
                                userType == "operator" && task.schedule_id > 0
                              ) &&
                              ((parseInt(task.is_allowed_to_edit) > 0 &&
                                task.is_requested == 0) ||
                                (parseInt(task.request_task_id) == 0 &&
                                  task.is_requested == 1))
                              ? ""
                              : "for-disabled"
                            }
                          `}
                        >
                          <div
                            className={`white-border-box me-0 color-white-60 action_icon with_bg d-flex close-toast ${isFrom == "homePage" ? 'h32wauto px-2' : 'h32w32'}`}
                            type="button"
                            id="TaskListStatusDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="d-flex align-items-center m-auto">
                              <span
                                className={`c-dots c-10 ${task.is_requested == 1
                                    ? "bg-warning"
                                    : task.status == 1
                                      ? "in-progress-white"
                                      : task.status == 4
                                        ? "in-progress-blue"
                                        : "done"
                                  } rounded-circle`}
                              ></span>
                              <span className={`c-font f-12 text-uppercase  ${isFrom == "homePage" ? 'ps-10px' : 'd-none'} ${task.is_requested == 1
                                ? "color-orange"
                                : task.status == 1
                                  ? "color-white-60"
                                  : task.status == 4
                                    ? "in-progress-blue"
                                    : "color-green"
                                }`}>
                                {task.is_requested == 1
                                  ? _l("l_request")
                                  : task.status == 1
                                    ? _l("l_todo_kpi_name")
                                    : task.status == 4
                                      ? _l("l_ongoing_kpi")
                                      : _l("l_completed")
                                }
                              </span>
                              {(task &&
                                parseInt(task.is_allowed_to_edit) > 0 &&
                                task.is_requested == 0) ||
                                (task &&
                                  parseInt(task.request_task_id) == 0 &&
                                  task.is_requested == 1) ? (
                                <TaskListStatusDropdown
                                  setTaskStatus={changeTaskStatus}
                                  is_task={1}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* staus end */}
                        {/* share Icon start */}
                        {selectedTaskIdforIconToggle == task.id &&
                          showDefaultIcons.includes("share") ? (
                          <div
                            className="comman_action_icon card-view-less-padding p-0 me-10px dropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            data-popper-placement="left-start"
                          >
                            <a href="#/" className={` white-border-box action_icon with_bg h32w32 on-hover-active-toggle-img`}
                              onClick={() => {
                                setTask_type(
                                  task.is_requested && task.is_requested == 1
                                    ? "request-task"
                                    : "task"
                                );
                              }}
                            >
                              <ShareNetworkIcon size={14} className="c-icons with-fill" weight="light" />
                            </a>
                            <ActionDropdown
                              actions={[
                                {
                                  actionName: _l("l_copy_link"),
                                  actionHandler: () => {
                                    navigator.clipboard.writeText(
                                      `${process.env.REACT_APP_MYR_WEB_URL
                                      }/${task_type}/${task ? task.hash : ""}`
                                    );
                                    dispatch(
                                      showMessage(
                                        "sucess",
                                        _l("l_success"),
                                        _l("l_link_copied")
                                      )
                                    );
                                    HideDropdown();
                                  },
                                  actionIcon: "CopyIcon",
                                },
                                {
                                  actionName: _l("l_send_to_email"),
                                  actionHandler: () => {
                                    setShowLink(true);
                                    HideDropdown();
                                  },
                                  actionIcon: "EnvelopeSimpleIcon",
                                },
                                {
                                  actionName: _l("l_open_link"),
                                  actionHandler: () => {
                                    window.open(
                                      `${process.env.REACT_APP_MYR_WEB_URL
                                      }/${task_type}/${task ? task.hash : ""}`
                                    );
                                    HideDropdown();
                                  },
                                  actionIcon: "LinkSimpleIcon",
                                },
                              ]}
                            />
                            <TaskShareLinkModal
                              show={showLink}
                              handleClose={handleCloseShareLink}
                              handleShow={handleShowShareLink}
                              link={`${process.env.REACT_APP_MYR_WEB_URL}/${task_type}/${task.hash}`}
                              data={{ id: task.id, contacts: contacts }}
                              docType={task_type}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* share Icon end */}
                        {/* task rating start */}
                        <>
                          {/* Task temporary implementation of average rating */}
                          {(showDefaultIcons.includes("star") &&
                            selectedTaskIdforIconToggle == task.id) ||
                            (task.average_rating &&
                              !isNaN(Number(task.average_rating)) &&
                              Number(task.average_rating)) ? (
                            <div className="  with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                              <>
                                {["bottom"].map((placement) => (
                                  <OverlayTrigger
                                    trigger="hover"
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Popover
                                        className="d-none"
                                        id={`popover-positioned-${placement}`}
                                      >
                                        <Popover.Body className="w-250 p-0">
                                          <div className="c-font f-12 color-white-60 p-10 pb-0">
                                            header
                                          </div>
                                          <ul className="notification-listing">
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail c-font f-14 text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail c-font f-14 text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail c-font f-14 text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail c-font f-14 text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail c-font f-14 text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a
                                      href="#/"
                                      className="comman_action_icon d-flex align-items-center close-toast border-0 on-hover-active-toggle-img text-on-hover-active-green hovertextgreen"
                                    >
                                      <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                        {task.average_rating &&
                                          !isNaN(Number(task.average_rating)) &&
                                          Number(task.average_rating) ? (
                                          <StarIcon className="HW18 color-green" />
                                        ) : (
                                          <StarIcon className="HW18" />
                                        )}
                                      </div>
                                      {task.average_rating &&
                                        <span className="c-font f-12 d-flex justify-content-center flex-grow-1 title-fonts ps-0 ms-10px">
                                          {task.average_rating &&
                                            !isNaN(Number(task.average_rating))
                                            ? client_id == 1080
                                              ? `${Number(
                                                task.average_rating
                                              ).toFixed(1)}`
                                              : Number(
                                                task.average_rating
                                              ).toFixed(1)
                                            : 0}
                                        </span>}
                                    </a>
                                  </OverlayTrigger>
                                ))}
                              </>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                        {/* task rating end */}
                        {/* cube start */}
                        {task.rel_type == "equipment" &&
                          showDefaultIcons.includes("cube") &&
                          selectedTaskIdforIconToggle == task.id ? (
                          <>
                            {task.surface > 0 ? (
                              <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1">
                                <span className={` p-0 `}> {task.surface}m<sup>2</sup></span>
                              </div>
                            ) : (
                              <></>
                            )}
                            {task.duration > 0 ? (
                              <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1">
                                <span className={` p-0`}>{task.duration}hrs</span>
                              </div>
                            ) : (
                              <></>
                            )}
                            <a
                              href="#/"
                              className="me-10px comman_action_icon position-relative"
                              onClick={() => {
                                if (task.map_attachment_url == "") {
                                  setShowMapModal(true);
                                } else {
                                  setShowMapInfoModal(true);
                                }
                              }}
                            >
                              <div className={`action_icon with_bg h32w32 on-hover-active-toggle-img`}>
                                <CubeIcon className="HW18" />
                              </div>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                        {/* cube end */}
                        {/* map icon start */}
                        {isLiteVersion ? (
                          (selectedSpace && selectedSpace > 0 && leftPanelTab != "my-space") || 
                          (selectedTaskIdforIconToggle == task.id &&
                            showDefaultIcons.includes("map")) ||
                            window.location.pathname == "/dashboard" ? (
                            <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                              <UncontrolledDropdown className="dropdown">
                                <DropdownToggle
                                  tag="a"
                                  className="action_icon with_bg h32w32 justify-content-center align-items-center"
                                  type="button"
                                  id="SapceListMainDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-bs-auto-close="true"
                                  onClick={() => {
                                    }}
                                >
                                  <div
                                    className={`on-hover-active-toggle-img text-on-hover-active-green ${selectedSpace > 0 ? "active" : ""
                                      }`}
                                  >
                                    <MapPinIcon className="HW18" />
                                  </div>
                                </DropdownToggle>
                                <DropdownItem
                                  className="p-0 no-hover"
                                  tag="div"
                                >
                                  <SpaceListDropdown
                                    openSpaceListDropdown={
                                      openSpaceListDropdown
                                    }
                                    SpaceList={spaceState}
                                    setOpenSpaceListDropdown={
                                      setOpenSpaceListDropdown
                                    }
                                    handler={handleTaskSpace}
                                    selectedSpace={selectedSpace}
                                    isFromTaskCard={true}
                                    setSelectedSpace={setSelectedSpace}
                                    unContolList={true}
                                  />
                                </DropdownItem>
                              </UncontrolledDropdown>
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        {/* map icon end */}
                        {/* HighPriorityIcon start */}
                        {taskPriority == 3 ||
                          (selectedTaskIdforIconToggle == task.id &&
                            showDefaultIcons.includes("priority")) ? (
                          <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                            <div
                              className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32 close-toast`}
                            >
                              <input
                                type="checkbox"
                                id="priority"
                                className="absolute-input"
                                checked={taskPriority == 3 ? true : false}
                                onChange={(e) => {
                                  changeTaskPriority(e);
                                }}
                                disabled={
                                  task && parseInt(task.request_task_id) > 0
                                }
                              />
                              <div className="check_box">
                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* HighPriorityIcon end */}
                        {/* push pin icon start  */}
                        {isTaskPinned == true ||
                          (selectedTaskIdforIconToggle == task.id &&
                            showDefaultIcons.includes("pin")) ? (
                          <div className="before-dnone comman_action_icon with_separator_10">
                            <a
                              href="#/"
                              className="action_icon with_bg h32w32 light-theme-img close-toast on-hover-active-toggle-img"
                            >
                              {isTaskPinned && isTaskPinned == true ? (
                                <div
                                  className="m-auto "
                                  onClick={() => {
                                    pinTask();
                                  }}
                                >
                                  <PushPinFillIcon className="Push-Pin-fill HW18" />
                                </div>
                              ) : (
                                <div
                                  className="m-auto"
                                  onClick={() => {
                                    pinTask();
                                  }}
                                >
                                  <PushPinIcon className="HW18" />
                                </div>
                              )}
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* push pin icon end  */}
                        {/* comment icon start */}
                        {Number(updatedCommentCount) > 0 ||
                          (selectedTaskIdforIconToggle == task.id &&
                            showDefaultIcons.includes("comment")) ? (
                          <div
                            className={`${isLiteVersion ? " " : ""
                              } dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                          >
                            <UncontrolledDropdown className="w-100">
                              <DropdownToggle
                                data-toggle="dropdown"
                                tag="span"
                                className="comman_action_icon w-100 d-flex close-toast border-0"
                              >
                                <a
                                  href="#/"
                                  onClick={(e) => {
                                    handleTaskCountsforAlltab(task, leftPanelTab, false)
                                    getMentionlist(task.id);
                                    getTaskComments();
                                  }}
                                  className="on-hover-active-toggle-img text-on-hover-active-green hovertextgreen"
                                >
                                  <div className="d-flex align-items-center w-100">
                                    <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                      <ChatCircleIcons className="HW18" />
                                      <span
                                        className={`rounded-pill badge-custom p-0 ${unreadCommentCount.current == 0
                                            ? "d-none"
                                            : ""
                                          }`}
                                        data-unread-cmt-id={task.id}
                                      >
                                        {unreadCommentCount.current}
                                      </span>
                                    </div>
                                    <span
                                      className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                      data-cmt-cnt-id={task.id}
                                    >
                                      {Number(updatedCommentCount) > 0
                                        ? Number(updatedCommentCount)
                                        : 0}
                                    </span>
                                  </div>
                                </a>
                              </DropdownToggle>
                              <DropdownItem toggle={false} className="p-0">
                                {
                                  <CommentMinified
                                    comments={commentsList}
                                    disabled={
                                      task &&
                                        parseInt(task.request_task_id) > 0 &&
                                        task.is_requested == 1
                                        ? true
                                        : false
                                    }
                                    handleAddComment={handleAddEditComment}
                                    deleteComment={handleDeleteComment}
                                    id={task.id}
                                    task={task}
                                    loading={commentsLoading}
                                    mentionList={mentionsList.map((name) => {
                                      let id =
                                        name.is_team && name.is_team == 1
                                          ? "-" + name.id
                                          : name.id;
                                      return {
                                        display: name.name,
                                        id: id,
                                        image: name.profile_image || "",
                                        isteam: name.is_team ? 1 : 0,
                                      };
                                    })}
                                    handleImagePreview={handleImagePreview}
                                    unContolList={true}
                                    totalUnreadCommentCount={
                                      task.unread_comment_count
                                    }
                                    showComments={showComments}
                                    setReplyCommentData={setReplyCommentData}
                                    fileLoader={fileUploadLoader}
                                    isLoading = {isChatSkeleton}
                                  />
                                }
                              </DropdownItem>
                            </UncontrolledDropdown>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Delete Icon start */}
                        {selectedTaskIdforIconToggle == task.id &&
                          showDefaultIcons.includes("delete") &&
                          parseInt(task.is_allowed_to_delete) == 1 ? (
                          <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                            <a
                              href="#/"
                              className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32 on-hover-active-toggle-img`}
                              onClick={() => {
                                resetToast();
                                dispatch(IsToastOpenOrClose(true));
                                let deleteConfirmationText =
                                  task && task.request_task_id > 0
                                    ? task.is_requested == 1
                                      ? _l("l_and_converted_task")
                                      : _l("l_and_request_task")
                                    : "";
                                setToastHeader(_l("l_are_you_sure"));
                                setToastClass("toast-with-btn");
                                setToastMessage(
                                  _l("l_you_want_tot_delete_this_task") +
                                  deleteConfirmationText +
                                  " ?"
                                );
                                setConfirmButtonText(_l("l_delete"));
                                setCancelButtonText(_l("l_cancel"));
                                setShowToast(true);
                                setRequestTaskId(
                                  task &&
                                    task.request_task_id &&
                                    task.request_task_id != 0
                                    ? task.request_task_id
                                    : 0
                                );
                              }}
                            >
                              <TrashIcon className="HW18" />
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Delete Icon end */}
                        {/* task assignees start */}
                        {taskAssigneePills.length > 0 ||
                          taskAllAssignee.length > 0 ? (
                          <div
                            className={`position-relative d-flex assignee-list-wrapper card-view-less-padding ${isLiteVersion ? "" : "px-2"
                              }`}
                          >
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus  ${globalView == "kanbanView" ? "mt-10" : ""
                                }  ${taskAssigneePills &&
                                  taskAssigneePills.length >= 1
                                  ? ""
                                  : "d-none"
                                }`}
                            >
                              {taskAssigneePills &&
                                taskAssigneePills.length >= 1 ? (
                                taskAssigneePills.map((assignee, index) => {
                                  if (index < 1)
                                    return (
                                      <div className="pe-10px">
                                        <div
                                          className="badge rounded-pill text-nowrap list-toggle-view-none"
                                          key={index}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <span className={` p-0 `}>
                                            {assignee.main_key == "my_task"
                                              ? "#"
                                              : ""}
                                            {_l(assignee.name)}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                })
                              ) : (
                                <></>
                              )}
                              {taskAssigneePills &&
                                taskAssigneePills.length > 1 ? (
                                <div className="pe-10px">
                                  <a
                                    href="#/"
                                    className="badge rounded-pill text-nowrap list-toggle-view-none"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={handleTaskAssigneeData}
                                  >
                                    <div className="unread_count m-auto c-font f-10">
                                      +{taskAssigneePills.length - 1}
                                    </div>
                                  </a>
                                  <GeneralListing
                                    handler={() => { }}
                                    list={
                                      taskAssigneePills &&
                                      taskAssigneePills.length &&
                                      taskAssigneePills.map((item, index) => {
                                        return {
                                          name: item.name,
                                          id: index,
                                        };
                                      })
                                    }
                                    isHash={true}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width d-flex ps-0 pe-10px with_minus before-dnone ${globalView == "kanbanView" ? "mt-10" : ""
                                } ${taskAllAssignee && taskAllAssignee.length >= 1
                                  ? ""
                                  : "d-none"
                                }`}
                            >
                              {taskAllAssignee &&
                                taskAllAssignee.length >= 1 ? (
                                taskAllAssignee.map((assignee, index) => {
                                  if (index < 1)
                                    return (
                                      <a
                                        href="#/"
                                        data-bs-toggle="tooltip"
                                        className="comman-image-box multi-wrapper-border position-relative h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                        key={index}
                                      >
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url('${assignee.user_image ||
                                              assignee.profile_image
                                              }')`,
                                          }}
                                        ></div>
                                        {Array.isArray(task.task_priority_user) && assignee.id && task.task_priority_user.includes(assignee.id) ? 
                                        <div className="absoluteiconbadge withbottomright badge-custom bg-transparent border-0 d-flex light-pill">
                                          <HighPriorityActiveIcon className="HW14 check_span_img active" />
                                        </div>
                                        : <></> }
                                      </a>
                                    );
                                })
                              ) : (
                                <></>
                              )}
                              <UncontrolledDropdown>
                                <DropdownToggle tag="div">
                                  {taskAllAssignee &&
                                    taskAllAssignee.length > 1 ? (
                                    <a
                                      href="#/"
                                      className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={handleTaskAssigneeData}
                                    >
                                      <div className="unread_count m-auto c-font f-12">
                                        +{taskAllAssignee.length - 1}
                                      </div>
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                  {taskAllAssignee && taskAllAssignee.length ? (
                                    <GeneralListing
                                      unControlList={true}
                                      handler={() => { }}
                                      list={
                                        taskAllAssignee &&
                                        taskAllAssignee.length &&
                                        taskAllAssignee
                                          .sort((a, b) => {
                                            if (a.name < b.name) return -1;
                                            if (a.name > b.name) return 1;
                                          })
                                          .map((item, index) => {
                                            return {
                                              name: item.full_name || item.name,
                                              image:
                                                item.user_image ||
                                                item.profile_image,
                                              id: item.id,
                                            };
                                          })
                                      }
                                      customSort={true}
                                      task_priority_user={task.task_priority_user}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </DropdownToggle>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* task assignees end */}
                        {/* separator Start */}
                        {globalView == "kanbanView" ? (
                          <></>
                        ) : (
                          <div
                            className={`px-0 ${showDefaultIcons.length > 0 ||
                                selectedTaskIdforIconToggle == task.id
                                ? "with_separator2_10"
                                : ""
                              } me-10px`}
                          ></div>
                        )}
                        {/* separator end */}
                        {/* calendar start */}
                        {((leftPanelTab == "homepage" && selectedTaskIdforIconToggle == task.id) || leftPanelTab != "homepage") ? <div
                          className="comman_action_icon custom-datepicker w-330px withinputabsolute me-10px"
                          data-bs-toggle="tooltip"
                        >
                          <div
                            className={`action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative `}
                          >
                            <CalendarBlankIcon className="HW18" />
                            <DatePicker
                              portalId="custom_datepicker"
                              calendarClassName="custom-datepicker"
                              timeCaption={getDatePickerTimeCaption()}
                              selected={
                                task.schedule_id > 0 ||
                                  task.rel_type == "equipment" ||
                                  task.is_requested == 1
                                  ? taskStartDate
                                  : taskDueDate
                              }
                              calendarStartDay={1}
                              locale={getDatePickerLocale()}
                              className="close-toast"
                              placeholderText={`${task.schedule_id > 0 ||
                                  task.rel_type == "equipment" ||
                                  task.is_requested == 1
                                  ? _l("l_startdate")
                                  : _l("l_duedate")
                                }`}
                              onChange={(date) => {
                                if (showStartDate) {
                                  setTaskStartDate(date);
                                  handleUpdateDate(date);
                                } else {
                                  setTaskDueDate(date);
                                  handleUpdateDate("", date);
                                }
                              }}
                              showTimeSelect
                              dateFormat="dd/MM/yyyy hh:mm a"
                              readOnly={
                                task.is_requested === "1"
                                  ? true
                                  : task.is_allowed_to_edit == 1
                                    ? false
                                    : true
                              }
                            >
                              <div className="datepicker-label"> {_l("l_select_date_and_time")}</div>
                            </DatePicker>
                          </div>
                        </div> : <></>}
                        {/* calendar end */}
                        {/* assigne to btn start */}
                        <div
                          className={`comman_action_icon card-view-none me-10px  ${isTaskAssignmentDisabled() ? "for-disabled" : ""
                            }`}
                        >
                          <div className="d-flex align-items-center">
                            <div className="dropup dropup-center">
                              <UncontrolledDropdown
                                setActiveFromChild={true}
                                isOpen={openAssigneeDropdown}
                                direction="end"
                                className="w-200"
                              >
                                <DropdownToggle
                                  id={`AddAssigneeMainDropdownNew_${task.id}`}
                                  className="border-0  p-0 d-flex align-items-center"
                                  onClick={() =>
                                    setOpenAssigneeDropdown(
                                      !openAssigneeDropdown
                                    )
                                  }
                                >
                                  <div
                                    className="action_icon with_bg on-hover-active-toggle-img text-on-hover-active-green light-theme-img color-white-80 d-flex align-items-center text-truncate h32w32"
                                    id={`AssigneeMainDropdownNew_${task.id}`}
                                  >
                                    <UserCircleGearIcon className="HW18" />
                                  </div>
                                </DropdownToggle>
                                <DropdownItem toggle={false} className="p-0">
                                {openAssigneeDropdown == true ? (
                                  <AddAssigneeMainDropdownNew
                                    key={task.id}
                                    projectId={project_id}
                                    taskId={task.id}
                                    openAssigneeDropdown={openAssigneeDropdown}
                                    setOpenAssigneeDropdown={
                                      setOpenAssigneeDropdown
                                    }
                                    fromTaskcard="1"
                                    selectedTagId={selectedTagId}
                                    pageName="task"
                                    selectedId={selectedTaskAssigneesId}
                                    isRequest={task.is_requested == 1}
                                    saveBtnToggle={true}
                                    selectedAssignee={selectedAssigneeNew}
                                    setSelectedAssignee={setSelectedAssigneeNew}
                                  />
                                ) : (
                                  <></>
                                )}
                                </DropdownItem>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                        {/* assigne to btn end */}
                        <div
                          className={`comman_action_icon  ${globalView == "kanbanView" ? "absoluteDotsTask" : ""
                            }`}
                        >
                          <a
                            href="#/"
                            className={`action_icon h32w32 with_bg with-border-active on-hover-active-toggle-img`}
                            onClick={() => {
                              handleToggleIcons(
                                isTaskPinned,
                                taskAssignee,
                                taskAssigneePills,
                                taskPriority,
                                task.comments_count,
                                task.rating,
                                setTaskCardIconToggle,
                                taskCardIconToggle
                              );
                              if (selectedTaskIdforIconToggle == task.id) {
                                setSelectedTaskIdforIconToggle(0);
                              } else {
                                setSelectedTaskIdforIconToggle(task.id);
                              }
                            }}
                          >
                            <DotsNineIcon className="HW18" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isLiteVersion ? (
                  <></>
                ) : (
                  <div className={`dropstart h32w32 `}>
                     <a
                      href="#/"
                      type="button"
                      className={`line-height-0 light-theme-img`}
                      id="deLeteDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      onClick={() => {
                        dispatch(IsToastOpenOrClose(false));
                        setTask_type(
                          task.is_requested && task.is_requested == 1
                            ? "request-task"
                            : "task"
                        );
                      }}
                    >
                      <DotsThreeVerticalIcon className="HW18" />
                    </a>
                      <DeleteDropdown
                      edit={false}
                      widthClass="w-140"
                        handleDelete={() => {
                        resetToast();
                        dispatch(IsToastOpenOrClose(true));
                        setShowToast(false);
                        let deleteConfirmationText =
                          task && task.request_task_id > 0
                            ? task.is_requested == 1
                              ? _l("l_and_converted_task")
                              : _l("l_and_request_task")
                            : "";
                        setToastHeader(_l("l_are_you_sure"));
                        setToastClass("toast-with-btn");
                        setToastMessage(
                          _l("l_you_want_tot_delete_this_task") +
                          deleteConfirmationText +
                          " ?"
                        );
                        setConfirmButtonText(_l("l_delete"));
                        setCancelButtonText(_l("l_cancel"));
                        setShowToast(true);
                        setRequestTaskId(
                          task &&
                            task.request_task_id &&
                            task.request_task_id != 0
                            ? task.request_task_id
                            : 0
                        );
                      }}
                      docType={task_type}
                      task={task}
                      pageName="task"
                      contacts={contacts}
                      allowToShare={true}
                      allowToCall={task.addedfrom > 0 ? true : false}
                      makeCall={makeCall}
                      taskUserAssignees={taskUserAssignees}
                      callListPos={"left"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* chaiAI view start */}
          {fromReply ? (
            <div
              className="task-list-card list-view no-hover bg-transparent border-0 for-disabled p-0 "
              onClick={() => {
                dispatch(commanoffcanvasisOpen(true));
                jquery(".ai-chat-header").removeClass("mt-10");
                if (fromReply) {
                  let element = document.getElementById("task_" + task.id);
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                    setTimeout(() => {
                      jquery(".ai-chat-header").addClass("mt-10");
                    }, 500);
                  }
                }
                handler(task.rel_id, task.id, true, null, task.request_task_id);
              }}
            >
              <div className="task-list-wrapper comman_action_icon base-body-color p-2">
                <div className=" d-flex  w-100 align-items-center">
                  <div className="task-creator-img">
                    <div className="comman-image-box h32w32 rounded-circle">
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                        style={{
                          backgroundImage: `url(${task.creator_profile_image})`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="task-list-name text-start text-truncate py-0 c-font f-14imp">
                    {task.name}
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-center task-bottom-part-full pt-2">
                  <div className="d-flex align-items-center">
                    <div className="bg-white-05 c-font custom-datepicker datepicker-input h32w40 m-auto ms-1 w-90  calendarpadding custom-datepicker d-flex flex-column   h32wauto justify-content-center me-2 text-truncate">
                      <div className="d-flex align-items-center py-1">
                      <CalendarBlankIcon className="HW18" />
                        <div className="">
                          <DatePicker
                            portalId="custom_datepicker"
                            calendarClassName="custom-datepicker w-90"
                            timeCaption={getDatePickerTimeCaption()}
                            selected={
                              task.schedule_id > 0 ||
                                task.rel_type == "equipment" ||
                                task.is_requested == 1
                                ? taskStartDate
                                : taskDueDate
                            }
                            calendarStartDay={1}
                            locale={getDatePickerLocale()}
                            className="close-toast"
                            placeholderText={`${task.schedule_id > 0 ||
                                task.rel_type == "equipment" ||
                                task.is_requested == 1
                                ? _l("l_startdate")
                                : _l("l_duedate")
                              }`}
                            onChange={(date) => {
                              if (showStartDate) {
                                setTaskStartDate(date);
                                handleUpdateDate(date);
                              } else {
                                setTaskDueDate(date);
                                handleUpdateDate("", date);
                              }
                            }}
                            showTimeSelect
                            dateFormat="dd/MM/yyyy"
                            readOnly={
                              task.is_requested === "1"
                                ? true
                                : task.is_allowed_to_edit == 1
                                  ? false
                                  : true
                            }
                          >
                            <div className="datepicker-label">
                              {_l("l_select_date_and_time")}
                            </div>
                          </DatePicker>
                        </div>
                         </div>
                    </div>
                    <div className="white-border-box d-flex align-items-center action_icon h32w32 with_bg me-2">
                      <a
                        href="#/"
                        className={`priority-checkbox text-truncate`}
                      >
                        <div className="check_box">
                          {taskPriority == 1 ? (
                            <HighPriorityActiveIcon className="HW18 check_span_img active" />
                          ) : (
                            <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                          )}
                        </div>
                      </a>
                    </div>
                    <div className="white-border-box d-flex align-items-center action_icon h32w32 with_bg me-2">
                      <a href="#/" className={``}>
                        <div className={`  m-auto `}>
                          {isTaskPinned && isTaskPinned == true ? (
                            <div className="m-auto ">
                              <PushPinFillIcon className="Push-Pin-fill HW16" />
                            </div>
                          ) : (
                            <div className="m-auto">
                              <PushPinIcon className="HW16" />
                            </div>
                          )}
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`white-border-box action_icon h32wauto with_bg me-2 p-2 `}
                    >
                      <a
                        href="#/"
                        className="d-flex align-items-center dropdown dropdown-center"
                      >
                        <span
                          className={`c-dots c-10 rounded-circle ${task.is_requested == 1
                              ? "bg-warning"
                              : task.status == 1
                                ? "in-progress-white"
                                : task.status == 4
                                  ? "in-progress-blue"
                                  : "done"
                            }`}
                        ></span>
                      </a>
                    </div>
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width ps-0 pe-2 d-flex with_minus ${taskAssigneePills && taskAssigneePills.length >= 1
                          ? ""
                          : "d-none"
                        }`}
                    >
                      {taskAssigneePills && taskAssigneePills.length >= 1 ? (
                        taskAssigneePills.map((assignee, index) => {
                          if (index < 1)
                            return (
                              <div
                                className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate"
                                key={index}
                              >
                                <span className={` p-0 `}>
                                  {assignee.main_key == "my_task" ? "#" : ""}
                                  {_l(assignee.name)}
                                </span>
                              </div>
                            );
                        })
                      ) : (
                        <></>
                      )}
                      {taskAssigneePills && taskAssigneePills.length > 1 ? (
                        <a
                          href="#/"
                          className="badge rounded-pill text-nowrap list-toggle-view-none me-1 "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={handleTaskAssigneeData}
                        >
                          <div className="unread_count m-auto c-font f-10">
                            +{taskAssigneePills.length - 1}
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                      {taskAssigneePills && taskAssigneePills.length ? (
                        <GeneralListing
                          handler={() => { }}
                          list={
                            taskAssigneePills &&
                            taskAssigneePills.length &&
                            taskAssigneePills.map((item, index) => {
                              return {
                                name: item.name,
                                id: index,
                              };
                            })
                          }
                          isHash={true}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus before-dnone ${pageName == "ChatAi" || globalView == "AichatView"
                          ? "before-block"
                          : ""
                        }  ${taskAllAssignee && taskAllAssignee.length >= 1
                          ? ""
                          : "d-none"
                        }`}
                    >
                      {taskAllAssignee && taskAllAssignee.length >= 1 ? (
                        taskAllAssignee.map((assignee, index) => {
                          if (index < 1)
                            return (
                              <a
                                href="#/"
                                data-bs-toggle="tooltip"
                                className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                key={index}
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${assignee.user_image ||
                                      assignee.profile_image
                                      }')`,
                                  }}
                                ></div>
                              </a>
                            );
                        })
                      ) : (
                        <></>
                      )}
                      {taskAllAssignee && taskAllAssignee.length > 1 ? (
                        <a
                          href="#/"
                          className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={handleTaskAssigneeData}
                        >
                          <div className="unread_count m-auto c-font f-12">
                            +{taskAllAssignee.length - 1}
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                      {taskAllAssignee && taskAllAssignee.length ? (
                        <GeneralListing
                          handler={() => { }}
                          list={
                            taskAllAssignee &&
                            taskAllAssignee.length &&
                            taskAllAssignee.map((item, index) => {
                              return {
                                name: item.full_name || item.name,
                                image: item.user_image || item.profile_image,
                                id: item.id,
                              };
                            })
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={` ${fromReply
                    ? " align-items-center base-body-color justify-content-between overflow-hidden radius_3 fst-italic"
                    : "w100minus30 chat-msg"
                  }`}
              >
                <div
                  className={`chat-text-box with-media with-task-card ${fromReply
                      ? "for-disabled base-body-color max-w320 w-auto"
                      : ""
                    } ${pageName == "ChatAi" || globalView == "AichatView"
                      ? "p-2 pb-0 w-100 "
                      : ""
                    } ${moment(taskTime).isAfter(moment(currentTime))
                      ? "opacity-50"
                      : "opacity-100"
                    } ${active ? "active z-index-7" : ""}`}
                >
                  <div
                    className={`task-list-card list-view ${pageName == "ChatAi" || globalView == "AichatView"
                        ? "aichatlist d-inline"
                        : ""
                      } `}
                  >
                    <div className="task-list-wrapper">
                      <div className="d-flex w-100 align-items-start">
                        <div className="d-flex task-list-detail">
                          <div className="task-left flex-grow-1 width100minus160">
                            <div className="d-flex align-items-center w-100 task-left-part-toggle">
                              {hideclass ? (
                                <></>
                              ) : (
                                <div className="task-creator-img">
                                  <div
                                    className={`h20w20 d-flex align-items-center me-2`}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      className="m-auto"
                                    />
                                  </div>
                                </div>
                              )}
                              <div
                                className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task"
                                onClick={() => {
                                  dispatch(commanoffcanvasisOpen(true));
                                  handler(
                                    task.rel_id,
                                    task.id,
                                    true,
                                    null,
                                    task.request_task_id
                                  );
                                }}
                              >
                                <div className=" d-flex  w-100 align-items-center">
                                  <div className="task-creator-img">
                                    <div className="comman-image-box h32w32 rounded-circle charcirclenewcolor">
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                        style={{
                                          backgroundImage: `url(${task.creator_profile_image})`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <a
                                    href="#/"
                                    className={`task-list-name text-start text-truncate ${pageName == "ChatAi" ||
                                        globalView == "AichatView"
                                        ? "py-0 c-font f-14imp"
                                        : ""
                                      } `}
                                  >
                                    {task.name}
                                  </a>
                                </div>
                              </div>
                              <div
                                className={`d-flex align-items-center task-bottom-part-full ${pageName == "ChatAi" ||
                                    globalView == "AichatView"
                                    ? "pt-2"
                                    : ""
                                  }`}
                              >
                                <div className="task-right-static-options">
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center multi_inner_wrapper justify-content-end w-120 w-auto ">
                                      <div
                                        className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}
                                      >
                                        <div className="dropdown-center multi_inner_wrapper with-min-width d-flex with_minus  ">
                                          <>
                                            {task.schedule_id > 0 &&
                                              taskProviderTitle &&
                                              taskProviderTitle != "" ? (
                                              <div className="badge rounded-pill text-nowrap list-toggle-view-none m-w80px text-truncate">
                                                <span className={` p-0 `}>
                                                  {"#"}
                                                  {taskProviderTitle}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {task.rel_type == "equipment" ? (
                                              <>
                                                {task.surface > 0 ? (
                                                  <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate">
                                                    <span className={` p-0 `}>
                                                      {task.surface}m
                                                      <sup>2</sup>
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {task.duration > 0 ? (
                                                  <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate">
                                                    <span className={` p-0`}>
                                                      {task.duration}hrs
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <div
                                              className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}
                                            >
                                              <div
                                                className={`dropdown-center multi_inner_wrapper with-min-width pe-2 d-flex with_separator_10 with_minus ${taskAssigneePills &&
                                                    taskAssigneePills.length >= 1
                                                    ? ""
                                                    : "d-none"
                                                  }`}
                                              >
                                                {taskAssigneePills &&
                                                  taskAssigneePills.length >=
                                                  1 ? (
                                                  taskAssigneePills.map(
                                                    (assignee, index) => {
                                                      if (index < 1)
                                                        return (
                                                          <div
                                                            className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate"
                                                            key={index}
                                                          >
                                                            <span
                                                              className={` p-0 `}
                                                            >
                                                              {assignee.main_key ==
                                                                "my_task"
                                                                ? "#"
                                                                : ""}
                                                              {_l(
                                                                assignee.name
                                                              )}
                                                            </span>
                                                          </div>
                                                        );
                                                    }
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAssigneePills &&
                                                  taskAssigneePills.length > 1 ? (
                                                  <a
                                                    href="#/"
                                                    className="badge rounded-pill text-nowrap list-toggle-view-none me-1 "
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    onClick={
                                                      handleTaskAssigneeData
                                                    }
                                                  >
                                                    <div className="unread_count m-auto c-font f-10">
                                                      +
                                                      {taskAssigneePills.length -
                                                        1}
                                                    </div>
                                                  </a>
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAssigneePills &&
                                                  taskAssigneePills.length ? (
                                                  <GeneralListing
                                                    handler={() => { }}
                                                    list={
                                                      taskAssigneePills &&
                                                      taskAssigneePills.length &&
                                                      taskAssigneePills.map(
                                                        (item, index) => {
                                                          return {
                                                            name: item.name,
                                                            id: index,
                                                          };
                                                        }
                                                      )
                                                    }
                                                    isHash={true}
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              <div
                                                className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator_10 with_minus before-dnone ${pageName == "ChatAi" ||
                                                    globalView == "AichatView"
                                                    ? "before-block"
                                                    : ""
                                                  }  ${taskAllAssignee &&
                                                    taskAllAssignee.length >= 1
                                                    ? ""
                                                    : "d-none"
                                                  }`}
                                              >
                                                {taskAllAssignee &&
                                                  taskAllAssignee.length >= 1 ? (
                                                  taskAllAssignee.map(
                                                    (assignee, index) => {
                                                      if (index < 1)
                                                        return (
                                                          <a
                                                            href="#/"
                                                            data-bs-toggle="tooltip"
                                                            className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                                            key={index}
                                                          >
                                                            <div
                                                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                              style={{
                                                                backgroundImage: `url('${assignee.user_image ||
                                                                  assignee.profile_image
                                                                  }')`,
                                                              }}
                                                            ></div>
                                                          </a>
                                                        );
                                                    }
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAllAssignee &&
                                                  taskAllAssignee.length > 1 ? (
                                                  <a
                                                    href="#/"
                                                    className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    onClick={
                                                      handleTaskAssigneeData
                                                    }
                                                  >
                                                    <div className="unread_count m-auto c-font f-12">
                                                      +
                                                      {taskAllAssignee.length -
                                                        1}
                                                    </div>
                                                  </a>
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAllAssignee &&
                                                  taskAllAssignee.length ? (
                                                  <GeneralListing
                                                    handler={() => { }}
                                                    list={
                                                      taskAllAssignee &&
                                                      taskAllAssignee.length &&
                                                      taskAllAssignee.map(
                                                        (item, index) => {
                                                          return {
                                                            name:
                                                              item.full_name ||
                                                              item.name,
                                                            image:
                                                              item.user_image ||
                                                              item.profile_image,
                                                            id: item.id,
                                                          };
                                                        }
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                      <div
                                        className={`comman_action_icon card-view-none  ps-0  ${pageName == "ChatAi" ||
                                            globalView == "AichatView"
                                            ? ""
                                            : "with_separator_10"
                                          } ${task.schedule_id == 0 &&
                                            task.is_allowed_to_edit > 0
                                            ? ""
                                            : task.schedule_id > 0 &&
                                              userType == "operator" &&
                                              contact_role != "3"
                                              ? ""
                                              : "for-disabled"
                                          }`}
                                      >
                                        <div className="d-flex align-items-center">
                                          <div className="dropup dropup-center">
                                            <div className="w-200 dropdown dropend">
                                              <UncontrolledDropdown
                                                setActiveFromChild={true}
                                                isOpen={openAssigneeDropdown}
                                                direction="end"
                                                className="w-200"
                                              >
                                                <DropdownToggle
                                                  id="AddAssigneeMainDropdownNew"
                                                  className="border-0  ms-10px p-0"
                                                  onClick={() =>
                                                    setOpenAssigneeDropdown(
                                                      !openAssigneeDropdown
                                                    )
                                                  }
                                                >
                                                  <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img h32w32">
                                                  <UserCircleGearIcon className="HW18" />
                                                  </div>
                                                </DropdownToggle>
                                              <DropdownItem toggle={false} className="p-0">
                                                {openAssigneeDropdown ==
                                                  true ? (
                                                  <AddAssigneeMainDropdownNew
                                                    key={task.id}
                                                    projectId={project_id}
                                                    taskId={task.id}
                                                    openAssigneeDropdown={
                                                      openAssigneeDropdown
                                                    }
                                                    setOpenAssigneeDropdown={
                                                      setOpenAssigneeDropdown
                                                    }
                                                    fromTaskcard="1"
                                                    selectedTagId={
                                                      selectedTagId
                                                    }
                                                    pageName="task"
                                                    selectedId={
                                                      selectedTaskAssigneesId
                                                    }
                                                    isRequest={
                                                      task.is_requested == 1
                                                    }
                                                    saveBtnToggle={true}
                                                    selectedAssignee={
                                                      selectedAssigneeNew
                                                    }
                                                    setSelectedAssignee={
                                                      setSelectedAssigneeNew
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                </DropdownItem>
                                              </UncontrolledDropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={` white-border-box p-0 d-flex list-toggle-view-box position-relative ${commanoffcanvasIsOpen
                                          ? "bg-white-05 border-0 h32w32"
                                          : "h32wauto"
                                        }`}
                                    >
                                      <a
                                        href="#/"
                                        className={`custom-datepicker datepicker-input w-90 m-auto ms-1 c-font ${fromReply ? "fst-italic" : ""
                                          } ${commanoffcanvasIsOpen
                                            ? "withinputabsolute bg-white-05"
                                            : ""
                                          }`}
                                        data-bs-toggle="tooltip"
                                      >
                                        <div className="d-flex align-items-center">
                                          <div className="h20w20">
                                          <CalendarBlankIcon className="HW18" />
                                          </div>
                                          <DatePicker
                                            portalId="custom_datepicker"
                                            calendarClassName="custom-datepicker"
                                            timeCaption={getDatePickerTimeCaption()}
                                            selected={
                                              task.schedule_id > 0 ||
                                                task.rel_type == "equipment" ||
                                                task.is_requested == 1
                                                ? taskStartDate
                                                : taskDueDate
                                            }
                                            calendarStartDay={1}
                                            locale={getDatePickerLocale()}
                                            className="close-toast"
                                            placeholderText={`${task.schedule_id > 0 ||
                                                task.rel_type == "equipment" ||
                                                task.is_requested == 1
                                                ? _l("l_startdate")
                                                : _l("l_duedate")
                                              }`}
                                            onChange={(date) => {
                                              if (showStartDate) {
                                                setTaskStartDate(date);
                                                handleUpdateDate(date);
                                              } else {
                                                setTaskDueDate(date);
                                                handleUpdateDate("", date);
                                              }
                                            }}
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy hh:mm a"
                                            readOnly={
                                              task.is_requested === "1"
                                                ? true
                                                : task.is_allowed_to_edit == 1
                                                  ? false
                                                  : true
                                            }
                                          >
                                            <div className="datepicker-label">
                                              {_l("l_select_date_and_time")}
                                            </div>
                                          </DatePicker>
                                        </div>
                                      </a>
                                    </div>
                                    <div
                                      className={` dropdown dropdown-center comman_action_icon card-view-less-padding p-0 ms-10px ${task &&
                                          !(
                                            userType == "operator" &&
                                            task.schedule_id > 0
                                          ) &&
                                          ((parseInt(task.is_allowed_to_edit) >
                                            0 &&
                                            task.is_requested == 0) ||
                                            (parseInt(task.request_task_id) ==
                                              0 &&
                                              task.is_requested == 1))
                                          ? ""
                                          : "for-disabled"
                                        }`}
                                    >
                                      <div
                                        className={`white-border-box me-0 color-white-60 action_icon with_bg  d-flex close-toast h32w32`}
                                        type="button"
                                        id="TaskListStatusDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <div className="d-flex align-items-center m-auto">
                                          <span
                                            className={`c-dots c-10 ${task.is_requested == 1
                                                ? "bg-warning"
                                                : task.status == 1
                                                  ? "in-progress-white"
                                                  : task.status == 4
                                                    ? "in-progress-blue"
                                                    : "done"
                                              } rounded-circle`}
                                          ></span>
                                          {(task &&
                                            parseInt(task.is_allowed_to_edit) >
                                            0 &&
                                            task.is_requested == 0) ||
                                            (task &&
                                              parseInt(task.request_task_id) ==
                                              0 &&
                                              task.is_requested == 1) ? (
                                            <TaskListStatusDropdown
                                              setTaskStatus={changeTaskStatus}
                                              is_task={1}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`${isLiteVersion ? " " : ""
                                        } comman_action_icon dropdown-center dropdown ms-10px text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                                    >
                                      <UncontrolledDropdown className="w-100">
                                        <DropdownToggle
                                          data-toggle="dropdown"
                                          tag="span"
                                          className="comman_action_icon w-100 d-flex close-toast border-0 ps-0"
                                        >
                                          <a
                                            href="#/"
                                            onClick={(e) => {
                                              getMentionlist(task.id);
                                              getTaskComments();
                                            }}
                                            className="on-hover-active-toggle-img text-on-hover-active-green hovertextgreen"
                                          >
                                            <div className="d-flex align-items-center w-100">
                                              <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                                <ChatCircleIcons className="HW18" />
                                                <span
                                                  className={`rounded-pill badge-custom p-0 ${unreadCommentCount.current ==
                                                      0 ||
                                                      unreadCommentCount.current ==
                                                      undefined ||
                                                      unreadCommentCount.current ==
                                                      null ||
                                                      unreadCommentCount.current ==
                                                      NaN
                                                      ? "d-none"
                                                      : ""
                                                    }`}
                                                  data-unread-cmt-id={task.id}
                                                >
                                                  {unreadCommentCount.current}
                                                </span>
                                              </div>
                                              <span
                                                className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                                data-cmt-cnt-id={task.id}
                                              >
                                                {Number(updatedCommentCount) > 0
                                                  ? updatedCommentCount
                                                  : 0}
                                              </span>
                                            </div>
                                          </a>
                                        </DropdownToggle>
                                        <DropdownItem
                                          toggle={false}
                                          className="p-0"
                                        >
                                          {
                                            <CommentMinified
                                              comments={commentsList}
                                              disabled={
                                                task &&
                                                  parseInt(task.request_task_id) >
                                                  0 &&
                                                  task.is_requested == 1
                                                  ? true
                                                  : false
                                              }
                                              handleAddComment={
                                                handleAddEditComment
                                              }
                                              deleteComment={
                                                handleDeleteComment
                                              }
                                              id={task.id}
                                              task={task}
                                              loading={commentsLoading}
                                              mentionList={mentionsList.map(
                                                (name) => {
                                                  let id =
                                                    name.is_team &&
                                                      name.is_team == 1
                                                      ? "-" + name.id
                                                      : name.id;
                                                  return {
                                                    display: name.name,
                                                    id: id,
                                                    image:
                                                      name.profile_image || "",
                                                    isteam: name.is_team
                                                      ? 1
                                                      : 0,
                                                  };
                                                }
                                              )}
                                              handleImagePreview={
                                                handleImagePreview
                                              }
                                              unContolList={true}
                                              totalUnreadCommentCount={
                                                task.unread_comment_count
                                              }
                                              showComments={showComments}
                                              fileLoader={fileUploadLoader}
                                            />
                                          }
                                        </DropdownItem>
                                      </UncontrolledDropdown>
                                    </div>
                                    <div className="comman_action_icon card-view-less-padding p-0 ms-10px">
                                      <div
                                        className={`priority-checkbox span-13 white-border-box action_icon with_bg close-toast h32w32`}
                                      >
                                        <input
                                          type="checkbox"
                                          id="priority"
                                          className="absolute-input"
                                          checked={
                                            taskPriority == 3 ? true : false
                                          }
                                          onChange={(e) => {
                                            changeTaskPriority(e);
                                          }}
                                        />
                                        <div className="check_box">
                                          <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                          <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="task-assign-section">
                            {task.parent_id > 0 ?
                              <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1 mb-10px">
                                <span className=" p-0 ">{_l("l_ai_task")}</span>
                              </div> : <></>}
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="before-dnone comman_action_icon with_separator_10 ps-1 pe-0">
                                <a
                                  href="#/"
                                  className="action_icon with_bg light-theme-img close-toast h32w32"
                                >
                                  {isTaskPinned && isTaskPinned == true ? (
                                    <div
                                      className="m-auto "
                                      onClick={() => {
                                        pinTask();
                                      }}
                                    >
                                      <PushPinFillIcon className="Push-Pin-fill HW18" />
                                    </div>
                                  ) : (
                                    <div
                                      className="m-auto"
                                      onClick={() => {
                                        pinTask();
                                      }}
                                    >
                                      <PushPinIcon className="HW18" />
                                    </div>
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {task && task.attachments && task.attachments.length > 0 ? (
                    <hr className={`w-7 my-2`} />
                  ) : (
                    <></>
                  )}
                  <div
                    className={`row image-upload-wrapper multi_inner_wrapper g-3 mb-2 px-1 ${task && task.attachments && task.attachments.length > 0
                        ? ""
                        : "d-none"
                      }`}
                  >
                    {task &&
                      task.attachments &&
                      task.attachments.length > 0 &&
                      task.attachments.map((img, index) => {
                        if (index < 3) {
                          return (
                            <>
                              <div className="col-lg-3 px-1">
                                <a
                                  href="#/"
                                  className="align-items-center  comman-round-box d-flex h50px radius_3 position-relative"
                                  onClick={() => {
                                    setShowTaskImage(true);
                                    setAttachmentDetails(img);
                                  }}
                                >
                                  <div
                                    className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                    style={{
                                      backgroundImage: `url('${img.image_url}')`,
                                    }}
                                  ></div>
                                </a>
                              </div>{" "}
                            </>
                          );
                        } else if (index == 3) {
                          return (
                            <>
                              {task.attachments.length < 5 ? (
                                <div className="col-lg-3 px-1">
                                  <a
                                    href="#/"
                                    className="align-items-center  comman-round-box d-flex h50px radius_3 position-relative "
                                    onClick={() => {
                                      setShowTaskImage(true);
                                      setAttachmentDetails(img);
                                    }}
                                  >
                                    <div
                                      className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                      style={{
                                        backgroundImage: `url('${img.image_url}')`,
                                      }}
                                    ></div>
                                  </a>
                                </div>
                              ) : (
                                <>
                                  <div className="col-lg-3 px-1">
                                    <a
                                      href="#/"
                                      className="align-items-center  comman-round-box d-flex h50px radius_3 position-relative with-fullimg-overlay"
                                      onClick={() => {
                                        setShowTaskImage(true);
                                        setAttachmentDetails(img);
                                      }}
                                    >
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                        style={{
                                          backgroundImage: `url('${img.image_url}')`,
                                        }}
                                      ></div>
                                      <div class="absolute fs-5 text-white c-font f-16 unread_count">
                                        {"+" + (task.attachments.length - 3)}
                                      </div>
                                    </a>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        }
                      })}
                  </div>
                  <div className="taskListing mb-2">
                    {task &&
                      task.comments &&
                      task.comments.length > 0 &&
                      task.comments.slice(0, 4).map((comment, index) => {
                        return (
                          <>
                            <div className="d-flex align-items-center mt-2 aichatTaskListing">
                              <div className="d-flex align-items-center">
                                <div
                                  className={`c-dots c-7 rounded-circle ${task.is_requested == 1
                                      ? "bg-warning"
                                      : task.status == 1
                                        ? "in-progress-white"
                                        : task.status == 4
                                          ? "in-progress-blue"
                                          : "done"
                                    }`}
                                ></div>
                                <div className="c-font f-10 mx-2 title-fonts">
                                  {moment(comment.dateadded).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </div>
                              </div>
                              <div className="c-font f-10 text-truncate w100minus100per text-start">
                                {ReactHtmlParser(
                                  comment.content &&
                                  comment.content.replace(/(<([^>]+)>)/gi, "")
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  {fromReply ? (
                    <></>
                  ) : (
                    <UncontrolledDropdown
                      setActiveFromChild={true}
                      isOpen={showOptions == task.id}
                      direction="end"
                      className={`dropdown dropdown-center chat-action-option dropup`}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          setshowOptions(0);
                        }, 500);
                      }}
                    >
                      <DropdownToggle
                        id={`taskOptions`}
                        type="button"
                        className={`border-0 line-height-0  p-0 d-flex align-items-center`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        onClick={() => {
                          if (showOptions == task.id) {
                            setshowOptions(0);
                          } else {
                            setshowOptions(task.id);
                          }
                          dispatch(IsToastOpenOrClose(false));
                          setTask_type(
                            task.is_requested && task.is_requested == 1
                              ? "request-task"
                              : "task"
                          );
                        }}
                      >
                        <DotsThreeVerticalIcon className="HW16" />
                      </DropdownToggle>
                      <DropdownItem toggle={false} className="p-0">
                      <DeleteDropdown
                        edit={false}
                        widthClass="w-140"
                        handleDelete={() => {
                          resetToast();
                          dispatch(IsToastOpenOrClose(true));
                          setShowToast(false);
                          let deleteConfirmationText =
                            task && task.request_task_id > 0
                              ? task.is_requested == 1
                                ? _l("l_and_converted_task")
                                : _l("l_and_request_task")
                              : "";
                          setToastHeader(_l("l_are_you_sure"));
                          setToastClass("toast-with-btn");
                          setToastMessage(
                            _l("l_you_want_tot_delete_this_task") +
                            deleteConfirmationText +
                            " ?"
                          );
                          setConfirmButtonText(_l("l_delete"));
                          setCancelButtonText(_l("l_cancel"));
                          setShowToast(true);
                          setRequestTaskId(
                            task &&
                              task.request_task_id &&
                              task.request_task_id != 0
                              ? task.request_task_id
                              : 0
                          );
                        }}
                        docType={task_type}
                        task={task}
                        pageName="task"
                        contacts={contacts}
                        allowToShare={true}
                        allowToCall={task.addedfrom > 0 ? true : false}
                        makeCall={makeCall}
                        taskUserAssignees={taskUserAssignees}
                        callListPos={"left"}
                        initReply={initReply}
                        newEditId={
                          msgid != ""
                            ? msgid
                            : task &&
                              task.request_task_id &&
                              task.request_task_id != 0
                              ? task.request_task_id
                              : task.id
                        }
                        unreadMessage={true}
                        setUnreadMessage={setUnreadMessage}
                      />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  )}
                </div>
                {fromReply ? (
                  <></>
                ) : (
                  <div className="chat-send-name c-font f-10 color-white-60">
                    <div className="d-flex align-items-center">
                      <span className="with_separator_10 ps-0 pe-2">
                        {"Myr.ai"}
                      </span>
                      <span className="with_separator_10 ps-2 pe-0">
                        {moment(
                          task.startdate
                            ? task.startdate
                            : task.converted_date
                              ? task.converted_date
                              : task.duedate
                        ).format("YYYY-MM-DD HH:mm")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {/* chaiAI view end */}
        </>
      )}
      {showMapModal ? (
        <EditTaskModal
          show={showMapModal}
          handleClose={() => {
            setShowMapModal(false);
          }}
          task_id={task.id}
          surface={task.surface}
          taskDuration={task.duration}
          attachment={task.map_attachment_url}
        />
      ) : (
        <></>
      )}
      {showMapInfoModal ? (
        <RobotPlanDetailModal
          show={showMapInfoModal}
          handleClose={() => {
            setShowMapInfoModal(false);
          }}
          handleOpenEdit={() => {
            setShowMapModal(true);
          }}
          surface={task.surface}
          duration={task.duration}
          attachment={task.map_attachment_url}
        />
      ) : (
        <></>
      )}
      {showImagePreview ? (
        <ImageViewer
          CloseCommentModal={closePreview}
          attachmentDate={attachments.date}
          attachmentURL={
            attachments.file_array[attachments.currentIndex].file_url
          }
          changeAttach={changeAttachment}
          handleAction={() => { }}
          isChat={false}
          showNextBtn={attachments.file_array.length > 0}
          showPrevBtn={attachments.file_array.length > 0}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {showTaskImage && Object.keys(attachmentDetails).length > 0 ? (
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: task.id,
            projectId: project_id,
            attachmentDetails: attachmentDetails,
            taskDetails: task,
            allAttachments: task.attachments,
          }}
          docType={"imageview"}
          selectedTaskId={task.id}
          pageName={pageName}
          handleParentComponentAction={() => { }}
          isCommentsection={false}
          setSelectedId={setSelectedAttachmentId}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
});
export default TaskCard;
